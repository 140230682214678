import { SelectItem } from 'utils/types';
import { FilterItem } from 'utils/types/FilterItem';
import { isValidDate } from '.';

const setFilters: (
  filterKey: string,
  filters: FilterItem<any>[]
) => void = (
  filterKey,
  filters
) => {
  if (!filterKey) {
    return;
  }
  localStorage.setItem(`filters_${filterKey}`, JSON.stringify(filters));
};

const getFilters: (
  filterKey: string
) => FilterItem<any>[] = (
  filterKey
) => {
  if (!filterKey) {
    return null;
  }
  const savedFilters = localStorage.getItem(`filters_${filterKey}`);
  if (savedFilters) {
    return JSON.parse(localStorage.getItem(`filters_${filterKey}`));
  }
  return null;
};

const deleteFilters: (
  filterKey: string
) => void = (
  filterKey
) => {
  if (!filterKey) {
    return;
  }
  localStorage.removeItem(`filters_${filterKey}`);
};

const setFiltersValue: (
  filters: FilterItem<any>[],
  key: string,
  value: any,
  label: string
) => FilterItem<any>[] = (
  filters,
  key,
  value,
  label
) => {
  if (typeof value === 'undefined' || value === null) {
    return filters.filter(filt => filt.key !== key);
  }
  return [
    ...filters.filter(filt => filt.key !== key),
    {
      key,
      label,
      stringValue: typeof value === 'string' ? value : undefined,
      numberValue: typeof value === 'number' ? value : undefined,
      booleanValue: typeof value === 'boolean' ? value : undefined,
      dateValue: isValidDate(value) ? value : undefined,
      itemValue: value?.value ? value : undefined,
      listValue: (Array.isArray(value) && value.length > 0) ? value : undefined
    }
  ];
};

const getFilterValue: (
  filter: FilterItem<any>
) => any = (
  filter
) => {
  if (!filter) {
    return null;
  }
  if (typeof filter.stringValue !== 'undefined') {
    // Cleaning old filter values
    return filter.stringValue.replace('CURRENT_', '');
  }
  if (typeof filter.numberValue !== 'undefined') {
    return filter.numberValue;
  }
  if (typeof filter.booleanValue !== 'undefined') {
    return filter.booleanValue;
  }
  if (typeof filter.dateValue !== 'undefined') {
    return filter.dateValue;
  }
  if (typeof filter.itemValue !== 'undefined') {
    return filter.itemValue;
  }
  if (typeof filter.listValue !== 'undefined') {
    return filter.listValue;
  }
  return null;
};

const getFiltersValue: (
  filters: FilterItem<any>[],
  key: string
) => any = (
  filters,
  key
) => {
  const filter = filters && filters.find(f => f.key === key);
  return getFilterValue(filter);
};

const getFiltersLabel: (
  filters: FilterItem<any>[],
  key: string
) => string = (
  filters,
  key
) => {
  const filter = filters && filters.find(f => f.key === key);
  return filter?.label;
};

const removeFiltersValue: (
  filters: FilterItem<any>[],
  key: string,
  itemValue?: string
) => FilterItem<any>[] = (
  filters,
  key,
  itemValue
) => {
  // If no item value is specified, remove the whole entry
  if (!itemValue) {
    return filters.filter(f => f.key !== key);
  }
  // If the entry isn't a list, remove the entry
  const currentList: SelectItem[] = getFiltersValue(filters, key);
  if (!Array.isArray(currentList)) {
    return filters.filter(f => f.key !== key);
  }
  // Else, replace the entry by removing the specified value
  const newList = currentList.filter(i => i.value !== itemValue);
  // If the updated list is empty, remove the entry
  if (!newList || newList.length === 0) {
    return filters.filter(f => f.key !== key);
  }
  return [
    ...filters.map(f => {
      if (f.key !== key) {
        return f;
      }
      return {
        ...f,
        listValue: newList
      };
    })
  ];
};

const buildFilterForm: (
  filters: FilterItem<any>[],
  freeSearch: string
) => {[prop: string]: any} = (
  filters,
  freeSearch
) => {
  if (!filters || filters.length === 0) {
    return { freeSearch };
  }
  return filters.reduce((form, filter) => {
    form[filter.key] = getFilterValue(filter);
    return form;
  }, { freeSearch });
};

export const FilterHelper = {
  setFilters,
  getFilters,
  deleteFilters,
  setFiltersValue,
  getFiltersValue,
  getFiltersLabel,
  removeFiltersValue,
  buildFilterForm
};
