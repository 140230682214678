import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';

import {
  faBuilding, faTimes, faUserCircle, faUsersCog
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider, Drawer, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { UsersService } from 'services';
import {
  APPLICATION_ROLES, APP_VERSION, CREATED, ENVIRONMENT, REVISION, ROUTES,
  STOCK_ROLES_WITH_INTERVENTION_MANAGER
} from 'utils/constants';
import { UserHelper, translate } from 'utils/helpers';

import FooterSupralog from 'components/Footer/FooterSupralog';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { Logout } from './Logout';

const SideMenuContainer = styled.div`
  display: flex;
  flex: 1;

  a {
    padding-left: 2.6rem;
    padding-right: 2.6rem;

    &:hover {
      svg {
        color: var(--blue);
      }
    }
  }

  ul {
    width: 100%;
  }
`;

const CompanyImg = styled.div`
  height: 100px;
  background: url('${props => props.image}') no-repeat center center;
  background-size: 60%;
`;

export const SideMenu = observer(({
  isOpen, setToggleMenu
}) => {
  const { userStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const { open } = useModal();
  const history = useHistory();

  const handleUpdateLevel = (selectedLevel) => {
    // Only update if the level is different from the current one
    if (userStore.currentLevel && userStore.currentLevel.value !== selectedLevel.value) {
      UsersService.updateLevel(selectedLevel).then(() => {
        history.push('/accueil');
        userStore.keycloak.updateToken(999999)
          .then((refreshed) => {
            refreshed && userStore.keycloak.login();
          })
          .catch(() => enqueueSnackbar(translate('errors.noRefreshToken'), { variant: 'error' }));
      });
    }
  };

  const displayChangeLevel = () => {
    setToggleMenu(false);
    open({
      type: 'UPDATE_USER_LEVEL',
      defaultLevel: userStore.currentLevel,
      levelOptions: userStore.accessibleLevels,
      onConfirm: handleUpdateLevel
    });
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={() => setToggleMenu(false)}>
      <Grid alignItems="flex-start" container>
        <IconButton onClick={() => setToggleMenu(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </Grid>
      <CompanyImg image={`${process.env.PUBLIC_URL}/assets/images/icons/companies/${userStore.currentUser?.person.companyName || 'DEFAULT'}.jpg`} />
      <SideMenuContainer>
        <List>
          <ListItem
            button
            component={Link}
            data-cy="headerMenuUser"
            to="/utilisateurs/current"
            onClick={() => setToggleMenu(false)}
          >
            <ListItemIcon><FontAwesomeIcon icon={faUserCircle} size="lg" /></ListItemIcon>
            <ListItemText primary={translate('menu.profil')} />
          </ListItem>
          <ListItem
            button
            component={Link}
            data-cy="headerMenuChangeLevel"
            to=""
            onClick={displayChangeLevel}
          >
            <ListItemIcon><FontAwesomeIcon icon={faBuilding} size="lg" /></ListItemIcon>
            <ListItemText primary={translate('menu.changeLevel')} />
          </ListItem>
          {UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER) && (
            <ListItem
              button
              component={Link}
              data-cy="headerMenuAdministration"
              to={UserHelper.hasAccessRight([APPLICATION_ROLES.DISTRIBUTOR_ADMIN]) ? ROUTES.ADMINISTRATION_SUPPLIERS_RECIPIENTS : ROUTES.ADMINISTRATION}
              onClick={() => setToggleMenu(false)}
            >
              <ListItemIcon><FontAwesomeIcon icon={faUsersCog} size="lg" /></ListItemIcon>
              <ListItemText primary={translate('menu.admin')} />
            </ListItem>
          )}
          <Divider />
          <Logout />
        </List>
      </SideMenuContainer>

      <Typography align="center">
        {`${ENVIRONMENT}${ENVIRONMENT !== 'PROD' ? ` - ${APP_VERSION}` : ''}`}
        <br />
        <Tooltip interactive leaveDelay={1000} title={REVISION}>
          <Typography component="span">
            {`Révision: ${REVISION.substring(0, 5)}`}
          </Typography>
        </Tooltip>
        <br />
        {`Créé le: ${format(parseISO(CREATED), 'dd/MM/yyyy à HH:mm:ss')}`}
      </Typography>

      <FooterSupralog borderPosition="top" />
    </Drawer>
  );
});
