import { Grid } from '@material-ui/core';

import { FormElement, SelectField } from 'components';
import {
  StructureLevelSelectOption,
  StructureLevelSelectValue
} from 'components/Form/ItemOption/StructureLevelSelectOption';

import {
  isEmpty, isEqual, unionWith, uniqWith
} from 'lodash';
import { groupBy } from 'lodash/collection';
import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'utils/helpers';
import { components } from 'react-select';

export const MultiValueRemove = (removeProps) => {
  const clearableValue = removeProps?.data?.clearableValue;
  return clearableValue && (
    <components.MultiValueRemove {...removeProps} />
  );
};

const ActivityRoleSelector = ({
  handleChangeLevel,
  defaultLevel,
  handleChangeLevelRoles,
  defaultLevelRoles,
  accessibleLevels,
  accessibleRoles,
  selectedLevels,
  isClearable,
  isLevelDisabled
}) => {
  const [level, setLevel] = useState(defaultLevel);
  const [levelRoles, setLevelRoles] = useState(defaultLevelRoles?.map(
    (value) => ({
      ...value,
      clearableValue: accessibleRoles?.map((role) => role.value).includes(value.value)
    })
  ));

  const compareRoles = ((role1, role2) => {
    if (role1.clearableValue && !role2.clearableValue) {
      return 1;
    }
    if (!role1.clearableValue && role2.clearableValue) {
      return -1;
    }
    return 1;
  });

  const updateLevelRoles = roles => {
    const newRoles = roles ? roles.map(role => ({
      ...role,
      clearableValue: role.clearableValue ?? accessibleRoles?.map((r) => r.value).includes(role.value),
      structureLevel: level
    })) : [];
    setLevelRoles(newRoles);
    handleChangeLevelRoles(newRoles);
  };

  useEffect(() => {
    if (!isEmpty(levelRoles)) {
      updateLevelRoles(levelRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  const roleAllowedForLevelActivities = role => (
    !level?.activity || role.activities?.map(activity => activity.value).includes(level.activity.value)
  );

  const roleOptions = accessibleRoles?.filter(roleAllowedForLevelActivities);

  return (
    <>
      <SelectField
        components={{ Option: StructureLevelSelectOption, SingleValue: StructureLevelSelectValue }}
        isClearable={!isEmpty(level) && isClearable}
        isDisabled={level.value && (!accessibleLevels.find(al => al.value === level.value) || isLevelDisabled)}
        label="modalUserUpdate.selectStructureLevel"
        name="structure-level-selector"
        options={accessibleLevels.map((option) => {
          if (selectedLevels.some((selectedLevel) => selectedLevel.value === option.value)) {
            return {
              ...option,
              isDisabled: true
            };
          }
          return option;
        })}
        required={false}
        value={level}
        onChange={value => {
          setLevel(value);
          handleChangeLevel(value);
        }}
      />

      {!isEmpty(level)
        && (
          <div style={{ marginLeft: 20, marginBottom: 20 }}>
            <SelectField
              closeMenuOnSelect={roleOptions?.filter(option => !levelRoles
                .map(lr => lr.value)
                .includes(option.value))?.length === 1}
              components={{ MultiValueRemove }}
              isDisabled={!accessibleLevels.find(al => al.value === level.value)}
              isMulti
              isOptionDisabled={(option) => !accessibleRoles.includes(option)}
              label="modalUserUpdate.rolesForStructureLevel"
              name="activity-roles"
              options={roleOptions}
              value={levelRoles.sort((role1, role2) => compareRoles(role1, role2))}
              onChange={updateLevelRoles}
            />
          </div>
        )}
    </>
  );
};

export const UpdateRolesForm = ({
 accessibleRolesByStructureLevel, formState, initialRoles
}) => {
  const getAvailableStructureLevels = useCallback(
    () => Array.from(accessibleRolesByStructureLevel.keys()),
    [accessibleRolesByStructureLevel]
  );
  const getAvailableStructureLevel = useCallback(
    (structureLevel) => getAvailableStructureLevels()
      .find(
        (structureLevelOption) => structureLevelOption?.value === (
          structureLevel?.value
            ? structureLevel.value
            : null
        ) // The structure level with "null" as value is the key to global roles in map
      ),
    [getAvailableStructureLevels]
  );
  const getAvailableRolesByLevel = useCallback(
    (structureLevel) => accessibleRolesByStructureLevel.get(getAvailableStructureLevel(structureLevel)),
    [accessibleRolesByStructureLevel, getAvailableStructureLevel]
  );
  const getAvailableStructureLevelsForActivity = useCallback(
    () => getAvailableStructureLevels().filter(
      // The structure level with "null" as value is the key to global roles in map
      (structureLevelOption) => !!structureLevelOption?.value
        && getAvailableRolesByLevel(structureLevelOption)?.length > 0
    ),
    [getAvailableStructureLevels, getAvailableRolesByLevel]
  );
  const isAccessibleRole = useCallback(
    (role, structureLevel) => getAvailableRolesByLevel(structureLevel)?.map((selectItem) => selectItem?.value)
      .includes(role?.value),
    [getAvailableRolesByLevel]
  );
  const isAtLeastOneImmutableRole = useCallback(
    (roles, structureLevel) => (roles
      ? roles.some((role) => !isAccessibleRole(role, structureLevel))
      : false),
    [isAccessibleRole]
  );

  const rolesByNeedStructureLevel = groupBy(initialRoles ?? [], 'needStructureLevel');
  const [activityRoles, setActivityRoles] = useState(rolesByNeedStructureLevel.true ? rolesByNeedStructureLevel.true.map(
    (value) => {
      const accessibleRoles = getAvailableRolesByLevel(value?.structureLevel);
      return ({
        ...value,
        clearableValue: accessibleRoles?.map((role) => role.value).includes(value.value)
      });
    }
  ) : []);
  const [globalRoles] = useState(rolesByNeedStructureLevel.false ? rolesByNeedStructureLevel.false.map(
    (value) => {
      const accessibleRoles = getAvailableRolesByLevel(null);
      return ({
        ...value,
        clearableValue: accessibleRoles?.map((role) => role.value).includes(value.value)
      });
    }
  ) : []);
  const [structureLevels, setStructureLevels] = useState([...uniqWith(activityRoles.map(r => r.structureLevel), isEqual)]);

  const getActivityRolesByLevel = useCallback(
    (structureLevel) => activityRoles
      .filter((r) => r.structureLevel?.value === structureLevel?.value),
    [activityRoles]
  );

  useEffect(() => {
    formState.setField('roles', unionWith(globalRoles, activityRoles, isEqual));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityRoles, globalRoles]);

  useEffect(() => {
    const lastStructureLevel = structureLevels.slice(-1)[0];
    if (structureLevels.length < getAvailableStructureLevels()?.length) {
      if (!lastStructureLevel || Object.keys(lastStructureLevel).length !== 0) {
        setStructureLevels([...structureLevels, {}]);
      }
    }
  }, [getAvailableStructureLevels, structureLevels]);

  const updateStructureLevelRoles = (level, index) => {
    const previousStructureLevel = structureLevels[index];
    if (previousStructureLevel) {
      setActivityRoles(activityRoles.filter((r) => r.structureLevel?.value !== previousStructureLevel?.value));
    }
    if (level != null) {
      setStructureLevels([...structureLevels.slice(0, index), level, ...structureLevels.slice(index + 1)]);
    } else {
      setStructureLevels([...structureLevels.slice(0, index), ...structureLevels.slice(index + 1)]);
    }
  };

  const handleChangeLevelRoles = (newRoles, structureLevel) => {
    const activityRolesNotForCurrentLevel = activityRoles
      .filter((r) => r.structureLevel?.value !== structureLevel?.value)
      .map(
        (value) => {
          const accessibleRoles = getAvailableRolesByLevel(structureLevel);
          return ({
            ...value,
            clearableValue: accessibleRoles ? accessibleRoles.map((role) => role.value).includes(value.value) : false
          });
        }
      );
    setActivityRoles(unionWith(activityRolesNotForCurrentLevel, newRoles, isEqual));
  };

  return (
    <Grid container direction="column">
      {(getAvailableStructureLevelsForActivity().length > 0 || !isEmpty(activityRoles)) && (
        <FormElement label={translate('modalUserUpdate.editActivityRoles')}>
          {structureLevels.map((structureLevel, index) => (
            <ActivityRoleSelector
              accessibleLevels={getAvailableStructureLevelsForActivity()}
              accessibleRoles={getAvailableRolesByLevel(structureLevel)}
              defaultLevel={structureLevel}
              defaultLevelRoles={getActivityRolesByLevel(structureLevel)}
              handleChangeLevel={(newLevel) => updateStructureLevelRoles(newLevel, index)}
              handleChangeLevelRoles={(newRoles) => handleChangeLevelRoles(newRoles, structureLevel)}
              isClearable={!isAtLeastOneImmutableRole(getActivityRolesByLevel(structureLevel), structureLevel)}
              isLevelDisabled={isAtLeastOneImmutableRole(getActivityRolesByLevel(structureLevel), structureLevel)}
              key={structureLevel?.value ?? index}
              selectedLevels={structureLevels}
            />
          ))}
        </FormElement>
      )}
    </Grid>
  );
};
