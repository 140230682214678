import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { STRUCTURE_LEVEL_ICON } from 'utils/constants';

const TreeNodeContent = ({ node }) => (
  <Grid alignItems="center" container direction="row" spacing={2}>
    <Grid item>
      <FontAwesomeIcon icon={STRUCTURE_LEVEL_ICON[node.type]} size="lg" />
    </Grid>
    <Grid item>
      <Typography style={{ maxWidth: 600, textOverflow: 'ellipsis', overflow: 'hidden' }}>{node.label}</Typography>
    </Grid>
  </Grid>
);

export default TreeNodeContent;
