import { InstitutionView } from 'models';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const institutionsUrl = `${API_URL}institutions`;

const getInstitutionsTrackDechets = freeSearch => RequestHelper.GET(`${institutionsUrl}/track-dechets?freeSearch=${freeSearch}`);

const getInstitutionModel = (institutionUuid) => RequestHelper.GET(`${institutionsUrl}/${institutionUuid}`);
const getInstitutionTree = (institutionUuid, activity) => RequestHelper.GET(`${institutionsUrl}/${institutionUuid}/tree?activity=${activity}`);
// POST
const getInstitutionsList: (
  params,
  page: number
) => Promise<any> = (
  params = {},
  page = 1
) => RequestHelper.POST(`${institutionsUrl}/filter?page=${page}`, params);

const getInstitutionsOptions = (params = {}) => RequestHelper.POST(`${institutionsUrl}/options`, params);

const createInstitution = institution => RequestHelper.POST(`${institutionsUrl}`, institution);

const updateInstitution = (id: string, institution: InstitutionView) => RequestHelper.PUT(`${institutionsUrl}/${id}`, institution);
const deleteInstitution = (id: string) => RequestHelper.DELETE(`${institutionsUrl}/${id}`);

export const InstitutionsService = {
  getInstitutionsTrackDechets,
  getInstitutionsList,
  getInstitutionsOptions,
  createInstitution,
  updateInstitution,
  deleteInstitution,
  getInstitutionModel,
  getInstitutionTree
};
