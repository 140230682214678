import React from 'react';
import styled from 'styled-components';

const StyledFlag = styled.img`
  vertical-align: middle;
  box-shadow: 0 2px 6px var(--grey);
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  box-sizing: border-box;
  margin: auto;
`;

type FlagContainerProps = {
  height: string;
  width: string;
}

const FlagContainer = styled.div<FlagContainerProps>`
  display: flex;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.height};
`;

const FlagImg = ({
 alt, isInsideSelect, countryCode, style, width
}) => (
  <StyledFlag
    alt={alt}
    src={isInsideSelect ? `https://flagcdn.com/w40/${countryCode}.png` : `https://flagcdn.com/${countryCode}.svg`}
    style={style}
    width={isInsideSelect ? 'auto' : width}
  />
);

export const Flag = ({
  countryCode, alt = 'country-flag', width = '40px', isInsideSelect = false, style
}: {
  alt?: string,
  countryCode: string,
  isInsideSelect?: boolean,
  style?: any,
  width?: string
}) => (
  <FlagContainer height={isInsideSelect ? '40px' : 'auto'} width={isInsideSelect ? 'auto' : width}>
    {countryCode && (<FlagImg alt={alt} countryCode={countryCode} isInsideSelect={isInsideSelect} style={style} width={width} />)}
  </FlagContainer>
);
