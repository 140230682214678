import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import {
  Button, DialogActions, DialogContent
} from '@material-ui/core';

import { InterventionMap } from 'components/Intervention';

import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

export const MapModal = ({
  onClose, defaultValues
}) => (
  <Fragment>
    <ModalHeader onClose={onClose}>
      {translate('modalMap.interventionAddress')}
    </ModalHeader>

    <DialogContent>
      <InterventionMap address={defaultValues.address} isInsidePopup isInteractive={defaultValues.isInteractive} />
    </DialogContent>

    <DialogActions>
      <Button color="primary" onClick={onClose}>
        {translate('button.close')}
      </Button>
    </DialogActions>
  </Fragment>
);

MapModal.propTypes = {
  defaultValues: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired
};
