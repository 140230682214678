import { faTint } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomIcon, FontIcon } from 'components';
import React from 'react';

export const FLUID_MOVEMENT_LIST = [
  {
    key: 'INPUT',
    label: 'mouvements.INPUT_TAB',
    labelShort: 'mouvements.INPUT',
    supplierOrRecipientLabel: 'modalFluidMovement.supplier',
    supplierOrRecipient: 'SUPPLIER',
    group: 'INPUT',
    availableToOperator: true,
    hasDocument: false,
    theoricalAmountLabel: 'common.boughtQuantity',
    measuredAmountLabel: 'common.measuredQuantity',
    color: 'var(--green)',
    icon: <CustomIcon
      icon={(
        <FontAwesomeIcon
          color="var(--green)"
          icon={faTint}
          size="lg"
        />
      )}
    />
  },
  {
    key: 'OUTPUT',
    label: 'mouvements.OUTPUT_TAB',
    labelShort: 'mouvements.OUTPUT',
    supplierOrRecipientLabel: 'modalFluidMovement.recipient',
    supplierOrRecipient: 'SUPPLIER',
    group: 'OUTPUT',
    availableToOperator: true,
    hasDocument: false,
    measuredAmountLabel: 'common.treatmentQuantity',
    color: 'var(--orange)',
    icon: <CustomIcon
      icon={(
        <FontAwesomeIcon
          color="var(--orange)"
          icon={faTint}
          size="lg"
        />
      )}
      status="remove"
    />
  },
  {
    key: 'RESET',
    label: 'mouvements.RESET_TAB',
    labelShort: 'mouvements.RESET',
    labelDistributor: 'mouvements.RESET_TAB_DISTRIBUTOR',
    labelShortDistributor: 'mouvements.RESET_DISTRIBUTOR',
    supplierOrRecipientLabel: 'modalFluidMovement.supplier',
    supplierOrRecipient: 'SUPPLIER',
    group: 'OUTPUT',
    availableToOperator: true,
    hasDocument: true,
    documentLabel: 'modalBottle.resetProof',
    measuredAmountLabel: 'common.resetQuantity',
    measuredAmountLabelDistributor: 'common.resetQuantityDistributor',
    color: 'var(--orange)',
    icon: <CustomIcon
      icon={(
        <FontIcon
          color="var(--orange)"
          fontSize="2.4rem"
          icon="icon-container"
          marginLeft="-0.5rem"
        />
      )}
      status="remove"
    />
  },
  {
    key: 'HANDOVER',
    label: 'mouvements.HANDOVER_TAB',
    labelShort: 'mouvements.HANDOVER',
    labelDistributor: 'mouvements.HANDOVER_TAB_DISTRIBUTOR',
    labelShortDistributor: 'mouvements.HANDOVER_DISTRIBUTOR',
    group: 'OUTPUT',
    availableToOperator: true,
    hasDocument: true,
    documentLabel: 'modalBottle.handoverProof',
    measuredAmountLabel: 'common.fluidQuantity',
    color: 'var(--orange)',
    icon: <CustomIcon
      icon={(
        <FontIcon
          color="var(--orange)"
          fontSize="2.4rem"
          icon="icon-container"
          marginLeft="-0.5rem"
        />
      )}
      status="remove"
    />
  },
  {
    key: 'RETRIEVAL',
    label: 'mouvements.RETRIEVAL_TAB',
    labelShort: 'mouvements.RETRIEVAL',
    supplierOrRecipientLabel: 'modalFluidMovement.client',
    supplierOrRecipient: 'RECIPIENT',
    group: 'INPUT',
    availableToOperator: false,
    hasDocument: true,
    documentLabel: 'modalBottle.retrievalProof',
    theoricalAmountLabel: 'common.retrievedQuantity',
    measuredAmountLabel: 'common.measuredQuantity',
    color: 'var(--green)',
    icon: <CustomIcon
      icon={(
        <FontAwesomeIcon
          color="var(--green)"
          icon={faTint}
          size="lg"
        />
      )}
    />
  },
  {
    key: 'SALE',
    label: 'mouvements.SALE_TAB',
    labelShort: 'mouvements.SALE',
    supplierOrRecipientLabel: 'modalFluidMovement.client',
    supplierOrRecipient: 'RECIPIENT',
    group: 'OUTPUT',
    availableToOperator: false,
    hasDocument: false,
    measuredAmountLabel: 'common.soldQuantity',
    color: 'var(--orange)',
    icon: <CustomIcon
      icon={(
        <FontAwesomeIcon
          color="var(--orange)"
          icon={faTint}
          size="lg"
        />
      )}
      status="remove"
    />
  },
  {
    key: 'VALIDATION',
    labelShort: 'mouvements.VALIDATION',
    group: 'UPDATE',
    availableToOperator: true,
    hasDocument: false,
    measuredAmountLabel: 'common.fluidQuantity'
  },
  {
    key: 'STOCK',
    labelShort: 'mouvements.STOCK',
    supplierOrRecipientLabel: 'modalFluidMovement.supplier',
    supplierOrRecipient: 'SUPPLIER',
    measuredAmountLabel: 'common.fluidQuantity',
    group: 'NONE',
    hasDocument: false,
    availableToOperator: true,
    icon: <CustomIcon
      icon={(
        <FontIcon
          color="var(--green)"
          fontSize="2.4rem"
          icon="icon-container"
          marginLeft="-0.5rem"
        />
      )}
    />
  }
];
