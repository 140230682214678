import i18next from 'i18next';
import {
  action, makeObservable, observable, runInAction
} from 'mobx';
import {
  CountriesService, I18nService, UsersService
} from 'services';
import { userStore } from 'stores/UserStore';
import { StorageHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

export const frenchLocale = 'fr_FR';

class I18nStore {
  languageList: SelectItem[];
  currentLanguage: string;
  isLoading: boolean;

  constructor() {
    this.languageList = [];
    this.currentLanguage = localStorage.getItem('i18nextLng') || frenchLocale;
    this.isLoading = true;

    makeObservable(this, {
      languageList: observable,
      currentLanguage: observable,
      isLoading: observable,
      loadLanguageList: action,
      loadLanguage: action,
      checkLanguage: action
    });
  }

  // eslint-disable-next-line
  findLanguageByLocale: (locale: string) => SelectItem | undefined = locale => this.languageList.find(l => l.value.toUpperCase().indexOf(locale.toUpperCase()) !== -1);

  setBundle(lng: string, bundle: string) {
    StorageHelper.SET('i18nextLngBundle', bundle);
    i18next.addResourceBundle(lng, 'translation', bundle, true, true);
    // Change the i18next language to the new one
    i18next.changeLanguage(lng)
      .then(() => runInAction(() => {
        const oldLanguage = localStorage.getItem('i18nextLng');
        if (oldLanguage && oldLanguage !== lng) {
          // Remove the old language from the resources if it exists
          i18next.removeResourceBundle(oldLanguage, 'translation');
        }
        // Set the language cache variable to the new one
        StorageHelper.SET('i18nextLng', lng);
        this.currentLanguage = lng;
      }));
  }

  loadLanguageList() {
    CountriesService.getLanguageList()
      .then(languages => runInAction(() => {
        this.languageList = languages;
      }));
  }

  loadLanguage = async (lng: string) => {
    this.isLoading = true;
    I18nService.getLabels('DATABILAN', lng)
      .then(bundle => this.setBundle(lng, bundle))
      .catch(() => this.setBundle(lng, JSON.parse(StorageHelper.GET('i18nextLngBundle'))))
      .finally(() => { this.isLoading = false; });
  };

  checkLanguage = async (lng: string) => {
    let actualLanguage = lng;
    if (!this.findLanguageByLocale(actualLanguage)) {
      const localLanguage = localStorage.getItem('i18nextLng');
      if (localLanguage && this.findLanguageByLocale(localLanguage)) {
        // Fallback to the local saved language if the required language is not activated for this application
        actualLanguage = localLanguage;
      } else if (this.findLanguageByLocale(frenchLocale)) {
        // Fallback to french if the local saved language is unavailable or not activated for this application
        actualLanguage = frenchLocale;
      } else if (this.languageList.length > 0) {
        // Fallback to the first activated language for this application if french is not available
        actualLanguage = this.languageList[0].value;
      }
    }
    if (actualLanguage !== lng) {
      UsersService.updateLocale(actualLanguage)
        .then(() => userStore.keycloak.updateToken(999999)
          .then((refreshed) => {
            refreshed && userStore.keycloak.login();
          }));
      return true;
    }
    return false;
  };
}

export const i18nStore = new I18nStore();
