import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { translate } from '../utils/helpers';
import { useModal } from './ModalProvider';

/**
 * Base hook to import Excel file and handle validation error popup
 * @param importFunction method use from a service to import and who take a Excel file in parameter
 * @param reloadListFunction method use to reload a list when the import function update a list
 * @param errorModalTitle optional : translation for the import error modal
 * @returns {isImporting, importFunc} 1rst to indicate if export is processing or done, 2nd the method used when click on the button to import
 */
export function useExcelImport(importFunction, reloadListFunction, isAsync = false, errorModalTitle: string = 'import.common.error') {
  const { open } = useModal();
  const [isImporting, setIsImporting] = useState(false);

  const importFunc = useCallback(() => {
    open({
      type: 'IMPORT',
      onConfirm: (excelFile) => {
        setIsImporting(true);
        if (isAsync) {
          importFunction(excelFile);
          enqueueSnackbar(translate('confirms.importDataInProgress'), { variant: 'success' });
          setIsImporting(false);
          return;
        }
        importFunction(excelFile)
          .then(() => {
            enqueueSnackbar(translate('confirms.fileImported'), { variant: 'success' });
            reloadListFunction && reloadListFunction();
          })
          .catch((exception) => {
            open({
              type: 'ERROR',
              text: exception !== undefined ? exception : translate('import.common.error'),
              title: errorModalTitle
            });
          })
          .finally(() => setIsImporting(false));
      },
      closeOnSubmit: false
    });
  }, [open, importFunction, reloadListFunction, errorModalTitle, isAsync]);

  return {
    isImporting,
    importFunc
  };
}
