import { faQuestion, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tour from 'reactour';
import { ResourceTutorialsService } from 'services';
import styled from 'styled-components';

import { useModal } from 'hooks';
import { translate } from 'utils/helpers';
import { COMMON_STEPS } from './common-steps';

const LaunchButton = styled.button`
  position: absolute;
  top: var(--header-height);
  right: 0;
  width: 56px;
  height: 56px;
  padding: 0;
  border: none;
  background: url('/assets/images/bg/helpButton.png') no-repeat center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    width: 76px;
    height: 76px;

    svg {
      font-size: 2rem;
    }
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all 0.3s;
  }
`;

export const ReactTour = ({
  accentColor, noDoneStep, steps, stepWelcome,
  defaultOpen, tourId, disabled
}) => {
  const { open } = useModal();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [stepsAvailable, setStepsAvailable] = useState([]);
  const [videos, setVideos] = useState(null);

  const storageKey = `tour-${tourId}-hasBeenViewed`;

  const getStorageTag = () => (
    localStorage.getItem(storageKey)
  );

  const setStorageTag = () => (
    !getStorageTag() && localStorage.setItem(storageKey, true)
  );

  useEffect(() => {
    setIsLoading(true);
    const resourceIdentifier = pathname.substring(1).replace('/', '_');
    ResourceTutorialsService.getResource(resourceIdentifier)
      .then(resp => setVideos(resp))
      .finally(() => setIsLoading(false));

    const currentSteps = [];

    const setupTourTimeout = setTimeout(() => {
      // Add welcome step if there is one
      stepWelcome && currentSteps.push({
        selector: '',
        content: stepWelcome
      });

      steps.map(((step) => {
        if (step.selector && document.querySelectorAll(step.selector) && document.querySelectorAll(step.selector).length > 0) {
          return currentSteps.push(step);
        }
        return null;
      }));

      // Add default done step for all tutorials
      !noDoneStep && currentSteps.push({
        selector: '[data-tour="step-done"]',
        content: COMMON_STEPS.done
      });

      setStepsAvailable(currentSteps);
      setIsLoading(false);
      defaultOpen && (!getStorageTag()) && setIsTourOpen(true);
    }, 3000);

    return () => {
      clearTimeout(setupTourTimeout);
    };
    // eslint-disable-next-line
  }, []);

  const closeTour = () => {
    setIsTourOpen(false);
    setStorageTag();
  };

  const openTour = () => (
    stepsAvailable.length > 0 && setIsTourOpen(true)
  );

  const handleopen = () => open({
    type: 'DISPLAY_TOUR',
    openTour: disabled ? null : openTour,
    videos
  });

  if (!videos || !videos.length) return null;

  return (
    <Fragment>
      <Tour
        accentColor={accentColor}
        closeWithMask={false}
        disableInteraction
        isOpen={isTourOpen}
        lastStepNextButton={(
          <span style={{ color: 'var(--blue)' }}>
            FERMER
          </span>
        )}
        rounded={5}
        startAt={0}
        steps={stepsAvailable}
        onAfterOpen={() => { document.body.style.overflowY = 'hidden'; }}
        onBeforeClose={() => { document.body.style.overflowY = 'auto'; }}
        onRequestClose={closeTour}
      />

      <Tooltip
        disableFocusListener
        disableTouchListener
        title={translate('common.help')}
      >
        <LaunchButton
          data-tour="step-done"
          onClick={handleopen}
        >
          {isLoading ? (
            <FontAwesomeIcon color="var(--white)" icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon color="var(--white)" icon={faQuestion} />
          )}
        </LaunchButton>
      </Tooltip>
    </Fragment>
  );
};

ReactTour.propTypes = {
  accentColor: PropTypes.string,
  defaultOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  noDoneStep: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string
    ]),
    selector: PropTypes.string
  })).isRequired,
  stepWelcome: PropTypes.shape({}),
  tourId: PropTypes.string
};

ReactTour.defaultProps = {
  accentColor: 'var(--turquoise)',
  defaultOpen: false,
  disabled: true,
  noDoneStep: false,
  stepWelcome: null,
  tourId: ''
};
