import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontIcon } from 'components';
import { useStores } from 'hooks';
import { DISTRIBUTOR_ROLES, ROUTES } from 'utils/constants';
import { translate, UserHelper } from 'utils/helpers';

const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  flex-flow: ${props => (props.isfooter === 'true' ? 'column' : 'row')} nowrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: ${props => (props.isfooter === 'true' ? '70px' : '54px')};
  padding: ${props => (props.isfooter === 'true' ? '0.7rem' : '1.6rem')};
  color: var(--black-light);

  &.active,
  &:hover {
    color: var(--blue);
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: ${props => (props.isfooter === 'true' ? '4px' : '2px')};
      background: var(--blue);
    }
  }

  span,
  svg {
    margin-right: ${props => (props.isfooter === 'true' ? '0' : '1rem')};
    margin-bottom: ${props => (props.isfooter === 'true' ? '0.5rem' : '0')};
  }
`;

export const MenuItem = ({
  title, icon, url, isFontIcon, fontSize, isFooter, dataTour, roles
}) => {
  const { userStore } = useStores();

  if (UserHelper.hasAccessRight(DISTRIBUTOR_ROLES) && !userStore.isViewingInstitution() && url === ROUTES.HOME) {
    return null;
  }
  if ((roles && roles.length > 0 && UserHelper.hasAccessRight(roles)) || !roles) {
    return (
      <StyledLink activeClassName="active" data-tour={dataTour} isfooter={isFooter.toString()} to={url}>
        {isFontIcon ? (
          <FontIcon fontSize={fontSize} icon={icon} />
        ) : (
          <FontAwesomeIcon icon={icon} size={isFooter ? '2x' : 'lg'} />
        )}
        {translate(title)}
      </StyledLink>
    );
  }

  return null;
};

MenuItem.propTypes = {
  dataTour: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]).isRequired,
  isFooter: PropTypes.bool,
  fontSize: PropTypes.string,
  url: PropTypes.string.isRequired,
  isFontIcon: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string)
};

MenuItem.defaultProps = {
  dataTour: '',
  fontSize: '2.2rem',
  isFontIcon: true,
  isFooter: false,
  roles: null
};
