import { EntityFieldView } from './EntityFieldView';
import { EquipmentFieldView } from './EquipmentFieldView';
import { FluidManipulationFieldView } from './FluidManipulationFieldView';
import { InterventionCategory } from './InterventionCategory';
import { LeakFieldView } from './LeakFieldView';
import { SignatureFieldView } from './SignatureFieldView';
import { ToolFieldView } from './ToolFieldView';

export class FormView {
  hashId?: string = '';
  operatorField?: EntityFieldView;
  equipmentOwnerField?: EntityFieldView;
  attestationNumber?: string = '';
  equipmentField?: EquipmentFieldView;
  interventionCategory?: InterventionCategory;
  observations?:string = '';
  leakDetectorField?: ToolFieldView;
  scaleField?: ToolFieldView;
  leakDetectionSystem?: boolean = false;
  fluidCategory?: string = '';
  fluidAmount?: string = '';
  controlFrequency?: string = '';
  leakField?: LeakFieldView;
  fluidManipulationField?: FluidManipulationFieldView;
  operatorSignatureField?: SignatureFieldView;
  customerSignatureField?: SignatureFieldView;
  interventionDate?: string;
}
