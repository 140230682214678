import { faUserEdit } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogContent, Grid } from '@material-ui/core';
import { UpdateRolesForm } from 'components/Form';
import { CustomActionButtons } from 'components/Modals/_CustomActionButtons';

import { ModalHeader } from 'components/Modals/ModalHeader';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormState } from 'react-use-form-state';
import { translate } from 'utils/helpers';

export const UpdateRolesModal = ({
  onClose, onConfirm, closeOnSubmit, defaultValues, accessibleRolesByStructureLevel
}) => {
  const [formState] = useFormState({
    roles: defaultValues.roles
  });

  const handleValidateModal = e => {
    e.preventDefault();
    onConfirm(formState.values.roles);
    if (closeOnSubmit) {
      onClose();
    }
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <Grid alignItems="center" container justifyContent="space-between">
          <div>
            <FontAwesomeIcon icon={faUserEdit} />
            {`${translate('modalUserUpdate.editUser')} : ${defaultValues.person.firstName} ${defaultValues.person.lastName}`}
          </div>
        </Grid>
      </ModalHeader>

      <DialogContent style={{ width: '50vw' }}>
        <UpdateRolesForm
          accessibleRolesByStructureLevel={accessibleRolesByStructureLevel}
          formState={formState}
          initialRoles={defaultValues.roles}
        />
      </DialogContent>

      <CustomActionButtons
        isValidated={defaultValues.person.validated}
        onClose={onClose}
      />
    </form>
  );
};

UpdateRolesModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

UpdateRolesModal.defaultProps = {
  closeOnSubmit: true
};
