import React from 'react';
import { StoresContext } from '../contexts';

/**
 * Hook used to access the react context in which all the Mobx Stores are stored.
 * <p>
 * This hook can be used to obtain multiple stores at once.
 * @example
 * const { fluidStore, userStore } = useStores();
 */
export const useStores = () => React.useContext(StoresContext);
