import PropTypes from 'prop-types';
import React from 'react';
import { useFormState } from 'react-use-form-state';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button, DialogActions, DialogContent, TextField
} from '@material-ui/core';

import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

export const DeleteBottleModal = ({
  onClose, onConfirm, closeOnSubmit
}) => {
  const [formState, { text }] = useFormState({
    comment: ''
  });

  const handleValidateModal = (e) => {
    e.preventDefault();

    onConfirm({
      ...formState.values
    });

    if (closeOnSubmit) {
      onClose();
    }
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader className="warningModal" onClose={onClose}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {translate('modalDeleteBottle.deleteBottle')}
      </ModalHeader>

      <DialogContent>
        <TextField
          label={translate('common.comment')}
          multiline
          name="comment"
          required
          rows={3}
          {...text('comment')}
          style={{ width: '100%' }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" id="deleteBottleConfirm" type="submit">{translate('button.validate')}</Button>
      </DialogActions>
    </form>
  );
};

DeleteBottleModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

DeleteBottleModal.defaultProps = {
  closeOnSubmit: true
};
