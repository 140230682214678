import { translate } from 'utils/helpers';

const getTranslatedType = ({ type, isNewFluid = undefined, identifier = '' }) => {
  let translatedType;
  if (type !== 'NEW_BOTTLE') {
    translatedType = translate(`bottles.${type}`);
  } else {
    translatedType = (isNewFluid === undefined) ? translate('bottles.NEW_OR_REGENERATED') : translate(`bottles.NEW_BOTTLE.${isNewFluid}`);
  }

  return `${translatedType} ${identifier && `(${identifier})`}`;
};

export const BottleHelper = {
  getTranslatedType
};
