import { faFileImport, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import {
  ButtonBlue,
  ButtonBlueLight,
  CustomIcon,
  FontIcon,
  GenericListContainer,
  ReactTour,
  SubtitlePage,
  Text
} from 'components';
import { useExcelImport, useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import {
  DocumentsService, FluidMovementsService, FluidsService, StocksService
} from 'services';
import styled from 'styled-components';
import { DISTRIBUTOR_ROLES } from 'utils/constants';
import { translate, UserHelper } from 'utils/helpers';

const ButtonContainer = styled.div`
  margin: 15px auto 30px;
`;

export const StockInitialisation = () => {
  const { open, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const isDistributor = UserHelper.hasAccessRight(DISTRIBUTOR_ROLES);

  const {
    isImporting: isImportingStocks,
    importFunc: importStocks
  } = useExcelImport(StocksService.importStocks, null);

  const {
    isImporting: isImportingMovements,
    importFunc: importMovements
  } = useExcelImport(FluidMovementsService.importFluidMovement, null);

  const {
    isImporting: isImportingManipulations,
    importFunc: importManipulations
  } = useExcelImport(FluidsService.importFluidManipulations, null);

  const {
    isImporting: isImportingFluidBottles,
    importFunc: importFluidBottles
  } = useExcelImport(FluidsService.importBottles, null);

  const createBottle = useCallback((form) => FluidsService.createBottle(form).then(() => {
    close();
    enqueueSnackbar(translate('confirms.bottle.create'), { variant: 'success' });
  }), [enqueueSnackbar, close]);

  const displayImportModal = useCallback((event) => {
    const { type } = event.target.dataset;
    switch (type) {
      case 'quantity':
        importStocks();
        break;
      case 'movement':
        importMovements();
        break;
      case 'manipulation':
        importManipulations();
        break;
      case 'fluidBottle':
        importFluidBottles();
        break;
      default:
        break;
    }
  }, [importStocks, importMovements, importManipulations, importFluidBottles]);

  const displayCreateBottleModal = useCallback(() => open({
    type: 'CREATE_BOTTLE',
    onSubmit: createBottle,
    isInit: true
  }), [open, createBottle]);

  return (
    <>
      <GenericListContainer>
        <SubtitlePage>{translate('pageStocks.initStock')}</SubtitlePage>
        <Grid container direction="column" spacing={2}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item style={{ maxWidth: '48%', minWidth: '550px' }}>
              <div style={{ minHeight: '60px' }}><b>{translate('initialization.process1Title')}</b></div>
              <ol>
                <li>
                  {translate('initialization.process1Step1')}
                </li>
                <ButtonContainer>
                  <Button onClick={() => DocumentsService.getImportFile('stocks')}>
                    <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                      {translate('common.exampleFile')}
                    </Text>
                  </Button>
                  <br />
                  <ButtonBlueLight data-type="quantity" onClick={displayImportModal}>
                    {isImportingStocks ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin />
                        {translate('button.importing')}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileImport} />
                        {translate('pageStocks.importInitialStock')}
                      </>
                    )}
                  </ButtonBlueLight>
                </ButtonContainer>
                <li>
                  {translate('initialization.process1Step2')}
                </li>
                <ButtonContainer>
                  <Button onClick={() => DocumentsService.getImportFile('movements')}>
                    <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                      {translate('common.exampleFile')}
                    </Text>
                  </Button>
                  <br />
                  <ButtonBlueLight data-type="movement" onClick={displayImportModal}>
                    {isImportingMovements ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin />
                        {translate('button.importing')}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileImport} />
                        {translate('pageStocks.importFluidMovement')}
                      </>
                    )}
                  </ButtonBlueLight>
                </ButtonContainer>
                {!isDistributor && (
                  <>
                    <li>
                      {translate('initialization.process1Step3')}
                    </li>
                    <ButtonContainer>
                      <Button onClick={() => DocumentsService.getImportFile('manipulations')}>
                        <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                          {translate('common.exampleFile')}
                        </Text>
                      </Button>
                      <br />
                      <ButtonBlueLight data-type="manipulation" onClick={displayImportModal}>
                        {isImportingManipulations ? (
                          <>
                            <FontAwesomeIcon icon={faSpinner} spin />
                            {translate('button.importing')}
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faFileImport} />
                            {translate('pageStocks.importFluidManipulation')}
                          </>
                        )}
                      </ButtonBlueLight>
                    </ButtonContainer>
                  </>
                )}
                <li>
                  {translate('initialization.process1Step4')}
                </li>
                <ButtonContainer>
                  <Button onClick={() => DocumentsService.getImportFile('fluidBottles')}>
                    <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                      {translate('common.exampleFile')}
                    </Text>
                  </Button>
                  <br />
                  <ButtonBlueLight data-type="fluidBottle" onClick={displayImportModal}>
                    {isImportingFluidBottles ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin />
                        {translate('button.importing')}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileImport} />
                        {translate('pageStocks.importFluidBottles')}
                      </>
                    )}
                  </ButtonBlueLight>
                </ButtonContainer>
                <ButtonContainer>
                  <ButtonBlue data-cy="createBottle" onClick={displayCreateBottleModal}>
                    <CustomIcon icon={<FontIcon icon="icon-container" />} />
                    {translate('button.createExistingBottle')}
                  </ButtonBlue>
                </ButtonContainer>
              </ol>
            </Grid>
            {!isMobileOnly && <Grid item style={{ borderLeft: '1px solid black', height: '500px', margin: 'auto' }} />}
            <Grid item style={{ maxWidth: '48%', minWidth: '550px' }}>
              <div style={{ minHeight: '60px' }}><b>{translate('initialization.process2Title')}</b></div>
              <ol>
                <li>
                  {translate('initialization.process2Step1')}
                </li>
                <ButtonContainer>
                  <Button onClick={() => DocumentsService.getImportFile('stocks')}>
                    <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                      {translate('common.exampleFile')}
                    </Text>
                  </Button>
                  <br />
                  <ButtonBlueLight data-type="quantity" onClick={displayImportModal}>
                    {isImportingStocks ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin />
                        {translate('button.importing')}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileImport} />
                        {translate('pageStocks.importCurrentStock')}
                      </>
                    )}
                  </ButtonBlueLight>
                </ButtonContainer>
                <li>
                  {translate('initialization.process2Step2')}
                </li>
                <ButtonContainer>
                  <Button onClick={() => DocumentsService.getImportFile('fluidBottles')}>
                    <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                      {translate('common.exampleFile')}
                    </Text>
                  </Button>
                  <br />
                  <ButtonBlueLight data-type="fluidBottle" onClick={displayImportModal}>
                    {isImportingFluidBottles ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin />
                        {translate('button.importing')}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileImport} />
                        {translate('pageStocks.importFluidBottles')}
                      </>
                    )}
                  </ButtonBlueLight>
                </ButtonContainer>
                <ButtonContainer>
                  <ButtonBlue data-cy="createBottle" onClick={displayCreateBottleModal}>
                    <CustomIcon icon={<FontIcon icon="icon-container" />} />
                    {translate('button.createExistingBottle')}
                  </ButtonBlue>
                </ButtonContainer>
              </ol>
            </Grid>
          </Grid>
        </Grid>
        <ReactTour steps={[]} />
      </GenericListContainer>
    </>
  );
};
