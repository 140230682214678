import { format } from 'date-fns';
import {
  BottleView,
  CountrySelectItem,
  CountryView,
  EntitySummaryItem, EquipmentSummaryItem, FluidView, InterventionAppointment, InterventionView, UserView
} from 'models';
import { FormFrontView } from 'models/detail/FormFrontView';
import { getDateFromAppointment } from 'models/detail/InterventionAppointment';
import { InterventionFrontView } from 'models/detail/InterventionFrontView';
import { RefrigerationUnitSummaryItem } from 'models/list/RefrigerationUnitSummary';
import { StateValues } from 'react-use-form-state';
import { COUNTRY_CODE, INTERVENTION_STATUS, StatusList } from 'utils/constants';
import { SelectItem } from 'utils/types';
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  DocumentHelper,
  FormHelper, IndexedDBHelper,
  StorageHelper,
  formatDateString,
  parseDate,
  translate
} from '.';
import { INTERVENTION_STORE, REFERENCE_DATA_STORE } from './IndexedDBHelper';

let USER_KEY = '';
let USER_INSTITUTION_KEY = '';
const INTERVENTION_FORM_KEY = 'intervention_form';
const INTERVENTION_DETAIL_KEY = 'intervention_detail';

export const EQUIPMENT_OWNERS_KEY = 'equipmentOwners';
export const EQUIPMENTS_KEY = 'equipments';
export const INSTALLATION_TYPES_KEY = 'installationTypes';
export const LEAK_DETECTORS_KEY = 'leakDetectors';
export const SCALES_KEY = 'scales';
export const BOTTLES_KEY = 'bottles';
export const COUNTRIES_KEY = 'countries';
export const FLUIDS_KEY = 'fluids';
export const INTERVENTION_STATUSES_KEY = 'interventionStatuses';

const setCurrentUserAndInstitution = (currentUser?: UserView) => {
  if (currentUser) {
    USER_KEY = `user_${currentUser.accountId}`;
    if (currentUser.currentLevel && currentUser.currentLevel.type === 'INSTITUTION_MODEL') {
      USER_INSTITUTION_KEY = `institution_${currentUser.currentLevel.value}`;
    } else {
      USER_INSTITUTION_KEY = '';
    }
  } else {
    USER_KEY = '';
    USER_INSTITUTION_KEY = '';
  }
};

const getFormattedDateTime: (
  date?: Date
) => InterventionAppointment = (
  date = new Date()
) => {
  const appointmentDateTime = {
    date: format(date, 'dd/MM/yyyy'),
    time: format(date, 'HH:mm:ss')
  };
  return appointmentDateTime;
};

const formatDate = (date, dateFormat = 'dd/MM/yyyy') => {
  if (!date) return '';
  return format(new Date(date), dateFormat);
};

const getCurrentWeek = () => {
  const today = new Date();
  const week: Date[] = [];

  for (let i = 1; i <= 7; i += 1) {
    const first = today.getDate() - today.getDay() + i;
    const day = new Date(today.setDate(first));
    week.push(day);
  }

  return week;
};

const displayAppointment: (
  appointment: InterventionAppointment,
  interventionDate?: Date
) => string = (
  appointment,
  interventionDate
) => {
  const date: string = (interventionDate && formatDate(interventionDate, DATE_FORMAT)) || (appointment && appointment.date);
  const time: string = appointment && appointment.time;
  if (!date) {
    return translate('errors.noDate');
  }

  if (time && time !== '00:00') {
    return formatDateString(`${date} ${time}`, DATETIME_FORMAT, DATETIME_FORMAT);
  }

  return date;
};

const displayAddress = (address) => {
  let result = address.address1;
  if (address.address2) {
    result += `, ${address.address2}`;
  }
  if (address.postalCode) {
    result += `, ${address.postalCode}`;
    if (address.city) {
      result += ` ${address.city}`;
    }
  } else if (address.city) {
    result += `, ${address.city}`;
  }
  if (address.country) {
    result += `, ${address.country.label}`;
  }
  return result;
};

const getAddress = (intervention) => {
  if (!intervention) {
    return '';
  }
  if (intervention.form) {
    if (intervention.form.equipmentField && intervention.form.equipmentField.equipment && intervention.form.equipmentField.equipment.address) {
      return displayAddress(intervention.form.equipmentField.equipment.address);
    }
    if (intervention.form.equipmentOwnerField && intervention.form.equipmentOwnerField.entity && intervention.form.equipmentOwnerField.entity.address) {
      return displayAddress(intervention.form.equipmentOwnerField.entity.address);
    }
  }
  return intervention.interventionAddress ?? intervention.form?.interventionAddress ?? '';
};

const getFirstDayOfTheMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 1);
};

const getLastDayOfTheMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() + 1, 0);
};

const getFirstDayOfTheYear = () => {
  const today = new Date();
  return new Date(today.getFullYear(), 0);
};

const getLastDayOfTheYear = () => {
  const today = new Date();
  return new Date(today.getFullYear() + 1, 0, -1);
};

/**
 * Builds an InterventionView for a planification action based on the frontend values.
 * @param formState The values to map to the InterventionView.
 * @returns An InterventionView compatible with the backend.
 */
const BUILD_PLANIFICATION: (
  intervention: StateValues<InterventionFrontView>
) => InterventionView = (
  intervention
) => {
  const builtForm: InterventionView = {
    ...intervention,
    currentStep: 0,
    customerField: {
      entity: intervention.equipmentOwner
    },
    equipmentField: {
      equipment: intervention.equipment
    },
    form: {
      interventionDate: getDateFromAppointment(intervention.appointment)
    }
  };

  return builtForm;
};

/**
 * Maps an InterventionView to a simplified object for the frontend.
 * @param intervention The InterventionView to map.
 * @returns A simplified InterventionFrontView.
 */
const BACKEND_MAPPER: (
  intervention: InterventionView
) => InterventionFrontView = (
  intervention
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { form, ...baseIntervention } = intervention;
  const frontendForm: InterventionFrontView = {
    ...baseIntervention,
    appointment: intervention.appointment,
    equipmentOwner: intervention.customerField?.entity,
    equipment: intervention.equipmentField?.equipment ? {
      ...intervention.equipmentField.equipment,
      circuits: [intervention.equipmentField.circuit],
      documentList: {
        documents: DocumentHelper.getDocumentsFormatted({ documents: intervention.equipmentField.equipment.documentList?.documents, withBase64: true })
      }
    } : null,
    circuit: intervention.equipmentField?.circuit
  };

  return frontendForm;
};

const MAP_COUNTRY : (
  country: CountryView
) => CountrySelectItem = (
  country
) => (country && {
  key: country.id,
  value: country.id,
  label: country.actualTranslation,
  countryCode: country.key
});

const MAP_COUNTRIES : (
  countries: CountryView[]
) => CountrySelectItem[] = (
  countries
) => countries.map(MAP_COUNTRY);

const loadInterventionDetailStored: (
  interventionId: string
) => Promise<InterventionFrontView> = interventionId => IndexedDBHelper.getData({
  store: INTERVENTION_STORE,
  key: `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_DETAIL_KEY}_${interventionId}`
}).then((intervention: InterventionFrontView) => intervention);

const saveInterventionDetailStored: (
  interventionId: string,
  interventionDetail: InterventionFrontView
) => Promise<void> = (
  interventionId,
  interventionDetail
) => IndexedDBHelper.updateData({
  store: INTERVENTION_STORE,
  key: `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_DETAIL_KEY}_${interventionId}`,
  data: interventionDetail
});

const loadInterventionFormStored: (
  interventionId: string
) => Promise<FormFrontView> = interventionId => IndexedDBHelper.getData({
  store: INTERVENTION_STORE,
  key: `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_FORM_KEY}_${interventionId}`
}).then((intervention: FormFrontView) => intervention);

const saveInterventionFormStored: (
  interventionId: string,
  intervention: FormFrontView
) => Promise<void> = (
  interventionId,
  intervention
) => IndexedDBHelper.updateData({
  store: INTERVENTION_STORE,
  key: `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_FORM_KEY}_${interventionId}`,
  data: intervention
});

const saveInterventionDetailAndForm: (
  interventionId: string,
  interventionDetail: InterventionView
) => Promise<void> = (
  interventionId,
  interventionDetail
) => saveInterventionDetailStored(interventionId, BACKEND_MAPPER(interventionDetail))
  .then(() => saveInterventionFormStored(interventionId, {
    ...FormHelper.BACKEND_MAPPER(interventionDetail),
    currentStep: interventionDetail.currentStep || 0
}));

/**
 * @deprecated
 */
const getStoredInterventionListFromLocalStorage = () => (
  Object.keys(localStorage)
    .filter(key => key.startsWith('intervention_'))
    .map(key => ({
      intervention: JSON.parse(localStorage[key]),
      key,
      id: key.split('intervention_')[1]
    }))
);

const getStoredInterventionList: () => Promise<InterventionFrontView[]> = () => (
  IndexedDBHelper.getAllData({
    store: INTERVENTION_STORE,
    range: IDBKeyRange.bound(
      `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_DETAIL_KEY}`,
      `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_DETAIL_KEY}\uffff`,
      false,
      false
    )
  }).then((inspList: InterventionFrontView[]) => inspList)
);

const getStoredInterventionFormList: () => Promise<FormFrontView[]> = () => (
  IndexedDBHelper.getAllData({
    store: INTERVENTION_STORE,
    range: IDBKeyRange.bound(
      `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_FORM_KEY}`,
      `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_FORM_KEY}\uffff`,
      false,
      false
    )
  }).then((formList: FormFrontView[]) => {
    formList.forEach(form => loadInterventionDetailStored(form.hashId).then(intervention => {
        form = {
          ...form,
          isOutOfSync: intervention.isOutOfSync,
          outOfSyncStatus: intervention.outOfSyncStatus
        };
      }));
    return formList;
  })
);

const deleteInterventionStored: (
  hashId?: string,
  identifier?: string
) => Promise<void> = (hashId, identifier) => {
  if (hashId) {
    IndexedDBHelper.deleteData({
      store: INTERVENTION_STORE,
      key: `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_DETAIL_KEY}_${hashId}`
    }).catch((error) => { throw error; })
      .then(() => IndexedDBHelper.deleteData({
        store: INTERVENTION_STORE,
        key: `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_FORM_KEY}_${hashId}`
      }).catch((error) => { throw error; }));
  }
  return IndexedDBHelper.deleteData({
    store: INTERVENTION_STORE,
    key: `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_DETAIL_KEY}_${identifier}`
  }).catch((error) => { throw error; })
    .then(() => IndexedDBHelper.deleteData({
      store: INTERVENTION_STORE,
      key: `${USER_KEY}_${USER_INSTITUTION_KEY}_${INTERVENTION_FORM_KEY}_${identifier}`
    }).catch((error) => { throw error; }));
};

  /**
   * @deprecated
   */
const transferCurrentInterventionsToIndexedDB = currentInterventions => new Promise((resolve, reject) => {
  // Save intervention detail by mapping old form data into intervention detail
  currentInterventions.map((intervention) => {
    const status = INTERVENTION_STATUS.ONGOING.key;
    return saveInterventionDetailStored(intervention.id, {
      ...intervention.intervention,
      appointment: intervention.intervention?.form?.appointment,
      customerField: {
        entity: intervention.intervention?.form?.equipmentOwner
      },
      fileNumber: intervention.intervention?.form?.fileNumber,
      form: intervention.intervention?.form,
      hashId: intervention.intervention?.form?.hashId,
      interventionDate: intervention.intervention?.form?.interventionDate,
      operator: intervention.intervention?.form?.operator,
      status,
      canEdit: true
    }).then(() => {
      // Save intervention form
      saveInterventionFormStored(intervention.id, {
        ...intervention.intervention.form,
        currentStep: intervention.intervention.currentStep > 0 ? intervention.intervention.currentStep - 1 : 0,
        status
      });
      // Delete intervention from localStorage
      resolve(StorageHelper.DELETE(intervention.key));
    }).catch(error => reject(error));
  });
});

const loadEquipmentOwnersStored: () => Promise<EntitySummaryItem[]> = () => IndexedDBHelper.getData({
  store: REFERENCE_DATA_STORE,
  key: `${EQUIPMENT_OWNERS_KEY}`
}).then((EO: EntitySummaryItem[]) => EO);

const loadEquipmentsStored: () => Promise<RefrigerationUnitSummaryItem[]> = () => IndexedDBHelper.getData({
  store: REFERENCE_DATA_STORE,
  key: `${EQUIPMENTS_KEY}`
}).then(E => E);

const loadLeakDetectorsStored: () => Promise<EquipmentSummaryItem[]> = () => IndexedDBHelper.getData({
  store: REFERENCE_DATA_STORE,
  key: `${LEAK_DETECTORS_KEY}`
}).then(LD => LD);

const loadScalesStored: () => Promise<EquipmentSummaryItem[]> = () => IndexedDBHelper.getData({
  store: REFERENCE_DATA_STORE,
  key: `${SCALES_KEY}`
}).then(S => S);

const loadFluidsStored: () => Promise<FluidView[]> = () => IndexedDBHelper.getData({
  store: REFERENCE_DATA_STORE,
  key: `${FLUIDS_KEY}`
}).then(fluids => fluids);

const loadInstallationTypesStored: () => Promise<SelectItem[]> = () => IndexedDBHelper.getData({
  store: REFERENCE_DATA_STORE,
  key: `${INSTALLATION_TYPES_KEY}`
}).then(types => types);

const loadBottlesStored: () => Promise<BottleView[]> = () => IndexedDBHelper.getData({
  store: REFERENCE_DATA_STORE,
  key: `${BOTTLES_KEY}`
}).then(bottles => bottles);

const loadCountriesStored: () => Promise<CountryView[]> = () => IndexedDBHelper.getData({
  store: REFERENCE_DATA_STORE,
  key: `${COUNTRIES_KEY}`
}).then(countries => countries);

const loadInterventionStatuses: () => Promise<StatusList> = () => IndexedDBHelper.getData({
  store: REFERENCE_DATA_STORE,
  key: `${INTERVENTION_STATUSES_KEY}`
}).then(interventionStatuses => interventionStatuses);

const getOutOfSyncReason = (outOfSyncStatus) => {
  if (outOfSyncStatus?.interventionStatus?.value === INTERVENTION_STATUS.TO_VALIDATE.key
    || outOfSyncStatus?.interventionStatus?.value === INTERVENTION_STATUS.TO_SIGN.key
    || outOfSyncStatus?.interventionStatus?.value === INTERVENTION_STATUS.FINISHED.key
    || outOfSyncStatus?.interventionStatus?.value === INTERVENTION_STATUS.CANCELED.key) {
    return translate('pageInterventionDetail.outOfSyncFinished', { status: translate(`interventionStatus.${outOfSyncStatus?.interventionStatus?.key}`) });
  }

  return translate('pageInterventionDetail.outOfSyncReassigned', { operator: outOfSyncStatus?.assignedOperator.label });
};

const JULY_MONTH = 6;
const getCerfaVersionFromDate: (
    parsedDate: Date
) => string = (
    parsedDate
) => {
  const interventionYear: number = parsedDate.getFullYear();
  const interventionMonth: number = parsedDate.getMonth();
  const interventionDay: number = parsedDate.getDate();
  if (interventionYear >= 2024 && interventionMonth >= JULY_MONTH && interventionDay > 25) {
    return 'v4';
  }
  if (interventionYear >= 2023) {
    return 'v3';
  }
  return 'v2';
};

const getCerfaVersion: (
  interventionDate: string,
  countryKey?: string
) => string = (
  interventionDate,
  countryKey
) => {
  const parsedDate = parseDate(interventionDate);
  let cerfaVersion = 'v4';
  if ((!countryKey || COUNTRY_CODE.FR === countryKey) && interventionDate) {
    cerfaVersion = getCerfaVersionFromDate(parsedDate);
  }
  if (!countryKey) {
    if (interventionDate) {
      return cerfaVersion;
    }
  } else {
    switch (countryKey) {
      case COUNTRY_CODE.FR:
        return cerfaVersion;
      case COUNTRY_CODE.GB:
      case COUNTRY_CODE.IT:
        return countryKey;
      default:
        return cerfaVersion;
    }
  }
  return cerfaVersion;
};

export const InterventionHelper = {
  BUILD_PLANIFICATION,
  BACKEND_MAPPER,
  MAP_COUNTRIES,
  MAP_COUNTRY,
  setCurrentUserAndInstitution,
  displayAppointment,
  getFormattedDateTime,
  formatDate,
  getAddress,
  getCurrentWeek,
  getFirstDayOfTheMonth,
  getLastDayOfTheMonth,
  getFirstDayOfTheYear,
  getLastDayOfTheYear,
  getStoredInterventionListFromLocalStorage,
  getStoredInterventionList,
  getStoredInterventionFormList,
  getOutOfSyncReason,
  saveInterventionDetailStored,
  saveInterventionDetailAndForm,
  saveInterventionFormStored,
  deleteInterventionStored,
  transferCurrentInterventionsToIndexedDB,
  loadInterventionFormStored,
  loadInterventionDetailStored,
  loadEquipmentOwnersStored,
  loadEquipmentsStored,
  loadLeakDetectorsStored,
  loadScalesStored,
  loadFluidsStored,
  loadInstallationTypesStored,
  loadBottlesStored,
  loadCountriesStored,
  loadInterventionStatuses,
  getCerfaVersion
};
