import { ControlDataEntry, StatsDetail } from 'models';
import { InterventionEntry } from 'models/list/InterventionList';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const statsUrl = `${API_URL}stats`;
const dataUrl = `${API_URL}data`;

// GET
const getNextIntervention: () => Promise<InterventionEntry> = () => RequestHelper.GET(`${statsUrl}/next-intervention`);

const getInterventionsStats: (period: string) => Promise<StatsDetail> = (period = 'week') => RequestHelper.GET(`${statsUrl}/${period}/interventions`);

const getValidations: () => Promise<ControlDataEntry[]> = () => RequestHelper.GET(`${dataUrl}/control`);

export const StatsService = {
  getNextIntervention,
  getInterventionsStats,
  getValidations
};
