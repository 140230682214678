import styled from 'styled-components';

export const TitlePage = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 2rem 0;
  color: var(--turquoise);
  font-size: 2.4rem;
  text-transform: uppercase;
  text-align: center;
`;

export const SubtitlePage = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 42px;
  margin: 1rem 0;
  color: var(--black);
  font-size: 1.8rem;
  text-transform: uppercase;
  text-align: center;
`;

export const TextError = styled.p`
  margin: 3rem 0;
  color: var(--grey-dark);
  font-Weight: 600;
  text-align: center;
`;

export const TextNoOptionsAvailable = styled.p`
  margin: 1rem 0 2rem 0;
  color: var(--orange);
  font-Weight: 600;
  text-align: left;
`;

export const SelectLabel = styled.p`
  margin: 0.8rem 0;
  font-size: 1.6rem;
  font-weight: 600;
`;
