import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const institutionGroupsURL = `${API_URL}institution-groups`;

const getInstitutionGroup = (institutionGroupUuid) => RequestHelper.GET(`${institutionGroupsURL}/${institutionGroupUuid}`);
const deleteInstitutionGroup = (institutionGroupUuid) => RequestHelper.DELETE(`${institutionGroupsURL}/${institutionGroupUuid}`);
const createInstitutionGroup = (institutionGroup, activity) => RequestHelper.POST(`${institutionGroupsURL}?activity=${activity}`, institutionGroup);
const updateInstitutionGroup = (institutionGroupUuid, institutionGroup, activity) => RequestHelper.PUT(`${institutionGroupsURL}/${institutionGroupUuid}?activity=${activity}`, institutionGroup);

export const InstitutionGroupService = {
  getInstitutionGroup,
  createInstitutionGroup,
  deleteInstitutionGroup,
  updateInstitutionGroup
};
