import { useEffectAfterRender } from 'hooks/useEffectAfterRender';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

export const useInfiniteLoading = (getItems) => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const loadItems = useCallback((pageChange = false) => {
    setIsLoading(true);
    getItems(currentPage)
      .then((response) => {
        setTotalSize(response.totalElements);
        setHasMore(response.totalPages > currentPage + 1);
        if (pageChange && currentPage > 0) {
          setItems(prevItems => [...prevItems, ...response.content]);
        } else {
          setItems(response.content);
        }
      })
      .catch(error => {
        setItems([]);
        enqueueSnackbar((error && error.message) || error, { variant: 'error' });
      })
      .finally(() => setIsLoading(false));
  }, [currentPage, getItems, enqueueSnackbar]);

  useEffectAfterRender(() => {
    loadItems(true);
  }, []);

  const reloadList = useCallback(() => {
    if (currentPage === 0) {
      loadItems();
    } else setCurrentPage(0);
  }, [currentPage, loadItems]);

  useEffect(() => {
    loadItems(currentPage !== 0);
    // eslint-disable-next-line
  }, [currentPage]);

  const loadMore = useCallback(() => {
    if (isLoading) {
      return;
    }
    setCurrentPage(currPage => currPage + 1);
  }, [isLoading]);

  return {
    items,
    hasMore,
    isLoading,
    totalSize,
    reloadList,
    loadMore
  };
};
