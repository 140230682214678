import { Grid, TextField } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Question } from 'components';
import { useStores } from 'hooks';
import { RefrigerationUnitFilter } from 'models/request/list/RefrigerationUnitFilter';
import React from 'react';
import Select from 'react-select';
import shortid from 'shortid';
import { VALIDATED_TYPES } from 'utils/constants';
import { FilterHelper, translate } from 'utils/helpers';

export const EquipmentListFilters = ({
  currentFilters, setCurrentFilters
}) => {
  const { fluidStore } = useStores();

  const handleChangeInput = (
    key: keyof RefrigerationUnitFilter,
    value: any,
    label: string
  ) => {
    setCurrentFilters(FilterHelper.setFiltersValue(currentFilters, key, value, label));
  };

  return (
    <Grid container direction="column">
      <Question label={translate('pageEquipmentList.findEquipment')}>
        <TextField
          label={translate('common.model')}
          name="model"
          value={FilterHelper.getFiltersValue(currentFilters, 'model') || ''}
          onChange={event => handleChangeInput('model', event.target.value, event.target.value)}
        />
        <TextField
          label={translate('common.serialNumber')}
          name="serialNumber"
          value={FilterHelper.getFiltersValue(currentFilters, 'serialNumber') || ''}
          onChange={event => handleChangeInput('serialNumber', event.target.value, event.target.value)}
        />
      </Question>

      <Question label={`${translate('modalBottle.chooseFluid')} :`}>
        <Select
          id="selectFluid"
          isClearable
          isSearchable
          menuPortalTarget={document.body}
          noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
          options={fluidStore.fluidOptions}
          placeholder={translate('common.searchByFluidType')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
          value={fluidStore.fluidOptions.find(f => f.value === FilterHelper.getFiltersValue(currentFilters, 'fluidId'))}
          onChange={(value) => handleChangeInput('fluidId', value?.value, value?.label)}
        />
      </Question>

      <Question label={translate('common.equipmentState')}>
        <ToggleButtonGroup
          aria-label={translate('common.validated')}
          exclusive
          size="medium"
          value={FilterHelper.getFiltersValue(currentFilters, 'validated')}
          onChange={(_, value) => {
            const label = (value !== null && value && translate('common.validated')) || translate('common.unvalidated');
            handleChangeInput('validated', value, label);
          }}
        >
          {VALIDATED_TYPES.map(type => (
            <ToggleButton
              aria-label={translate(`common.${type.key}`)}
              key={shortid.generate()}
              value={type.value}
            >
              {translate(`common.${type.key}`)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Question>

      <Question label={translate('common.activated')}>
        <ToggleButtonGroup
          aria-label={translate('common.activated')}
          exclusive
          size="medium"
          value={FilterHelper.getFiltersValue(currentFilters, 'activated')}
          onChange={(_, value) => {
            const label = (value !== null && value && translate('common.activated')) || translate('common.deactivated');
            handleChangeInput('activated', value, label);
          }}
        >
          <ToggleButton
            aria-label={translate('common.yes')}
            key={shortid.generate()}
            value={true}
          >
            {translate('common.yes')}
          </ToggleButton>
          <ToggleButton
            aria-label={translate('common.no')}
            key={shortid.generate()}
            value={false}
          >
            {translate('common.no')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Question>
    </Grid>
  );
};
