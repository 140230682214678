import React from 'react';

import { Grid, TextField } from '@material-ui/core';
import Select from 'react-select';

import { Datepicker, Question } from 'components';
import { useStores } from 'hooks';
import { translate } from 'utils/helpers';

export const PendingMovementsListFilters = ({
  currentFilters, setCurrentFilters
}) => {
  const { fluidStore } = useStores();
  const bottleIdentifier = (currentFilters.find(filt => filt.key === 'bottleIdentifier')
    && currentFilters.find(filt => filt.key === 'bottleIdentifier').label) || '';
  const bsffIdentifier = (currentFilters.find(filt => filt.key === 'bsffIdentifier')
    && currentFilters.find(filt => filt.key === 'bsffIdentifier').label) || '';

  const fluidType = (currentFilters.find(filt => filt.key === 'fluidType') && currentFilters.find(filt => filt.key === 'fluidType').value) || null;

  const startDateValue = (currentFilters.find(filt => filt.key === 'startDate') && currentFilters.find(filt => filt.key === 'startDate').label) || null;
  const endDateValue = (currentFilters.find(filt => filt.key === 'endDate') && currentFilters.find(filt => filt.key === 'endDate').label) || null;

  const handleChangeInput = (value, name) => (
    setCurrentFilters([...currentFilters.filter(filt => filt.key !== name), { key: name, label: value }])
  );

  const handleChangeDate = ({ date, key }) => {
    key && setCurrentFilters([...currentFilters.filter(filt => filt.key !== key), { key, label: date }]);
  };

  return (
    <section>
      <Question label={translate('modalFluidMovement.bottleIdentifier')}>
        <TextField
          label={translate('common.searchByIdentifier')}
          name="bottleIdentifier"
          value={bottleIdentifier}
          onChange={event => handleChangeInput(event.target.value, 'bottleIdentifier')}
        />
      </Question>

      <Question label={translate('common.bsffIdentifier')}>
        <TextField
          label={translate('common.searchByIdentifier')}
          name="bsffIdentifier"
          value={bsffIdentifier}
          onChange={event => handleChangeInput(event.target.value, 'bsffIdentifier')}
        />
      </Question>

      <Question label={translate('common.fluidType')}>
        <Select
          id="fluidType"
          isClearable
          isSearchable
          menuPortalTarget={document.body}
          noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
          options={fluidStore.fluidOptions}
          placeholder={translate('common.selectType')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
          value={fluidType || ''}
          onChange={(select) => {
            if (select) {
              setCurrentFilters([...currentFilters.filter(filt => filt.key !== 'fluidType'), {
                key: 'fluidType', label: select.label, value: select
              }]);
            } else {
              setCurrentFilters([...currentFilters.filter(filt => filt.key !== 'fluidType')]);
            }
          }}
        />
      </Question>

      <Question label={translate('common.date')}>
        <Grid container direction="column">
          <Datepicker
            label="common.startDate"
            maxDate={endDateValue || new Date()}
            value={startDateValue}
            onChange={date => handleChangeDate({ date, key: 'startDate' })}
          />
          <Datepicker
            label="common.endDate"
            minDate={startDateValue || new Date()}
            value={endDateValue}
            onChange={date => handleChangeDate({ date, key: 'endDate' })}
          />
        </Grid>
      </Question>
    </section>
  );
};
