import React, { Fragment } from 'react';

import styled from 'styled-components';

import shortid from 'shortid';

import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { pluralize, translate } from 'utils/helpers';

import {
  ButtonBigDashed, FlexContainer, FontIcon, ItemOption, ItemSelected, Text, TextMultiline
} from 'components';

import { useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { LeakEntryView } from 'models';
import {
  Args, FormState, InputInitializer, StateErrors
} from 'react-use-form-state';
import { Question } from '../Question/Question';

const LeakItemContainer = styled.div`
  margin-bottom: 1rem;
`;

interface FormStateLeaksView {
  leakFound?: boolean;
  leaks?: LeakEntryView[];
}

export const Leaks = observer(({
  formState,
  text
}: {
  formState: FormState<FormStateLeaksView, StateErrors<FormStateLeaksView, string>>,
  text: InputInitializer<FormStateLeaksView, Args<keyof FormStateLeaksView, void>, any>
}) => {
  const { open, close } = useModal();

  const getNextIndex = () => {
    let index = 1;
    // eslint-disable-next-line no-loop-func
    while (formState.values.leaks.find(l => l.index === index)) {
      index++;
    }
    return index;
  };

  const handleForm = (form: LeakEntryView, create: boolean) => {
    const currentLeaks = formState.values.leaks;

    if (create) {
      // Get the next available index and add the leak to the list
      const index = getNextIndex();
      currentLeaks.push({
        ...form,
        index
      });
    } else {
      // Update the indexed leak in the list
      const existingLeakIndex = currentLeaks.findIndex(leak => leak.index === form.index);
      currentLeaks[existingLeakIndex] = form;
    }

    // Update the leak list
    formState.setField('leaks', currentLeaks);

    close();
  };

  const removeLeak = (index: number) => {
    // Remove the leak with the matching index
    const currentLeaks = formState.values.leaks.filter(leak => leak.index !== index);
    formState.setField('leaks', currentLeaks);
    (currentLeaks.length === 0) && formState.setField('leakFound', false);
  };

  const resetLeaks = () => {
    const messageSingle = translate('interventionForm.leaks.resetLeakSingle');
    const messageMulti = translate('interventionForm.leaks.resetLeakMulti');

    open({
      type: 'WARNING',
      text: formState.values.leaks.length > 1 ? messageMulti : messageSingle,
      buttonConfirm: translate('button.accept'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => formState.setField('leaks', []),
      onCancel: () => formState.setField('leakFound', true)
    });
  };

  return (
    <Question label={translate('interventionForm.leaks.questionSawLeak')}>
      <ToggleButtonGroup
        aria-label={translate('interventionForm.leaks.questionSawLeak')}
        exclusive
        name="leakFound"
        size="medium"
        {...text('leakFound')}
        onChange={(event, value) => {
          if (value === null) return;
          formState.setField('leakFound', value);
          value === false && formState.values.leaks.length > 0 && resetLeaks();
        }}
      >
        <ToggleButton
          aria-label={translate('common.yes')}
          data-cy="foundLeaks"
          value
          onClick={() => open({
            type: 'ADD_LEAK',
            onSubmit: (form: LeakEntryView) => handleForm(form, true),
            onClose: formState.values.leaks.length === 0 && (() => formState.setField('leakFound', false))
          })}
        >
          {translate('common.yes')}
        </ToggleButton>
        <ToggleButton
          aria-label={translate('common.no')}
          value={false}
        >
          {translate('common.no')}
        </ToggleButton>
      </ToggleButtonGroup>

      {formState.values.leaks && formState.values.leaks.length > 0 && (
        <Fragment>
          <Text fontWeight={600}>
            {translate('interventionForm.leaks.allLeakAdded', {
              total: formState.values.leaks.length,
              leaks: pluralize('fuite', formState.values.leaks.length),
              added: pluralize('constatée', formState.values.leaks.length)
            })}
          </Text>
          {formState.values.leaks.map((leak, index) => (
            <LeakItemContainer data-cy={`leakAdded${index}`} key={shortid.generate()}>
              <ItemSelected
                deleteAction={() => removeLeak(leak.index)}
                editAction={() => open({
                  type: 'ADD_LEAK',
                  onSubmit: (form: LeakEntryView) => handleForm(form, false),
                  onClose: formState.values.leaks.length === 0 && (() => formState.setField('leakFound', false)),
                  currentLeak: leak
                })}
              >
                <ItemOption>
                  <FlexContainer alignItems="center" flexDirection="column">
                    <FontIcon
                      color={leak.leakRepaired ? 'var(--green)' : 'var(--red)'}
                      fontSize="4rem"
                      icon="icon-hazard"
                    />
                    <Text color={leak.leakRepaired ? 'var(--green)' : 'var(--red)'} textTransform="uppercase">
                      {`${leak.leakRepaired ? translate('common.done') : translate('common.todo')}`}
                    </Text>
                  </FlexContainer>
                  <article>
                    <TextMultiline>{leak.location}</TextMultiline>
                  </article>
                  {leak.documents && leak.documents.length > 0 && (
                    <FlexContainer alignItems="center">
                      {leak.documents.map(picture => (
                        <img
                          alt={picture.name}
                          key={shortid.generate()}
                          src={picture.base64Content}
                          onClick={() => open({
                            ...picture,
                            type: 'DISPLAY_PICTURE'
                          })}
                        />
                      ))}
                    </FlexContainer>
                  )}
                </ItemOption>
              </ItemSelected>
            </LeakItemContainer>
          ))}
        </Fragment>
      )}

      {formState.values.leakFound && formState.values.leaks.length < 3 && (
        <p>
          <ButtonBigDashed onClick={() => open({
            type: 'ADD_LEAK',
            onSubmit: (form: LeakEntryView) => handleForm(form, true),
            onClose: formState.values.leaks.length === 0 && (() => formState.setField('leakFound', false))
          })}>
            <FontAwesomeIcon icon={faPlus} />
            {translate('interventionForm.leaks.addNewLeak')}
          </ButtonBigDashed>
        </p>
      )}
    </Question>
  );
});
