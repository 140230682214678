import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { translate } from 'utils/helpers';
import sanitizeHtml from 'sanitize-html';

export const ConfirmModal = ({
  onClose, onConfirm, text, title
}) => (
  <Fragment>
    <DialogTitle className="confirmModal">
      <FontAwesomeIcon icon={faCheckCircle} />
      {title ?? translate('common.actionSuccessful')}
    </DialogTitle>

    <DialogContent
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }}
      style={{ maxWidth: '400px' }}
    />

    <DialogActions>
      <Button color="primary" onClick={() => { if (onConfirm) { onConfirm(); } return onClose(); }}>
        {translate('button.close')}
      </Button>
    </DialogActions>
  </Fragment>
);

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  title: PropTypes.string
};

ConfirmModal.defaultProps = {
  onConfirm: null,
  text: '',
  title: null
};
