import { CountryView } from 'models';
import { userStore } from 'stores';
import { APPLICATION_ROLES } from 'utils/constants';

const hasAccessRight = (requiredRoles: string[]) => {
  if (userStore.scope && userStore.scope.roles) {
    return requiredRoles.some((role) => Object.values(userStore.scope.roles).includes(role));
  }
  return false;
};

const hasAccessRightCreateIntervention = () => hasAccessRight([
  APPLICATION_ROLES.OPERATOR,
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.INTERVENTION_MANAGER,
  APPLICATION_ROLES.APPOINTMENT_MANAGER
]);

const FRANCE: CountryView = {
  id: '0',
  key: 'fr',
  activated: false,
  actualTranslation: 'France',
  identifiers: [],
  numberOfLegalStatuses: 0,
  translations: []
};

// TODO: Load the full level description to identify the country
const getDefaultCountry: () => CountryView = () => {
  const { scope } = userStore;
  return scope?.country ?? FRANCE;
};

export const UserHelper = {
  hasAccessRight,
  hasAccessRightCreateIntervention,
  getDefaultCountry
};
