/* eslint-disable react/jsx-no-bind */
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Typography } from '@material-ui/core';
import {
  ButtonBlue, FlexContainer, FontIcon, GenericList, GenericListItem,
  InterventionListItemDetails, SkeletonList, SubtitlePage, TextError, Wrapper
} from 'components';
import { useStores } from 'hooks';
import { FormFrontView } from 'models/detail/FormFrontView';
import { InterventionEntry } from 'models/list/InterventionList';
import React from 'react';
import { isMobile } from 'react-device-detect';
import shortid from 'shortid';
import { StatusList } from 'utils/constants';
import { FormHelper, translate, UserHelper } from 'utils/helpers';

export const ListCardMode = ({
  INTERVENTION_STATUS,
  currentPage,
  deleteAction,
  displayModalCreateIntervention,
  filterStoredInterventionOnSearch,
  interventionList,
  isExportable,
  isLoading,
  lastAutomaticDownload,
  loadMore,
  maxPage,
  reloadList,
  storageUsage,
  storedInterventionList
}: {
  INTERVENTION_STATUS: StatusList,
  currentPage: number,
  deleteAction: (e, hashId: string, identifier: string, isFromStorage: boolean) => void,
  displayModalCreateIntervention: () => void,
  filterStoredInterventionOnSearch: (form: FormFrontView) => boolean,
  interventionList: InterventionEntry[],
  isExportable: (interventionStatus: string) => boolean,
  isLoading: boolean,
  lastAutomaticDownload: string,
  loadMore: () => void,
  maxPage: number,
  reloadList: () => void,
  storageUsage: {
    interventionsRemaining: number,
    interventionsStored: number,
    percentageUsed: string
  },
  storedInterventionList: FormFrontView[]
}) => {
  const { userStore } = useStores();

  return (
    <>
      {storedInterventionList.length > 0 && (
        <>
          <Grid alignItems="center" container direction="column" spacing={1}>
            <Grid item>
              <Grid alignItems="center" container justifyContent="center" spacing={2}>
                <Grid item>
                  <SubtitlePage>{translate('pageInterventionList.interventionsOngoing')}</SubtitlePage>
                </Grid>
                {storageUsage && (
                  <Grid item>
                    <Typography>{`${translate('pageInterventionList.percentageUsed', { percent: storageUsage.percentageUsed })}`}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {lastAutomaticDownload && (
              <Grid item>
                <Typography style={{ marginTop: isMobile ? 0 : '-10px', marginBottom: 20 }} variant="body2">
                  {`${translate('pageInterventionList.lastAutomaticDownload', { lastAutomaticDownload })}`}
                </Typography>
              </Grid>
            )}
          </Grid>

          <GenericList dataCy="interventionListStored" dataTour="step-intervention-list-local">
            {storedInterventionList
              .filter(intervention => filterStoredInterventionOnSearch(intervention))
              .map((storedIntervention) => {
                if (storedIntervention) {
                  return (
                    <GenericListItem
                      isDownloaded
                      key={shortid.generate()}
                      link={{
                        pathname: `/interventions/${storedIntervention.hashId || storedIntervention.fileNumber}/detail`,
                        state: {
                          isNew: !storedIntervention.hashId
                        }
                      }}
                    >
                      <InterventionListItemDetails
                        deleteAction={e => deleteAction(e, storedIntervention.hashId, storedIntervention.fileNumber, true)}
                        intervention={FormHelper.FRONT_LIST_MAPPER(storedIntervention)}
                        INTERVENTION_STATUS={INTERVENTION_STATUS}
                        isDeletable={true}
                        isExportable={isExportable}
                        tooltipCheckText={translate('pageInterventionList.tooltipExportCerfa')}
                        tooltipDeleteText={translate('pageInterventionList.tooltipDelete')}
                      />
                    </GenericListItem>
                  );
                }
                return null;
              })}
          </GenericList>
        </>
      )}

      {!isLoading && (!interventionList || interventionList.length === 0)
        ? (
          <FlexContainer alignItems="center" flexDirection="column">
            <FontIcon color="var(--grey)" fontSize="6rem" icon="icon-intervention" marginTop="1rem" />
            {storedInterventionList.length === 0 && <TextError>{translate('errors.noIntervention')}</TextError>}

            {userStore.isViewingInstitution() && UserHelper.hasAccessRightCreateIntervention() && (
              <ButtonBlue data-cy="createNewIntervention" onClick={displayModalCreateIntervention}>
                <FontIcon fontSize="2rem" icon="icon-create-intervention" />
                {translate('pageHome.buttonCreate')}
              </ButtonBlue>
            )}
          </FlexContainer>
        ) : interventionList && interventionList.length > 0 && (
          <>
            <SubtitlePage>
              {translate('pageInterventionList.interventionsOthers')}
              <IconButton className="ml1" onClick={reloadList}>
                <FontAwesomeIcon color="var(--blue)" icon={faSync} spin={isLoading} />
              </IconButton>
              {/* <CustomTooltip text={translate('pageInterventionList.interventionsOthersTooltip')} /> */}
            </SubtitlePage>
            <GenericList
              dataTour="step-intervention-list-database"
              hasMore={currentPage < maxPage}
              loadMore={loadMore}
            >
              {interventionList
                // Hide started interventions that are in the IndexedDB from the main list
                .filter((intervention) => {
                  const storedIdList = storedInterventionList.map(i => i.hashId || i.fileNumber);
                  return (!storedIdList.includes(intervention.hashId) && !storedIdList.includes(intervention.fileNumber));
                })
                .map(intervention => (
                  <GenericListItem key={shortid.generate()} link={`/interventions/${intervention.hashId}/detail`}>
                    <InterventionListItemDetails
                      deleteAction={(e, hashId) => deleteAction(e, hashId, null, false)}
                      intervention={intervention}
                      INTERVENTION_STATUS={INTERVENTION_STATUS}
                      isDeletable={intervention.canDelete}
                      isExportable={isExportable}
                      tooltipCheckText={translate('pageInterventionList.tooltipExportCerfa')}
                      tooltipDeleteText={translate('pageInterventionList.tooltipDelete')}
                    />
                  </GenericListItem>
                ))}
            </GenericList>
          </>
        )
      }

      {isLoading && <Wrapper><SkeletonList /></Wrapper>}
    </>
  );
};
