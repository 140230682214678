import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { AddressView } from 'models';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledText = styled.p<any>`
  ${props => props.position && css`position: ${props.position};`}
  ${props => props.display && css`display: ${props.display};`}
  ${props => props.top && css`top: ${props.top};`}
  ${props => props.left && css`left: ${props.left};`}
  ${props => props.right && css`right: ${props.right};`}
  ${props => props.bottom && css`bottom: ${props.bottom};`}
  ${props => props.width && css`width: ${props.width};`}
  ${props => props.margin && css`margin: ${props.margin};`}
  ${props => props.marginLeft && css`margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && css`margin-right: ${props.marginRight};`}
  ${props => props.marginTop && css`margin-top: ${props.marginTop};`}
  ${props => props.marginBottom && css`margin-bottom: ${props.marginBottom};`}
  ${props => props.padding && css`padding: ${props.padding};`}
  ${props => props.color && css`color: ${props.color};`}
  ${props => props.lineHeight && css`line-height: ${props.lineHeight};`}
  ${props => props.fontSize && css`font-size: ${props.fontSize};`}
  ${props => props.fontFamily && css`font-family: ${props.fontFamily};`}
  ${props => props.fontStyle && css`font-style: ${props.fontStyle};`}
  ${props => props.fontWeight && css`font-weight: ${props.fontWeight};`}
  ${props => props.textAlign && css`text-align: ${props.textAlign};`}
  ${props => props.textDecoration && css`text-decoration: ${props.textDecoration};`}
  ${props => props.textTransform && css`text-transform: ${props.textTransform};`}
  ${props => props.textIndent && css`text-indent: ${props.textIndent};`}
  ${props => props.verticalAlign && css`vertical-align: ${props.verticalAlign};`}
  ${props => props.letterSpacing && css`letter-spacing: ${props.letterSpacing};`}
  ${props => props.border && css`border: ${props.border};`}
  ${props => props.background && css`background: ${props.background};`}
  ${props => props.textShadow && css`text-shadow: ${props.textShadow};`}
  ${props => props.boxShadow && css`box-shadow: ${props.boxShadow};`}
  ${props => props.zindex && css`z-index: ${props.zindex};`}
`;

const StyledTextMultiline = styled<any>(StyledText)`
  white-space: pre-line;
`;

export const Text = ({ children, ...props }) => (
  <StyledText {...props}>{children}</StyledText>
);

export const TextMultiline = ({ children, ...props }) => (
  <StyledTextMultiline {...props}>{children}</StyledTextMultiline>
);

const TextAddressString = ({
  address = ''
}: {
  address: string
}) => (
  <>
    {address}
  </>
);

const TextAddressObject = ({
  address = new AddressView()
}: {
  address: AddressView
}) => (
  <>
    {`${address.address1 ?? ''} ${address.address2 ?? ''}`}
    <br />
    {`${address.postalCode || ''} ${address.city || ''} ${address.country ? address.country.label : ''}`}
  </>
);

export const TextAddress = ({
  address,
  withIcon = false,
  variant = 'body2'
}: {
  address: AddressView | string,
  variant?: Variant,
  withIcon?: boolean
}) => (
  <Typography variant={variant}>
    {withIcon && <FontAwesomeIcon className="mr1" icon={faMapMarkerAlt} />}
    {typeof address === 'string' ? <TextAddressString address={address} /> : <TextAddressObject address={address} />}
  </Typography>
);
