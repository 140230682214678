import PropTypes from 'prop-types';
import React, { Children } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

export const Translate = ({
  children, text, component, variant
}) => {
  const { t } = useTranslation();

  return (
    <Typography component={component} variant={variant}>
      {
        children ? (
          Children.map(children, (child) => {
            if (typeof child === 'string') {
              return t(child);
            }
            return child;
          })
        ) : t([text, text.split('.')[1]])
      }
    </Typography>
  );
};

Translate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  component: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string
};

Translate.defaultProps = {
  children: null,
  component: 'span',
  text: null,
  variant: null
};
