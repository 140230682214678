import {
  faCalendarAlt, faCheck, faEdit, faFileImport, faInfoCircle, faSpinner, faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Fab, IconButton, TableCell
} from '@material-ui/core';
import {
  ButtonBlue,
  ButtonBlueLight,
  ButtonFloating,
  CustomIcon,
  FlexContainer,
  GenericListContainer,
  GenericListFilters,
  GenericTable,
  ReactTour,
  SkeletonTableSmall,
  SubtitlePage,
  Text,
  TextError
} from 'components';
import { differenceInMonths } from 'date-fns';
import React, {
  Fragment, useCallback, useState
} from 'react';
import Media from 'react-media';
import { DocumentsService, EquipmentsService, ToolsService } from 'services';
import shortid from 'shortid';
import { APPLICATION_ROLES, TOOLS_MONTHS_EXPIRATION, TOOLS_MONTHS_EXPIRATION_MAX } from 'utils/constants';
import {
  DocumentHelper, FilterHelper,
  UserHelper,
  formatDateString, translate
} from 'utils/helpers';

import { faFileExcel, faTools } from '@fortawesome/pro-light-svg-icons';
import { ToolListFilter } from 'components/Tools/ToolList/ToolListFilter';
import {
  useEffectAfterRender, useExcelImport, useModal, useStores
} from 'hooks';
import { ModalState } from 'hooks/ModalProvider';
import { observer } from 'mobx-react-lite';
import { EquipmentView } from 'models';
import { ToolSummaryItem } from 'models/list/ToolSummary';
import { EquipmentFilter } from 'models/request/list/EquipmentFilter';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { HeaderType } from 'utils/types';
import { FilterItem } from 'utils/types/FilterItem';
import { ToggleValidationButton } from '../../Form/ToggleButtons/ToggleValidationButton';

const getDateColorDisplay = (date) => {
  if (!date) return 'var(--black)';

  const monthsOld = differenceInMonths(new Date(), new Date(date));

  if (monthsOld > TOOLS_MONTHS_EXPIRATION_MAX) {
    return 'var(--red)';
  }
  if (monthsOld > TOOLS_MONTHS_EXPIRATION) {
    return 'var(--orange)';
  }

  return 'var(--black)';
};

const ActivationTemplate = ({
  row, updateToolInList
}) => {
  const [activatedTool, setActivatedTool] = useState(row?.activated);

  const { enqueueSnackbar } = useSnackbar();

  const handleToggleChange = useCallback((e, tool, value) => {
    const activated = value === 'on';
    e.preventDefault();
    EquipmentsService.toggleActivation(tool?.id)
        .then(
            () => {
              setActivatedTool(activated);
              enqueueSnackbar(translate('confirms.toolList.update'), { variant: 'success' });
              updateToolInList({
                ...tool,
                activated
              });
            }
        ).catch(
        (error) => enqueueSnackbar(error?.message ?? error, { variant: 'error' })
    );
  }, [enqueueSnackbar, updateToolInList]);

  return (
    <ToggleValidationButton
        name="activationToggle"
        value={activatedTool ? 'on' : 'off'}
        onChange={(e, value) => handleToggleChange(e, row, value)}
    />
  );
};

const getListHeaders: (
  updateToolInList: (tool: ToolSummaryItem) => void,
  editAction: (tool: ToolSummaryItem) => void
) => HeaderType<ToolSummaryItem>[] = (
  updateToolInList,
  editAction
) => [
  {
    name: 'brand',
    label: translate('common.brand'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.brand || '-'}
      </TableCell>
    )
  }, {
    name: 'model',
    label: translate('common.model'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.model || '-'}
      </TableCell>
    )
  }, {
    name: 'serialNumber',
    label: translate('common.serialNumber'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.serialNumber}
      </TableCell>
    )
  }, {
    name: 'lastCheckupDate',
    label: translate('common.verificationDate'),
    template: row => (
      <TableCell key={shortid.generate()} style={{ color: getDateColorDisplay(row.lastControlDate) }}>
        <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '0.5rem' }} />
        {(row.lastControlDate && `${formatDateString(row.lastControlDate)}`) || '-'}
      </TableCell>
    )
  },
  {
    name: 'type',
    label: translate('common.toolTypes'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.type.label}
      </TableCell>
    )
  }, {
    name: 'validated',
    label: translate('common.validated'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <FontAwesomeIcon color={row.validated ? 'var(--green)' : 'var(--red)'} icon={row.validated ? faCheck : faTimes} />
      </TableCell>
    )
  }, {
    name: 'actions',
    label: translate('common.actions'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <IconButton onClick={() => editAction(row)}>
          <FontAwesomeIcon color="var(--blue)" icon={faEdit} size="sm" />
        </IconButton>
      </TableCell>
    )
  },
  {
    name: 'activation',
    label: translate('common.listHeader.activation'),
    toolTipLabel: translate('common.tooltip.activation'),
    toolTipIcon: faInfoCircle,
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ActivationTemplate row={row} updateToolInList={updateToolInList}/>
        </div>
      </TableCell>
    )
  }
];

export const ToolList = observer(() => {
  const { userStore } = useStores();
  const { open, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const filterKey = 'toolList';
  const locationState: any = location.state;
  const historyFilter: FilterItem<EquipmentFilter>[] = (locationState && locationState.filters);
  const defaultFilters: FilterItem<EquipmentFilter>[] = [{
    key: 'activated',
    label: translate('common.activated'),
    booleanValue: true
  }];

  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [toolList, setToolList] = useState([]);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(historyFilter);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [listSize, setListSize] = useState(0);

  const getToolList = useCallback(async ({ pageChange = false } = {}) => {
    setIsLoading(true);
    await ToolsService.filterToolList(FilterHelper.buildFilterForm(filters, search), currentPage).then((response) => {
      if (response) {
        let allTools = response.content;
        if (pageChange && currentPage > 1) {
          allTools = [].concat(...toolList, response.content);
        }

        setToolList(allTools);
        setMaxPage(response.totalPages);
        setListSize(response.totalElements);
      }
    }).finally(() => setIsLoading(false));
  }, [search, filters, toolList, currentPage]);

  const reloadList = useCallback(() => {
    if (currentPage === 1) getToolList({ pageChange: false });
    else setCurrentPage(1);
  }, [currentPage, getToolList]);

  useEffectAfterRender(() => {
    reloadList();
    // eslint-disable-next-line
  }, [filters, search]);

  useEffectAfterRender(() => {
    getToolList({ pageChange: currentPage !== 1 });
    // eslint-disable-next-line
  }, [currentPage]);

  const createTool = useCallback((form: EquipmentView) => {
    const formFormatted = {
      ...form,
      documents: DocumentHelper.getDocumentsFormatted({ documents: form.documentList.documents, withBase64: false })
    };

    if (formFormatted.id) {
      return ToolsService.updateTool(formFormatted.id, formFormatted).then(() => {
        close();
        enqueueSnackbar(translate('confirms.toolList.update'), { variant: 'success' });
        reloadList();
      }).catch((error) => enqueueSnackbar(error, { variant: 'error', autoHideDuration: 5000 }));
    }

    return ToolsService.createTool(formFormatted).then(() => {
      close();
      enqueueSnackbar(translate('confirms.toolList.create'), { variant: 'success' });
      reloadList();
    }).catch((error) => enqueueSnackbar(error, { variant: 'error', autoHideDuration: 5000 }));
  }, [close, enqueueSnackbar, reloadList]);

  const displayToolModal = useCallback(row => open({
    type: 'ADD_TOOL',
    onSubmit: createTool,
    toolId: row.id,
    withPicture: true,
    openSecondaryModal: (options: ModalState) => open(options, true)
  }), [open, createTool]);

  const {
    isImporting,
    importFunc: importTool
  } = useExcelImport(ToolsService.importTools, reloadList);

  const loadMore = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setCurrentPage(currentPage + 1);
  }, [isLoading, currentPage]);

  const exportTools = useCallback(() => {
    setIsExporting(true);
    const EXPORT_MAIL_SIZE = 1000;
    if (Number(listSize) > EXPORT_MAIL_SIZE) {
      const timeout = new Promise((res: any) => {
        setTimeout(() => res(), 1500);
      });
      const controller = new AbortController();
      const { signal } = controller;
      Promise.race([ToolsService.exportToolsAsync(FilterHelper.buildFilterForm(filters, search), signal), timeout])
        .then(() => {
          enqueueSnackbar(
            translate('confirms.tool.exportByEmail').replace('<br />', '\n'),
            { variant: 'success', autoHideDuration: 5000 }
          );
          controller.abort();
        })
        .catch((error) => enqueueSnackbar(
          (error.message || error || translate('errors.export')).replace('<br />', '\n'),
          { variant: 'error', autoHideDuration: 5000 }
        ))
        .finally(() => setIsExporting(false));
    } else {
      ToolsService.exportTools(FilterHelper.buildFilterForm(filters, search)).then((resp) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = DocumentHelper.getExcelWithBase64(resp);
        downloadLink.download = resp.name;
        downloadLink.click();
      })
        .catch((error) => enqueueSnackbar((error.message || error || translate('errors.export')).replace('<br />', '\n'), { variant: 'error', autoHideDuration: 5000 }))
        .finally(() => setIsExporting(false));
    }
  }, [listSize, enqueueSnackbar, filters, search]);

  const renderFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <ToolListFilter
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const renderToolListTable = (listHeadersTable, withRowAction) => (
    <GenericTable<ToolSummaryItem>
      dataCy="toolList"
      hasMore={currentPage < maxPage}
      headers={listHeadersTable}
      id="toolList"
      loadMore={loadMore}
      rows={toolList}
      total={listSize}
      onRowClick={withRowAction && displayToolModal}
    />
  );

  const renderButtonCreateMobile = useCallback(() => (
    <ButtonFloating
      dataCy="createNewTool"
      onClick={displayToolModal}
    >
      <Fab aria-label="create-tool" color="primary">
        <CustomIcon icon={<FontAwesomeIcon icon={faTools} />} />
      </Fab>
    </ButtonFloating>
  ), [displayToolModal]);

  const renderButtonContainer = useCallback(() => (
    <div>
      <ButtonBlue
        data-cy="createTool"
        data-tour="step-admin-toolCreate"
        onClick={displayToolModal}
      >
        <CustomIcon icon={<FontAwesomeIcon icon={faTools} />} />
        {translate('button.createTool')}
      </ButtonBlue>
      <br />
      <br />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        {UserHelper.hasAccessRight([APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN]) && (
          <Fragment>
            <div>
              <div>
                <ButtonBlueLight
                  data-cy="importTool"
                  data-tour="step-intervention-equipmentOwnerImport"
                  onClick={importTool}
                >
                  {isImporting ? (
                    <Fragment>
                      <FontAwesomeIcon icon={faSpinner} spin />
                      {translate('button.importing')}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <FontAwesomeIcon icon={faFileImport} />
                      {translate('button.importTools')}
                    </Fragment>
                  )}
                </ButtonBlueLight>
                <Button onClick={() => DocumentsService.getImportFile('tools')}>
                  <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                    {translate('common.exampleFile')}
                  </Text>
                </Button>
              </div>
            </div>
          </Fragment>
        )}
        <div>
          <ButtonBlueLight
            data-cy="exportEquipments"
            data-tour="step-equipment-export"
            disabled={isExporting}
            onClick={exportTools}
          >
            <FontAwesomeIcon icon={isExporting ? faSpinner : faFileExcel} spin={isExporting} />
            {'Export des outillages'}
          </ButtonBlueLight>
        </div>
      </div>
      <br />
    </div>
  ), [importTool, exportTools, displayToolModal, isImporting, isExporting]);

  const updateToolInList: (tool: ToolSummaryItem) => void = useCallback((toolUpdated) => {
    setToolList(toolList.map(
        (t) => (t.id === toolUpdated.id ? toolUpdated : t)
    ));
  }, [toolList, setToolList]);

  return (
    <>
      <GenericListContainer>
        <SubtitlePage>
          {translate('pageToolList.title')}
        </SubtitlePage>
        {userStore.isViewingInstitution() && renderButtonContainer()}
        <GenericListFilters
          ComponentFilter={renderFilters}
          defaultFilters={defaultFilters}
          disabled={false}
          filterKey={filterKey}
          filters={filters}
          isVisible
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          withSidePanel
        />

        <div data-tour="step-admin-toolList">
          {!isLoading && toolList && toolList.length === 0
            ? (
              <FlexContainer alignItems="center" flexDirection="column">
                <TextError>{translate('pageToolList.noToolAvailable')}</TextError>
                {userStore.isViewingInstitution() && (
                  <ButtonBlue
                    data-cy="createTool"
                    onClick={displayToolModal}
                  >
                    <CustomIcon icon={<FontAwesomeIcon icon={faTools} />} />
                    {translate('button.createTool')}
                  </ButtonBlue>
                )}
              </FlexContainer>
            ) : (
              <Media query="(max-width: 768px)">
                {matches => (matches
                  ? renderToolListTable([...getListHeaders(updateToolInList, displayToolModal)].splice(0, 3), true)
                  : renderToolListTable(getListHeaders(updateToolInList, displayToolModal), false)
                )}
              </Media>
            )
          }
        </div>

        {isLoading && <SkeletonTableSmall />}

        {userStore.isViewingInstitution() && (
          <Media
            query="(max-width: 1080px)"
            render={renderButtonCreateMobile}
          />
        )}
      </GenericListContainer>

      <ReactTour steps={[]} />
    </>
  );
});
