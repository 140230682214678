import i18n from 'i18next';
import { HeadersType } from 'models/headers.model';
import { MethodEnum } from 'models/http.enum';
import { KeyCloakUtils } from 'utils/keycloak/keycloakUtils';
import { httpSettings } from 'utils/request';

import { enqueueSnackbar } from 'notistack';
import { deleteStoredFilters, translate } from '.';
import { APPLICATION_ROLES } from '../constants';
import { UserHelper } from './UserHelper';

const hasExistingTranslation = (message) => {
  if (i18n.exists(`errors.${message}`)) {
    return translate(`errors.${message}`);
  }

  return message;
};

const processErrorList = (errorList) => {
  let processedMessage = '';
  errorList && errorList.map((errorItem) => {
    processedMessage += `- ${errorItem} <br />`;
    return processedMessage;
  });
  return processedMessage;
};

export const processError = (error) => {
  if (error && error.message) {
    return hasExistingTranslation(error.message);
  }
  if (error && !Array.isArray(error) && typeof error === 'object') {
    let processedMessage = '';
    Object.keys(error).forEach((key) => {
      if (Array.isArray(error[key])) {
        processedMessage += `<b>${key}:</b><br /> ${processErrorList(error[key])} <br />`;
        return processedMessage;
      }

      processedMessage = error[key].message;
      return processedMessage;
    });
    return processedMessage;
  }
  if (error && Array.isArray(error)) {
    return processErrorList(error);
  }
  if (error) {
    return hasExistingTranslation(error);
  }
  return hasExistingTranslation('UNCATCHED_ERROR');
};

const defaultErrorHandler = (error) => {
  throw processError(error);
};

const redirectToAuthentication = () => {
  const keycloak = KeyCloakUtils.currentKeycloakInstance();
  if (!keycloak.tokenParsed && navigator.onLine) {
    deleteStoredFilters();
    return keycloak.login();
  }

  return keycloak.logout();
};

const saveCsrfInLocalStorage = (headers) => {
  if (!headers) {
    return;
  }
  headers.forEach((value, key) => {
    const splittedKey = key.split('-');
    if (splittedKey.length === 3 && splittedKey[1] === 'xsrf') {
      localStorage.setItem(`${splittedKey[2].toLowerCase()}XsrfToken`, value);
    }
  });
};

const defaultResponseHandler = async (response, internalAPI = true) => {
  const { status, headers } = response;

  saveCsrfInLocalStorage(headers);

  // If this is an external API manually handle errors (for instance not having a redirect to home with a 403)
  if (!internalAPI) {
    return response.json();
  }

  switch (status) {
    case 200:
    case 201:
      // Return the response
      return response.json();
    case 202:
      return null;
    case 401: {
      // Redirect to the authentication page
      return redirectToAuthentication();
    } case 402:
      // Redirect to DATACOMMONS when the user doesnt have the correct access rights
      // window.location.href = DATATECNEA_URL;
      return null;
    case 403:
      // If no application roles : logout
      if (!UserHelper.hasAccessRight(Object.values(APPLICATION_ROLES))) {
        return redirectToAuthentication();
      }
      return response.json().then((error) => {
        enqueueSnackbar(processError(error), { variant: 'error' });
        window.location.href = '/accueil';
        throw error;
      });
    case 404:
      throw new Error(translate('errors.NOT_FOUND_ERROR'));
    case 400:
    case 412:
      return response.json().then((error) => {
        throw error;
      });
    case 500:
    case 503:
      // Display the error
      return response.json().then((error) => {
        enqueueSnackbar(processError(error), { variant: 'error' });
        throw error;
      });
    default:
      // Display a generic error message
      return response.json().then((error) => {
        enqueueSnackbar(processError({ message: 'UNCATCHED_ERROR' }), { variant: 'error' });
        throw error;
      });
  }
};

const GET = (url: string, headers?: HeadersType, signal = undefined, internalAPI = true) => fetch(url, httpSettings({
  headers,
  method: MethodEnum.GET,
  credentials: 'include',
  signal
}, internalAPI))
  .then(response => defaultResponseHandler(response, internalAPI))
  .catch(defaultErrorHandler);

const POST = (url: string, body: object = {}, headers: HeadersType = undefined, signal = undefined, app = undefined) => fetch(url, httpSettings({
  method: MethodEnum.POST,
  credentials: 'include',
  headers,
  body,
  signal,
  app
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const PUT = (url: string, body: object = {}, headers: HeadersType = undefined, signal = undefined, app = undefined) => fetch(url, httpSettings({
  method: MethodEnum.PUT,
  headers,
  credentials: 'include',
  body,
  signal,
  app
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const PATCH = (url: string, body: object = {}, headers: HeadersType = undefined, signal = undefined, app = undefined) => fetch(url, httpSettings({
  method: MethodEnum.PATCH,
  headers,
  credentials: 'include',
  body,
  signal,
  app
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

const DELETE = (url: string, body: object = {}, headers: HeadersType = undefined, signal = undefined, app = undefined) => fetch(url, httpSettings({
  method: MethodEnum.DELETE,
  headers,
  credentials: 'include',
  body,
  signal,
  app
}))
  .then(defaultResponseHandler)
  .catch(defaultErrorHandler);

// Regroup all functions inside a single const to simplify
export const RequestHelper = {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE
};
