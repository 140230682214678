import { SelectItem } from 'utils/types';
import { ContactDetail } from './ContactDetail';
import { CountryFormView } from './CountryFormView';
import { DocumentListView } from './DocumentListView';

export class EquipmentView extends CountryFormView {
  id: string;
  modelId: string;
  ownerEntityId: string;
  brand: string = '';
  model: string = '';
  serialNumber: string = '';
  internalIdentification: string = '';
  acquisitionDate: string;
  wasNewAtAcquisition: boolean;
  isNew: boolean;
  lastControlDate: string;
  comment: string;
  contact: ContactDetail;
  type: SelectItem;
  documentList: DocumentListView;
  validated: boolean;
  activated: boolean = true;
}

export const getIdentifier: (equipment: EquipmentView) => string = (equipment) => {
  if (!equipment) {
    return '';
  }
  let identifier = equipment.brand || '';
  if (equipment.model) {
    if (identifier && equipment.model !== identifier) {
      identifier += ` ${equipment.model}`;
    } else {
      identifier = equipment.model;
    }
  }
  if (equipment.serialNumber) {
    identifier += ` ${equipment.serialNumber}`;
  }
  if (equipment.internalIdentification) {
    identifier += ` (${equipment.internalIdentification})`;
  }
  return identifier;
};
