import { InterventionList, InterventionView } from 'models';
import { InterventionStatusView } from 'models/detail/InterventionStatusView';
import { InterventionFilter } from 'models/request/list/InterventionFilter';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const interventionUrl = `${API_URL}interventions`;

// GET
const getIntervention: (
  hashId: string
) => Promise<InterventionView> = (
  hashId
) => RequestHelper.GET(`${interventionUrl}/${hashId}`);

const getInterventionStatus: (
  hashId: string
) => Promise<InterventionStatusView> = (
  hashId
) => RequestHelper.GET(`${interventionUrl}/${hashId}/status`);

const getInterventionDownload: (
  hashId: string
) => Promise<InterventionView> = (
  hashId
) => RequestHelper.GET(`${interventionUrl}/${hashId}/download`);

const getOptions = () => RequestHelper.GET(`${interventionUrl}/options`);

const getInterventionCerfa: (
  hashId: string
) => Promise<any> = (
  hashId
) => RequestHelper.GET(`${interventionUrl}/${hashId}/cerfa`);

const getInterventionsPending: (
  deviceId: string
) => Promise<InterventionView[]> = (
  deviceId
) => RequestHelper.GET(`${interventionUrl}/download-pending?deviceId=${deviceId}`);

const getInterventionStatuses: () => Promise<SelectItem[]> = () => RequestHelper.GET(`${interventionUrl}/statuses`);

// POST
const checkFileNumber: (
  fileNumber: string
) => Promise<boolean> = (
  fileNumber
) => RequestHelper.POST(`${interventionUrl}/check`, { fileNumber });

const getList: (
  params: InterventionFilter,
  page: number
) => Promise<InterventionList> = (
  params = {},
  page = 1
) => RequestHelper.POST(`${interventionUrl}/filter?page=${page}`, params);

const getTemporaryCerfa = (intervention: InterventionView) => RequestHelper.POST(`${interventionUrl}/temporary-cerfa`, intervention);

const saveIntervention = (intervention: InterventionView) => RequestHelper.POST(`${interventionUrl}`, intervention);

const getCustomFileNumber = () => RequestHelper.POST(`${interventionUrl}/file-number`, {});

const exportInterventions = params => RequestHelper.POST(`${interventionUrl}/export`, params);

const exportInterventionsByEmail = (params, signal) => RequestHelper.POST(`${interventionUrl}/notif-export`, params, undefined, signal);

// PUT
const startIntervention = (hashId: string, intervention: InterventionView) => RequestHelper.PUT(`${interventionUrl}/${hashId}/start`, intervention);

const planIntervention = (hashId: string, intervention: InterventionView) => RequestHelper.PUT(`${interventionUrl}/${hashId}/plan`, intervention);

const updateIntervention = (hashId: string, intervention: InterventionView) => RequestHelper.PUT(`${interventionUrl}/${hashId}`, intervention);

const reassignOperator = (hashId: string, operator) => RequestHelper.PUT(`${interventionUrl}/${hashId}/reassign`, operator);

const finishIntervention = (hashId: string, intervention: InterventionView) => RequestHelper.PUT(`${interventionUrl}/${hashId}/finish`, intervention);

// DELETE
const deleteIntervention = hashId => RequestHelper.DELETE(`${interventionUrl}/${hashId}`);

export const InterventionsService = {
  getIntervention,
  getInterventionStatus,
  getInterventionDownload,
  getOptions,
  getInterventionCerfa,
  getInterventionsPending,
  getInterventionStatuses,
  checkFileNumber,
  getList,
  getTemporaryCerfa,
  saveIntervention,
  getCustomFileNumber,
  exportInterventions,
  exportInterventionsByEmail,
  startIntervention,
  planIntervention,
  updateIntervention,
  reassignOperator,
  finishIntervention,
  deleteIntervention
};
