import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';

import { ButtonBlueLight } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledCard = styled.section`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  max-width: ${props => props.maxWidth};
  margin: 2rem 0 ${props => (props.hasButton ? '3rem' : '2rem')} 0;
  border-radius: 1.6rem;
  border: 1px solid rgb(213,225,239);
  background: ${props => props.background};
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
  transition: all var(--transitionTime);

  &:hover {
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.2);
  }
`;

const CardArticle = styled.article`
  padding: 2rem 1.5rem ${props => (props.hasButton ? '4rem' : '2rem')} 1.5rem;
  color: ${props => (props.isWhite ? 'var(--black)' : 'var(--white)')};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const CardTitle = styled.h2`
  margin: 0 0 2rem 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.8rem;

  svg {
    margin-right: 0.5rem;
  }
`;

const CardSubtitle = styled.h3`
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--grey);
`;

const CardArrow = styled(({ ...restProps }) => <span {...restProps} />)`
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  color: ${props => (props.isWhite ? 'var(--blue)' : 'var(--white)')};
`;

const CardButton = styled.div`
  position: absolute;
  bottom: -1.7rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

export const CustomCardTitle = ({
  children = undefined, title, icon = undefined, backgroundColor = '#dadada'
}) => (
  <Typography
    align="center"
    component="h6"
    style={{
      padding: '1rem', borderBottom: '1px solid #eeeeee', backgroundColor, textTransform: 'uppercase'
    }}
    variant="h6"
  >
    {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '1rem' }} />}
    {title}
    {children && (
      <div style={{ textAlign: 'end', marginTop: '-27px' }}>
        {children}
      </div>
    ) }
  </Typography>
);
export const Card = ({
  background, buttonText, buttonLink, buttonState, buttonAction, buttonCyData,
  children, hasArrow, link, title, subtitle, titleComponent, titleIcon, dataTour, maxWidth
}) => (
  <StyledCard background={background} data-tour={dataTour} hasButton={buttonText} maxWidth={maxWidth}>
    <CardArticle hasButton={buttonText} isWhite={background === 'var(--white)'}>
      {titleComponent}
      {title && (
        <CardTitle>
          {titleIcon && (
            <FontAwesomeIcon icon={titleIcon} />
          )}
          {title}
        </CardTitle>
      )}
      {subtitle && (
        <CardSubtitle>
          {subtitle}
        </CardSubtitle>
      )}
      {hasArrow && (
        link ? (
          <Link to={link}>
            <CardArrow isWhite={background === 'var(--white)'}>
              <FontAwesomeIcon icon={faArrowRight} />
            </CardArrow>
          </Link>
        ) : (
          <CardArrow isWhite={background === 'var(--white)'}>
            <FontAwesomeIcon icon={faArrowRight} />
          </CardArrow>
        )
      )}
      {children}
    </CardArticle>
    {buttonText && buttonAction && (
      <CardButton>
        <ButtonBlueLight data-cy={buttonCyData} onClick={buttonAction}>
          {buttonText}
        </ButtonBlueLight>
      </CardButton>
    )}
    {buttonText && !buttonAction && (
      <CardButton>
        <Link to={{
          pathname: buttonLink,
          ...buttonState
        }}
        >
          <ButtonBlueLight data-cy={buttonCyData}>
            {buttonText}
          </ButtonBlueLight>
        </Link>
      </CardButton>
    )}
  </StyledCard>
);

Card.propTypes = {
  background: PropTypes.string,
  buttonAction: PropTypes.func,
  buttonCyData: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonState: PropTypes.shape({}),
  buttonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]).isRequired,
  hasArrow: PropTypes.bool,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  maxWidth: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  titleComponent: PropTypes.element,
  titleIcon: PropTypes.shape({})
};

Card.defaultProps = {
  background: 'var(--white)',
  buttonAction: null,
  buttonCyData: '',
  buttonLink: '',
  buttonState: {},
  buttonText: '',
  dataTour: '',
  hasArrow: false,
  link: '',
  maxWidth: '575px',
  subtitle: '',
  title: '',
  titleComponent: null,
  titleIcon: null
};
