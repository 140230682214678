import { AddressView } from './AddressView';
import { CountrySelectItem } from './CountryView';
import { IdentifierView } from './IdentifierView';

export class CountryFormView {
  address?: AddressView;
  institutionIdentifiers?: IdentifierView[] = [];
  identifiers?: IdentifierView[] = [];
  institutionIdentifier?: string = '';
  identifier?: string = '';
  institutionName?: string = '';
  name?: string = '';
  isPublic?: boolean = false;

  // Front specific fields
  address1?: string = '';
  address2?: string = '';
  city?: string = '';
  country?: CountrySelectItem;
  postalCode?: string = '';
}

export const getIdentifier: (
  form: CountryFormView
) => string = (
  form
) => {
  if (form.institutionIdentifier) {
    return form.institutionIdentifier;
  }
  return form.identifier;
};

export const getIdentifiers: (
  form: CountryFormView
) => IdentifierView[] = (
  form
) => {
  if (form.institutionIdentifiers) {
    return form.institutionIdentifiers;
  }
  return form.identifiers;
};

const printAddressDetail: (
  address: AddressView,
  withCountry?: boolean
) => string = (
  address,
  withCountry = false
) => {
  if (!address || !address.address1) {
    return '';
  }
  const {
    address1, address2, postalCode, city, country
  } = address;
  return `${address1}${address2 ? `, ${address2}` : ''}, ${postalCode} ${city}${(withCountry && country) ? `, ${country.label}` : ''}`;
};

export const printAddress: (
  form: CountryFormView,
  withCountry?: boolean
) => string = (
  form,
  withCountry = false
) => {
  let fullAddress = '';
  if (!form) {
    return fullAddress;
  }
  const {
 address, address1, address2, postalCode, city, country
} = form;
  fullAddress = printAddressDetail(address, withCountry);
  if (!fullAddress) {
    fullAddress = printAddressDetail({
      address1, address2, postalCode, city, country
    }, withCountry);
  }
  return fullAddress;
};
