import { CountrySelectItem } from './CountryView';

export class AddressView {
  address1: string = '';
  address2?: string = '';
  city: string = '';
  country?: CountrySelectItem;
  id?: string = '';
  postalCode: string = '';
}
