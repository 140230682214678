import { Grid, TextField } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Question } from 'components/Intervention';
import { LegalEntityFilter } from 'models';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import { VALIDATED_TYPES } from 'utils/constants';
import { FilterHelper, translate } from 'utils/helpers';

export const EquipmentOwnerListFilters = ({
  currentFilters, setCurrentFilters
}) => {
  const handleChangeInput = (
    key: keyof LegalEntityFilter,
    value: any,
    label: string
  ) => {
    setCurrentFilters(FilterHelper.setFiltersValue(currentFilters, key, value, label));
  };

  return (
    <Grid container direction="column">
      <Question label={translate('pageEquipmentOwnerList.findEquipmentOwner')}>
        <TextField
          label={translate('common.lastName')}
          name="firstName"
          value={FilterHelper.getFiltersValue(currentFilters, 'firstName') || ''}
          onChange={event => handleChangeInput('firstName', event.target.value, event.target.value)}
        />
        <TextField
          inputProps={{ maxLength: 14 }}
          label={translate('common.siret')}
          name="siret"
          value={FilterHelper.getFiltersValue(currentFilters, 'siret') || ''}
          onChange={event => handleChangeInput('siret', event.target.value, event.target.value)}
        />
      </Question>

      <Question label={translate('common.equipmentOwnerState')}>
        <ToggleButtonGroup
          aria-label={translate('common.validated')}
          exclusive
          size="medium"
          value={FilterHelper.getFiltersValue(currentFilters, 'validated')}
          onChange={(_, value) => {
            const label = (value !== null && value && translate('common.validated')) || translate('common.unvalidated');
            handleChangeInput('validated', value, label);
          }}
        >
          {VALIDATED_TYPES.map(type => (
            <ToggleButton
              aria-label={translate(`common.${type.key}`)}
              key={shortid.generate()}
              value={type.value}
            >
              {translate(`common.${type.key}`)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Question>
    </Grid>
  );
};

EquipmentOwnerListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};
