import {
  Divider, Grid,
  TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Question } from 'components';
import { useStores } from 'hooks';
import { BottleFilter } from 'models/request/list/BottleFilter';
import React from 'react';
import Select from 'react-select';
import shortid from 'shortid';
import { BOTTLE_TYPES_FILTERS, VALIDATED_TYPES } from 'utils/constants';
import { BottleHelper, FilterHelper, translate } from 'utils/helpers';
import { FilterItem } from 'utils/types/FilterItem';

export const BottleListFilters = ({
  currentFilters,
  setCurrentFilters
}: {
  currentFilters: FilterItem<BottleFilter>[],
  setCurrentFilters: (filters: FilterItem<BottleFilter>[]) => void
}) => {
  const { fluidStore } = useStores();

  const handleChangeInput = (
    key: keyof BottleFilter,
    value: any,
    label: string
  ) => {
    setCurrentFilters(FilterHelper.setFiltersValue(currentFilters, key, value, label));
  };

  return (
    <Grid container direction="column">
      <Question label={translate('modalBottle.bottleType')}>
        <ToggleButtonGroup
          aria-label={translate('modalBottle.bottleType')}
          exclusive
          size="medium"
          value={FilterHelper.getFiltersValue(currentFilters, 'bottleType')?.value || ''}
          onChange={(_, value) => handleChangeInput('bottleType', { value, label: value }, BottleHelper.getTranslatedType({ type: value }))}
        >
          {BOTTLE_TYPES_FILTERS.map(type => (
            <ToggleButton
              aria-label={BottleHelper.getTranslatedType({ type: type.key })}
              key={shortid.generate()}
              value={type.key}
            >
              {BottleHelper.getTranslatedType({ type: type.key })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Question>

      <Question label={translate('modalFluidMovement.bottleIdentifier')}>
        <TextField
          label={translate('common.searchByIdentifier')}
          name="bottleIdentifier"
          value={FilterHelper.getFiltersValue(currentFilters, 'bottleIdentifier') || ''}
          onChange={event => handleChangeInput('bottleIdentifier', event.target.value, event.target.value)}
        />
      </Question>

      <Question label={translate('common.fluidInBottle')}>
        <Select
          id="selectFluid"
          isClearable
          isSearchable
          menuPortalTarget={document.body}
          noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
          options={fluidStore.fluidOptions}
          placeholder={translate('common.selectType')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
          value={{ value: FilterHelper.getFiltersValue(currentFilters, 'fluidId'), label: FilterHelper.getFiltersLabel(currentFilters, 'fluidId') }}
          onChange={(value) => handleChangeInput('fluidId', value?.value, value?.label)}
        />
      </Question>

      <Question label="">
        <Grid container justifyContent="space-between" spacing={2} wrap="wrap">
          <Grid item>
            <Question label={translate('common.bottleState')}>
              <ToggleButtonGroup
                aria-label={translate('common.validated')}
                exclusive
                size="medium"
                value={FilterHelper.getFiltersValue(currentFilters, 'validated')}
                onChange={(_, value) => {
                  const label = (value !== null && value && translate('common.validated')) || translate('common.unvalidated');
                  handleChangeInput('validated', value, label);
                }}
              >
                {VALIDATED_TYPES.map(type => (
                  <ToggleButton
                    aria-label={translate(`common.${type.key}`)}
                    key={shortid.generate()}
                    value={type.value}
                  >
                    {translate(`common.${type.key}`)}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Question>
          </Grid>
          <Grid item>
            <Question label={translate('common.bottleWarning')}>
              <ToggleButtonGroup
                aria-label={translate('common.bottleWarning')}
                exclusive
                size="medium"
                value={FilterHelper.getFiltersValue(currentFilters, 'hasWarning')}
                onChange={(_, value) => {
                  const label = (value !== null && value && translate('common.yes')) || translate('common.no');
                  handleChangeInput('hasWarning', value, label);
                }}
              >
                <ToggleButton
                  aria-label={translate('common.yes')}
                  key={shortid.generate()}
                  value={true}
                >
                  {translate('common.yes')}
                </ToggleButton>
                <ToggleButton
                  aria-label={translate('common.no')}
                  key={shortid.generate()}
                  value={false}
                >
                  {translate('common.no')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Question>
          </Grid>
          <Grid item>
            <Question label={translate('common.isRetrieved')}>
              <ToggleButtonGroup
                aria-label={translate('common.isRetrieved')}
                exclusive
                size="medium"
                style={{ marginBottom: '2rem' }}
                value={FilterHelper.getFiltersValue(currentFilters, 'isRetrieved')}
                onChange={(_, value) => {
                  const label = (value !== null && value && translate('common.retrieved')) || translate('common.inStock');
                  handleChangeInput('isRetrieved', value, label);
                }}
              >
                <ToggleButton
                  key={shortid.generate()}
                  value={true}
                >
                  {translate('common.retrieved')}
                </ToggleButton>
                <ToggleButton
                  key={shortid.generate()}
                  value={false}
                >
                  {translate('common.inStock')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Question>
          </Grid>
        </Grid>
      </Question>

      <Divider />

      <Question label={translate('common.isRegisteredTD')}>
        <ToggleButtonGroup
          aria-label={translate('common.isRegisteredTD')}
          exclusive
          size="medium"
          style={{ marginBottom: '2rem' }}
          value={FilterHelper.getFiltersValue(currentFilters, 'isRegisteredTD')}
          onChange={(_, value) => {
            const label = (value !== null && value && translate('common.yes')) || translate('common.no');
            handleChangeInput('isRegisteredTD', value, label);
          }}
        >
          <ToggleButton
            key={shortid.generate()}
            value={true}
          >
            {translate('common.yes')}
          </ToggleButton>
          <ToggleButton
            key={shortid.generate()}
            value={false}
          >
            {translate('common.no')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Question>

      {FilterHelper.getFiltersValue(currentFilters, 'isRegisteredTD') && (
        <>
          <Question label={translate('common.bsffIdentifier')}>
            <TextField
              label={translate('common.bsffIdentifier')}
              name="bsffIdentifier"
              value={FilterHelper.getFiltersValue(currentFilters, 'bsffIdentifier') || ''}
              onChange={event => handleChangeInput('bsffIdentifier', event.target.value, event.target.value)}
            />
          </Question>

          <Question label={translate('common.bottleRetrievalConfirmed')}>
            <ToggleButtonGroup
              aria-label={translate('common.bottleRetrievalConfirmed')}
              exclusive
              size="medium"
              style={{ marginBottom: '2rem' }}
              value={FilterHelper.getFiltersValue(currentFilters, 'retrievalConfirmed')}
              onChange={(_, value) => {
                const label = (value !== null && value && translate('common.yes')) || translate('common.no');
                handleChangeInput('retrievalConfirmed', value, label);
              }}
            >
              <ToggleButton
                key={shortid.generate()}
                value={true}
              >
                {translate('common.yes')}
              </ToggleButton>
              <ToggleButton
                key={shortid.generate()}
                value={false}
              >
                {translate('common.no')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Question>
        </>
      )}
    </Grid>
  );
};
