import React, {
  Fragment, useEffect, useState
} from 'react';

import styled from 'styled-components';

import {
  faFilePdf, faPrint, faSignature, faSpinner, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Paper } from '@material-ui/core';

import { InterventionsService } from 'services';
import {
  DocumentHelper, formatDate, formatDateString, FormHelper, InterventionHelper, translate
} from 'utils/helpers';

import { InterventionView, SignatureFieldView } from 'models';

import {
  ButtonBlue, ButtonBlueLight, FlexContainer, Text
} from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { getName } from 'models/detail/EntityView';
import { FormFrontView } from 'models/detail/FormFrontView';
import { SignatureType } from 'models/detail/SignatureFieldView';
import { FormState, StateErrors } from 'react-use-form-state';
import { COUNTRY_CODE } from 'utils/constants';

const GeneratingCerfaContainer = styled(FlexContainer).attrs({
  flexDirection: 'column',
  alignItems: 'center'
})`
  padding-top: 1rem;
  color: var(--green);
`;

const CerfaContainer = styled(FlexContainer).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
})`
  padding: 1rem;

  img {
    height: 200px;
    cursor: pointer;
  }

  section {
    flex: 1;

    button {
      min-width: 180px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SignatureDone = styled(FlexContainer).attrs({
  flexDirection: 'column',
  alignItems: 'center'
})`
  position: relative;
  text-align: center;

  div {
    width: 100%;
    text-align: left;
  }

  img {
    max-width: 300px;
    margin-bottom: 1rem;
    border: 2px solid var(--black);
    cursor: pointer;
  }

  button {
    position: absolute;
    top: 0.5rem;
    right: 0;
    min-width: 20px;

    svg {
      font-size: 1.8rem;
    }
  }
`;

export const Signatures = observer(({
  countryCode,
  formState
}: {
  countryCode: string,
  formState: FormState<FormFrontView, StateErrors<FormFrontView, string>>
}) => {
  const { userStore } = useStores();
  const { open, close } = useModal();
  const [isFetchingCerfa, setIsFetchingCerfa] = useState(false);
  const [generatedCerfa, setGeneratedCerfa] = useState(null);

  const cerfaVersion = InterventionHelper.getCerfaVersion(formState.values.interventionDate, countryCode);

  const isFrance = countryCode === COUNTRY_CODE.FR;
  const isCanada = countryCode === COUNTRY_CODE.CA;

  useEffect(() => {
    if (!formState) {
      return;
    }
    setIsFetchingCerfa(true);

    const completedIntervention: InterventionView = {
      fileNumber: formState.values.fileNumber,
      appointment: formState.values.appointment,
      country: formState.values.country,
      form: FormHelper.BUILD({ ...formState.values })
    };

    InterventionsService.getTemporaryCerfa(completedIntervention).then((response) => {
      response && setGeneratedCerfa({
        name: response.name,
        base64Content: DocumentHelper.getPDFWithBase64(response)
      });
    }).catch(() => {
      setIsFetchingCerfa(true);
    }).finally(() => setIsFetchingCerfa(false));
    // eslint-disable-next-line
  }, [formState.values.operatorSignatureField, formState.values.customerSignatureField]);

  const handleConfirm = (form: SignatureFieldView) => {
    formState.setField(form.type, form);
    if (form.interventionDate) {
      formState.setField('interventionDate', form.interventionDate);
    }
    close();
  };

  const handleOpen = (type: SignatureType, defaultValues?: SignatureFieldView) => (
    open({
      type: 'ADD_SIGNATURE',
      onSubmit: handleConfirm,
      defaultValues: {
        ...defaultValues,
        type
      }
    })
  );

  const displayPDFModal = cerfa => DocumentHelper.displayPDF({
    displayModal: modalType => open({
      type: modalType,
      title: translate('interventionForm.signatures.interventionReportTemporary'),
      pdf: cerfa
    })
  });

  const deleteSignature = (type: SignatureType) => {
    formState.setField(type, new SignatureFieldView());
  };

  return (
    <Fragment>
      {isFetchingCerfa && (
        <GeneratingCerfaContainer>
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
          <Text fontSize="1.8rem" fontWeight={600}>
            {translate('interventionForm.signatures.cerfaGeneration')}
          </Text>
        </GeneratingCerfaContainer>
      )}
      {!isFetchingCerfa && generatedCerfa && (
        <Fragment>
          <Text fontSize="1.8rem" fontWeight={600} textAlign="center">
            {translate('interventionForm.signatures.cerfaGenerated')}
          </Text>
          <Paper>
            <CerfaContainer>
              {isFrance && (
                <img
                  alt="Voir le rapport d'intervention généré"
                  src={`${process.env.PUBLIC_URL}/assets/images/cerfa/${cerfaVersion}/cerfa.jpg`}
                  onClick={() => generatedCerfa && displayPDFModal(generatedCerfa)}
                  onKeyDown={() => generatedCerfa && displayPDFModal(generatedCerfa)}
                />
              )}
              <FlexContainer alignItems="center" flexDirection="column" justifyContent="center">
                <ButtonBlueLight
                  margin="0 0 3rem 0"
                  onClick={() => generatedCerfa && displayPDFModal(generatedCerfa)}
                >
                  <FontAwesomeIcon icon={faFilePdf} />
                  {translate('button.consult')}
                </ButtonBlueLight>
                {generatedCerfa && (
                  <a download={generatedCerfa.name} href={generatedCerfa.base64Content} rel="noopener noreferrer" target="_blank">
                    <ButtonBlueLight>
                      <FontAwesomeIcon icon={faPrint} />
                      {translate('button.print')}
                    </ButtonBlueLight>
                  </a>
                )}
              </FlexContainer>
            </CerfaContainer>
          </Paper>
        </Fragment>
      )}

      <Text fontSize="1.8rem" fontWeight={600} margin="3rem 0" textAlign="center">
        {translate('interventionForm.signatures.timeToSign')}
        {!formState.values.operatorSignatureField.name && (
          <>
            <br />
            <span style={{ fontSize: '1.4rem', textAlign: 'center', color: 'var(--orange)' }}>
              {translate('interventionForm.signatures.mandatoryOperatorSign')}
            </span>
          </>
        )}
      </Text>
      <FlexContainer alignItems="center" flexDirection="column">
        {formState.values.operatorSignatureField.document
          && formState.values.operatorSignatureField.document.base64Content
          && (
            <SignatureDone>
              <div data-cy="signaturesDoneOperator">
                <Text display="inline-block" fontWeight={600} margin="1rem 0" width="90%">
                  {formState.values.operatorSignatureField.name}
                  {formState.values.operatorSignatureField.occupation && (` (${formState.values.operatorSignatureField.occupation})`)}
                </Text>
                <br />
                {formState.values.operatorSignatureField.signatureDate
                  && (`(${formatDateString(formState.values.operatorSignatureField.signatureDate)})`)}
                <Button onClick={() => deleteSignature('operatorSignatureField')}>
                  <FontAwesomeIcon color="var(--red)" icon={faTrashAlt} />
                </Button>
              </div>
              <img
                alt={translate('interventionForm.signatures.operatorSign')}
                src={formState.values.operatorSignatureField.document.base64Content}
                onClick={() => handleOpen('operatorSignatureField', formState.values.operatorSignatureField)}
              />
            </SignatureDone>
          )}
        <ButtonBlue
          data-cy="signaturesOperator"
          margin="0 0 3rem 0"
          onClick={() => handleOpen('operatorSignatureField', formState.values.operatorSignatureField?.name
            ? formState.values.operatorSignatureField
            : {
              name: userStore.getUserName(),
              occupation: (formState.values.operator && translate('roles.DATABILAN_OPERATOR')) || '',
              signatureDate: formatDate(new Date()),
              interventionDate: formState.values.interventionDate || formatDate(new Date())
          })}
        >
          <FontAwesomeIcon icon={faSignature} />
          {translate('interventionForm.signatures.operatorSign')}
        </ButtonBlue>

        {formState.values.customerSignatureField.document
          && formState.values.customerSignatureField.document.base64Content
          && (
            <SignatureDone>
              <div data-cy="signaturesDoneOwner">
                <Text display="inline-block" fontWeight={600} margin="1rem 0 " width="90%">
                  {formState.values.customerSignatureField.name}
                  {formState.values.customerSignatureField.occupation && (` (${formState.values.customerSignatureField.occupation})`)}
                </Text>
                <br />
                {formState.values.customerSignatureField.signatureDate && (`(${formatDateString(formState.values.customerSignatureField.signatureDate)})`)}
                <Button onClick={() => deleteSignature('customerSignatureField')}>
                  <FontAwesomeIcon color="var(--red)" icon={faTrashAlt} />
                </Button>
              </div>
              <img
                alt={translate('interventionForm.signatures.ownerSign')}
                src={formState.values.customerSignatureField.document.base64Content}
                onClick={() => handleOpen('customerSignatureField', formState.values.customerSignatureField)}
              />
            </SignatureDone>
          )}
        {!isCanada && (
          <ButtonBlue
            data-cy="signaturesOwner"
            margin="0 0 3rem 0"
            onClick={() => handleOpen('customerSignatureField', formState.values.customerSignatureField?.name
              ? formState.values.customerSignatureField
              : {
                name: (formState.values.equipmentOwner?.ownerType === 'PERSON' && getName(formState.values.equipmentOwner)) || '',
                signatureDate: formatDate(new Date())
            })}
          >
            <FontAwesomeIcon icon={faSignature} />
            {translate('interventionForm.signatures.ownerSign')}
          </ButtonBlue>
        )}
      </FlexContainer>
    </Fragment>
  );
});
