import { CountryView } from './CountryView';
import { EntityView } from './EntityView';
import { InterventionAppointment } from './InterventionAppointment';
import { InterventionStatusView } from './InterventionStatusView';
import { OperatorSummary } from './OperatorSummary';
import { CircuitView, RefrigerationUnitView } from './RefrigerationUnitView';

export class InterventionFrontView {
  hashId?: string = '';
  identifier?: string = '';
  fileNumber?: string = '';
  comment?: string = '';
  status?: string;
  country?: CountryView;
  operator?: OperatorSummary;
  appointment: InterventionAppointment;
  equipmentOwner: EntityView;
  equipment: RefrigerationUnitView;
  circuit: CircuitView;
  canEdit?: boolean = true;
  canPlan?: boolean = false;
  canReassign?: boolean = false;

  // Front fields
  observations?: string = '';
  isOutOfSync?: boolean;
  outOfSyncStatus?: InterventionStatusView;
  isWaitingToSubmit?: boolean;
}
