import React, { useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  INTERVENTION_ACCESS_ROLES, NOT_DISTRIBUTOR_ROLES, ROUTES, STOCK_ROLES_WITH_INTERVENTION_MANAGER
} from 'utils/constants';
import { UserHelper } from 'utils/helpers';

import {
  PageAdministration,
  PageCookies,
  PageHome,
  PageInterventionDetail,
  PageInterventionInspection,
  PageInterventionList,
  PageInterventionPlanification,
  PageRGPD,
  PageStocks,
  PageUserDetail
} from 'pages';

import { BottleDetail, EquipmentList } from 'components';
import { useStores } from 'hooks';

const InterventionAccessRoute = (props) => {
  const hasAccessRights = UserHelper.hasAccessRight(INTERVENTION_ACCESS_ROLES);

  if (!hasAccessRights) {
    return <Redirect to={ROUTES.HOME} />;
  }
  return <Route {...props} />;
};

const AdministrativeRoute = ({ component: Component, ...rest }) => {
  const hasAccessRights = UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER);

  const handleRouteRender = useCallback(() => (
    hasAccessRights
      ? <Component />
      : <Redirect to={ROUTES.HOME} />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [hasAccessRights]);

  return (
    <Route {...rest} render={handleRouteRender} />
  );
};

const RouteHome = ({ component: Component, ...rest }) => {
  const { userStore } = useStores();
  const handleRouteRender = useCallback(() => (
    (UserHelper.hasAccessRight(NOT_DISTRIBUTOR_ROLES) || userStore.isViewingInstitution())
      ? <Component />
      : <Redirect to={ROUTES.STOCKS} />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [userStore]);

  return (
    <Route {...rest} render={handleRouteRender} />
  );
};

export const Routes = () => (
  <Switch>
    <RouteHome component={PageHome} exact path={ROUTES.HOME} />
    <Route component={BottleDetail} exact path={ROUTES.CONTAINERS.DETAIL} />
    <InterventionAccessRoute component={PageInterventionList} exact path={ROUTES.INTERVENTION.LIST} />
    <InterventionAccessRoute component={PageInterventionDetail} exact path={ROUTES.INTERVENTION.DETAIL} />
    <InterventionAccessRoute component={PageInterventionPlanification} exact path={ROUTES.INTERVENTION.PLAN} />
    <InterventionAccessRoute component={PageInterventionInspection} exact path={ROUTES.INTERVENTION.FORM} />
    <Route component={PageUserDetail} exact path={ROUTES.USERS.DETAIL} />
    <Route component={EquipmentList} exact path={ROUTES.EQUIPEMENT_OWNER} />

    <Route component={PageStocks} exact path={ROUTES.STOCKS} />
    <Route component={PageStocks} exact path={ROUTES.STOCKS_INITIALISATION} />
    <Route component={PageStocks} exact path={ROUTES.STOCKS_PENDING} />
    <Route component={PageStocks} exact path={ROUTES.STOCKS_FLUIDS} />
    <Route component={PageStocks} exact path={ROUTES.STOCKS_CONTAINERS} />
    <Route component={PageStocks} exact path={ROUTES.STOCKS_VALIDATION} />
    <Route component={PageStocks} exact path={ROUTES.STOCKS_BALANCE} />

    <Route component={PageRGPD} exact path={ROUTES.RGPD} />
    <Route component={PageCookies} exact path={ROUTES.COOKIES} />

    <AdministrativeRoute component={PageAdministration} exact path={ROUTES.ADMINISTRATION_TOOLS} />
    <AdministrativeRoute component={PageAdministration} exact path={ROUTES.ADMINISTRATION_SUPPLIERS_RECIPIENTS} />
    <AdministrativeRoute component={PageAdministration} exact path={ROUTES.ADMINISTRATION_USERS} />
    <AdministrativeRoute component={PageAdministration} exact path={ROUTES.ADMINISTRATION_INSTITUTIONS} />
    <AdministrativeRoute component={PageAdministration} exact path={ROUTES.ADMINISTRATION_TRACK_DECHETS} />
    <AdministrativeRoute component={PageAdministration} exact path={ROUTES.ADMINISTRATION} />

    <Redirect to={ROUTES.HOME} />
  </Switch>
  );
