import React, { useEffect, useState } from 'react';

import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import shortid from 'shortid';

import {
  Card, CustomPieChart, FlexContainer, TextError
} from 'components';
import { CardTitle } from 'components/Card/Card';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { StatsService } from 'services';
import { APPLICATION_ROLES, PERIODS } from 'utils/constants';
import { InterventionHelper, translate, UserHelper } from 'utils/helpers';

const currentWeek = InterventionHelper.getCurrentWeek();
const DATE_FILTERS = {
  week: {
    startDate: currentWeek[0],
    endDate: currentWeek[currentWeek.length - 1]
  },
  month: {
    startDate: InterventionHelper.getFirstDayOfTheMonth(),
    endDate: InterventionHelper.getLastDayOfTheMonth()
  },
  year: {
    startDate: InterventionHelper.getFirstDayOfTheYear(),
    endDate: InterventionHelper.getLastDayOfTheYear()
  }
};

export const CardAllInterventions = observer(() => {
  const { userStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [allInterventions, setAllInterventions] = useState([]);
  const [currentPeriod, setCurrentPeriod] = useState('week');

  useEffect(() => {
    async function setStats() {
      setIsLoading(true);
      const INTERVENTION_STATUS = await InterventionHelper.loadInterventionStatuses().then(IS => IS);
      const CHART_DATA = [];
      // Fetch the downloaded interventions
      let storedList = {};
      let nbStoredInterventions = 0;
      await InterventionHelper.getStoredInterventionList()
        .then((inspList) => {
          // Count the number of stored interventions by status
          storedList = inspList.reduce((p, c) => {
            const { status, hashId } = c;
            if (!p.hasOwnProperty(status)) {
              p[status] = { synchronized: 0, all: 0 };
            }
            p[status].all++;
            if (hashId) {
              p[status].synchronized++;
            }
            return p;
          }, {});
          nbStoredInterventions = inspList.length;
        });

      if (!userStore.isOffline) {
        // If the app is online, call the server to get all the data
        StatsService.getInterventionsStats(currentPeriod).then((response) => {
          if (response) {
            const serverOnlyInterventionsToStart = Math.max(
              response.interventionsToStart - (storedList[INTERVENTION_STATUS?.READY?.key]?.synchronized || 0),
              0
            );
            const serverOnlyOngoingInterventions = Math.max(
              response.ongoingInterventions - (storedList[INTERVENTION_STATUS?.ONGOING?.key]?.synchronized || 0),
              0
            );
            const thisGuyDidNotWork = [
              response.completedInterventions,
              response.interventionsToValidate,
              serverOnlyInterventionsToStart,
              serverOnlyOngoingInterventions
            ].every(value => value === 0);

            if (!thisGuyDidNotWork) {
              if (serverOnlyInterventionsToStart) {
                CHART_DATA.push({
                  name: translate('interventionState.TO_START'),
                  value: serverOnlyInterventionsToStart,
                  fill: 'var(--turquoise-dark)',
                  url: {
                    pathname: '/interventions',
                    filters: [{
                      ...INTERVENTION_STATUS.PENDING
                    }, {
                      ...INTERVENTION_STATUS.READY
                    }, {
                      key: 'startDate',
                      label: DATE_FILTERS[currentPeriod].startDate
                    }, {
                      key: 'endDate',
                      label: DATE_FILTERS[currentPeriod].endDate
                    }]
                  }
                });
              }

              if (response.interventionsToValidate) {
                CHART_DATA.push({
                  name: translate('interventionState.TO_VALIDATE'),
                  value: response.interventionsToValidate,
                  fill: 'var(--green-light)',
                  url: {
                    pathname: '/interventions',
                    filters: [{
                      ...INTERVENTION_STATUS.TO_VALIDATE
                    }, {
                      key: 'startDate',
                      label: DATE_FILTERS[currentPeriod].startDate
                    }, {
                      key: 'endDate',
                      label: DATE_FILTERS[currentPeriod].endDate
                    }]
                  }
                });
              }

              if (response.completedInterventions) {
                CHART_DATA.push({
                  name: translate('interventionState.DONE'),
                  value: response.completedInterventions,
                  fill: 'var(--green-dark)',
                  url: {
                    pathname: '/interventions',
                    filters: [{
                      ...INTERVENTION_STATUS.TO_SIGN
                    }, {
                      ...INTERVENTION_STATUS.FINISHED
                    }, {
                      key: 'startDate',
                      label: DATE_FILTERS[currentPeriod].startDate
                    }, {
                      key: 'endDate',
                      label: DATE_FILTERS[currentPeriod].endDate
                    }]
                  }
                });
              }

              if (serverOnlyOngoingInterventions) {
                CHART_DATA.push({
                  name: translate('interventionState.ONGOING'),
                  value: serverOnlyOngoingInterventions,
                  fill: 'var(--orange)',
                  url: {
                    pathname: '/interventions',
                    filters: [{
                      ...INTERVENTION_STATUS.ONGOING
                    }, {
                      key: 'startDate',
                      label: DATE_FILTERS[currentPeriod].startDate
                    }, {
                      key: 'endDate',
                      label: DATE_FILTERS[currentPeriod].endDate
                    }]
                  }
                });
              }
            }
            // Add the stored interventions to the stats
            if (nbStoredInterventions > 0) {
              CHART_DATA.push({
                name: translate('interventionState.TO_SYNCHRONIZE'),
                value: nbStoredInterventions,
                fill: 'var(--orange)',
                url: {
                  pathname: '/interventions',
                  filters: [{
                    key: 'TO_SYNCHRONIZE',
                    label: translate('interventionState.TO_SYNCHRONIZE')
                  }]
                }
              });
            }
          }
        }).then(() => setAllInterventions(CHART_DATA))
          .finally(() => setIsLoading(false));
      } else {
        // If the app is offline, only used the stored data to build the chart
        if (nbStoredInterventions > 0) {
          CHART_DATA.push({
            name: translate('interventionState.TO_SYNCHRONIZE'),
            value: nbStoredInterventions,
            fill: 'var(--orange)',
            url: {
              pathname: '/interventions',
              filters: []
            }
          });
        }
        setAllInterventions(CHART_DATA);
        setIsLoading(false);
      }
    }

    setStats();
  }, [currentPeriod, userStore.isOffline]);

  if (!allInterventions || !UserHelper.hasAccessRight([APPLICATION_ROLES.OPERATOR, APPLICATION_ROLES.INTERVENTION_MANAGER, APPLICATION_ROLES.ADMIN])) {
    return null;
  }

  return (
    <Card
      buttonCyData="goToAppointmentsOfTheDay"
      buttonLink="/interventions"
      buttonState={
        {
          filters: [{
            key: 'startDate',
            label: new Date()
          }, {
            key: 'endDate',
            label: new Date()
          }]
        }
      }
      buttonText={userStore.isOffline ? '' : translate('pageHome.cardAllIntervention.buttonText')}
      dataTour="step-all-interventions"
      titleComponent={(
        <FlexContainer alignItems="flex-start" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          <CardTitle>{translate('pageHome.cardAllIntervention.title')}</CardTitle>
          {!userStore.isOffline && (
            <ToggleButtonGroup
              aria-label="Sélectionner une période"
              exclusive
              size="small"
              value={currentPeriod}
              onChange={(event, value) => {
                if (value === null) return;
                event.preventDefault();
                setCurrentPeriod(value);
              }}
            >
              {PERIODS.map(period => (
                <ToggleButton
                  aria-label={translate(`periods.${period}`)}
                  data-cy={period}
                  key={shortid.generate()}
                  value={period}
                >
                  {translate(`periods.${period}`)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </FlexContainer>
      )}
    >
      {isLoading && <FontAwesomeIcon color="var(--blue)" icon={faSpinner} size="2x" spin style={{ display: 'block', margin: '4.9rem auto' }} />}
      {!isLoading && allInterventions.length === 0
        ? <TextError>{translate('errors.noIntervention')}</TextError>
        : !isLoading && <CustomPieChart chartData={allInterventions} />
      }
    </Card>
  );
});
