import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const structureLevelUrl = `${API_URL}structure-levels`;

const moveStructureLevel = (moveData) => RequestHelper.PUT(`${structureLevelUrl}/move`, moveData);

export const StructureLevelService = {
  moveStructureLevel
};
