import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import {
  Cell, Legend, Pie, PieChart, ResponsiveContainer
} from 'recharts';
import shortid from 'shortid';

export const CustomPieChart = ({ chartData }) => {
  const history = useHistory();

  const goToUrl = (data) => {
    const url = data.payload ? data.payload.url : data.url;

    url && setTimeout(() => {
      history.push({
        ...url
      });
    }, 100);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel: any = ({
    cx, cy, midAngle, innerRadius, outerRadius, value
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text dominantBaseline="central" fill="white" style={{ pointerEvents: 'none' }} textAnchor="middle" x={x} y={y}>
        {value}
        {/* <tspan style={{ fontSize: '1rem' }}>{` (${(percent * 100).toFixed(0)}%)`}</tspan> */}
      </text>
    );
  };

  return (
    <ResponsiveContainer height={isMobileOnly ? 250 : 130}>
      <PieChart>
        <Pie
          data={chartData}
          dataKey="value"
          innerRadius={15}
          label={renderCustomizedLabel}
          labelLine={false}
          outerRadius={60}
          paddingAngle={2}
          style={{ cursor: 'pointer' }}
          onClick={goToUrl}
        >
          {chartData.map(entry => <Cell fill={entry.fill} key={shortid.generate()} />)}
        </Pie>
        <Legend
          align={isMobileOnly ? 'center' : 'right'}
          iconType="circle"
          layout="vertical"
          verticalAlign={isMobileOnly ? 'bottom' : 'middle'}
          onClick={goToUrl}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
