import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';

import {
  faBuilding, faPlus, faTag, faUser
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, Typography } from '@material-ui/core';
import {
  ButtonBigDashed, ButtonBlueLight, ItemOption, ItemSelected,
  Question, RequiredSelectHack, SelectCreateContainer, Text, TextAddress, TextNoOptionsAvailable
} from 'components';
import debounce from 'debounce-promise';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { EntitySummaryItem, EntityView } from 'models';
import { RefrigerationUnitView } from 'models/detail/RefrigerationUnitView';
import { isMobileOnly } from 'react-device-detect';
import Media from 'react-media';
import AsyncSelect from 'react-select/async';
import { FormState, StateErrors } from 'react-use-form-state';
import { EquipmentOwnersService } from 'services';
import { InterventionHelper, translate } from 'utils/helpers';

const EquipmentOwnerOption = ({
  entity,
  isInsideSelect = false,
  ...rest
}: {
  entity: EntityView,
  isInsideSelect?: boolean,
  rest?
}) => (
  <ItemOption {...rest} isInsideSelect={isInsideSelect}>
    <FontAwesomeIcon icon={!entity.institutionIdentifier ? faUser : faBuilding} size="2x" />
    <article data-cy="equipmentOwnerSelected">
      <Text fontSize="2rem">{`${entity.firstName || 'N/A'} ${entity.lastName || ''}`}</Text>
      {entity.institutionIdentifier && (
      <p>
        <FontAwesomeIcon icon={faTag} />
        {entity.institutionIdentifier}
      </p>
      )}
      {entity.address && (
        <TextAddress address={entity.address} variant="body1" withIcon />
      )}
    </article>
  </ItemOption>
);

/**
 * Subtype of InterventionFrontView and FormFrontView used to manage the equipment for an intervention
 */
interface FormStateEquipmentOwnerView {
  equipment?: RefrigerationUnitView;
  equipmentOwner?: EntityView;
}

export const EquipementOwner = observer(({
  formState,
  isRequired = true
}: {
  formState: FormState<FormStateEquipmentOwnerView, StateErrors<FormStateEquipmentOwnerView, string>>,
  isRequired?: boolean
}) => {
  const { userStore } = useStores();
  const { open, close } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [availableOwners, setAvailableOwners] = useState<EntitySummaryItem[]>([]);
  const [equipmentFilteredOwners, setEquipmentFilteredOwners] = useState<EntitySummaryItem[]>([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    // If the owners are already stored, use the stored list
    InterventionHelper.loadEquipmentOwnersStored()
      .then((storedOwners) => {
        setAvailableOwners(storedOwners);
      });
    // Reload the list in the background
    if (!userStore.isOffline) {
      setIsLoading(true);
      EquipmentOwnersService.getEquipmentOwnerListFull({ size: 0 })
        .then(owners => setAvailableOwners(owners))
        .finally(() => setIsLoading(false));
    }
  }, [userStore.isOffline]);

  useEffect(() => {
    // When the equipment is changed, set the owner automatically
    if (!formState.values.equipmentOwner && formState.values.equipment && formState.values.equipment.ownerEntityId) {
      const matchingOwner = availableOwners.filter(o => o.id === formState.values.equipment.ownerEntityId);
      if (matchingOwner && matchingOwner[0]) {
        formState.setField('equipmentOwner', matchingOwner[0]);
      }
    }
    setEquipmentFilteredOwners(availableOwners.slice(0, 100));
    // eslint-disable-next-line
  }, [formState.values.equipment, availableOwners]);

  const handleForm = (form: EntityView) => {
    formState.setField('equipmentOwner', form);
    close();
  };

  const softIncludes = (a: string, b: string) => {
    if (!a || !b) {
      return false;
    }
    return a.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()
      .includes(b.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase());
  };

  const getAsyncOptions = useCallback(inputValue => new Promise((resolve) => {
    // If there are no available owners, return an empty list
    if (!availableOwners) {
      resolve([]);
      return;
    }

    // If the search input is empty, return the first 100 results
    if (!inputValue) {
      resolve(availableOwners.slice(0, 100));
      return;
    }
    const a = availableOwners.filter(o => softIncludes(o.firstName, inputValue)
      || softIncludes(o.lastName, inputValue)
      || softIncludes(o.firstName, inputValue)
      || softIncludes(o.institutionIdentifier, inputValue)
      || softIncludes(o.email, inputValue)
      || softIncludes(o.address?.address1, inputValue)
      || softIncludes(o.address?.address2, inputValue)
      || softIncludes(o.address?.city, inputValue)
      || softIncludes(o.address?.postalCode, inputValue)
      || softIncludes(o.address?.country?.label, inputValue));
    resolve(a.slice(0, 100));
  }), [availableOwners]);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const CustomOption = ({
    data,
    innerProps
  }: {
    data: EntitySummaryItem,
    innerProps
  }) => (
    <EquipmentOwnerOption entity={data} isInsideSelect {...innerProps} />
  );

  const displayNoOptionAvailable = () => {
    if (searchInput) {
      return (
        <Fragment>
          <TextNoOptionsAvailable>{translate('errors.noEquipmentOwnerInput', { searchInput })}</TextNoOptionsAvailable>
          <ButtonBigDashed
            data-cy="addEquipmentOwner"
            onClick={() => open({
              type: 'ADD_EQUIPMENT_OWNER',
              onSubmit: handleForm,
              defaultValues: {
                institutionName: searchInput
              }
            })}
          >
            <FontAwesomeIcon icon={faPlus} />
            {translate('button.createEquipmentOwner')}
          </ButtonBigDashed>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <TextNoOptionsAvailable>{translate('errors.noEquipmentOwner')}</TextNoOptionsAvailable>
        <ButtonBigDashed
          data-cy="addEquipmentOwner"
          onClick={() => open({
            type: 'ADD_EQUIPMENT_OWNER',
            onSubmit: handleForm
          })}
        >
          <FontAwesomeIcon icon={faPlus} />
          {translate('button.createEquipmentOwner')}
        </ButtonBigDashed>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Question label={translate('interventionForm.equipmentOwner.questionOwner')} tooltip="interventionFormSteps.ownerTooltip">
        {formState.values.equipmentOwner ? (
          <ItemSelected
            deleteAction={() => formState.setField('equipmentOwner', null)}
            editAction={!formState.values.equipmentOwner.id ? () => open({
              type: 'ADD_EQUIPMENT_OWNER',
              onSubmit: handleForm,
              defaultValues: formState.values.equipmentOwner
            }) : null}
          >
            <EquipmentOwnerOption entity={formState.values.equipmentOwner} />
          </ItemSelected>
        ) : (
          <>
            <Typography gutterBottom>{translate('interventionForm.equipmentOwner.selectEquipmentsOwnerPlaceholder')}</Typography>
            <Typography gutterBottom>{translate('interventionForm.equipmentOwner.selectEquipmentsOwnerPlaceholder2')}</Typography>
            <SelectCreateContainer>
              <div>
                <AsyncSelect
                  cacheOptions
                  components={{ Option: CustomOption }}
                  defaultOptions={equipmentFilteredOwners}
                  id="selectEquipmentOwner"
                  isClearable
                  isLoading={!availableOwners?.length && isLoading}
                  isSearchable
                  loadingMessage={() => translate('interventionForm.equipmentOwner.loadingEquipmentsOwner')}
                  loadOptions={inputValue => debouncedLoadOptions(inputValue)}
                  menuPlacement={isMobileOnly ? 'top' : 'bottom'}
                  noOptionsMessage={displayNoOptionAvailable}
                  placeholder={translate('common.searchByNameSiret')}
                  value={formState.values.equipmentOwner || ''}
                  onChange={handleForm}
                  onInputChange={value => setSearchInput(value)}
                />
                {isRequired && <RequiredSelectHack value={formState.values.equipmentOwner || ''} />}
              </div>
              <div>
                <Media query="(max-width: 768px)">
                  {matches => (matches
                    ? (
                      <Fab
                        color="primary"
                        size="small"
                        onClick={() => open({
                          type: 'ADD_EQUIPMENT_OWNER',
                          onSubmit: handleForm
                        })}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Fab>
                    ) : (
                      <ButtonBlueLight
                        data-cy="addEquipmentOwner"
                        onClick={() => open({
                          type: 'ADD_EQUIPMENT_OWNER',
                          onSubmit: handleForm
                        })}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {translate('button.createEquipmentOwner')}
                      </ButtonBlueLight>
                    )
                  )}
                </Media>
              </div>
            </SelectCreateContainer>
          </>
        )}
      </Question>
    </Fragment>
  );
});
