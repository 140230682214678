import {
  faIdCard,
  faMailBulk,
  faSpinner,
  faSync,
  faUserEdit
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  CustomCardTitle,
  InfoLine,
  SkeletonDetailUser,
  Text,
  TextAddress,
  TextError,
  TitlePage,
  Wrapper
} from 'components';
import { useModal, useStores } from 'hooks';
import { uniqBy } from 'lodash/array';

import { joinRender } from 'collectionUtils';
import { WarningHeader } from 'components/Header/WarningHeader';
import { UserView } from 'models';
import { RoleSelectItem } from 'models/detail/RoleSelectItem';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { UsersService } from 'services';
import shortid from 'shortid';
import styled from 'styled-components';
import { INTERVENTION_ACCESS_ROLES, ROUTES } from 'utils/constants';
import {
  IndexedDBHelper,
  UserHelper,
  formatDateStringISO,
  generateUUID,
  translate
} from 'utils/helpers';
import { KEYCLOAK_STORE } from 'utils/helpers/IndexedDBHelper';
import { KeyCloakUtils } from 'utils/keycloak/keycloakUtils';
import { StructureLevelOption } from 'utils/types/StructureLevelOption';

const UserDetailHeader = styled.header`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 1rem 0;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    color: var(--white);
    font-size: 2.6rem;
    border-radius: 50%;
    border: 2px solid var(--blue-dark);
    background-color: var(--blue);
  }

  button {
    margin-left: 2rem;
  }
`;

const useStyles = makeStyles(() => ({
  gridItem: {
    flex: 1,
    minWidth: '250px',
    marginTop: '2rem'
  }
}));

const Emails = ({ user }) => (
  <InfoLine
        displayIf={user.person.contact.contactEmails}
        info={user?.person?.contact?.contactEmails && (joinRender(
                user.person.contact.contactEmails.split(';'),
                (mail) => (
                  <a href={`mailto:${mail}`} key={shortid.generate()}>
                    <strong>{mail}</strong>
                  </a>
                ),
                ', '
            )
        )}
        label="common.contactEmails.label"
    />);

export const UserDetail = () => {
  const { id: personId } = useParams<any>();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { open } = useModal();
  const { userStore } = useStores();
  const [areAdministrableLevelsLoading, setAreAdministrableLevelsLoading] = useState<boolean>(false);
  const [isPrimaryDevice, setIsPrimaryDevice] = useState<boolean>(undefined);
  const [accessibleRolesByStructureLevel, setAccessibleRolesByStructureLevel] = useState<Map<StructureLevelOption, RoleSelectItem[]>>(new Map());
  const [isCurrentUser, setCurrentUser] = useState<boolean>(false);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserView>(null);
  const keycloak = KeyCloakUtils.currentKeycloakInstance();
  const canEditRoles = Array.from(accessibleRolesByStructureLevel, ([, value]) => (value.length)).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
  ) > 0;
  const disabledStatus = user?.hasAccount && !user?.accountActivated ? `(${translate('common.deactivated')})` : '';

  const loadUser = useCallback(() => {
    setIsUserLoading(true);
    UsersService.getUser(personId)
      .then((resp) => {
        setUser(resp);
        setIsUserLoading(false);
      });
  }, [personId]);

  const resetPassword = useCallback(() => UsersService.resetPassword()
      .then(() => {
        keycloak.logout();
      })
      .catch((err) => {
        enqueueSnackbar((err && err.message) || err, { variant: 'error' });
      }), [enqueueSnackbar, keycloak]);

  const displayModalUpdatePassword = useCallback(
      () => open({
        type: 'WARNING',
        title: translate('userDetails.action.updatePassword'),
        text: translate('userDetails.confirmation.updatePassword'),
        onConfirm: () => resetPassword(),
        buttonCancel: translate('button.cancel'),
        buttonConfirm: translate('button.confirm'),
        confirmOnly: true
      }),
      [resetPassword, open]
  );

  useEffect(() => {
    const { currentUser } = userStore;
    const userCompanyToGetRoles = personId === 'current' ? currentUser?.person?.mainCompany?.value : user?.person?.mainCompany?.value;
    if (userCompanyToGetRoles) {
        setAreAdministrableLevelsLoading(true);
        UsersService.getAccessibleRolesWithStructureLevel(userCompanyToGetRoles)
            .then((response) => {
                const map = new Map();
                Object.values(response).forEach((value) => map.set(value.structureLevelOption, value.roleSelectItems));
                setAccessibleRolesByStructureLevel(map);
            }).finally(() => setAreAdministrableLevelsLoading(false));
    }
  }, [personId, user?.person?.mainCompany?.value, userStore]);

  useEffect(() => {
    const { currentUser } = userStore;
    if (currentUser && (personId === 'current' || personId === currentUser.accountId)) {
      setUser(currentUser);
      setCurrentUser(true);
      return;
    }
    loadUser();
    // eslint-disable-next-line
  }, [personId, loadUser]);
  // Add config data
  useEffect(() => {
    if (isUserLoading || !user || !isCurrentUser) {
      return;
    }
    // Get the device id for the current user
    UsersService.getUserConfigsDeviceId()
      .then((resp) => {
        if (!resp) return;
        // Get the stored id from the current device
        IndexedDBHelper.getData({
          store: KEYCLOAK_STORE,
          key: `deviceId_${userStore.currentUser?.accountId}`
        })
          .then((storedDeviceId) => {
            if (!storedDeviceId) return;
            setIsPrimaryDevice(resp === storedDeviceId);
          });
      });
  }, [isUserLoading, user, isCurrentUser, userStore.currentUser]);

  const handleChangePrimaryDevice = useCallback((event) => {
    const { target: { checked } } = event;
    const userId = userStore.currentUser?.accountId;

    if (checked) {
      const newDeviceId = generateUUID();
      UsersService.setUserConfigsDeviceId(newDeviceId)
        .then(() => {
          IndexedDBHelper.updateData({
            store: KEYCLOAK_STORE,
            key: `automaticInterventionDownload_${userId}`,
            data: true
          });
          IndexedDBHelper.updateData({
            store: KEYCLOAK_STORE,
            key: `deviceId_${userId}`,
            data: newDeviceId
          });

          userStore.setDeviceId(newDeviceId);
          enqueueSnackbar(translate('confirms.intervention.deviceSet'), { variant: 'success' });
        })
        .catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }));
    } else {
      UsersService.setUserConfigsDeviceId(null)
        .then(() => {
          IndexedDBHelper.updateData({
            store: KEYCLOAK_STORE,
            key: `automaticInterventionDownload_${userId}`,
            data: false
          });
          IndexedDBHelper.deleteData({
            store: KEYCLOAK_STORE,
            key: `deviceId_${userId}`
          });
          IndexedDBHelper.deleteData({
            store: KEYCLOAK_STORE,
            key: `lastAutomaticInterventionDownload_${userId}`
          });

          userStore.setDeviceId('');
          enqueueSnackbar(translate('confirms.intervention.deviceUnset'), { variant: 'success' });
        })
        .catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }));
    }

    setIsPrimaryDevice(checked);
    // eslint-disable-next-line
  }, [enqueueSnackbar]);

  const updateUser = useCallback((userUpdated) => {
    UsersService.updateUser(personId, userUpdated)
      .then(() => {
        enqueueSnackbar(
          <span dangerouslySetInnerHTML={
            { __html: sanitizeHtml(translate('confirms.userDetail.update')) }
          }/>,
          { variant: 'success' }
        );
        setTimeout(() => userStore.keycloak.login(), 1000);
      })
      .catch(error => {
        enqueueSnackbar((error && error.message) || error, { variant: 'error' });
      });
  }, [enqueueSnackbar, personId, userStore.keycloak]);

  const updateRoles = useCallback((userId, roles) => {
    UsersService.updateRoles(userId, roles)
      .then(() => {
          enqueueSnackbar(<span dangerouslySetInnerHTML={
              { __html: sanitizeHtml(translate('confirms.userDetail.update').replace('<br />', '\n')) }
              }/>, { variant: 'success' });
          setTimeout(() => userStore.keycloak.login(), 1000);
      })
        .catch(error => {
            enqueueSnackbar((error && error.message) || error, { variant: 'error' });
      });
  }, [enqueueSnackbar, userStore.keycloak]);

  const openUpdateUser = useCallback(() => open({
    type: 'UPDATE_USER',
    defaultValues: user,
    onConfirm: updateUser,
    closeOnSubmit: false
  }), [open, updateUser, user]);

  const openUpdateRoles = useCallback(() => open({
    type: 'UPDATE_ROLES',
    defaultValues: user,
    accessibleRolesByStructureLevel,
    onConfirm: roles => updateRoles(user.accountId, roles),
    closeOnSubmit: false
  }), [open, user, accessibleRolesByStructureLevel, updateRoles]);

  if (isUserLoading) {
    return <SkeletonDetailUser />;
  }

  if (!isUserLoading && !user) {
    return (
      <Grid alignContent="center" container direction="column" justifyContent="center">
        <TextError>{translate('errors.noUserDetail')}</TextError>
        <Link to={ROUTES.ADMINISTRATION_USERS}>
          <Button color="primary" variant="contained">
            {translate('button.backToAdminPage')}
          </Button>
        </Link>
      </Grid>
    );
  }

  return (
    <>
      {Boolean(user?.accountActivated) && !user?.roles?.length && (
        <Grid
          item
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 50 // medium
          }}
        >
          <WarningHeader>
            <Typography style={{ color: 'var(--white)' }}>
              {translate('pageUserDetails.warning.selectRoles')}
            </Typography>
          </WarningHeader>
        </Grid>
        )}
      <Wrapper>
        <UserDetailHeader>
          <span>{`${user.person.firstName.charAt(0)}${user.person.lastName.charAt(0)}`}</span>
          <TitlePage data-cy="userDetailTitle">
            {`${user.person.firstName} ${user.person.lastName} ${disabledStatus}`}
          </TitlePage>
        </UserDetailHeader>

        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Card>
              <CustomCardTitle backgroundColor="var(--grey-light)"
              title={translate('pageUserDetail.general')}>
                {isCurrentUser && (
                <Button
                        color="primary"
                        style={{ marginRight: 5 }}
                        variant="contained"
                        onClick={displayModalUpdatePassword}
                    >
                  {translate('button.userDetails.updatePassword')}
                </Button>
                )}
                <Button
                color="primary"
                startIcon={<FontAwesomeIcon icon={faUserEdit} />}
                variant="contained"
                onClick={openUpdateUser}
              >
                  {translate('button.edit')}
                </Button>
              </CustomCardTitle>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid className={classes.gridItem} item>
                    <Card style={{ height: '100%' }}>
                      <CustomCardTitle
                        icon={faIdCard}
                        title={translate('pageUserDetail.myInfos')}
                      />
                      <CardContent>
                        <InfoLine
                          info={user.login}
                          label="common.login"
                        />
                        <InfoLine
                            displayIf={user.person.contact.technicalEmail}
                            info={<a
                                href={`mailto:${user.person.contact.technicalEmail}`}>{user.person.contact.technicalEmail}</a>}
                            label="common.email"
                        />
                        <InfoLine
                          displayIf={Boolean(user.person.creationDate)}
                          info={formatDateStringISO(user.person.creationDate)}
                          label="common.creationDate"
                        />
                        <InfoLine info={user.person.occupation} label="common.occupation" />
                        <InfoLine info={user.person.attestationNumber} label="common.capacityAttestationNumber" />
                        <InfoLine info={user.person?.mainCompany?.label} label="common.company" />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid className={classes.gridItem} item>
                    <Card style={{ height: '100%' }}>
                      <CustomCardTitle
                        icon={faMailBulk}
                        title={translate('common.contact')}
                      />
                      <CardContent>
                        <InfoLine
                          displayIf={user.person.address && user.person.address.city}
                          info={<TextAddress address={user.person.address} />}
                          label="common.address"
                        />
                        <Emails user={user}/>
                        <InfoLine
                          displayIf={user.person.contact.phoneNumber}
                          info={<a
                          href={`tel:${user.person.contact.phoneNumber}`}>{user.person.contact.phoneNumber}</a>}
                          label="common.phone"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {isCurrentUser && !userStore.isOffline && UserHelper.hasAccessRight(INTERVENTION_ACCESS_ROLES) && (
            <Grid item>
              <Card>
                <CustomCardTitle
                backgroundColor="var(--grey-light)"
                icon={faSync}
                title={translate('pageUserDetail.automaticSynchro')}
              />
                <CardContent>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={isPrimaryDevice}
                        onChange={handleChangePrimaryDevice}
                      />
                    )}
                    key={shortid.generate()}
                    label={translate('pageUserDetail.checkboxPrimaryDevice')}
                  />
                </CardContent>
              </Card>
            </Grid>
        )}

          {!isUserLoading && user && (
            <Grid item>
              {user.accountActivated && (
                <Card>
                  <CustomCardTitle backgroundColor="var(--grey-light)"
                    title={translate('pageUserDetail.roles')}>
                    {user.canEdit && canEditRoles && (
                      <Button
                        color="primary"
                        disabled={areAdministrableLevelsLoading}
                        startIcon={(
                          <FontAwesomeIcon
                            icon={areAdministrableLevelsLoading ? faSpinner : faUserEdit}
                            spin={areAdministrableLevelsLoading}
                          />
                        )}
                        variant="contained"
                        onClick={openUpdateRoles}
                      >
                        {translate('button.edit')}
                      </Button>
                    )}
                  </CustomCardTitle>
                  {user.roles?.length > 0 ? (
                    <CardContent>
                      <Grid container direction="column" spacing={3}>
                        <Grid className={classes.gridItem} item>
                          <div>
                            {uniqBy(user.roles, role => role.label)
                              .map(role => (
                                <Text key={shortid.generate()}>
                                  {role.label}
                                </Text>
                              ))}
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  ) : (
                    <TextError>{translate('errors.noUserRoles')}</TextError>
                  )}
                </Card>
              )}
            </Grid>
        )}
        </Grid>
      </Wrapper>
    </>
  );
};
