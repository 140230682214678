/* eslint-disable react/jsx-no-duplicate-props */
import { faTint, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Checkbox, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, InputAdornment, Paper, TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  ButtonDelete, CustomIcon, CustomTooltip, Datepicker, FlexContainer, FormLine,
  ItemOption, Question, RequiredSelectHack, Text, TextError, Warn
} from 'components';
import { SkeletonForm } from 'components/Skeletons';
import { TextNoOptionsAvailable } from 'components/Text';
import debounce from 'debounce-promise';
import { useFetch, useStores } from 'hooks';
import { ModalProps } from 'hooks/ModalProvider';
import { BottleView, FluidMovementView } from 'models';
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useFormState } from 'react-use-form-state';
import sanitizeHtml from 'sanitize-html';
import {
  FluidMovementsService, FluidsService, InstitutionsService, LegalEntityService, TrackDechetsService
} from 'services';
import shortid from 'shortid';
import { COUNTRY_CODE, DISTRIBUTOR_ROLES } from 'utils/constants';
import { FLUID_MOVEMENT_LIST } from 'utils/fluid_movement_constants';
import {
  DATE_FORMAT,
  DocumentHelper,
  FormHelper,
  UserHelper,
  formatDate,
  getSmallDescription, translate
} from 'utils/helpers';
import { SelectItem } from 'utils/types';
import { ModalHeader } from './ModalHeader';

const GROUP_INPUT = 'INPUT';
const GROUP_OUTPUT = 'OUTPUT';

const TYPE_INPUT = 'INPUT';
const TYPE_OUTPUT = 'OUTPUT';
const TYPE_RESET = 'RESET';
const TYPE_STOCK = 'STOCK';
const TYPE_HANDOVER = 'HANDOVER';
const TYPE_VALIDATION = 'VALIDATION';
const TYPE_RETRIEVAL = 'RETRIEVAL';
const TYPE_SALE = 'SALE';

const createMiniature = (document) => {
  const extension = DocumentHelper.getExtension(document);
  switch (extension) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return (
        <FlexContainer className="modalPicturesContainer">
          <img alt={document.name} key={shortid.generate()} src={document.base64Content} />
        </FlexContainer>
      );
    default:
      return null;
  }
};

export const CreateFluidMovementModal = ({
  onSubmit, onClose, movementId
}: ModalProps<FluidMovementView> & {
  movementId?: string
}) => {
  const { fluidStore } = useStores();

  const {
    response: tdConnectionStatus,
    isLoading: isCheckingStatus
  } = useFetch(() => TrackDechetsService.getConnectionStatus(), { response: {}, isLoading: false }, [], false);
  const [uploadError, setUploadError] = useState('');
  const [linkedDocument, setLinkedDocument] = useState(null);
  const [searchTransportersTD, setSearchTransportersTD] = useState('');
  const [searchSuppliersOrRecipients, setSearchSuppliersOrRecipients] = useState('');
  const [suppliersOrRecipients, setSuppliersOrRecipients] = useState<SelectItem[]>([]);
  const [formattedBottleList, setFormattedBottleList] = useState<SelectItem[]>([]);
  const [isLoadingSuppliersOrRecipients, setIsLoadingSuppliersOrRecipients] = useState(false);
  const [isLoadingTransportersTD, setIsLoadingTransportersTD] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMovementGroup, setCurrentMovementGroup] = useState(GROUP_INPUT);
  const [fluidFilter, setFluidFilter] = useState<SelectItem>();
  const [isTransporterEmpty, setIsTransporterEmpty] = useState(false);

  const isDistributor = UserHelper.hasAccessRight(DISTRIBUTOR_ROLES);
  const isFrance = UserHelper.getDefaultCountry()?.key === COUNTRY_CODE.FR;

  const [formState, { text, number, checkbox }] = useFormState<FluidMovementView>(new FluidMovementView());

  const getCurrentFluidMovementConfig = useCallback(() => {
    if (!formState.values.movementType?.value) {
      return FLUID_MOVEMENT_LIST.find(movement => movement.key === TYPE_INPUT);
    }
    return FLUID_MOVEMENT_LIST.find(movement => movement.key === formState.values.movementType?.value);
  }, [formState.values.movementType]);

  useEffect(() => {
    if (!movementId) {
      formState.setField('movementType', { value: TYPE_INPUT, label: TYPE_INPUT });
      formState.setField('movementDate', formatDate(new Date()));
      return;
    }
    setIsLoading(true);

    FluidMovementsService.getFluidMovement(movementId)
      .then((resp) => {
        resp.linkedDocument
          && setLinkedDocument({ name: resp.linkedDocument.name, base64Content: DocumentHelper.getPDFWithBase64(resp.linkedDocument) });
        Object.entries(resp).forEach(([key, value]) => formState.setField(key as any, value));
        formState.setField('fluid', FormHelper.SELECT_ADAPTOR_TO_FRONT({ selectItem: resp.fluid }));
        formState.setField('supplierOrRecipient', resp.supplierOrRecipient);
      }).finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [movementId]);

  const setLinkedDocumentFromDocuments = (docs) => {
    setLinkedDocument(docs[0]);
  };

  useEffect(() => {
    if (fluidStore.isLoadingBottles) {
      return;
    }
    let bottles = fluidStore.bottleExtendedOptions;
    if (fluidFilter) {
      bottles = bottles.filter(bottle => bottle.fluid?.value === fluidFilter.value);
    }
    setFormattedBottleList(bottles.map(bottleItem => ({
      value: bottleItem.id,
      label: bottleItem.identifier,
      fluid: bottleItem.fluid
  })));
}, [fluidFilter, fluidStore.bottleExtendedOptions, fluidStore.isLoadingBottles]);

  useEffect(() => {
    FluidsService.getBottleOptionsExtended({ movementType: formState.values.movementType })
      .then(bottleOptions => {
        let bottles = bottleOptions;
        if (fluidFilter) {
          bottles = bottles.filter(bottle => bottle.fluid?.value === fluidFilter.value);
        }
        setFormattedBottleList(bottles.map(bottleItem => ({
          value: bottleItem.id,
          label: bottleItem.identifier,
          fluid: bottleItem.fluid
        })));
      });
  }, [formState.values.movementType, fluidFilter]);

  useEffect(() => {
    LegalEntityService.getLegalEntitiesOptionsFiltered({ types: [getCurrentFluidMovementConfig().supplierOrRecipient] })
      .then(res => setSuppliersOrRecipients(res))
      .finally(() => setIsLoadingSuppliersOrRecipients(false));
  }, [getCurrentFluidMovementConfig]);

  useEffect(() => {
    // is Retrieval should be false by default for Retrieval movement
    if (!movementId && formState.values.movementType?.value === TYPE_RETRIEVAL) {
      formState.setField('isRetrievalBottle', false);
    }
    // eslint-disable-next-line
  }, [formState.values.movementType, movementId]);

  useEffect(() => {
    setIsTransporterEmpty(!formState.values.transporter);
  }, [formState.values.transporter]);

  const isCreating = !formState.values.hashId;
  const isTypeInput = formState.values.movementType?.value === TYPE_INPUT;
  const isTypeStock = formState.values.movementType?.value === TYPE_STOCK;
  const isTypeHandover = formState.values.movementType?.value === TYPE_HANDOVER;
  const isTypeRetrieval = formState.values.movementType?.value === TYPE_RETRIEVAL;
  const isTypeOutput = formState.values.movementType?.value === TYPE_OUTPUT;
  const isTypeValidation = formState.values.movementType?.value === TYPE_VALIDATION;
  const isTypeSale = formState.values.movementType?.value === TYPE_SALE;
  const isGroupInput = getCurrentFluidMovementConfig().group === GROUP_INPUT;
  const needsFluidDisplay = (formState.values.bottle && formState.values.movementType?.value !== TYPE_RESET)
    || (formState.values.movementType?.value === TYPE_STOCK);
  const { hasDocument } = getCurrentFluidMovementConfig();

  const handleValidateModal = (e) => {
    e.preventDefault();

    // Get the bottle data
    let bottle: BottleView;
    if (formState.values.bottle && formState.values.bottle.id) {
      // Use an existing bottle
      bottle = fluidStore.bottleExtendedOptions.find(bottleItem => bottleItem.id === formState.values.bottle.id);
    } else {
      // Build a new bottle from the movement fields
      bottle = {
        ...new BottleView(),
        identifier: formState.values.bottle as unknown as string
      };
    }

    let bottles: BottleView[] = [];
    if (formState.values.bottles?.length === 1) {
      bottle = fluidStore.bottleExtendedOptions.find(bottleItem => bottleItem.id === formState.values.bottles[0].id);
    } else if (formState.values.bottles?.length > 1) {
      bottles = formState.values.bottles.map(b => fluidStore.bottleExtendedOptions.find(bottleOption => bottleOption.id === b.id));
    }

    const request: FluidMovementView = {
      ...formState.values,
      hashId: movementId,
      movementType: { value: getCurrentFluidMovementConfig().key, label: '' },
      bottle,
      bottles,
      fluid: formState.values.fluid ? FormHelper.SELECT_ADAPTOR_TO_BACK(formState.values.fluid) : null,
      theoricalAmount: Number(formState.values.theoricalAmount),
      measuredAmount: Number(formState.values.measuredAmount),
      maximumCapacity: Number(formState.values.maximumCapacity),
      volume: formState.values.volume && Number(formState.values.volume)
    };

    if (linkedDocument && hasDocument) {
      request.linkedDocument = {
        ...linkedDocument,
        base64Content: DocumentHelper.getDocumentWithoutBase64(linkedDocument)
      };
    }
    onSubmit(request);
  };

  const getAsyncTransportersOptions = useCallback(inputValue => new Promise((resolve) => {
    if (!inputValue) {
      setIsLoadingTransportersTD(false);
      return;
    }
    setIsLoadingTransportersTD(true);
    if (inputValue.length < 3) {
      return;
    }

    InstitutionsService.getInstitutionsTrackDechets(inputValue)
      .then(instiList => resolve(instiList))
      .finally(() => setIsLoadingTransportersTD(false));
  }), []);

  const getAsyncSuppliersOrRecipientsOptions = useCallback(inputValue => new Promise((resolve) => {
    if (!inputValue) {
      setIsLoadingSuppliersOrRecipients(false);
      return;
    }
    setIsLoadingSuppliersOrRecipients(true);
    if (inputValue.length < 3) {
      return;
    }

    LegalEntityService.getLegalEntitiesOptionsFiltered({ freeSearch: inputValue, types: [getCurrentFluidMovementConfig().supplierOrRecipient] })
      .then(res => resolve(res))
      .finally(() => setIsLoadingSuppliersOrRecipients(false));
  }), [getCurrentFluidMovementConfig]);

  const debouncedTransporterOptions = debounce(getAsyncTransportersOptions, 500);
  const debouncedSuppliersOrRecipientsOptions = debounce(getAsyncSuppliersOrRecipientsOptions, 500);

  const CustomOption = ({ data, innerProps }) => (
    <ItemOption {...innerProps} isInsideSelect>
      <article>
        <Text fontSize="2rem" margin="0">{data.label}</Text>
        <Text color="var(--grey)" fontSize="1.4rem" margin="0">{data.fluid && `${translate('common.fluid')} : ${data.fluid.label}`}</Text>
      </article>
    </ItemOption>
  );

  const resetFields = useCallback(() => {
    formState.setField('supplierOrRecipient', null);
    formState.setField('bottles', []);
    formState.setField('bottle', null);
    formState.setField('fluid', null);
    formState.setField('theoricalAmount', null);
    formState.setField('measuredAmount', null);
    formState.setField('volume', null);
  }, [formState]);

  const displayNoOptionAvailable = useCallback((search) => {
    if (!search || search.length < 3) {
      return (
        <TextNoOptionsAvailable>{translate('modalFluidMovement.startSearch')}</TextNoOptionsAvailable>
      );
    }
    return (
      <TextNoOptionsAvailable>{translate('modalFluidMovement.noInstitution')}</TextNoOptionsAvailable>
    );
  }, []);

  const getModalMinSizeStyle = useCallback(() => {
    let minWidth = 700;
    let minHeight = 650;

    if (isDistributor) {
      minWidth = 900;
    }
    if (movementId) {
      minWidth = 400;
      minHeight = 500;
    }
    if (isMobileOnly) {
      minWidth = 200;
      minHeight = 500;
    }
    return { minWidth: `${minWidth}px`, minHeight: `${minHeight}px` };
  }, [isDistributor, movementId]);

  if (isCheckingStatus || !tdConnectionStatus || isLoading) {
    return (
      <SkeletonForm />
    );
  }

  return (
    <form autoComplete="off" style={getModalMinSizeStyle()} onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <CustomIcon icon={<FontAwesomeIcon icon={faTint} />} marginRight="1rem" />
        {movementId ? translate('modalFluidMovement.editFluidMovement') : translate('modalFluidMovement.newFluidMovement')}
      </ModalHeader>

      <DialogContent>
        <FlexContainer flexDirection="column">
          {!movementId && !tdConnectionStatus.isValid && isFrance && (
            <Warn maxWidth="600px">{tdConnectionStatus.message}</Warn>
          )}

          {!movementId && (
            <>
              <Question label={translate('modalFluidMovement.fluidMovementGroup')}>
                <ToggleButtonGroup
                  aria-label={translate('modalFluidMovement.fluidMovementGroup')}
                  data-cy="selectType"
                  exclusive
                  size="medium"
                  value={currentMovementGroup}
                  onChange={(event, value) => {
                    setCurrentMovementGroup(value);
                    resetFields();
                    if (value === GROUP_INPUT) {
                      formState.setField('movementType', { value: TYPE_INPUT, label: TYPE_INPUT });
                    } else {
                      formState.setField('movementType', { value: TYPE_OUTPUT, label: TYPE_OUTPUT });
                    }
                  }}
                >
                  <ToggleButton
                    aria-label="input"
                    data-cy="input"
                    key={shortid.generate()}
                    value={GROUP_INPUT}
                  >
                    {translate('modalFluidMovement.input')}
                  </ToggleButton>
                  <ToggleButton
                    aria-label="output"
                    data-cy="output"
                    key={shortid.generate()}
                    value={GROUP_OUTPUT}
                  >
                    {translate('modalFluidMovement.output')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Question>
              <Question label={translate(currentMovementGroup === GROUP_INPUT
                ? 'modalFluidMovement.fluidMovementInput'
                : 'modalFluidMovement.fluidMovementOutput')}
              >
                <ToggleButtonGroup
                  aria-label={translate('modalFluidMovement.fluidMovementGroup')}
                  data-cy="selectType"
                  exclusive
                  size="medium"
                  value={formState.values.movementType?.value}
                  onChange={(event, value) => {
                    if (value === null) return;
                    formState.setField('movementType', { value, label: value });
                    resetFields();
                  }}
                >
                  {FLUID_MOVEMENT_LIST
                    .filter(movement => movement.group === currentMovementGroup)
                    .filter(movement => movement.availableToOperator || isDistributor)
                    .map(movement => (
                      <ToggleButton
                        aria-label={movement.label}
                        data-cy={movement.key}
                        key={shortid.generate()}
                        value={movement.key}
                      >
                        <span dangerouslySetInnerHTML={
                          { __html: sanitizeHtml(translate(isDistributor && movement.labelDistributor ? movement.labelDistributor : movement.label)) }
                        }
                        />
                      </ToggleButton>
                    ))}
                </ToggleButtonGroup>
              </Question>
            </>
          )}

          {isGroupInput && !movementId && (
            <Fragment>
              {isTypeInput && !isDistributor && (
                <Warn maxWidth="600px">{translate('warnings.movement.bottleRecup')}</Warn>
              )}
              <TextField
                error={!!formState.errors.bottle}
                label={translate('modalFluidMovement.bottleIdentifier')}
                name="bottle"
                required
                style={{ margin: '5px 0' }}
                {...text('bottle')}
                inputProps={{ pattern: '.*\\S.*' }}
              />

              {isTypeStock && (
                <FlexContainer alignItems="center">
                  <ToggleButtonGroup
                    aria-label="isRetrievalBottle"
                    data-cy="isRetrievalBottle"
                    exclusive
                    size="small"
                    value={formState.values.isRetrievalBottle}
                    onChange={(event, value) => {
                      if (value === null) return;
                      formState.setField('isRetrievalBottle', value);
                    }}
                  >
                    <ToggleButton
                      aria-label={translate('bottles.RETRIEVAL_BOTTLE')}
                      data-cy="yes"
                      key={shortid.generate()}
                      value
                    >
                      {translate('bottles.RETRIEVAL_BOTTLE')}
                    </ToggleButton>
                    <ToggleButton
                      aria-label={translate('bottles.TRANSFER_BOTTLE')}
                      data-cy="no"
                      key={shortid.generate()}
                      value={false}
                    >
                      {translate('bottles.TRANSFER_BOTTLE')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FlexContainer>
              )}

              {/* <Datepicker
                dataCy="bottleValidityDate"
                label="common.bottleValidityDate"
                style={{ marginTop: '.5rem' }}
                value={formState.values.bottleValidityDate}
                onChange={date => formState.setField('bottleValidityDate', date)}
                  /> */}
            </Fragment>
          )}

          {isTypeRetrieval && !movementId && (
            <FlexContainer alignItems="center">
              <ToggleButtonGroup
                aria-label="isRetrievalBottle"
                data-cy="isRetrievalBottle"
                exclusive
                size="small"
                value={formState.values.isRetrievalBottle}
                onChange={(event, value) => {
                  if (value === null) return;
                  formState.setField('isRetrievalBottle', value);
                }}
              >
                <ToggleButton
                  aria-label={translate('bottles.RETRIEVAL_BOTTLE')}
                  data-cy="yes"
                  key={shortid.generate()}
                  value
                >
                  {translate('bottles.RETRIEVAL_BOTTLE')}
                </ToggleButton>
                <ToggleButton
                  aria-label={translate('bottles.NEW_OR_REGENERATED')}
                  data-cy="no"
                  key={shortid.generate()}
                  value={false}
                >
                  {translate('bottles.NEW_OR_REGENERATED')}
                </ToggleButton>
              </ToggleButtonGroup>
            </FlexContainer>
          )}

          {getCurrentFluidMovementConfig().supplierOrRecipient && (
            <Question label={translate(getCurrentFluidMovementConfig().supplierOrRecipientLabel)}>
              <AsyncSelect
                cacheOptions
                defaultOptions={suppliersOrRecipients}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                id="selectInstitution"
                inputValue={searchSuppliersOrRecipients}
                isClearable
                isLoading={isLoadingSuppliersOrRecipients}
                isSearchable
                loadingMessage={() => translate('modalFluidMovement.loadingInstitutions')}
                loadOptions={debouncedSuppliersOrRecipientsOptions}
                menuPlacement={isMobileOnly ? 'top' : 'bottom'}
                menuPortalTarget={document.body}
                noOptionsMessage={() => displayNoOptionAvailable(searchSuppliersOrRecipients)}
                placeholder={translate('common.searchByNameSiret')}
                styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                value={formState.values.supplierOrRecipient || null}
                onChange={select => formState.setField('supplierOrRecipient', select)}
                onInputChange={value => setSearchSuppliersOrRecipients(value)}
              />
              {isTypeOutput && !isTypeHandover && <RequiredSelectHack value={formState.values.supplierOrRecipient || ''} />}
              {isTypeOutput && tdConnectionStatus.isValid && (
                <Text color="var(--orange)" fontSize="14px">{translate('warnings.tdConnection.recipient')}</Text>
              )}
            </Question>
          )
          }

          {isTypeOutput && !movementId && tdConnectionStatus.isValid && (
            <Question label={`${translate('modalFluidMovement.transporter')} :`}>
              <AsyncSelect
                cacheOptions
                getOptionLabel={option => `${option.name} (${option.siret})`}
                getOptionValue={option => option.siret}
                id="selectTransporter"
                inputValue={searchTransportersTD}
                isClearable
                isLoading={isLoadingTransportersTD}
                isSearchable
                loadingMessage={() => translate('modalFluidMovement.loadingInstitutions')}
                loadOptions={debouncedTransporterOptions}
                menuPlacement={isMobileOnly ? 'top' : 'bottom'}
                menuPortalTarget={document.body}
                noOptionsMessage={() => displayNoOptionAvailable(searchTransportersTD)}
                placeholder={translate('common.searchByNameSiret')}
                styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                value={formState.values.transporter || null}
                onChange={select => formState.setField('transporter', select)}
                onInputChange={value => setSearchTransportersTD(value)}
              />
              {isTransporterEmpty && (
                <>
                  <Text color="var(--orange)" fontSize="14px">{translate('modalFluidMovement.notTransporterWarning')}</Text>
                  <TextField
                    error={!!formState.errors.transporterImat}
                    label={translate('common.transporterImat')}
                    name="transporterImat"
                    required
                    {...text('transporterImat')}
                  />
                </>
              )}
            </Question>
          )}

          {!isGroupInput && !movementId && (
            <Fragment>
              <Question label={`${translate('modalBottle.chooseFluid')} :`}>
                <Select
                  id="selectFluidFilter"
                  isClearable
                  isSearchable
                  menuPortalTarget={document.body}
                  noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                  options={fluidStore.fluidOptions}
                  placeholder={translate('common.searchByFluidType')}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                  value={fluidFilter}
                  onChange={setFluidFilter}
                />
              </Question>
            </Fragment>
          )}

          {!isGroupInput && !isTypeSale && !movementId && (
            <Question label={`${translate('modalFluidMovement.chooseBottle')} :`}>
              <Select
                components={{ Option: CustomOption }}
                id="selectBottle"
                isClearable
                isSearchable
                menuPortalTarget={document.body}
                noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                options={formattedBottleList}
                placeholder={translate('common.searchByBottle')}
                styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                value={formState.values.bottle ? {
                  value: formState.values.bottle.id,
                  label: formState.values.bottle.identifier
                } : null}
                onChange={(select) => {
                  if (select) {
                    const matchBottle = fluidStore.bottleExtendedOptions.find(bottleItem => bottleItem.id === select.value);
                    if (matchBottle) {
                      formState.setField('bottle', matchBottle);
                      formState.setField('fluid', FormHelper.SELECT_ADAPTOR_TO_FRONT({ selectItem: matchBottle.fluid }));
                      formState.setField('theoricalAmount', matchBottle.theoricalAmount);
                      formState.setField('measuredAmount', Number(matchBottle.measuredAmount));
                      matchBottle.volume && formState.setField('volume', Number(matchBottle.volume));
                    }
                  } else {
                    resetFields();
                  }
                }}
              />
              <RequiredSelectHack value={formState.values.bottle || ''} />
            </Question>
          )}

          {isTypeSale && !movementId && (
            <Question label={`${translate('modalFluidMovement.chooseBottles')} :`}>
              <Select
                components={{ Option: CustomOption }}
                id="selectBottle"
                isClearable
                isMulti
                isSearchable
                menuPortalTarget={document.body}
                noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                options={formattedBottleList}
                placeholder={translate('common.searchByBottle')}
                styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                value={formState.values.bottles ? formState.values.bottles.map(b => ({
                  value: b.id,
                  label: b.identifier
                })) : null}
                onChange={(select) => {
                  if (select) {
                    formState.setField('bottles', select.map((s: SelectItem) => ({
                      id: s.value,
                      identifier: s.label
                    })));
                    if (select?.length === 1) {
                      const matchBottle = fluidStore.bottleExtendedOptions.find(bottleItem => bottleItem.id === select[0].value);
                      if (matchBottle) {
                        formState.setField('bottle', matchBottle);
                        formState.setField('fluid', FormHelper.SELECT_ADAPTOR_TO_FRONT({ selectItem: matchBottle.fluid }));
                        formState.setField('theoricalAmount', matchBottle.theoricalAmount);
                        formState.setField('measuredAmount', Number(matchBottle.measuredAmount));
                        matchBottle.volume && formState.setField('volume', Number(matchBottle.volume));
                      }
                    }
                  } else {
                    resetFields();
                  }
                }}
              />
              <RequiredSelectHack value={formState.values.bottles || ''} />
              {formState.values.bottles?.length > 1 && (
                <>
                  <br />
                  <Warn maxWidth="600px">{translate('warnings.movement.bottlesDefaultQuantity')}</Warn>
                </>
              )}
            </Question>
          )}

          {isGroupInput ? (
            <Fragment>
              {!movementId ? (
                <Fragment>
                  <Question label={`${translate('modalBottle.chooseFluid')} :`}>
                    <Select
                      id="selectFluid"
                      isClearable
                      isDisabled={movementId}
                      isSearchable
                      menuPortalTarget={document.body}
                      noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                      options={fluidStore.fluidOptions}
                      placeholder={translate('common.searchByFluidType')}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                      value={(formState.values.fluid && formState.values.fluid.value) ? formState.values.fluid : ''}
                      onChange={value => formState.setField('fluid', value)}
                    />
                    {isTypeInput && (
                      <RequiredSelectHack value={formState.values.fluid || ''} />
                    )}
                  </Question>

                  {(isTypeInput || (isTypeRetrieval && !formState.values.isRetrievalBottle)) && (
                    <Fragment>
                      <ToggleButtonGroup
                        aria-label={translate('common.fluidType')}
                        data-cy="isNewFluid"
                        exclusive
                        size="small"
                        style={{ marginTop: '1rem' }}
                        value={formState.values.isNewFluid}
                        onChange={(event, value) => {
                          if (value === null) return;
                          formState.setField('isNewFluid', value);
                        }}
                      >
                        <ToggleButton
                          aria-label={translate('fluids.newFluid')}
                          data-cy="newFluid"
                          key={shortid.generate()}
                          value
                        >
                          {translate('fluids.newFluid')}
                        </ToggleButton>
                        <ToggleButton
                          aria-label={translate('fluids.regeneratedFluid')}
                          data-cy="regeneratedFluid"
                          key={shortid.generate()}
                          value={false}
                        >
                          {translate('fluids.regeneratedFluid')}
                        </ToggleButton>
                      </ToggleButtonGroup>

                      {isTypeInput && (
                        <>
                          <FlexContainer alignItems="center">
                            <Text margin="0 2rem 0 0">{translate('fluids.boughtInOperatorCountry')}</Text>
                            <ToggleButtonGroup
                              aria-label={translate('fluids.boughtInOperatorCountry')}
                              data-cy="boughtInOperatorCountry"
                              exclusive
                              size="small"
                              style={{ marginTop: '1rem' }}
                              value={formState.values.boughtInOperatorCountry}
                              onChange={(event, value) => {
                                if (value === null) return;
                                formState.setField('boughtInOperatorCountry', value);
                              }}
                            >
                              <ToggleButton
                                aria-label={translate('common.yes')}
                                data-cy="yes"
                                key={shortid.generate()}
                                value
                              >
                                {translate('common.yes')}
                              </ToggleButton>
                              <ToggleButton
                                aria-label={translate('common.no')}
                                data-cy="no"
                                key={shortid.generate()}
                                value={false}
                              >
                                {translate('common.no')}
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </FlexContainer>
                          {!formState.values.boughtInOperatorCountry && (
                            <Warn maxWidth="600px">{translate('warnings.movement.boughtInFrance')}</Warn>
                          )}
                        </>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              ) : needsFluidDisplay && (
                <Text color="var(--grey)">
                  {`${translate('common.fluid')} : ${(formState.values.fluid && formState.values.fluid.label) || '-'}`}
                </Text>
              )}

              {(isTypeInput || isTypeValidation)
                ? (
                  <FormLine>
                    <TextField
                      disabled={isTypeValidation && !!movementId}
                      error={!!formState.errors.theoricalAmount}
                      label={translate(getCurrentFluidMovementConfig().theoricalAmountLabel)}
                      name="theoricalAmount"
                      required
                      {...number('theoricalAmount')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{translate('common.weightUnit')}</InputAdornment>
                      }}
                      inputProps={{
                        step: '0.01',
                        min: 0
                      }}
                    />
                    <CustomTooltip text={translate('modalFluidMovement.tooltipTheorical')} />
                  </FormLine>
                ) : !isGroupInput && formState.values.bottle && (
                  <Text color="var(--grey)">{`${translate('common.boughtQuantity')} : ${formState.values.theoricalAmount || '0'}${translate('common.weightUnit')}`}</Text>
                )}
            </Fragment>
          ) : formState.values.bottle && formState.values.movementType?.value !== TYPE_RESET && (
            <Text
              color="var(--grey)"
            >
              {`${translate('common.fluid')} : ${(formState.values.fluid && formState.values.fluid.label) || '-'}`}
            </Text>
          )}

          {!movementId && !isGroupInput && (formState.values.bottle || formState.values.bottles?.length === 1) && (
            <Warn maxWidth="600px">{translate('modalFluidMovement.confirmAmount')}</Warn>
          )}

          {(isGroupInput || isTypeValidation || (!isGroupInput && (formState.values.bottle || formState.values.bottles?.length === 1))) && (
            <FormLine>
              <TextField
                error={!!formState.errors.measuredAmount}
                label={translate(isDistributor && getCurrentFluidMovementConfig().measuredAmountLabelDistributor
                  ? getCurrentFluidMovementConfig().measuredAmountLabelDistributor
                  : getCurrentFluidMovementConfig().measuredAmountLabel)}
                name="measuredAmount"
                required={!isTypeHandover}
                {...number('measuredAmount')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{translate('common.weightUnit')}</InputAdornment>
                }}
                inputProps={{
                  step: '0.01',
                  min: 0
                }}
                style={{ marginTop: '.5rem' }}
              />
              {isTypeInput && (
                <CustomTooltip text={translate('modalFluidMovement.tooltipMeasured')} />
              )}
            </FormLine>
          )}

          {formState.values.bottle && (
            <TextField
              disabled={(movementId || !isGroupInput) && !!formState.values.bottle.volume}
              InputProps={{
                endAdornment: <InputAdornment position="end">L</InputAdornment>
              }}
              inputProps={{
                step: '0.01'
              }}
              label={translate('common.bottleVolume')}
              name="volume"
              style={{ marginTop: '.5rem' }}
              {...number('volume')}
            />
          )}

          {(isTypeHandover || isTypeValidation) && (
            <Question label={`${translate('modalBottle.comment')} :`}>
              <TextField
                InputLabelProps={{ required: false }}
                label={translate('modalBottle.commentLabel')}
                multiline
                name="comment"
                required
                rows={3}
                {...text('comment')}
              />
            </Question>
          )}

          {hasDocument && (
            <Question label={translate(getCurrentFluidMovementConfig().documentLabel)}>
              <input
                accept={isTypeRetrieval ? 'application/pdf' : 'image/*,application/pdf'}
                type="file"
                onChange={event => DocumentHelper.handleFileUpload({
                  callback: setLinkedDocumentFromDocuments, callbackError: setUploadError, event, needResize: !isTypeRetrieval
                })}
              />

              {uploadError && <TextError>{uploadError}</TextError>}

              {linkedDocument && (
                <Grid container direction="row" style={{ marginTop: 10 }}>
                  <Grid item xs={11}>
                    <Paper style={{ padding: 10 }} variant="outlined">
                      <Text fontWeight="bold" margin="5px 0px">{translate('common.importedFile')}</Text>
                      <a download={linkedDocument.name} href={linkedDocument.base64Content}>{getSmallDescription(linkedDocument.name, 40)}</a>
                    </Paper>
                  </Grid>
                  {!movementId && (
                    <Grid item style={{ alignSelf: 'center' }} xs={1}>
                      <ButtonDelete onClick={() => setLinkedDocument(null)}>
                        <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                      </ButtonDelete>
                    </Grid>
                  )}
                </Grid>
              )}

              {linkedDocument && createMiniature(linkedDocument)}
            </Question>
          )}

          <Datepicker
            dataCy="createFluidMovementDate"
            disableFuture
            format={DATE_FORMAT}
            label="common.operationDate"
            required
            style={{ marginTop: '.5rem' }}
            value={formState.values.movementDate}
            onChange={date => formState.setField('movementDate', date)}
          />

          {isTypeOutput && isCreating && tdConnectionStatus.isValid && (
            <FormControl>
              <FormLine>
                <FormControlLabel
                  control={<Checkbox value={formState.values.ignoreTd} />}
                  key="active"
                  label={translate('common.ignoreTd')}
                  {...checkbox('ignoreTd')}
                />
              </FormLine>
            </FormControl>
          )}
        </FlexContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          type="submit"
        >
          {(isTypeOutput && !movementId && tdConnectionStatus.isValid && !formState.values.ignoreTd) ? translate('button.saveTD') : translate('button.save')}
        </Button>
      </DialogActions>
    </form>
  );
};
