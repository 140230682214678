import {
  faCalendarAlt, faCalendarMinus, faCalendarPlus, faChevronDown, faInfoCircle, faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
  IconButton, List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import {
  CustomBarChart, CustomTooltip, FontIcon, SubtitlePage
} from 'components';
import {
  BottleSummaryItem, FluidManipulationSummaryItem, FluidMovementSummaryItem, StockVariationView
} from 'models';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import styled from 'styled-components';
import { MONTHS } from 'utils/constants';
import { BottleHelper, formatDateString, translate } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const FluidDetailContainer = styled.section`
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid #e6e6e6;

  .recharts-responsive-container {
    margin-bottom: 3rem;
  }

  ul {
    width: 100%;

    li a {
      display: flex;
      align-items: center;
      width: 100%;

      &:hover {
        text-decoration: none;
        background-color: var(--grey-lighter);
      }
    }
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 0;
`;

const BottleListItem = ({
  bottleType,
  isNewFluid,
  identifier,
  fluidLabel,
  fluidAmount,
  receptionDate,
  retrievalDate,
  hasWarning
}: {
  bottleType: string,
  fluidAmount: number,
  fluidLabel: string,
  hasWarning: boolean,
  identifier: string,
  isNewFluid: boolean,
  receptionDate?: string,
  retrievalDate?: string
}) => (
  <ListItemText
    primary={(
      <>
        {BottleHelper.getTranslatedType({
          type: bottleType,
          isNewFluid,
          identifier
        })}
        {hasWarning && (
          <CustomTooltip
            icon={faInfoCircle}
            size="xs"
            text={translate('warnings.bottle.modifiedCanHaveLeak')}
          />
        )}
      </>
    )}
    secondary={(
      <>
        <span>{`${translate('common.fluid')}: ${fluidLabel || '-'} - ${translate('common.quantity')}: ${fluidAmount}${translate('common.weightUnit')}`}</span>
        <br />
        <span>
          <FontAwesomeIcon icon={faCalendarPlus} style={{ marginRight: '0.5rem' }} />
          {receptionDate && `${translate('common.receivedOn')} ${formatDateString(receptionDate)}`}
        </span>
        {retrievalDate && (
          <>
            <br />
            <span>
              <FontAwesomeIcon icon={faCalendarMinus} style={{ marginRight: '0.5rem' }} />
              {`${translate('common.returnedOn')} ${formatDateString(retrievalDate)}`}
            </span>
          </>
        )}
      </>
    )}
  />
);

export const FluidDetail = ({
  fluidName,
  stockTransactions,
  fluidBottles,
  yearSelected,
  fluidManipulations,
  fluidMovements,
  clearFluidDetail
}: {
  clearFluidDetail: () => void,
  fluidBottles: BottleSummaryItem[],
  fluidManipulations: FluidManipulationSummaryItem[],
  fluidMovements: FluidMovementSummaryItem[],
  fluidName: string,
  stockTransactions: StockVariationView[],
  yearSelected: SelectItem
}) => {
  const chartData = stockTransactions.map(fluid => ({
    Mois: translate(`common.months.${MONTHS[fluid.monthNumber]}`),
    [translate('stock.newFluid.input')]: fluid.newInputAmount,
    [translate('stock.regeneratedFluid.input')]: fluid.regeneratedInputAmount,
    [translate('stock.reusableFluid.input')]: fluid.reusableInputAmount,
    [translate('stock.usedFluid.input')]: fluid.usedInputAmount,

    [translate('stock.newFluid.output')]: fluid.newOutputAmount,
    [translate('stock.regeneratedFluid.output')]: fluid.regeneratedOutputAmount,
    [translate('stock.reusableFluid.output')]: fluid.reusableOutputAmount,
    [translate('stock.usedFluid.output')]: fluid.usedOutputAmount
  }));

  return (
    <FluidDetailContainer data-tour="step-dashboard-graph-detail" id="fluidDetail">
      <SubtitlePage>
        {`${translate('pageStocks.detailTitle', { fluidName })} ${yearSelected?.value}`}
        <CloseButton onClick={clearFluidDetail}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
      </SubtitlePage>
      <CustomBarChart
        barKeys={[{
          key: translate('stock.newFluid.input'),
          fill: 'var(--violet-light)',
          stackId: 'a'
        }, {
          key: translate('stock.regeneratedFluid.input'),
          fill: 'var(--violet)',
          stackId: 'a'
        }, {
          key: translate('stock.reusableFluid.input'),
          fill: 'var(--violet-dark)',
          stackId: 'a'
        }, {
          key: translate('stock.usedFluid.input'),
          fill: 'var(--violet-darker)',
          stackId: 'a'
        }, {
          key: translate('stock.newFluid.output'),
          fill: 'var(--orange-light)',
          stackId: 'b'
        }, {
          key: translate('stock.regeneratedFluid.output'),
          fill: 'var(--orange)',
          stackId: 'b'
        }, {
          key: translate('stock.reusableFluid.output'),
          fill: 'var(--orange-dark)',
          stackId: 'b'
        }, {
          key: translate('stock.usedFluid.output'),
          fill: 'var(--orange-darker)',
          stackId: 'b'
        }]}
        chartData={chartData}
        displayDataInChart={false}
        labelFontSize="1.2rem"
        xAxisKey="Mois"
      />
      {fluidBottles && fluidBottles.length > 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            {translate('pageStocks.detailBottleList')}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              {fluidBottles.map(bottle => (
                <ListItem dense disableGutters key={shortid.generate()}>
                  <Link to={`/bouteilles/${bottle.id}`}>
                    <ListItemIcon>
                      <FontIcon fontSize="3.6rem" icon="icon-container" />
                    </ListItemIcon>
                    <BottleListItem {...bottle} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {fluidManipulations && fluidManipulations.length > 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            {translate('pageStocks.detailManipulationList')}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              {fluidManipulations.map(manip => (
                <ListItem dense disableGutters key={shortid.generate()}>
                  <ListItemIcon>
                    <FontIcon fontSize="3.6rem" icon="icon-stocks" />
                  </ListItemIcon>
                  <ListItemText
                    primary={(
                      <Fragment>
                        {`${translate(`manipulations.${manip.manipulationType}`)} (${translate('common.bottle')}: ${manip.bottle || '-'})`}
                      </Fragment>
                    )}
                    secondary={(
                      <Fragment>
                        <span>{`${translate('common.fluid')}: ${manip.fluid && manip.fluid.label} - ${translate('common.quantity')}: ${manip.fluidAmount}${translate('common.weightUnit')}`}</span>
                        <br />
                        <span>
                          <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '0.5rem' }} />
                          {manip.manipulationDate && `${translate('common.doneOn')} ${formatDateString(manip.manipulationDate)}`}
                        </span>
                      </Fragment>
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      {fluidMovements && fluidMovements.length > 0 && (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            {translate('pageStocks.detailFluidMovementList')}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List>
              {fluidMovements.map(mouv => (
                <ListItem dense disableGutters key={shortid.generate()}>
                  <ListItemIcon>
                    <FontIcon fontSize="3.6rem" icon="icon-intervention" />
                  </ListItemIcon>
                  <ListItemText
                    primary={(
                      <Fragment>
                        {`${translate(`mouvements.${mouv.movementType}`)} (${translate('common.bottle')}: ${mouv.bottle ? mouv.bottle.label : '-'})`}
                      </Fragment>
                    )}
                    secondary={(
                      <Fragment>
                        <span>
                          {`${translate('common.fluid')}: ${mouv.fluid && mouv.fluid.label} -
                          ${translate('common.quantity')}: ${mouv.measuredAmount}${translate('common.weightUnit')}`}
                        </span>
                        <br />
                        <span>
                          <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '0.5rem' }} />
                          {mouv.movementDate && `${translate('common.doneOn')} ${formatDateString(mouv.movementDate)}`}
                        </span>
                      </Fragment>
                    )}
                  />
                </ListItem>
              ))}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </FluidDetailContainer>
  );
};
