import { SelectItem } from 'utils/types';
import { CountryFormView } from './CountryFormView';

export class EntityView extends CountryFormView {
  attestationNumber?: string;
  contactName?: string;
  email?: string;
  entityTypes?: SelectItem[] = [];
  possibleTypes?: string[] = [];
  equipmentsValidated: boolean = true;
  firstName: string = '';
  id: string = '';
  lastName?: string;
  ownerCompanyId?: string;
  ownerType?: 'INSTITUTION' | 'PERSON' = 'INSTITUTION';
  phoneNumber?: string;
  validated: boolean = false;
}

export const getName = (entity: EntityView) => {
  if (!entity) {
    return '';
  }
  let name = entity.firstName;
  if (!name) {
    name = entity.institutionName;
  }
  if (entity.lastName) {
    name += ` ${entity.lastName}`;
  }
  return name;
};
