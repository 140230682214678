import { Document } from './Document';

export class SignatureFieldView {
  name?: string = '';
  occupation?: string = '';
  signatureDate?: string;
  document?: Document;

  // Front specific fields
  interventionDate?: string;
  type?: SignatureType;
}

export type SignatureType = 'operatorSignatureField' | 'customerSignatureField';
