import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'utils/helpers';

export const InfoLine = ({
  label, info, displayIf
}) => {
  const isDisplayed = displayIf !== undefined ? Boolean(displayIf) : Boolean(info);

  if (!isDisplayed) return null;

  return (
    <Typography component="div" variant="body2">
      {`${translate(label)} : `}
      <strong>{info}</strong>
    </Typography>
  );
};

InfoLine.propTypes = {
  displayIf: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number
  ]),
  label: PropTypes.string
};

InfoLine.defaultProps = {
  displayIf: undefined,
  info: '',
  label: ''
};
