import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFormState } from 'react-use-form-state';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button, DialogActions, DialogContent, TextField
} from '@material-ui/core';

import { InterventionsService } from 'services';
import { translate } from 'utils/helpers';

import { Text } from 'components';
import { ModalHeader } from './ModalHeader';

export const UpdateInterventionNumberModal = ({
  onClose, onConfirm, closeOnSubmit
}) => {
  const [fileNumberExist, setFileNumberExist] = useState(translate('errors.fileNumberActualExist'));
  const [formState, { text }] = useFormState({
    fileNumber: ''
  });

  const handleValidateModal = (e) => {
    e.preventDefault();
    setFileNumberExist(null);
    const encodedFileNumber = encodeURIComponent(formState.values.fileNumber);
    // Check if the fileNumber doesn't exist already
    InterventionsService.checkFileNumber(encodedFileNumber).then((response) => {
      if (response) {
        setFileNumberExist(translate('errors.fileNumberAlreadyExist'));
      } else if (response === false) {
        onConfirm(encodedFileNumber);

        if (closeOnSubmit) {
          onClose();
        }
      }
    });
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader className="warningModal" onClose={onClose}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {translate('modalUpdateInterventionNumber.title')}
      </ModalHeader>

      <DialogContent style={{ minWidth: '600px' }}>
        <Text color="var(--red)" fontWeight={600} margin="0" style={{ minHeight: '20px' }}>
          {fileNumberExist}
        </Text>
        <TextField
          error={formState.errors.fileNumber && formState.errors.fileNumber !== null}
          inputProps={{ maxLength: 32 }}
          label={translate('common.cerfaNumber')}
          name="fileNumber"
          required
          {...text('fileNumber')}
          style={{ width: '100%' }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};

UpdateInterventionNumberModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

UpdateInterventionNumberModal.defaultProps = {
  closeOnSubmit: true
};
