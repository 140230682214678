import {
  EntitySummary,
  EntitySummaryItem,
  EntityView,
  EquipmentSummary,
  EquipmentSummaryItem,
  LegalEntityFilter
} from 'models';
import { RefrigerationUnitFilter } from 'models/request/list/RefrigerationUnitFilter';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const equipmentOwnersUrl = `${API_URL}equipment-owners`;

// GET
const getEquipmentOwnerDetail: (id: string) => Promise<EntityView> = (id) => RequestHelper.GET(`${equipmentOwnersUrl}/${id}`);

// POST
const getEquipmentOwnerList: (
  filter: LegalEntityFilter,
  page: number
) => Promise<EntitySummary> = (
  filter = {},
  page = 1
) => RequestHelper.POST(`${equipmentOwnersUrl}/filter?page=${page}`, filter);

const getEquipmentOwnerListFull: (
  filter: LegalEntityFilter
) => Promise<EntitySummaryItem[]> = (
  filter = {}
) => RequestHelper.POST(`${equipmentOwnersUrl}/extended-options`, filter);

const getEquipmentList: (
  ownerId: string,
  filter: RefrigerationUnitFilter,
  page: number
) => Promise<EquipmentSummary> = (
  ownerId,
  filter = {},
  page = 1
) => RequestHelper.POST(`${equipmentOwnersUrl}/${ownerId}/equipments?page=${page}`, filter);

const getEquipmentListFull: (
  ownerId: string,
  filter: RefrigerationUnitFilter
) => Promise<EquipmentSummaryItem[]> = (
  ownerId,
  filter = {}
) => RequestHelper.POST(`${equipmentOwnersUrl}/${ownerId}/equipment-list`, filter);

const createEquipmentOwner: (
  form: EntityView
) => Promise<void> = form => RequestHelper.POST(`${equipmentOwnersUrl}`, form);

const updateEquipmentOwner: (
  hashId: string,
  form: EntityView
) => Promise<void> = (
  hashId,
  form
) => RequestHelper.PUT(`${equipmentOwnersUrl}/${hashId}`, form);

const mergeEquipmentOwner = (hashIdEdited, hashIdMerged) => RequestHelper.POST(`${equipmentOwnersUrl}/${hashIdEdited}/fuse`, { otherId: hashIdMerged });

const exportEquipmentsFromLegalEntity = (id: string, filter: RefrigerationUnitFilter) => RequestHelper.POST(`${equipmentOwnersUrl}/${id}/export`, filter);

export const EquipmentOwnersService = {
  createEquipmentOwner,
  getEquipmentList,
  getEquipmentListFull,
  getEquipmentOwnerDetail,
  getEquipmentOwnerList,
  getEquipmentOwnerListFull,
  updateEquipmentOwner,
  mergeEquipmentOwner,
  exportEquipmentsFromLegalEntity
};
