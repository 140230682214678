import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Typography
} from '@material-ui/core';
import { Question, SelectOperator } from 'components';
import { Warn } from 'components/Warn/Warn';
import { OperatorSummary } from 'models';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import sanitizeHtml from 'sanitize-html';
import { OperatorsService } from 'services';
import { InterventionHelper, translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

export const ReassignOperatorModal = ({
  onClose, onConfirm, operator, interventionStatus
}) => {
  const [operatorOptions, setOperatorOptions] = useState<OperatorSummary[]>([]);
  const [INTERVENTION_STATUS, setInterventionStatus] = useState(null);
  const [formState] = useFormState({
    operator: null
  });

  useEffect(() => {
    InterventionHelper.loadInterventionStatuses().then(IS => setInterventionStatus(IS));

    OperatorsService.getOptions().then((response) => {
      if (response && response.length > 0) {
        const operators: OperatorSummary[] = response
          .filter(ope => ope.personModelId !== operator?.personModelId);
        setOperatorOptions(operators);
      }
    });
  }, [operator]);

  const handleValidateModal = (e) => {
    e.preventDefault();
    onConfirm(formState.values.operator);
    onClose();
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding} />
        {translate('modalReassignOperator.title')}
      </ModalHeader>

      <DialogContent>
        {operator && (
          <Typography gutterBottom>
            {`${translate('modalReassignOperator.operatorCurrentlyAssigned')} : `}
            <strong>{operator.fullName}</strong>
          </Typography>
        )}

        {INTERVENTION_STATUS && (
          (interventionStatus === INTERVENTION_STATUS.READY.key
            || interventionStatus === INTERVENTION_STATUS.ONGOING.key) && (
            <Warn dangerouslySetInnerHTML={{
              __html: sanitizeHtml(translate(
                  'modalReassignOperator.warningMessage',
                  { status: translate(INTERVENTION_STATUS[interventionStatus].label) }
              ))
            }}
            />
          )
        )}

        <Question label={translate('modalReassignOperator.selectOperator')}>
          <SelectOperator
            isRequired
            options={operatorOptions}
            value={formState.values.operator}
            onChange={value => formState.setField('operator', value)}
          />
        </Question>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};

ReassignOperatorModal.propTypes = {
  interventionStatus: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  operator: PropTypes.shape({})
};

ReassignOperatorModal.defaultProps = {
  operator: {}
};
