import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { faEdit, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, ButtonGroup, Paper } from '@material-ui/core';

const StyledItemSelected = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  button svg {
    font-size: 2rem;
  }

  & > section {
    max-width: 80%;
  }

  section {
    min-width: 80px;
  }

  article {
    max-width: 100%;
  }

  p {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  img {
    cursor: pointer;
  }
`;

export const ItemSelected = ({ deleteAction, editAction, children }) => (
  <Paper style={{ marginBottom: '2rem' }}>
    <StyledItemSelected>
      {children}
      <ButtonGroup variant="text">
        {editAction && (
          <Button onClick={editAction}>
            <FontAwesomeIcon color="var(--blue)" icon={faEdit} />
          </Button>
        )}
        <Button onClick={deleteAction}>
          <FontAwesomeIcon color="var(--red)" icon={faTimes} />
        </Button>
      </ButtonGroup>
    </StyledItemSelected>
  </Paper>
);

ItemSelected.propTypes = {
  editAction: PropTypes.func,
  deleteAction: PropTypes.func.isRequired
};

ItemSelected.defaultProps = {
  editAction: null
};
