import { faSpinner, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField
} from '@material-ui/core';
import { FlexContainer, SelectField } from 'components';
import { UpdateRolesForm } from 'components/Form';
import { ModalHeader } from 'components/Modals/ModalHeader';
import { useFetch, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { UsersService } from 'services';
import { FormHelper, translate } from 'utils/helpers';

export const CreateUserModal = ({
  onClose,
  onConfirm,
  closeOnSubmit
}) => {
  const [formState, {
    text,
    email,
    checkbox,
    raw
  }] = useFormState({
    accountActivated: false,
    mainCompany: null,
    roles: [],
    email: null
  });

  const { enqueueSnackbar } = useSnackbar();
  const { userStore } = useStores();
  const {
    currentCompany,
    scope
  } = userStore;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accessibleRolesByStructureLevel, setAccessibleRolesByStructureLevel] = useState(new Map());
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const { response: companies } = useFetch(() => UsersService.getCompanyChildrenOptions(), []);

  useEffect(() => {
    const accountActivated = formState.values?.accountActivated;
    const mainCompany = scope?.company;
    if (accountActivated && mainCompany) {
      setIsLoadingRoles(true);
      UsersService.getAccessibleRolesWithStructureLevel(mainCompany?.value)
        .then((response) => {
          const map = new Map();
          Object.values(response).forEach((value) => map.set(value.structureLevelOption, value.roleSelectItems));
          setAccessibleRolesByStructureLevel(map);
        })
        .finally(() => setIsLoadingRoles(false));
    }
  }, [formState.values?.accountActivated, scope?.company]);

  const createUser = useCallback((form) => {
    setIsSubmitting(true);
    UsersService.createUser(form)
      .then(response => {
        response && onConfirm(response);
        if (closeOnSubmit) {
          onClose();
        }
      })
      .catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }))
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [closeOnSubmit, onClose, onConfirm, enqueueSnackbar]);

  const handleValidateModal = e => {
    e.preventDefault();

    const isFormValid = document.forms.createUserForm.reportValidity() && !formState.errors.email;

    if (isFormValid) {
      createUser({
        login: formState.values.login,
        password: formState.values.password,
        person: {
          firstName: formState.values.firstName,
          lastName: formState.values.lastName,
          attestationNumber: formState.values.attestationNumber,
          mainCompany: formState.values.mainCompany || currentCompany,
          contact: {
            technicalEmail: formState.values.email
          }
        },
        accountActivated: formState.values.accountActivated,
        roles: formState.values.roles
      });
    }
  };

  useEffect(() => {
    if (!formState.values.accountActivated) {
      formState.errors.login = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values.accountActivated, formState.errors.login]);

  return (
    <form autoComplete="off"
          name="createUserForm"
          style={{ overflow: 'auto' }}
          onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faUserPlus}/>
        {translate('modalUser.newUser')}
      </ModalHeader>

      <DialogContent style={{ minWidth: '400px' }}>
        <Grid container direction="row" item spacing={2} style={{ maxWidth: '1400px' }}>
          <Grid item style={{ width: '500px' }}>
            <FlexContainer flexDirection="column">
              <FormControlLabel
                control={<Checkbox color="primary"/>}
                label={translate('common.userAccount')}
                {...checkbox('accountActivated')}
              />
              {Boolean(companies?.length) && (
                <SelectField
                  id="mainCompany"
                  label="common.company"
                  name="mainCompany"
                  options={companies}
                  required
                  {...raw('mainCompany')}
                />
              )}
              <TextField
                autoComplete="off"
                autoFocus
                error={!!formState.errors.lastName}
                label={translate('common.lastName')}
                name="lastName"
                required
                {...text('lastName')}
              />
              <TextField
                autoComplete="new-password"
                error={!!formState.errors.firstName}
                label={translate('common.firstName')}
                name="firstName"
                required
                {...text('firstName')}
              />
              <TextField
                autoComplete="new-password"
                error={!!formState.errors.email}
                helperText={formState.errors.email}
                label={translate('common.email')}
                name="email"
                required
                type="email"
                {...email({
                  name: 'email',
                  // Additional validation for emails because useFormState validation stops at example@example
                  validate: value => FormHelper.validateEmail(value)
                })}
              />
              <TextField
                  autoComplete="new-password"
                  error={!!formState.errors.attestationNumber}
                  helperText={formState.errors.attestationNumber}
                  label={translate('common.capacityAttestationNumber')}
                  name="attestationNumber"
                  {...text('attestationNumber')}
              />
              {formState.values.accountActivated && (
                <>
                  <TextField
                    autoComplete="new-password"
                    disabled={!formState.values.accountActivated}
                    error={!!formState.errors.login}
                    label={translate('common.login')}
                    name="login"
                    required={formState.values.accountActivated}
                    {...text('login')}
                  />
                  <br/>
                </>
              )}
            </FlexContainer>
          </Grid>
          {
            formState.values.accountActivated && (
              <Grid item style={{
                maxHeight: '800px',
                width: '550px'
              }}>
                {isLoadingRoles ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <UpdateRolesForm
                    accessibleRolesByStructureLevel={accessibleRolesByStructureLevel}
                    formState={formState}
                  />
                )}
              </Grid>
            )
          }
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" disabled={isSubmitting} type="submit">
          {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin/> : translate('common.create')}
        </Button>
      </DialogActions>
    </form>
  );
};

CreateUserModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

CreateUserModal.defaultProps = {
  closeOnSubmit: true
};
