import {
  faBoxCheck,
  faCheck,
  faClipboardList,
  faSortAlt,
  faSync,
  faTachometerAlt,
  faTint
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs } from '@material-ui/core';
import {
  BottleList,
  CustomIcon,
  FluidBalance,
  FluidMovementsList,
  FontIcon,
  StockInitialisation,
  StocksDashboard,
  TitlePage,
  ValidationList,
  Wrapper
} from 'components';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import shortid from 'shortid';
import {
  DISTRIBUTOR_ROLES, ROUTES, STOCK_ROLES, STOCK_ROLES_WITH_INTERVENTION_MANAGER
} from 'utils/constants';
import { translate, UserHelper } from 'utils/helpers';

import { PendingMovementsList } from 'components/FluidMovements';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';

export const PageStocks = observer(() => {
  const { userStore } = useStores();
  const { pathname } = useLocation();
  const currentLocation = pathname.split('/');

  const [currentTab, setCurrentTab] = useState(0);
  const [tabComponents, setTabComponents] = useState([<StocksDashboard key="stocksDashboard" />]);

  useEffect(() => {
    const isSingleInstitution = userStore.isViewingInstitution();
    // Add TABS depending on the user access rights
    const TABS_COMPONENTS = [<StocksDashboard key="stocksDashboard" />];
    const TAB_MAPPING = ['stats'];
    if (UserHelper.hasAccessRight(STOCK_ROLES) && isSingleInstitution) {
      TABS_COMPONENTS.push(<StockInitialisation key="stocksInitialisation" />);
      TAB_MAPPING.push('initialisation');
    }

    if (UserHelper.hasAccessRight(DISTRIBUTOR_ROLES) && isSingleInstitution) {
      TABS_COMPONENTS.push(<PendingMovementsList key="pendingMovementsList" />);
      TAB_MAPPING.push('confirmer');
    }

    if (UserHelper.hasAccessRight(STOCK_ROLES)) {
      TABS_COMPONENTS.push(<FluidMovementsList key="fluidMovementsList" />);
      TAB_MAPPING.push('fluides');
    }

    if (UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER)) {
      TABS_COMPONENTS.push(<BottleList key="bottleList"/>);
      TAB_MAPPING.push('bouteilles');
    }

    if (UserHelper.hasAccessRight(STOCK_ROLES) && isSingleInstitution) {
      TABS_COMPONENTS.push(<ValidationList key="validationList"/>);
      TAB_MAPPING.push('validation');
    }

    if (UserHelper.hasAccessRight(STOCK_ROLES)) {
      TABS_COMPONENTS.push(<FluidBalance key="fluidBalance" />);
      TAB_MAPPING.push('bilan');
    }

    setTabComponents(TABS_COMPONENTS);
    setCurrentTab(TAB_MAPPING.findIndex(url => url === currentLocation[currentLocation.length - 1]));
    // eslint-disable-next-line
  }, [userStore.currentLevel]);

  const handleTabChange = (e, tab) => setCurrentTab(tab);

  return (
    <Wrapper>
      <TitlePage>
        {translate('pageStocks.stockManagement')}
      </TitlePage>
      <Tabs
        aria-label={translate('pageStocks.stockManagement')}
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        variant="fullWidth"
        onChange={handleTabChange}
      >
        <Tab
          component={Link}
          data-cy="stocksDashboard"
          data-tour="step-dashboard-tab"
          icon={<FontAwesomeIcon icon={faTachometerAlt} size="2x" />}
          label={translate('pageStocks.dashboard')}
          to={ROUTES.STOCKS}
        />

        {UserHelper.hasAccessRight(STOCK_ROLES) && userStore.isViewingInstitution() && (
          <Tab
            component={Link}
            data-cy="stocksInitialisation"
            data-tour="step-init-tab"
            icon={<FontAwesomeIcon icon={faSync} size="2x" />}
            key={shortid.generate()}
            label={translate('pageStocks.initialisation')}
            to={ROUTES.STOCKS_INITIALISATION}
          />
        )}

        {UserHelper.hasAccessRight(DISTRIBUTOR_ROLES) && userStore.isViewingInstitution() && (
          <Tab
            component={Link}
            data-cy="stocksPending"
            data-tour="step-pending-tab"
            icon={<CustomIcon customIcon={faTint} icon={<FontAwesomeIcon icon={faBoxCheck} size="2x" />} marginRightCustomIcon="22px" marginTopCustomIcon="14px" />}
            key={shortid.generate()}
            label={translate('pageStocks.pending')}
            to={ROUTES.STOCKS_PENDING}
          />
        )}

        {UserHelper.hasAccessRight(STOCK_ROLES) && (
          <Tab
            component={Link}
            data-cy="stocksFluidMovementsList"
            data-tour="step-movement-tab"
            icon={<CustomIcon customIcon={faSortAlt} icon={<FontAwesomeIcon icon={faTint} size="2x" />} marginRightCustomIcon="-5px" />}
            key={shortid.generate()}
            label={translate('pageStocks.fluidMovementList')}
            to={ROUTES.STOCKS_FLUIDS}
          />
        )}

        {UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER) && (
          <Tab
            component={Link}
            data-cy="stocksBottleList"
            data-tour="step-bottles"
            icon={<FontIcon fontSize="3rem" icon="icon-container" />}
            label={translate('pageStocks.bottleList')}
            to={ROUTES.STOCKS_CONTAINERS}
          />
        )}

        {UserHelper.hasAccessRight(STOCK_ROLES) && userStore.isViewingInstitution() && (
          <Tab
            component={Link}
            data-cy="stocksValidationList"
            data-tour="step-validation-tab"
            icon={<FontAwesomeIcon icon={faCheck} size="2x" />}
            key={shortid.generate()}
            label={translate('pageStocks.validationList')}
            to={ROUTES.STOCKS_VALIDATION}
          />
        )}

        {UserHelper.hasAccessRight(STOCK_ROLES) && (
          <Tab
            component={Link}
            data-cy="stocksFluidBalance"
            data-tour="step-fluidBalance"
            icon={<CustomIcon customIcon={faTint} icon={<FontAwesomeIcon icon={faClipboardList} size="2x" />} marginRightCustomIcon="-8px" />}
            key={shortid.generate()}
            label={translate('pageStocks.fluidBalance')}
            to={ROUTES.STOCKS_BALANCE}
          />
        )}
      </Tabs>
      <section>
        {tabComponents[currentTab]}
      </section>
    </Wrapper>
  );
});
