import { faBuilding, faFileImport, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TableCell, Typography } from '@material-ui/core';
import {
  ButtonBlue,
  ButtonBlueLight,
  CustomIcon,
  GenericListFilters,
  GenericTable,
  ReactTour,
  SkeletonTableSmall,
  SubtitlePage,
  Text,
  TextError
} from 'components';
import { FlexContainer } from 'components/FlexContainer/FlexContainer';
import { GenericListContainer } from 'components/GenericList/GenericList';

import { useEffectAfterRender, useExcelImport, useModal } from 'hooks';
import { observer } from 'mobx-react-lite';
import { EntitySummaryItem, EntityView, LegalEntityFilter } from 'models';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useState } from 'react';
import { DocumentsService, LegalEntityService } from 'services';
import shortid from 'shortid';
import { FilterHelper, translate, UserHelper } from 'utils/helpers';
import { HeaderType } from 'utils/types';
import { FilterItem } from 'utils/types/FilterItem';
import {
  ACCESS_TO_RECIPIENTS_ROLES,
  DISTRIBUTOR_ROLES,
  LEGAL_ENTITY_TYPES
} from '../../../utils/constants';
import { SupplierOrRecipientListFilter } from './SupplierOrRecipientListFilter';

const listHeaders: (
  hasAccessToRecipients: boolean
) => HeaderType<EntitySummaryItem>[] = hasAccessToRecipients => [
  {
    name: 'supplierOrRecipient',
    label: `${translate('common.supplier')}  ${hasAccessToRecipients ? `/ ${translate('common.recipient')}` : ''}`,
    template: row => (
      <TableCell key={shortid.generate()}>
        {`${row.firstName} ${row.lastName}`}
      </TableCell>
    )
  }, {
    name: 'siret',
    label: translate('common.siret'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.institutionIdentifier}
      </TableCell>
    )
  },
  {
    name: 'legalEntityType',
    label: translate('common.legalEntityType'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.types.map(type => type.label).join(', ')}
      </TableCell>
    )
  }
];

export const SupplierOrRecipientList = observer(() => {
  const filterKey = 'supplierOrRecipientList';

  const { open, close } = useModal();

  const { enqueueSnackbar } = useSnackbar();
  const [supplierList, setSupplierList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<FilterItem<LegalEntityFilter>[]>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [listSize, setListSize] = useState(0);

  const hasAccessToRecipients = UserHelper.hasAccessRight(ACCESS_TO_RECIPIENTS_ROLES);

  const getSupplierList = useCallback(({ pageChange = false } = {}) => {
    setIsLoading(true);

    const types = [LEGAL_ENTITY_TYPES.SUPPLIER];
    if (hasAccessToRecipients) {
      types.push(LEGAL_ENTITY_TYPES.RECIPIENT);
    }
    const finalFilters: LegalEntityFilter = {
      ...FilterHelper.buildFilterForm(filters, search),
      types
    };

    LegalEntityService.filterLegalEntities(finalFilters, currentPage)
      .then((response) => {
        let allSuppliers = response.content;
        if (pageChange && currentPage > 1) {
          allSuppliers = [].concat(...supplierList, allSuppliers);
        }
        setSupplierList(allSuppliers);
        setMaxPage(response.totalPages);
        setListSize(response.totalElements);
      })
      .finally(() => setIsLoading(false));
  }, [hasAccessToRecipients, filters, search, currentPage, supplierList]);

  const reloadList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 1) getSupplierList({ pageChange: false });
    else setCurrentPage(1);
    // eslint-disable-next-line
  }, [currentPage]);

  useEffectAfterRender(() => {
    if (currentPage === 1) getSupplierList({ pageChange: false });
    else setCurrentPage(1);
    // eslint-disable-next-line
  }, [filters, search]);

  useEffectAfterRender(() => {
    getSupplierList({ pageChange: currentPage !== 1 });
    // eslint-disable-next-line
  }, [currentPage]);

  const {
    isImporting,
    importFunc: importSupplierOrRecipient
  } = useExcelImport(LegalEntityService.importLegalEntities, reloadList);

  const createUpdateSupplier = useCallback((form: EntityView) => {
    if (form.id) {
      return LegalEntityService.updateLegalEntity(form.id, form)
        .then(() => {
          reloadList();
          enqueueSnackbar(translate(hasAccessToRecipients
            ? 'confirms.supplierOrRecipientList.updateSupplierOrRecipient'
            : 'confirms.supplierOrRecipientList.updateSupplier'), { variant: 'success' });
          close();
        })
        .catch((error) => {
          enqueueSnackbar(error.replace('<br />', '\n'), { variant: 'error' });
        });
    }

    return LegalEntityService.createLegalEntity(form)
      .then(() => {
        reloadList();
        enqueueSnackbar(translate(hasAccessToRecipients
          ? 'confirms.supplierOrRecipientList.createSupplierOrRecipient'
          : 'confirms.supplierOrRecipientList.createSupplier'), { variant: 'success' });
        close();
      })
      .catch((error) => {
        enqueueSnackbar(error.replace('<br />', '\n'), { variant: 'error' });
      });
  }, [close, enqueueSnackbar, reloadList, hasAccessToRecipients]);

  const showSupplier = useCallback(row => open({
    type: 'CREATE_SUPPLIER_OR_RECIPIENT',
    onSubmit: createUpdateSupplier,
    entityId: row.id
  }), [createUpdateSupplier, open]);

  const handleLoadMore = useCallback(() => !isLoading && setCurrentPage(currentPage + 1), [isLoading, currentPage]);

  const renderFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <SupplierOrRecipientListFilter
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const renderButtonContainer = useCallback(() => (
    <div>
      <ButtonBlue
        data-cy="createSupplier"
        data-tour="step-admin-supplierCreate"
        onClick={() => open({
          type: 'CREATE_SUPPLIER_OR_RECIPIENT',
          onSubmit: createUpdateSupplier
        })}
      >
        <CustomIcon icon={<FontAwesomeIcon icon={faBuilding} />} />
        {translate('button.create')}
      </ButtonBlue>
      {hasAccessToRecipients && (
        <Fragment>
          <br />
          <br />
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <ButtonBlueLight
                onClick={importSupplierOrRecipient}
              >
                {isImporting ? (
                  <Fragment>
                    <FontAwesomeIcon icon={faSpinner} spin />
                    {translate('button.importing')}
                  </Fragment>
                ) : (
                  <Fragment>
                    <FontAwesomeIcon icon={faFileImport} />
                    {translate('button.import')}
                  </Fragment>
                )}
              </ButtonBlueLight>
              <Button onClick={() => DocumentsService.getImportFile('entity')}>
                <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                  {translate('common.exampleFile')}
                </Text>
              </Button>
            </div>
          </div>
          <br />
        </Fragment>
      )}
    </div>
  ), [isImporting, importSupplierOrRecipient, createUpdateSupplier, hasAccessToRecipients, open]);

  return (
    <>
      <GenericListContainer>
        <SubtitlePage>
          {hasAccessToRecipients
            ? translate('pageSupplierOrRecipientList.titleSupplierOrRecipient')
            : translate('pageSupplierOrRecipientList.titleSupplier')}
        </SubtitlePage>

        <Typography align="center" gutterBottom>
          {hasAccessToRecipients
            ? translate('pageSupplierOrRecipientList.noticeSupplierOrRecipient')
            : translate('pageSupplierOrRecipientList.noticeSupplier')}
        </Typography>

        {renderButtonContainer()}

        <GenericListFilters
          ComponentFilter={renderFilters}
          disabled={false}
          filterKey={filterKey}
          filters={filters}
          isVisible
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          withSidePanel
        />

        <div data-tour="step-admin-supplierList">
          {!isLoading && supplierList?.length === 0
            ? (
              <FlexContainer alignItems="center" flexDirection="column">
                <TextError>{translate(UserHelper.hasAccessRight(DISTRIBUTOR_ROLES) ? 'errors.noSupplierOrRecipient' : 'errors.noSupplier')}</TextError>
              </FlexContainer>
            ) : (
              <GenericTable<EntitySummaryItem>
                dataCy="supplierList"
                hasMore={currentPage < maxPage}
                headers={listHeaders(hasAccessToRecipients)}
                loadMore={handleLoadMore}
                rows={supplierList}
                total={listSize}
                onRowClick={row => showSupplier(row)}
              />
            )
          }
        </div>
      </GenericListContainer>

      <ReactTour steps={[]} />

      {isLoading && <SkeletonTableSmall />}
    </>
  );
});
