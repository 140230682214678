import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button, DialogActions, DialogContent
} from '@material-ui/core';

import { DocumentHelper, translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

export const DisplayPictureModal = ({
  onClose, name, base64Content
}) => {
  let src;
  if (base64Content && base64Content.includes('data:image')) {
    src = picture.base64Content;
  } else {
    src = DocumentHelper.getImageWithBase64({ base64Content });
  }
  return (
    <Fragment>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faImage} />
        {`${translate('common.document')}: ${name}`}
      </ModalHeader>

      <DialogContent>
        <img alt={name} src={src} style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {translate('button.close')}
        </Button>
      </DialogActions>
    </Fragment>
);
};

DisplayPictureModal.propTypes = {
  base64Content: PropTypes.string.isRequired,
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

DisplayPictureModal.defaultProps = {
  name: ''
};
