import {
  Button, DialogActions, DialogContent, Divider, Grid, Paper, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

const StyledContainer = styled.div`
  z-index: var(--zindexBig);
`;

const StyledDialogContent = styled(DialogContent)`
  max-width: 600px;
  min-height: 245px;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 83px;
  width: 83px;
`;

const StyledDivider = styled(Divider)`
  margin: 5px 0 !important;
`;

export const BrowserPopupModal = ({
  title, onClose, onConfirm, primaryText, secondaryText
}) => {
  const handleOnConfirm = useCallback(() => {
    onClose();
    onConfirm();
  }, [onClose, onConfirm]);

  return (
    <StyledContainer>
      <ModalHeader className="warningModal">
        {title}
      </ModalHeader>

      <StyledDialogContent>
        <Typography>
          {primaryText}
        </Typography>

        <StyledDivider />

        <Typography gutterBottom variant="subtitle2">
          {secondaryText}
        </Typography>

        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <Paper>
              <a href="https://www.google.com/intl/fr_fr/chrome/" rel="noopener noreferrer" target="_blank">
                <StyledDiv>
                  <img alt="Google Chrome icon" src={`${process.env.PUBLIC_URL}/assets/images/icons/google_chrome_icon.png`} />
                </StyledDiv>
                <Typography align="center">
                  Chrome
                </Typography>
              </a>
            </Paper>
          </Grid>
          <Grid item>
            <Paper>
              <a href="https://www.mozilla.org/fr/firefox/new/" rel="noopener noreferrer" target="_blank">
                <StyledDiv>
                  <img alt="Firefox icon" src={`${process.env.PUBLIC_URL}/assets/images/icons/firefox_icon.png`} />
                </StyledDiv>
                <Typography align="center">
                  Firefox
                </Typography>
              </a>
            </Paper>
          </Grid>
        </Grid>
      </StyledDialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleOnConfirm}>
          {translate('button.close')}
        </Button>
      </DialogActions>
    </StyledContainer>
  );
};

BrowserPopupModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string.isRequired
};
