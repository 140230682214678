import { useStores } from 'hooks';
import React from 'react';
import styled from 'styled-components';

const StyledButtonFloating = styled.div<any>`
  position: fixed;
  bottom: ${props => (props.isOffline ? `${Number(props.bottom + 50)}px` : `${props.bottom}px`)};
  right: 20px;
  z-index: var(--zindexSmall);
`;

export const ButtonFloating = ({
  bottom = 90,
  children,
  dataCy = '',
  dataTour = '',
  onClick
}: {
  bottom?: number,
  children,
  dataCy?: string,
  dataTour?: string,
  onClick: (e: any) => void
}) => {
  const { userStore } = useStores();
  return (
    <StyledButtonFloating
      bottom={bottom}
      data-cy={dataCy}
      data-tour={dataTour}
      isOffline={userStore.isOffline}
      onClick={onClick}
    >
      {children}
    </StyledButtonFloating>
  );
};
