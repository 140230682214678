import React, { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'; // Docs: https://www.npmjs.com/package/react-signature-canvas
import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';

import { faEraser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button, DialogActions, DialogContent, IconButton, TextField
} from '@material-ui/core';

import { Datepicker, FlexContainer } from 'components';
import { ModalProps } from 'hooks/ModalProvider';
import { SignatureFieldView } from 'models';
import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

const SignatureCanvasContainer = styled.div<{disabled: boolean}>`
  width: 280px;
  height: 280px;
  margin: 2rem auto 1rem auto;
  border: 2px solid var(--black);
  filter: ${props => (props.disabled ? 'brightness(75%)' : 'brightness(100%)')};

  canvas {
    width: 100%;
    height: 100%;
    ${props => (props.disabled && 'cursor: not-allowed;')}
  }
`;

export const AddSignatureModal = ({
  defaultValues = new SignatureFieldView(),
  onClose,
  onSubmit
}: ModalProps<SignatureFieldView> & {
  defaultValues: SignatureFieldView
}) => {
  const [formState, { text }] = useFormState<SignatureFieldView>(defaultValues);
  const [canvasActivated, setCanvasActivated] = useState(true);
  let signature: SignatureCanvas;

  useEffect(() => {
    if (defaultValues.document?.base64Content) {
      const image = new Image();
      image.onload = () => {
        signature.getCanvas().getContext('2d').drawImage(image, 0, 0);
        signature.off();
        setCanvasActivated(false);
      };
      image.src = defaultValues.document.base64Content;
    }
    // eslint-disable-next-line
  }, []);

  const clearCanvas = () => {
    signature.clear();
    signature.on();
    setCanvasActivated(true);
  };

  const handleValidateModal = (e) => {
    e.preventDefault();

    const base64Content = signature.getCanvas().toDataURL('image/png');

    onSubmit({
      ...formState.values,
      document: {
        base64Content,
        name: `${formState.values.type}_${formState.values.name}`,
        extension: '.png'
      }
    });
  };

  return (
    <form autoComplete="off" onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('common.sign')}</ModalHeader>

      <DialogContent>
        <FlexContainer flexDirection="column">
          <TextField
            error={formState.errors.name && formState.errors.name !== null}
            label={translate('modalSign.name')}
            name="name"
            required
            {...text('name')}
          />
          <TextField
            error={formState.errors.occupation && formState.errors.occupation !== null}
            label={translate('modalSign.occupation')}
            name="occupation"
            required
            {...text('occupation')}
          />
          <Datepicker
            disableFuture
            label="common.signatureDate"
            name="signatureDate"
            required
            value={formState.values.signatureDate}
            onChange={value => formState.setField('signatureDate', value)}
          />

          {formState.values.type === 'operatorSignatureField' && (
            <Datepicker
              disableFuture
              error={!!formState.errors.interventionDate}
              label="common.interventionDate"
              name="interventionDate"
              required
              value={formState.values.interventionDate}
              onChange={value => formState.setField('interventionDate', value)}
            />
          )}
        </FlexContainer>

        <SignatureCanvasContainer disabled={!canvasActivated}>
          <SignatureCanvas clearOnResize={false} ref={(ref) => { signature = ref; }} throttle={5} velocityFilterWeight={0.3} />
        </SignatureCanvasContainer>
      </DialogContent>

      <DialogActions>
        <IconButton onClick={clearCanvas}>
          <FontAwesomeIcon color="var(--blue)" icon={faEraser} />
        </IconButton>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.saveSignature')}</Button>
      </DialogActions>
    </form>
  );
};
