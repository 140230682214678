import { MAPBOX_TOKEN } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

// DEPRECATED
// Docs : https://geo.api.gouv.fr/adresse
const geoApiUrl = 'https://api-adresse.data.gouv.fr/search';

// Docs : https://docs.mapbox.com/api/search/geocoding/
const mapboxUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

// GET

// POST
const getAddressList = (query = '', params = '&limit=20') => RequestHelper.GET(`${geoApiUrl}/?q=${query}${params}`);

const getAddressListMapbox: (
  query: string,
  country?: string,
  language?: string
) => Promise<any> = (query = '', country = '', language = 'fr') => RequestHelper.GET(`${mapboxUrl}/${query}.json?access_token=${MAPBOX_TOKEN}&limit=10&country=${country}&language=${language}`);

export const AddressService = {
  getAddressList,
  getAddressListMapbox
};
