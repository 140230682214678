import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

import Skeleton from '@material-ui/lab/Skeleton';

import { FlexContainer, Wrapper } from 'components';

const ChartBar = styled(Skeleton)`
  height: ${props => props.height}px;
  width: 9%;
  margin-right: 1%;
`;

export const SkeletonChart = () => (
  <Wrapper>
    <FlexContainer alignItems="flex-end">
      {new Array(10).fill().map(() => <ChartBar height={Math.round(Math.random() * 300)} key={shortid.generate()} />)}
    </FlexContainer>
  </Wrapper>
);
