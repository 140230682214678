import { faAt, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogContent } from '@material-ui/core';
import {
  ButtonBlue, ButtonBlueLight, FlexContainer, Text
} from 'components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

const DashedLine = styled.div`
  width: 33%;
  border-top: 4px dashed var(--blue);
`;

export const SendInterventionModal = ({
  onClose, onConfirm, cerfaVersion
}) => {
  const confirmAndClose = () => {
    onConfirm();
    return onClose();
  };

  return (
    <Fragment>
      <ModalHeader onClose={onClose}>
        {translate('modalSendIntervention.title')}
      </ModalHeader>

      <DialogContent>
        <FlexContainer alignItems="center" justifyContent="space-around">
          <img alt={translate('modalSendIntervention.title')} height="80px" src={`${process.env.PUBLIC_URL}/assets/images/cerfa/${cerfaVersion}/cerfa.jpg`} />
          <DashedLine />
          <img alt={translate('modalSendIntervention.sendCopy')} src={`${process.env.PUBLIC_URL}/assets/images/icons/sendTo.png`} />
        </FlexContainer>

        <Text fontSize="2.6rem">{translate('modalSendIntervention.sendCopy')}</Text>

        <Text fontWeight={600}>{translate('modalSendIntervention.cerfaFormat')}</Text>

        <FlexContainer flexDirection="column">
          <ButtonBlue margin="0 0 2rem 0" onClick={confirmAndClose}>
            <FontAwesomeIcon icon={faAt} />
            {translate('modalSendIntervention.byMail')}
          </ButtonBlue>

          <ButtonBlueLight margin="0 0 2rem 0" onClick={confirmAndClose}>
            <FontAwesomeIcon icon={faEnvelope} />
            {translate('modalSendIntervention.byLetter')}
          </ButtonBlueLight>
        </FlexContainer>
      </DialogContent>
    </Fragment>
  );
};

SendInterventionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cerfaVersion: PropTypes.string
};

SendInterventionModal.defaultProps = {
  cerfaVersion: 'v3'
};
