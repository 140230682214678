import Keycloak from 'keycloak-js'; // Docs : https://www.npmjs.com/package/keycloak-js

import {
  KEYCLOAK_AUTH_SERVER_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_RESOURCE
} from 'utils/constants';

const initOptions = {
  realm: KEYCLOAK_REALM,
  url: KEYCLOAK_AUTH_SERVER_URL,
  clientId: KEYCLOAK_RESOURCE,
  'public-client': true,
  'confidential-port': 0
};

const keycloak = Keycloak(initOptions);

const init = (): Promise<any> => new Promise((resolve, reject) => {
  keycloak
    .init({ onLoad: 'login-required', checkLoginIframe: false })
    .then(() => resolve(keycloak))
    .catch((error) => {
      reject(error);
    });
});

const logOut = () => {
  keycloak.logout();
};

const currentKeycloakInstance = () => keycloak;

export const KeyCloakUtils = {
  init,
  logOut,
  currentKeycloakInstance
};
