import PropTypes from 'prop-types';
import React from 'react';

import { FontIcon } from 'components';

const MIN_PERCENTAGE = 0.2;
const MAX_PERCENTAGE = 0.8;
const MIN_AMOUNT = 5;

export const BottleFilledIcon = ({
  bottleType, theoricalAmount, maximumCapacity, fontSize
}) => {
  if (maximumCapacity) {
    if (bottleType.key === 'NEW_BOTTLE') {
      if (theoricalAmount === 0) {
        return <FontIcon color="var(--red)" fontSize={fontSize} icon="icon-container-03" />;
      }
      if ((theoricalAmount / maximumCapacity) < MIN_PERCENTAGE) {
        return <FontIcon color="var(--orange)" fontSize={fontSize} icon="icon-container-13" />;
      }
      if ((theoricalAmount / maximumCapacity) < MAX_PERCENTAGE) {
        return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container-23" />;
      }
      return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container" />;
    }

    if (bottleType.key === 'RETRIEVAL_BOTTLE') {
      if (theoricalAmount === 0) {
        return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container-03" />;
      }
      if ((theoricalAmount / maximumCapacity) < MIN_PERCENTAGE) {
        return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container-13" />;
      }
      if ((theoricalAmount / maximumCapacity) < MAX_PERCENTAGE) {
        return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container-23" />;
      }
      if (theoricalAmount < maximumCapacity) {
        return <FontIcon color="var(--orange)" fontSize={fontSize} icon="icon-container" />;
      }
      return <FontIcon color="var(--red)" fontSize={fontSize} icon="icon-container" />;
    }

    if (theoricalAmount === 0) {
      return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container-03" />;
    }
    if ((theoricalAmount / maximumCapacity) < MIN_PERCENTAGE) {
      return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container-13" />;
    }
    if ((theoricalAmount / maximumCapacity) < MAX_PERCENTAGE) {
      return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container-23" />;
    }
    return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container" />;
  }

  if (bottleType.key === 'NEW_BOTTLE') {
    if (theoricalAmount === 0) {
      return <FontIcon color="var(--red)" fontSize={fontSize} icon="icon-container-03" />;
    }
    if (theoricalAmount < MIN_AMOUNT) {
      return <FontIcon color="var(--orange)" fontSize={fontSize} icon="icon-container-13" />;
    }
    return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container-23" />;
  }

  return <FontIcon color="var(--green)" fontSize={fontSize} icon="icon-container" />;
};

BottleFilledIcon.propTypes = {
  bottleType: PropTypes.shape({}).isRequired,
  fontSize: PropTypes.string,
  theoricalAmount: PropTypes.number.isRequired,
  maximumCapacity: PropTypes.number
};

BottleFilledIcon.defaultProps = {
  fontSize: '2rem',
  maximumCapacity: null
};
