import {
  ButtonBlue, CardAllInterventions, CardBottleList,
  CardNextIntervention, CardValidations, FlexContainer, FontIcon, Wrapper
} from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Media from 'react-media';
import styled from 'styled-components';
import { APPLICATION_ROLES } from 'utils/constants';
import { translate, UserHelper } from 'utils/helpers';

const PageHomeContainer = styled.div`
  & > section > section {
    flex: 1;
  }
`;

const CardsContainer = styled(FlexContainer)`
  & > section {
    min-width: 48%;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

export const PageHome = observer(() => {
  const { userStore } = useStores();
  const { open } = useModal();

  const displayDesktop = () => (
    <>
      {/* <CardProfile /> */}
      {/* <CardLeaks /> */}
      {/* <CardCerfas /> */}
      <CardsContainer flexWrap="wrap" justifyContent="center">
        <CardNextIntervention />
        <CardAllInterventions />
        {UserHelper.hasAccessRight([
          APPLICATION_ROLES.ADMIN,
          APPLICATION_ROLES.SUPER_ADMIN,
          APPLICATION_ROLES.INTERVENTION_MANAGER
        ]) && (
        <CardValidations />
          )}
      </CardsContainer>
      {userStore.isViewingInstitution() && <CardBottleList />}
    </>
  );

  const displayMobile = () => (
    <FlexContainer flexDirection="column" justifyContent="center">
      {/* <CardProfile /> */}
      {/* <CardLeaks /> */}
      {/* <CardCerfas /> */}
      <CardNextIntervention />
      <CardAllInterventions />
      {UserHelper.hasAccessRight([
        APPLICATION_ROLES.ADMIN,
        APPLICATION_ROLES.SUPER_ADMIN,
        APPLICATION_ROLES.INTERVENTION_MANAGER
      ]) && (
      <CardValidations />
        )}
      <CardBottleList />
    </FlexContainer>
  );

  const displayCreateIntervention = () => {
    open({ type: 'CREATE_INTERVENTION' });
  };

  return (
    <Wrapper>
      <PageHomeContainer>
        {userStore.isViewingInstitution() && UserHelper.hasAccessRightCreateIntervention() && (
          <FlexContainer alignItems="center" justifyContent="center">
            <ButtonBlue data-cy="createNewIntervention" margin="2rem 0" onClick={displayCreateIntervention}>
              <FontIcon fontSize="2rem" icon="icon-create-intervention" />
              {translate('pageHome.buttonCreate')}
            </ButtonBlue>
          </FlexContainer>
        )}

        <Media query="(max-width: 1080px)">
          {matches => (matches
            ? displayMobile()
            : displayDesktop()
          )}
        </Media>
      </PageHomeContainer>
    </Wrapper>
  );
});
