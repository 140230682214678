import {
  faCalendar, faSearch, faSlidersH, faSpinner,
  faTimes, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Chip, Drawer, IconButton, InputAdornment, TextField
} from '@material-ui/core';
import {
  ButtonBlue, ButtonBlueLight, FlexContainer, Text
} from 'components';
import { useEffectAfterRender } from 'hooks';
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import Media from 'react-media';
import shortid from 'shortid';
import styled from 'styled-components';
import {
  FilterHelper,
  FormHelper, translate
} from 'utils/helpers';
import { FilterItem } from 'utils/types/FilterItem';

const InputAndFilters = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'space-between'
})`
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-radius: var(--borderRadius);

  .MuiTextField-root {
    flex: 1;
  }

  input {
    &:disabled {
      cursor: not-allowed;
    }
  }

  & > button {
    height: 40px;
    margin-left: 1rem;
    border: none;
    background: none;
    cursor: pointer;

    svg {
      margin-right: 0.5rem;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;

      p {
        color: var(--grey);
      }
    }
  }
`;

const TagList = styled(FlexContainer)`
  flex: 1;
  flex-wrap: wrap;
  overflow-x: auto;

  div:not(:last-child),
  svg:not(.MuiSvgIcon-root) {
    margin-right: 0.5rem;
  }
`;

const FilterDrawerMain = styled.main`
  width: 100%;
  min-width: var(--wrapperWidthMin);
  flex: 1;
  margin: 0 auto;
  padding: 0 2rem;

  h2 {
    justify-content: flex-start;
  }
`;

const FilterDrawerButtons = styled(FlexContainer).attrs({
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center'
})`
  padding: 2rem;

  @media (min-width: 481px) {
    button:first-child {
      margin-right: 2rem;
    }
  }

  @media (max-width: 480px) {
    flex-direction: column;

    button:first-child {
      margin-bottom: 2rem;
    }
  }
`;

const DeleteButton = styled(Button)`
  &:hover {
    color: var(--red);
  }
`;

export const GenericListFilters = ({
  ComponentFilter,
  defaultFilters = [],
  disabled = true,
  filterKey = '',
  filters,
  isVisible = false,
  placeholder = '',
  search = '',
  setFilters,
  setSearch,
  withSidePanel = false
}: {
  ComponentFilter?: React.FC<{currentFilters: FilterItem<any>[], setCurrentFilters: (filters: FilterItem<any>[]) => void}>,
  defaultFilters?: FilterItem<any>[],
  disabled?: boolean,
  filterKey?: string,
  filters?: FilterItem<any>[],
  isVisible?: boolean,
  placeholder?: string,
  search?: string,
  setFilters?: (filters: FilterItem<any>[]) => void,
  setSearch: (search: string) => void,
  withSidePanel?: boolean
}) => {
  // Control for the side panel
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  // Filters being edited in the side menu before saving
  const [tempFilters, setTempFilters] = useState<FilterItem<any>[]>([]);
  // Buffer for the search field
  const [internalSearch, setInternalSearch] = useState(search);
  const [isSearching, setIsSearching] = useState(false);

  // Only trigger the search after a second without any input change
  const debouncedSearch = FormHelper.useDebounce(internalSearch, 1000);

  useEffect(() => {
    if (!withSidePanel) {
      return;
    }
    // If automatic filters are defined, use them
    if (filters) {
      FilterHelper.setFilters(filterKey, filters);
      setTempFilters(filters);
    } else {
      // Else, if saved filters exist, use them
      const savedFilters = FilterHelper.getFilters(filterKey);
      if (savedFilters) {
        setFilters(savedFilters);
        setTempFilters(savedFilters);
      } else if (defaultFilters && defaultFilters.length > 0) {
        // Else, use the default filters
        setFilters(defaultFilters);
        setTempFilters(defaultFilters);
        FilterHelper.setFilters(filterKey, defaultFilters);
      } else {
        setFilters([]);
        setTempFilters([]);
        FilterHelper.setFilters(filterKey, []);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffectAfterRender(() => {
    setSearch(debouncedSearch);
    setIsSearching(false);
  }, [setSearch, debouncedSearch]);

  const handleInputChange = useCallback((input) => {
    setIsSearching(true);
    // Use an internal variable as a buffer to avoid triggering the search on every input change
    setInternalSearch(input.target.value);
  }, []);

  const removeFilters = useCallback(() => {
    // Remove all filters
    setFilters([]);
    // Reset the temporary filters
    setTempFilters([]);
    // Remove any saved filters from the local storage
    FilterHelper.setFilters(filterKey, []);
  }, [setFilters, filterKey]);

  const resetFilters = useCallback(() => {
    // Reset the temporary filters back to their original value
    setTempFilters(filters);
    // Close the side panel
    setSidePanelOpen(false);
  }, [filters]);

  const setNewFilters = useCallback(() => {
    // Save the filters in the local storage for future use
    FilterHelper.setFilters(filterKey, tempFilters);
    // Update the filters
    setFilters(tempFilters);
    // Close the side panel
    setSidePanelOpen(false);
  }, [tempFilters, setFilters, filterKey]);

  const handleDeleteFilter = useCallback((filter: FilterItem<any>, itemValue?: string) => {
    // Update the temp filters
    const newFilters = FilterHelper.removeFiltersValue(filters, filter.key as string, itemValue);
    setTempFilters(newFilters);
    // Save the filters in the local storage for future use
    FilterHelper.setFilters(filterKey, newFilters);
    // Update the filters
    setFilters(newFilters);
    // Close the side panel
    setSidePanelOpen(false);
  }, [filters, filterKey, setFilters]);

  if (!isVisible) {
    return null;
  }

  return (
    <Fragment>
      <InputAndFilters>
        <TextField
          autoComplete="off"
          disabled={disabled}
          InputProps={{
            endAdornment: internalSearch !== '' && (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setInternalSearch('')}>
                  <FontAwesomeIcon color="var(--grey)" icon={faTimes} size="xs" />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                {isSearching
                  ? <FontAwesomeIcon icon={faSpinner} spin />
                  : <FontAwesomeIcon color="var(--grey)" icon={faSearch} />
                }
              </InputAdornment>
            )
          }}
          label={translate('common.searchElement')}
          name="search"
          placeholder={placeholder}
          value={internalSearch}
          variant="outlined"
          onChange={handleInputChange}
        />
        {withSidePanel && (
          <Button data-cy="genericListFilters" disabled={disabled} type="button" onClick={() => setSidePanelOpen(true)}>
            <FontAwesomeIcon icon={faSlidersH} />
            <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
              {translate('button.filters')}
            </Text>
          </Button>
        )}
      </InputAndFilters>
      {filters && filters.length > 0 && (
        <FlexContainer alignItems="center" justifyContent="space-between" style={{ marginBottom: '2rem' }}>
          <TagList data-cy="genericListFiltersList">
            {filters.map((filter) => {
              if (filter.listValue) {
                return filter.listValue.map(item => (
                  <Chip
                    color="primary"
                    key={shortid.generate()}
                    label={item.label}
                    style={{ marginBottom: '0.5rem' }}
                    onDelete={() => handleDeleteFilter(filter, item.value)}
                  />
                ));
              }
              let formattedFilterLabel: any = filter.label;
              if (filter.dateValue) {
                formattedFilterLabel = (
                  <Fragment>
                    <FontAwesomeIcon icon={faCalendar} />
                    {filter.label}
                  </Fragment>
                );
              }
              return formattedFilterLabel && (
                <Chip
                  color="primary"
                  key={shortid.generate()}
                  label={formattedFilterLabel}
                  style={{ marginBottom: '0.5rem' }}
                  onDelete={() => handleDeleteFilter(filter)}
                />
              );
            })}
          </TagList>
          {filters.length > 0 && (
            <DeleteButton
              startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={removeFilters}
            >
              <Media
                query="(min-width: 768px)"
                render={() => translate('button.removeFilters')}
              />
            </DeleteButton>
          )}
        </FlexContainer>

      )}

      {withSidePanel && (
        <Drawer anchor="right" id="filterDrawer" open={isSidePanelOpen} onClose={() => setSidePanelOpen(false)}>
          <FlexContainer justifyContent="flex-end">
            <Button onClick={resetFilters}>
              <FontAwesomeIcon color="var(--blue)" icon={faTimes} size="2x" />
            </Button>
          </FlexContainer>
          <FilterDrawerMain>
            {ComponentFilter && ComponentFilter({ currentFilters: tempFilters, setCurrentFilters: setTempFilters })}
          </FilterDrawerMain>
          <FilterDrawerButtons>
            <ButtonBlueLight data-cy="genericListFilterCancel" onClick={resetFilters}>
              {translate('button.cancel')}
            </ButtonBlueLight>
            <ButtonBlue data-cy="genericListFilterValidate" onClick={setNewFilters}>
              {translate('button.validateFilters')}
            </ButtonBlue>
          </FilterDrawerButtons>
        </Drawer>
      )}
    </Fragment>
  );
};
