// Enum
import { HeadersType } from 'models/headers.model';
import { MethodEnum } from 'models/http.enum';

import { userStore } from 'stores';

const getXSRFCookie = (app) => (app
    ? localStorage.getItem(`${app.toLowerCase()}XsrfToken`)
    : localStorage.getItem('databilanXsrfToken'));

export const getDefaultHeaders = (app, method: string = 'GET'): HeadersType => {
  const headers: HeadersType = {
    'Content-Type': 'application/json'
  };

  switch (method.toLowerCase()) {
    case 'post':
    case 'put':
    case 'delete':
    case 'patch':
      headers['X-XSRF-TOKEN'] = getXSRFCookie(app);
      break;
    default:
      break;
  }

  return headers;
};

interface InitModel {
  app?: string;
  body?: string | object | FormData;
  credentials?: string;
  headers?: HeadersType;
  method: MethodEnum;
  signal?: AbortSignal;
}

export const overrideHeaders = (app, headers: HeadersType, method: string) => (method === 'GET' ? headers : {
  ...headers,
  'X-XSRF-TOKEN': getXSRFCookie(app)
});

export const httpSettings = (settings: InitModel = { method: MethodEnum.GET }, internalAPI = true): object => {
  const headers: HeadersType = settings.headers
      ? overrideHeaders(settings.app, settings.headers, settings.method)
      : getDefaultHeaders(settings.app, settings.method);
  const init: InitModel = settings;

  if (init.body) {
    switch (headers['Content-Type']) {
      case 'application/json':
        if (!(init.body instanceof FormData)) {
          init.body = JSON.stringify(init.body);
        }
        break;
      case 'application/x-www-form-urlencoded': {
        const formDataEntries: any = init.body instanceof FormData ? init.body.entries() : Object.entries(init.body);
        const formDataArray: string[] = [];
        formDataEntries.forEach(entry => {
          formDataArray.push(`${encodeURIComponent(entry.key)}=${encodeURIComponent(entry.value)}`);
        });
        init.body = formDataArray.join('&');
        break;
      } default:
        break;
    }
  }

  const { keycloak } = userStore;
  const token = keycloak?.token || '';
  const locale = keycloak?.tokenParsed?.locale || '';

  headers.Accept = 'application/json';
  if (token && !headers.Authorization && internalAPI) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (locale && internalAPI) {
    headers['Accept-Language'] = locale;
  }

  init.headers = headers;
  return init;
};
