import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl, FormControlLabel, InputLabel, Radio, RadioGroup
} from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import { translate } from 'utils/helpers';

const StyledSwitchDouble = styled('div')(({ value }) => ({
  position: 'relative',
  width: '70px',
  backgroundColor: '#D9D9D9FF',
  transition: 'all 0.3s',
  borderRadius: '20px',
  boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',

  ...(value === 'on' ? {
    backgroundColor: '#8aca8d'
  } : {
    backgroundColor: '#f97575'
  }),

  'label .MuiRadio-root': {
    opacity: 0
  },

  '.MuiFormControlLabel-root': {
    width: '35px',
    margin: 0
  },
  '.MuiFormGroup-root, .MuiRadio-root': {
    width: '100%'
  },
  '.MuiRadio-root': {
    height: '22px'
  }
}));

const StyledSlider = styled('span')(({ value }) => ({
  position: 'absolute',
  width: '26px',
  height: '26px',
  left: '50%',
  top: '-2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#D9D9D9FF',
  transition: 'all 0.3s',
  borderRadius: '50%',
  ...(value === 'on' ? {
    left: '100%',
    transform: 'translateX(-26px)',
    backgroundColor: '#0ea04b'
  } : {
    left: 0,
    transform: 'translateX(0)',
    backgroundColor: '#e2001a'
  }),

  svg: {
    color: '#ffffff'
  }
}));

export const ToggleValidationButton = ({
 label = '',
 name,
 onChange,
 value
}) => (
  <>
    {label && <InputLabel shrink>{translate(label)}</InputLabel>}
    <StyledSwitchDouble value={value}>
      <FormControl className="formControl">
        <RadioGroup
            aria-labelledby={translate(label)}
            name={name}
            row
            value={value}
            onChange={onChange}
          >
          <FormControlLabel
              control={<Radio icon={<FontAwesomeIcon color='#DC004E' icon={faTimes} size="xs" />} />}
              label=""
              value="off"
            />
          <FormControlLabel
              control={<Radio icon={<FontAwesomeIcon color='#38853C' icon={faCheck} size="xs" />} />}
              label=""
              value="on"
            />
        </RadioGroup>
        <StyledSlider value={value}>
          {value === 'off' && <FontAwesomeIcon icon={faTimes} />}
          {value === 'on' && <FontAwesomeIcon icon={faCheck} />}
        </StyledSlider>
      </FormControl>
    </StyledSwitchDouble>
  </>
  );
