import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DialogActions } from '@material-ui/core';
import React, { useCallback } from 'react';
import { translate } from 'utils/helpers';

export const CustomActionButtons = ({
  forValidation = false,
  isLoading = false,
  onCancel,
  onClose
}: {
  forValidation?: boolean,
  isLoading?: boolean,
  onCancel?: () => void,
  onClose: () => void
}) => {
  const handleClick = useCallback(() => {
    onCancel && onCancel();
    onClose();
  }, [onCancel, onClose]);

  return (
    <DialogActions>
      <Button
        disabled={isLoading}
        onClick={handleClick}
      >
        {translate(forValidation ? 'button.reportValidation' : 'button.cancel')}
      </Button>
      <Button
        color="primary"
        disabled={isLoading}
        type="submit"
      >
        {isLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin/>
        ) : (
          translate(forValidation ? 'button.validateData' : 'button.save')
        )}
      </Button>
    </DialogActions>
  );
};
