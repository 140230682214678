import { FluidBalanceView } from 'models/detail/FluidBalanceView';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const fluidBalancesUrl = `${API_URL}fluid-balances`;

// GET
const getYears: () => Promise<SelectItem[]> = () => RequestHelper.GET(`${fluidBalancesUrl}/years`);

// POST
const getAllBalances: (
  filter
) => Promise<FluidBalanceView[]> = filter => RequestHelper.POST(`${fluidBalancesUrl}`, filter);

const getFluidBalanceFromYear: (year : string, institutionId: string) => Promise<Array<FluidBalanceView>> = ((year, institutionId) => RequestHelper.POST(`${fluidBalancesUrl}/for-operator`, { year, institutionIds: [institutionId] }));

const getAllFluidBalanceFromYearAndCompany = ((year, companyId) => (
  RequestHelper.POST(`${fluidBalancesUrl}/for-operator/${companyId}`, { year, institutionIds: [] })
));

const exportReport = ({ date, fluid = null }) => RequestHelper.POST(`${fluidBalancesUrl}/export`, { date, fluid });

const notifExportReport = ({ date, fluid = null }, signal) => RequestHelper.POST(`${fluidBalancesUrl}/notif-export`, { date, fluid }, undefined, signal);

const massSynchronizeWithDatafluides = ((year) => (
  RequestHelper.POST(
    `${fluidBalancesUrl}/mass-synchronize-with-datafluides?year=${year}`
  )
));

export const FluidBalancesService = {
  getYears,
  getAllBalances,
  getFluidBalanceFromYear,
  getAllFluidBalanceFromYearAndCompany,
  exportReport,
  notifExportReport,
  massSynchronizeWithDatafluides
};
