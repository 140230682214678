import React from 'react';

import { DialogTitle } from '@material-ui/core';

import { CloseButton } from './_CloseButton';

export const ModalHeader = ({
  onClose, children, className = 'modal-header'
}: {
  children: any,
  className?: string,
  onClose?: (e: any) => void
}) => (
  <DialogTitle
    className={className}
    id="modal-header"
    style={{ paddingRight: '4rem' }}
  >
    {children}
    {onClose && <CloseButton onClose={onClose} />}
  </DialogTitle>
);
