import { API_URL, INSEE_API_TOKEN } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

// Docs : https://api.insee.fr/catalogue/site/themes/wso2/subthemes/insee/pages/item-info.jag?name=Sirene&version=V3&provider=insee
const inseeUrl = 'https://api.insee.fr/entreprises/sirene/V3.11';

const customHeadersInseeApi = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${INSEE_API_TOKEN}`
};

const companyUrl = `${API_URL}companies`;

// GET DATABILAN
const getCompanyStructureLevels = companyId => RequestHelper.GET(`${companyUrl}/${companyId}/structureLevels`);
const getCompanyTree = (companyUuid, activity) => RequestHelper.GET(`${companyUrl}/${companyUuid}/tree?activity=${activity}`);

const getParentCompany = companyId => RequestHelper.GET(`${companyUrl}/${companyId}/parent`);

const isPublic = companyId => RequestHelper.GET(`${companyUrl}/${companyId}/public`);

// GET INSEE
const getCompanyBySiren = (siren = '') => RequestHelper.GET(`${inseeUrl}/siren/${siren}`, customHeadersInseeApi, undefined, false);

const getInstitutionBySiret = (siret = '') => RequestHelper.GET(`${inseeUrl}/siret/${siret}`, customHeadersInseeApi, undefined, false);

export const CompanyService = {
  getCompanyBySiren,
  getCompanyStructureLevels,
  getInstitutionBySiret,
  getCompanyTree,
  getParentCompany,
  isPublic
};
