import {
  faBuilding, faTools, faUsers, faUserTie
} from '@fortawesome/pro-regular-svg-icons';
import { faRecycle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs } from '@material-ui/core';
import {
  EquipmentOwnerList, SupplierOrRecipientList, TitlePage, TrackDechets,
  UserList, Wrapper
} from 'components';
import StructureLevelTree from 'components/StructureLevelTree/StructureLevelTree';
import { ToolList } from 'components/Tools/ToolList/ToolList';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import shortid from 'shortid';
import {
  ACCESS_TO_RECIPIENTS_ROLES, ADMIN_ROLES, APPLICATION_ROLES, COUNTRY_CODE, ROUTES,
  STOCK_ROLES_WITH_INTERVENTION_MANAGER
} from 'utils/constants';
import { translate, UserHelper } from 'utils/helpers';

export const PageAdministration = () => {
  const history = useHistory<any>();
  const { pathname } = useLocation();
  const currentLocation = pathname.split('/');

  const isFrance = UserHelper.getDefaultCountry()?.key === COUNTRY_CODE.FR;

  const TAB_MAPPING = [];
  const TABS_COMPONENTS = [];

  // Add TABS depending on the user access rights
  if (UserHelper.hasAccessRight([
    APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.INTERVENTION_MANAGER
  ])) {
    TAB_MAPPING.push('detenteurs', 'outillages');
    TABS_COMPONENTS.push(<EquipmentOwnerList />, <ToolList />);
  }

  if (UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER)) {
    TAB_MAPPING.push('fournisseurs-clients');
    TABS_COMPONENTS.push(<SupplierOrRecipientList />);
  }

  if (UserHelper.hasAccessRight(ADMIN_ROLES)) {
    TAB_MAPPING.push('utilisateurs', 'etablissements', 'track-dechets');
    TABS_COMPONENTS.push(<UserList />, <StructureLevelTree />, <TrackDechets />);
  }

  if (UserHelper.hasAccessRight(ADMIN_ROLES) && isFrance) {
    TAB_MAPPING.push('track-dechets');
    TABS_COMPONENTS.push(<TrackDechets />);
  }

  const currentTabMapped = TAB_MAPPING.findIndex(url => url === currentLocation[currentLocation.length - 1]);
  const [currentTab, setCurrentTab] = useState((history.location.state && history.location.state.tabSelected) || currentTabMapped || 0);

  useEffect(() => {
    if (currentTabMapped > -1) {
      setCurrentTab(currentTabMapped);
    } else {
      setCurrentTab(0);
    }
  }, [pathname, currentTabMapped]);

  return (
    <Wrapper>
      <TitlePage>{translate('pageAdmin.title')}</TitlePage>
      <Tabs
        aria-label={translate('pageAdmin.title')}
        data-cy="adminTabs"
        data-tour="step-admin-more"
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        variant="fullWidth"
      // onChange={handleTabChange}
      >
        {UserHelper.hasAccessRight([APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.INTERVENTION_MANAGER]) && [
          <Tab
            component={Link}
            data-cy="adminEquipments"
            data-tour="step-admin-equipments"
            icon={<FontAwesomeIcon icon={faUserTie} size="2x" />}
            key={shortid.generate()}
            label={translate('pageAdmin.tabOwnerEquipment')}
            to={ROUTES.ADMINISTRATION}
          />,
          <Tab
            component={Link}
            data-cy="adminLeakDetectors"
            data-tour="step-admin-leak-detectors"
            icon={<FontAwesomeIcon icon={faTools} size="2x" />}
            key={shortid.generate()}
            label={translate('pageAdmin.tabTools')}
            to={ROUTES.ADMINISTRATION_TOOLS}
          />
        ]}

        {UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER) && [
          <Tab
            component={Link}
            data-cy="adminSuppliers"
            data-tour="step-suppliers-users"
            icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
            key={shortid.generate()}
            label={translate(UserHelper.hasAccessRight(ACCESS_TO_RECIPIENTS_ROLES)
              ? 'pageAdmin.tabSuppliersOrRecipients'
              : 'pageAdmin.tabSuppliers')}
            to={ROUTES.ADMINISTRATION_SUPPLIERS_RECIPIENTS}
          />
        ]}

        {UserHelper.hasAccessRight(ADMIN_ROLES) && [
          <Tab
            component={Link}
            data-cy="adminUsers"
            data-tour="step-admin-users"
            icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
            key={shortid.generate()}
            label={translate('pageAdmin.tabUser')}
            to={ROUTES.ADMINISTRATION_USERS}
          />,
          <Tab
            component={Link}
            data-cy="adminInstitutions"
            data-tour="step-admin-institutions"
            icon={<FontAwesomeIcon icon={faBuilding} size="2x" />}
            key={shortid.generate()}
            label={translate('pageAdmin.tabInstitution')}
            to={ROUTES.ADMINISTRATION_INSTITUTIONS}
          />
        ]}

        {UserHelper.hasAccessRight(ADMIN_ROLES) && isFrance && [
          <Tab
            component={Link}
            data-cy="adminTrackDechets"
            data-tour="step-admin-trackDechets"
            icon={<FontAwesomeIcon icon={faRecycle} size="2x" />}
            key={shortid.generate()}
            label={translate('pageAdmin.tabTrackDechets')}
            to={ROUTES.ADMINISTRATION_TRACK_DECHETS}
          />
        ]}
      </Tabs>
      <section>
        {TABS_COMPONENTS[currentTab]}
      </section>
    </Wrapper>
  );
};
