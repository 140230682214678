import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledCustomIcon = styled.span`
  position: relative;
  min-width: 21px;
  text-align: left;

  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};

  svg:last-child {
    position: absolute;
    top: ${props => props.marginTopCustomIcon};
    right: ${props => props.marginRightCustomIcon};
    margin: 0;
    font-size: 1rem;
  }
`;

export const CustomIcon = ({
  icon, status, marginLeft, marginRight, customIcon, marginRightCustomIcon, marginTopCustomIcon
}) => (
  <StyledCustomIcon marginLeft={marginLeft} marginRight={marginRight} marginRightCustomIcon={marginRightCustomIcon} marginTopCustomIcon={marginTopCustomIcon}>
    {icon}
    {status === 'remove'
      ? <FontAwesomeIcon icon={faMinusCircle} />
      : <FontAwesomeIcon icon={customIcon || faPlusCircle} />
    }
  </StyledCustomIcon>
);

CustomIcon.propTypes = {
  customIcon: PropTypes.shape({}),
  icon: PropTypes.element.isRequired,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  marginRightCustomIcon: PropTypes.string,
  marginTopCustomIcon: PropTypes.string,
  status: PropTypes.string
};

CustomIcon.defaultProps = {
  customIcon: null,
  marginLeft: '0px',
  marginRight: '0px',
  marginRightCustomIcon: '1px',
  marginTopCustomIcon: '0',
  status: 'add'
};
