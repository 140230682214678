import { EquipmentView } from 'models';
import { ToolSummary } from 'models/list/ToolSummary';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { EquipmentFilter } from '../models/request/list/EquipmentFilter';

const toolUrl = `${API_URL}tools`;

// POST
const filterToolList: (
  params,
  page: number
) => Promise<ToolSummary> = (
  params = {},
  page = 1
) => RequestHelper.POST(`${toolUrl}/filter?page=${page}`, params);

const getLeakDetectors: (
  page?: number
) => Promise<ToolSummary> = (
  page = 1
) => RequestHelper.POST(`${toolUrl}/filter?page=${page}&size=500`, { activated: true, type: { value: 'LEAK_DETECTOR', label: 'LEAK_DETECTOR' } });

const getScales: (
  page?: number
) => Promise<ToolSummary> = (
  page = 1
) => RequestHelper.POST(`${toolUrl}/filter?page=${page}&size=500`, { activated: true, type: { value: 'SCALE', label: 'SCALE' } });

const getTool: (
  id: string
) => Promise<EquipmentView> = (
  id
) => RequestHelper.GET(`${toolUrl}/${id}`);

const createTool: (
  tool: EquipmentView
) => Promise<void> = tool => RequestHelper.POST(`${toolUrl}`, tool);

const updateTool: (
  id: string,
  tool: EquipmentView
) => Promise<void> = (
  id,
  tool
) => RequestHelper.PUT(`${toolUrl}/${id}`, tool);

const mergeTool = (hashIdEdited, hashIdMerged) => RequestHelper.POST(`${toolUrl}/${hashIdEdited}/fuse`, { otherId: hashIdMerged });

const importTools = document => RequestHelper.POST(`${toolUrl}/import`, document);

const exportTools = (filter: EquipmentFilter) => RequestHelper.POST(`${toolUrl}/export`, filter);

const exportToolsAsync = (filter: EquipmentFilter, signal) => RequestHelper.POST(`${toolUrl}/export-async`, filter, undefined, signal);

const toggleActivation = (id: string) => RequestHelper.PUT(`${toolUrl}/${id}/toggle-activation`);

export const ToolsService = {
  filterToolList,
  getLeakDetectors,
  getScales,
  getTool,
  mergeTool,
  createTool,
  updateTool,
  importTools,
  exportTools,
  exportToolsAsync,
  toggleActivation
};
