import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  faAirConditioner, faCalendarAlt, faCalendarCheck, faClock, faMapMarkerAlt, faTag
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import styled from 'styled-components';

import {
  Card, FlexContainer, FontIcon, SkeletonCard, Text
} from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { StatsService } from 'services';
import { APPLICATION_ROLES } from 'utils/constants';
import { translate, UserHelper } from 'utils/helpers';

const CardNextInterventionContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'space-between'
})`

  @media (max-width: 480px) {
    .icon-intervention {
      display: none;
    }
  }
`;

const CardNextInterventionText = styled.div`
  flex: 1;
  color: var(--white);
  font-family: var(--fontRoboto);

  p {
    margin: 0 0 0.5rem 0;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const CardNextInterventionTimer = styled(FlexContainer).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
})`
  max-width: 100px;
  min-width: 70px;
  color: var(--blue);
  padding: 0.5rem 1rem;
  font-size: 2rem;
  background-color: var(--white);
  border-radius: 1rem;

  svg {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
    font-size: 1.4rem;
    text-align: center;

    span {
      font-size: 1.8rem;
    }
  }
`;

export const CardNextIntervention = observer(() => {
  const { userStore } = useStores();
  const { open } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [intervention, setIntervention] = useState(null);
  const [interventionDateTime, setInterventionDateTime] = useState('');

  useEffect(() => {
    setIsLoading(true);
    StatsService.getNextIntervention().then((response) => {
      if (response && response.fileNumber) {
        setIntervention(response);

        if (response.appointment && response.appointment.date) {
          const formattedDateTime = `${response.appointment.date.split('/').reverse().join('-')} ${response.appointment.time}`;
          setInterventionDateTime(formattedDateTime);
        }
      }
    }).finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <SkeletonCard />;
  }

  if (!intervention || !UserHelper.hasAccessRight([APPLICATION_ROLES.OPERATOR]) || userStore.isOffline) {
    return null;
  }

  return (
    <Card
      background="var(--blue)"
      buttonAction={intervention.interventionAddress ? (() => open({
        type: 'MAP',
        defaultValues: { address: intervention.interventionAddress, isInteractive: true }
      })) : null}
      buttonCyData="openInterventionMap"
      buttonLink={`/interventions/${intervention.hashId}/detail`}
      buttonText={
        intervention.interventionAddress
          ? translate('pageHome.cardNextIntervention.buttonText')
          : translate('pageHome.cardNextIntervention.buttonDetail')
      }
      dataTour="step-next-intervention"
      hasArrow
      link={`/interventions/${intervention.hashId}/detail`}
      title={translate('pageHome.cardNextIntervention.title')}
      titleIcon={faCalendarAlt}
    >
      <CardNextInterventionContainer>
        <FontIcon fontSize="8rem" icon="icon-intervention" marginRight="2rem" />
        <CardNextInterventionText>
          <Link to={`/interventions/${intervention.hashId}/detail`}>
            <Text color="var(--white)" fontSize="1.8rem" fontWeight={600} margin="0">
              {intervention.customerName}
            </Text>
          </Link>
          <p>
            <FontAwesomeIcon icon={faCalendarCheck} />
            {intervention.appointment && `${intervention.appointment.date} - ${intervention.appointment.time}`}
          </p>
          <p>
            <FontAwesomeIcon icon={faTag} />
            N°&nbsp;
            {intervention.fileNumber}
          </p>
          {intervention.equipmentIdentifier && (
            <p>
              <FontAwesomeIcon icon={faAirConditioner} />
              {intervention.equipmentIdentifier}
            </p>
          )}
          {intervention.interventionAddress && (
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              {intervention.interventionAddress}
            </p>
          )}
        </CardNextInterventionText>
        {interventionDateTime && (
          <CardNextInterventionTimer>
            <FontAwesomeIcon icon={faClock} size="lg" />
            <p>{formatDistanceToNow(new Date(interventionDateTime), { locale: fr, addSuffix: true })}</p>
          </CardNextInterventionTimer>
        )}
      </CardNextInterventionContainer>
    </Card>
  );
});
