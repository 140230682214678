import React, { useCallback, useEffect, useState } from 'react';

import {
  faEdit, faFilePdf, faInfoCircle, faTint, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, TableCell } from '@material-ui/core';
import {
  BottleFilledIcon, ButtonBlue, ButtonBlueLight, ButtonDelete, ButtonText, CustomTooltip, FlexContainer,
  FontIcon, GenericListContainer, GenericTable, SkeletonTableSmall, SubtitlePage, Text, TextError, TitlePage, Wrapper
} from 'components';
import { useModal, useStores } from 'hooks';
import i18n from 'i18next';
import { observer } from 'mobx-react-lite';
import { BottleView, FluidVariationSummary } from 'models';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { FluidMovementsService, FluidsService, InterventionsService } from 'services';
import shortid from 'shortid';
import styled from 'styled-components';
import { ROUTES, STOCK_ROLES_WITH_INTERVENTION_MANAGER } from 'utils/constants';
import {
  BottleHelper, DocumentHelper, formatDateString, translate, UserHelper
} from 'utils/helpers';
import { HeaderType } from 'utils/types';

const getListHeadersTable: (
  displayPDFModal,
  editAction,
  deleteAction
) => HeaderType<FluidVariationSummary>[] = (displayPDFModal, editAction, deleteAction) => [
  {
    name: 'type',
    label: translate('common.type'),
    template: row => {
      const manipulationExists = i18n.exists(`manipulations.${row.type}`);
      return (
        <TableCell key={shortid.generate()}>
          {manipulationExists ? translate(`manipulations.${row.type}`) : translate(`mouvements.${row.type}`)}
        </TableCell>
      );
    }
  }, {
    name: 'variationDate',
    label: translate('common.date'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.variationDate ? formatDateString(row.variationDate) : '-'}
      </TableCell>
    )
  }, {
    name: 'variation',
    label: translate('common.variation'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.variation > 0 ? '+' : ''}
        {`${row.variation} ${translate('common.weightUnit')}`}
      </TableCell>
    )
  }, {
    name: 'interventionNumber',
    label: translate('common.cerfaNumber'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.interventionNumber ? (
          <ButtonText
            color="var(--blue)"
            underlineHover
            onClick={() => {
              InterventionsService.getInterventionCerfa(row.interventionNumber.key)
                .then(response => displayPDFModal(response));
            }}
          >
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '0.5rem' }} />
            {row.interventionNumber.label}
          </ButtonText>
        ) : '-'}
      </TableCell>
    )
  }, {
    name: 'userName',
    label: translate('common.userName'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.userName || '-'}
      </TableCell>
    )
  }, {
    name: 'comment',
    label: translate('common.comment'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.comment || '-'}
      </TableCell>
    )
  },
  {
    name: 'actions',
    label: translate('common.actions'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <Grid container>
          {row.canEdit && (
            <Grid item>
              <IconButton onClick={() => editAction(row)}>
                <FontAwesomeIcon color="var(--blue)" icon={faEdit} size="sm" />
              </IconButton>
            </Grid>
          )}
          {row.canDelete && (
            <Grid item>
              <ButtonDelete onClick={() => deleteAction(row)}>
                <FontAwesomeIcon icon={faTrashAlt} size="sm" />
              </ButtonDelete>
            </Grid>
          )}
        </Grid>
      </TableCell>
    )
  }
];

const BottleDetailHeader = styled.header`
  text-align: center;
  margin-bottom: 3rem;
  padding: 2rem;
  border-bottom: 1px solid #d3d2d6;

  h1 {
    margin: 1rem 0;
  }

  p {
    margin: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const BottleDetail = observer(() => {
  const history = useHistory();
  const { open, close } = useModal();
  const { fluidStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [currentBottle, setCurrentBottle] = useState(new BottleView());
  const [variationList, setVariationList] = useState<FluidVariationSummary[]>([]);

  const { id: bottleHashId } = useParams<any>();

  const getVariationList = useCallback(() => {
    setIsLoading(true);

    FluidsService.getFluidVariations(bottleHashId)
      .then(response => setVariationList(response)).finally(() => setIsLoading(false));
  }, [bottleHashId]);

  const getBottleDetail = useCallback(() => {
    FluidsService.getBottle(bottleHashId).then(response => setCurrentBottle(response));
  }, [bottleHashId]);

  useEffect(() => {
    if (bottleHashId) {
      getVariationList();
      getBottleDetail();
    }
  }, [bottleHashId, getVariationList, getBottleDetail]);

  const updateBottle = (form: BottleView) => {
    FluidsService.updateBottle(form.id, form)
      .then(() => {
        close();
        open({
          type: 'CONFIRM',
          text: translate('confirms.bottleDetail.update'),
          onConfirm: () => {
            fluidStore.loadFluidBottleExtendedOptions();
            getBottleDetail();
            getVariationList();
          }
        });
      });
  };

  const handleDeleteBottle = useCallback(() => {
    open({
      type: 'WARNING',
      text: translate('warnings.bottle.delete'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => {
        FluidsService.deleteBottle(currentBottle.id)
          .then(() => {
            history.push(ROUTES.STOCKS_CONTAINERS);
            enqueueSnackbar(translate('confirms.bottle.deleted'), { variant: 'success' });
          });
      }
    });
  }, [currentBottle, open, enqueueSnackbar, history]);

  const updateFluidMovement = useCallback((form) => {
    if (form.hashId) {
      return FluidMovementsService.updateFluidMovement(form.hashId, form).then(() => {
        enqueueSnackbar(translate('confirms.movement.update'), { variant: 'success' });
        getBottleDetail();
        getVariationList();
        close();
      });
    }

    return null;
  }, [close, getVariationList, getBottleDetail, enqueueSnackbar]);

  const editAction = useCallback((row: FluidVariationSummary) => open({
    type: 'CREATE_FLUID_MOVEMENT',
    onSubmit: updateFluidMovement,
    movementId: row.variationHashId
  }), [updateFluidMovement, open]);

  const deleteAction = useCallback((row: FluidVariationSummary) => {
    open({
      type: 'WARNING',
      text: translate('warnings.movement.delete'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => {
        FluidMovementsService.deleteFluidMovement(row.variationHashId)
          .then(() => {
            enqueueSnackbar(translate('confirms.movement.deleted'), { variant: 'success' });
            getBottleDetail();
            getVariationList();
          });
      }
    });
  }, [open, getVariationList, getBottleDetail, enqueueSnackbar]);

  const displayPDFModal = response => DocumentHelper.displayPDF({
    displayModal: modalType => open({
      type: modalType,
      title: response.name,
      pdf: {
        name: response.name,
        base64Content: DocumentHelper.getPDFWithBase64(response)
      }
    })
  });

  const { canEdit, canDelete } = currentBottle;

  return (
    <Wrapper>
      <BottleDetailHeader>
        <BottleFilledIcon
          bottleType={currentBottle.bottleType || {}}
          fontSize="4rem"
          maximumCapacity={currentBottle.maximumCapacity}
          theoricalAmount={currentBottle.theoricalAmount || 0}
        />
        <TitlePage>
          {BottleHelper.getTranslatedType({
            type: currentBottle.bottleType && currentBottle.bottleType.key,
            isNewFluid: currentBottle.isNewFluid,
            identifier: currentBottle.identifier
          })}
          {currentBottle.hasWarning && (
            <CustomTooltip
              icon={faInfoCircle}
              text={translate('warnings.bottle.modifiedCanHaveLeak')}
            />
          )}
        </TitlePage>
        {currentBottle.fluid && (
          <Text>
            <FontAwesomeIcon icon={faTint} />
            {currentBottle.fluid.label || '-'}
          </Text>
        )}
        <Text>
          {`${translate('common.initialQuantity')} : ${currentBottle.initialAmount !== null ? `${currentBottle.initialAmount} ${translate('common.weightUnit')}` : '-'}`}
        </Text>
        <Text>
          {`${translate('common.bottleQuantity')} : ${currentBottle.measuredAmount !== null ? `${currentBottle.measuredAmount} ${translate('common.weightUnit')}` : '-'}`}
        </Text>
        <Text>
          {`${translate('common.maximumCapacity')} : ${currentBottle.maximumCapacity !== null ? `${currentBottle.maximumCapacity} ${translate('common.weightUnit')}` : '-'}`}
        </Text>
        <FlexContainer alignItems="center" justifyContent="center" style={{ marginTop: '1rem' }}>
          {currentBottle.bottleType && currentBottle.bottleType.key === 'RETRIEVAL_BOTTLE' && currentBottle.retrievalDate && (currentBottle.hasInterventions || currentBottle.bsffIdentifier) && (
            <ButtonBlueLight
              color="var(--blue)"
              style={{ marginRight: '2rem' }}
              onClick={(e) => {
                e.stopPropagation();
                FluidsService.getBsd(currentBottle.id)
                  .then(response => displayPDFModal(response));
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '0.5rem' }} />
              {translate('common.BSD')}
            </ButtonBlueLight>
          )}

          {UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER) && canEdit && (
            <ButtonBlue
              data-cy="editBottle"
              onClick={() => open({
                type: 'CREATE_BOTTLE',
                bottleId: bottleHashId,
                onSubmit: updateBottle
              })}
            >
              <FontIcon icon="icon-container" />
              {translate('button.edit')}
            </ButtonBlue>
          )}

          {canDelete && (
            <ButtonDelete onClick={handleDeleteBottle}>
              <FontAwesomeIcon color="var(--grey)" icon={faTrashAlt} size="xs" />
            </ButtonDelete>
          )}
        </FlexContainer>
      </BottleDetailHeader>
      <GenericListContainer>
        <SubtitlePage>{translate('pageBottleDetail.title')}</SubtitlePage>

        {isLoading && <SkeletonTableSmall />}
        {!isLoading && (
          <div data-tour="step-bottles-variationList">
            {variationList.length === 0
              ? <TextError>{translate('errors.noOperation')}</TextError>
              : (
                <GenericTable<FluidVariationSummary>
                  dataCy="variationList"
                  headers={getListHeadersTable(displayPDFModal, editAction, deleteAction)}
                  rows={variationList}
                />
              )
            }
          </div>
        )}

      </GenericListContainer>
    </Wrapper>
  );
});
