import {
  Button, DialogActions, DialogContent, TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  FlexContainer, FontIcon, InputCounter, Question, SelectLabel, Text, TextError
} from 'components';
import { LeakEntryView } from 'models';
import React, { useState } from 'react';
import { useFormState } from 'react-use-form-state';
import shortid from 'shortid';
import { DocumentHelper, translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

const LOCATION_LENGTH_MAX = 100;

export const AddLeakModal = ({
  currentLeak,
  onClose,
  onSubmit
}: {
  currentLeak: LeakEntryView,
  onClose: (e?: any) => void,
  onSubmit: (leak: LeakEntryView) => void
}) => {
  const [picturesAdded, setPicturesAdded] = useState((currentLeak && currentLeak.documents) || []);
  const [missingLeakStatus, setMissingLeakStatus] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [formState, { text }] = useFormState<LeakEntryView>(currentLeak || new LeakEntryView());

  const handleValidateModal = (e) => {
    e.preventDefault();

    if (formState.values.leakRepaired === null) {
      setMissingLeakStatus(true);
      return;
    }

    setMissingLeakStatus(false);
    onSubmit({
      ...formState.values,
      index: currentLeak?.index,
      documents: picturesAdded
    });
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontIcon fontSize="2.4rem" icon="icon-hazard" marginRight="1rem" />
        {currentLeak ? translate('modalLeak.editLeak') : translate('modalLeak.newLeak')}
      </ModalHeader>

      <DialogContent>
        <FlexContainer flexDirection="column">
          <Question label={translate('modalLeak.leakLocationQuestion')}>
            <InputCounter maxLength={LOCATION_LENGTH_MAX} value={formState.values.location}>
              <TextField
                autoFocus
                error={formState.errors.location && formState.errors.location !== null}
                inputProps={{
                  maxLength: LOCATION_LENGTH_MAX
                }}
                label={translate('modalLeak.leakLocation')}
                minRows={3}
                multiline
                name="location"
                required
                value={formState.values.location}
                onChange={event => formState.setField('location', event.target.value)}
              />
            </InputCounter>
          </Question>

          <Question label={`${translate('modalLeak.leakRepaired')} :`}>
            <ToggleButtonGroup
              aria-label={translate('modalLeak.leakRepaired')}
              exclusive
              name="leakRepaired"
              size="medium"
              {...text('leakRepaired')}
              onChange={(event, value) => {
                if (value === null) return;
                formState.setField('leakRepaired', value);
              }}
            >
              <ToggleButton aria-label={translate('common.done')} data-cy="addLeakDone" value>
                {translate('common.done')}
              </ToggleButton>
              <ToggleButton aria-label={translate('common.todo')} data-cy="addLeakTodo" value={false}>
                {translate('common.todo')}
              </ToggleButton>
            </ToggleButtonGroup>
            {missingLeakStatus && (
              <Text color="var(--red)" margin="0.5rem 0">
                {translate('modalLeak.leakRepairedMissing')}
              </Text>
            )}
          </Question>

          <SelectLabel>{translate('modalLeak.leakPicture')}</SelectLabel>
          <input
            placeholder={translate('modalLeak.leakPictureAdd')}
            type="file"
            onChange={event => DocumentHelper.handleFileUpload({ callback: setPicturesAdded, callbackError: setUploadError, event })}
          />
          {uploadError && <TextError>{uploadError}</TextError>}
          {picturesAdded && picturesAdded.length > 0 && (
            <FlexContainer className="modalPicturesContainer">
              {picturesAdded.map(picture => (
                <img alt={picture.name} key={shortid.generate()} src={picture.base64Content} />
              ))}
            </FlexContainer>
          )}
        </FlexContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};
