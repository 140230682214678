import React, { useCallback } from 'react';

import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectField } from '..';

export const SelectLanguage = ({
  value, onChange, required, options, isLoading = false
}) => {
  const handleChange = useCallback(language => onChange(language), [onChange]);

  return (
    <>
      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <SelectField
          isClearable={false}
          label="common.selectLanguage"
          name="language"
          options={options}
          required={required}
          value={value}
          onChange={handleChange}
        />
      )}
    </>
  );
};
