import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';

const Figure = styled.figure`
  margin: 0;

  figcaption {
    margin: auto;
    text-align: center;
  }
`;

const VIMEO_PLAYER = 'https://player.vimeo.com/video';

export const Video = ({
  name, url
}) => {
  const isCompleteUrl = url && url.indexOf('vimeo') > -1;
  const videoId = isCompleteUrl ? url.match(/\/(\d{9})\/\b/)[1] : url;
  const iframeHeight = isMobileOnly ? 250 : 400;

  if (!videoId) return null;

  return (
    <Figure>
      {/* Hide name for now
        https://supralog.myjetbrains.com/youtrack/issue/DATABILAN-1004
      */}
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height={iframeHeight}
        id="vimeoPlayer"
        src={`${VIMEO_PLAYER}/${videoId}`}
        title={name}
        width="100%"
      />
    </Figure>
  );
};

Video.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};
