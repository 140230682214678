import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { translate } from 'utils/helpers';

const StyledFooterSupralog = styled.div`
  position: relative;
  padding: 1rem 0;
  margin-bottom: 2rem;

  ${props => props.borderPosition === 'top' && css`
    margin: 1rem 0;
    padding: 1rem 3rem;
    border-top: 1px solid #716D84;

    .MuiGrid-item {
      max-width: 210px;
      margin: 0 auto;
    }
  `}

  ${props => props.borderPosition === 'sides' && css`
    img {
      margin-left: 0.8rem;
    }

    &::before,
    &::after {
      width: 150px;
      content: '';
      position: absolute;
      top: 50%;
      border-top: 1px solid #716D84;
      z-index: -1;
    }

    &::before {
      right: 105%;
    }

    &::after {
      left: 105%;
    }
  `}

  @media(max-width: 900px) {
    &::before,
    &::after {
      display: none;
    }
  }
`;

const FooterSupralog = ({ borderPosition = 'sides' }) => (
  <StyledFooterSupralog borderPosition={borderPosition}>
    <Grid item>
      <Grid alignItems="center" container justifyContent="center">
        <Typography align="center">
          {translate('footer.platformSupralog')}
        </Typography>
        <img
          alt={translate('menu.logoAlt')}
          height="30"
          src={`${process.env.PUBLIC_URL}/assets/images/supralog.png`}
          title={translate('menu.logoAlt')}
        />
      </Grid>
    </Grid>
  </StyledFooterSupralog>
);

export default FooterSupralog;
