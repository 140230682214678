import {
  Button, Checkbox, DialogActions, DialogContent, FormControlLabel, TextField
} from '@material-ui/core';
import {
  CustomTooltip, FlexContainer, FontIcon, Question, SelectCountryDetail, Text
} from 'components';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useStores } from 'hooks';
import { CountryView, InterventionView } from 'models';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';
import { InterventionsService } from 'services';
import { APPLICATION_ROLES } from 'utils/constants';
import { InterventionHelper, translate, UserHelper } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

export const CreateInterventionModal = ({
  onClose
}) => {
  const { userStore } = useStores();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [generatedFileNumber, setGeneratedFileNumber] = useState(format(new Date(), 'ddMMyy-hhmm', { locale: fr }));
  const [fileNumberExist, setFileNumberExist] = useState(null);
  const [INTERVENTION_STATUS, setInterventionStatus] = useState(null);
  const [country, setCountry] = useState<CountryView>(null);

  const [formState, { text, checkbox }] = useFormState({
    fileNumber: generatedFileNumber,
    isGeneratedFileNumber: true
  });

  const currentFileNumber = encodeURIComponent(formState.values.isGeneratedFileNumber ? generatedFileNumber : formState.values.fileNumber);
  const newAppointment = InterventionHelper.getFormattedDateTime(new Date());
  const defaultIntervention = new InterventionView();

  useEffect(() => {
    InterventionHelper.loadInterventionStatuses().then(IS => setInterventionStatus(IS));

    InterventionsService.getCustomFileNumber().then((response) => {
      if (response) {
        setGeneratedFileNumber(response);
        formState.setField('fileNumber', response);
      }
    });
    // eslint-disable-next-line
  }, []);

  class PathView {
    pathname: string;
    state: any;
  }

  const handleValidateModal = (link: string | PathView) => {
    // Check if the fileNumber doesn't exist already
    InterventionsService.checkFileNumber(currentFileNumber).then((response) => {
      if (response) {
        return setFileNumberExist(translate('errors.fileNumberAlreadyExist'));
      }

      if (response === false) {
        // Process the link
        setFileNumberExist(null);
        history.push(link);
        return onClose();
      }

      return null;
    }).catch(() => {
      history.push(link);
      onClose();
    });
  };

  const handlePlanIntervention = () => {
    handleValidateModal({
      pathname: `/interventions/${currentFileNumber}/plan`,
      state: {
        isNew: true,
        country: JSON.parse(JSON.stringify(country))
      }
    });
  };

  const handleStartIntervention = () => {
    const newIntervention: InterventionView = {
      ...defaultIntervention,
      country,
      fileNumber: currentFileNumber,
      appointment: newAppointment,
      status: INTERVENTION_STATUS?.ONGOING.key || 'ONGOING'
    };

    if (!userStore.isOffline) {
      return InterventionsService.checkFileNumber(currentFileNumber).then((response) => {
        if (response) {
          return setFileNumberExist(translate('errors.fileNumberAlreadyExist'));
        }

        if (response === false) {
          // Process the link
          setFileNumberExist(null);
          InterventionsService.startIntervention(currentFileNumber, newIntervention).then((resp) => {
            InterventionHelper.saveInterventionDetailAndForm(resp.hashId, resp).then(() => {
              InterventionHelper.loadInterventionFormStored(resp.hashId).then((storedInterventionForm) => {
                history.push({
                  pathname: `/interventions/${resp.hashId}/inspection`,
                  state: {
                    needToBeStored: true,
                    currentStep: storedInterventionForm.currentStep,
                    storedInterventionForm
                  }
                });
              });
            });
          }).catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }));
          return onClose();
        }

        return null;
      });
    }

    return InterventionHelper.saveInterventionDetailAndForm(currentFileNumber, newIntervention).then(() => {
      InterventionHelper.loadInterventionFormStored(currentFileNumber).then((storedInterventionForm) => {
        handleValidateModal({
          pathname: `/interventions/${currentFileNumber}/inspection`,
          state: {
            needToBeStored: true,
            currentStep: storedInterventionForm.currentStep,
            storedInterventionForm
          }
        });
      });
    });
  };

  return (
    <form>
      <ModalHeader onClose={onClose}>
        <FontIcon fontSize="2.6rem" icon="icon-create-intervention" marginRight="1rem" />
        {translate('modalIntervention.newIntervention')}
      </ModalHeader>

      <DialogContent>
        <Question label={`${translate('modalIntervention.cerfaNumber')} :`}>
          {fileNumberExist && <Text color="var(--red)" fontWeight={600} margin="0">{fileNumberExist}</Text>}
          <FlexContainer alignItems="center">
            <FormControlLabel
              control={<Checkbox value="isGeneratedFileNumber" />}
              data-cy="createInterventionFileNumber"
              label={`${translate('modalIntervention.useGeneratedNumber')} (${generatedFileNumber})`}
              {...checkbox('isGeneratedFileNumber')}
            />
            <CustomTooltip text={translate('modalIntervention.tooltipText')} />
          </FlexContainer>
          {!checkbox('isGeneratedFileNumber').checked && (
            <TextField
              error={formState.errors.fileNumber && formState.errors.fileNumber !== null}
              inputProps={{ maxLength: 32 }}
              label={translate('common.cerfaNumber')}
              name="fileNumber"
              {...text('fileNumber')}
            />
          )}
          <Question label={`${translate('modalIntervention.country')} :`}>
            <SelectCountryDetail
              required
              value={country}
              onChange={setCountry}
            />
          </Question>
        </Question>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        {!userStore.isOffline && (
          <Button
            color="primary"
            data-cy="planIntervention"
            disabled={!currentFileNumber}
            onClick={handlePlanIntervention}
          >
            {translate('button.planIntervention')}
          </Button>
        )}
        {UserHelper.hasAccessRight([APPLICATION_ROLES.OPERATOR]) && (
          <Button
            color="primary"
            disabled={!currentFileNumber}
            onClick={handleStartIntervention}
          >
            {translate('button.startIntervention')}
          </Button>
        )}
      </DialogActions>
    </form>
  );
};
