// Docs : https://geo.api.gouv.fr/adresse
import {
  faMapMarkerAltSlash, faSearch, faSpinner, faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Chip, ClickAwayListener, Divider, Grid, IconButton,
  InputAdornment, TextField, Typography
} from '@material-ui/core';
import { Question, SelectCountry } from 'components';
import { FormLine } from 'components/Intervention';
import { useStores } from 'hooks';
import { nestedField } from 'hooks/nestedField';
import { AddressView } from 'models';
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import { FormState, StateErrors } from 'react-use-form-state';
import { AddressService } from 'services';
import styled from 'styled-components';
import { FormHelper, translate } from 'utils/helpers';

const AutocompleteContainer = styled.section`
  position: relative;
  z-index: 10;
`;

const AddressList = styled.ul`
  position: absolute;
  top: 56px;
  width: 100%;
  max-height: 250px;
  margin: 0;
  padding: 0;
  background: var(--white);
  border: 1px solid #eeeeee;
  overflow: auto;
`;

const AddressItem = styled.li`
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in;
  padding: 1rem;
  cursor: pointer;

  &:hover, &:focus {
    background-color: #eeeeee;
  }
`;

const ENTER_KEYCODE = 13;

export const FormAddress = ({
  formState,
  isRequired = false,
  text,
  withCountry = false
}: {
  formState: FormState<any, StateErrors<any, string>>,
  isRequired?: boolean,
  text,
  withCountry?: boolean
}) => {
  const { i18nStore } = useStores();
  const [query, setQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [addressListIsVisible, setAddressListIsVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(new AddressView());
  const debouncedQuery = FormHelper.useDebounce(query, 500);

  useEffect(() => {
    const getAddressList = async () => {
      if (debouncedQuery) {
        setIsSearching(true);
        const country = formState.values.address?.country?.countryCode || '';
        try {
          const resultsMapbox = await AddressService.getAddressListMapbox(debouncedQuery, country, i18nStore.currentLanguage)
            .then(response => response.features);
          setAddressList(resultsMapbox);
          setAddressListIsVisible(true);
          setIsSearching(false);
        } catch {
          setIsSearching(false);
        }
      } else {
        setAddressList([]);
      }
    };

    getAddressList();
    // eslint-disable-next-line
  }, [debouncedQuery]);

  useEffect(() => {
    if (selectedAddress && selectedAddress.address1) {
      formState.setField('address1', selectedAddress.address1);
      formState.setField('address2', selectedAddress.address2);
      formState.setField('postalCode', selectedAddress.postalCode);
      formState.setField('city', selectedAddress.city);
      if (withCountry && selectedAddress.country) {
        formState.setField('country', selectedAddress.country);
        formState.setField('address', selectedAddress);
      } else {
        formState.setField('address', {
          ...selectedAddress,
          country: formState.values.address?.country
        });
      }
      setAddressListIsVisible(false);
    }
    // eslint-disable-next-line
  }, [selectedAddress]);

  const handleChangeQuery = useCallback(event => setQuery(event.target.value), []);

  const handleClearQuery = useCallback(() => {
    setQuery('');
    setAddressList([]);
    setIsSearching(false);
  }, []);

  const handleShowAddressList = useCallback(() => setAddressListIsVisible(true), []);

  const handleHideAddressList = useCallback(() => setAddressListIsVisible(false), []);

  const handleSelectAddress = useCallback((address) => {
    const postcode = address.context.find(ctx => ctx.id.includes('postcode'));
    const city = address.context.find(ctx => ctx.id.includes('place'));
    const country = address.context.find(ctx => ctx.id.includes('country'));

    const addressFormatted: AddressView = {
      address1: (address.properties.address ? address.properties.address : `${address.address || ''} ${address.text || ''}`).trim(),
      postalCode: postcode ? postcode.text : '',
      city: city ? city.text : '',
      country: country ? { value: country.short_code, countryCode: country.short_code, label: country.text } : formState.values.country
    };

    setSelectedAddress(addressFormatted);
    handleHideAddressList();
  }, [handleHideAddressList, formState.values.country]);

  const handleSelectCountry = value => {
    formState.setField('address', {
      ...formState.values.address,
      country: value
    });
    formState.setField('country', value);
};

  return (
    <Question label={translate('common.address')}>
      <ClickAwayListener onClickAway={handleHideAddressList}>
        <AutocompleteContainer>
          <TextField
            autoComplete="new-password"
            fullWidth
            InputProps={{
              endAdornment: query !== '' && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleClearQuery}>
                    <FontAwesomeIcon color="var(--primary-color)" icon={faTimes} size="xs" />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  {isSearching
                    ? <FontAwesomeIcon icon={faSpinner} spin />
                    : <FontAwesomeIcon color="var(--primary-color)" icon={faSearch} />}
                </InputAdornment>
              )
            }}
            label={translate('common.addressAutocomplete')}
            value={query}
            variant="outlined"
            onChange={handleChangeQuery}
            onFocus={handleShowAddressList}
          />
          {addressListIsVisible && (
            <AddressList>
              {addressList.length > 0 && addressList.map(address => (
                <Fragment key={address.id}>
                  <AddressItem
                    tabIndex={0}
                    onClick={() => handleSelectAddress(address)}
                    onKeyDown={e => e.keyCode === ENTER_KEYCODE && handleSelectAddress(address)}
                  >
                    <div style={{ minWidth: '120px' }}>
                      <Chip label={translate(`common.${address.place_type[0]}`)} />
                    </div>
                    <div>
                      <Typography style={{ fontWeight: 600 }}>{address.place_name}</Typography>
                    </div>
                  </AddressItem>
                  <Divider />
                </Fragment>
              ))}
              {addressList.length === 0 && debouncedQuery && !isSearching && (
                <Typography align="center" style={{ padding: '2rem' }}>
                  <FontAwesomeIcon icon={faMapMarkerAltSlash} size="2x" />
                  <br />
                  {translate('errors.noAddressList')}
                </Typography>
              )}
            </AddressList>
          )}
        </AutocompleteContainer>
      </ClickAwayListener>
      <FormLine autoComplete="off">
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="new-password"
              label={translate('common.address')}
              name="address1"
              required={isRequired || FormHelper.isFieldRequired({
                fieldsToCheck: [formState.values.address?.postalCode, formState.values.address?.city]
              })}
              {...nestedField(text, formState, 'address.address1')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              autoComplete="new-password"
              label={translate('common.address2')}
              name="address2"
              {...nestedField(text, formState, 'address.address2')}
            />
          </Grid>
        </Grid>
      </FormLine>
      <FormLine autoComplete="off">
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item sm={4} xs={12}>
                <TextField
                  autoComplete="new-password"
                  label={translate('common.postalCode')}
                  name="postalCode"
                  required={isRequired || FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address?.address1, formState.values.address?.city]
                  })}
                  {...nestedField(text, formState, 'address.postalCode')}
                />
              </Grid>
              <Grid item sm={8} xs={12}>
                <TextField
                  autoComplete="new-password"
                  label={translate('common.city')}
                  name="city"
                  required={isRequired || FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address?.address1, formState.values.address?.postalCode]
                  })}
                  {...nestedField(text, formState, 'address.city')}
                />
              </Grid>
            </Grid>
          </Grid>
          {withCountry && (
            <Grid item xs={12}>
              <SelectCountry
                required={FormHelper.isFieldRequired({
                  fieldsToCheck: [formState.values.address?.address1, formState.values.address?.postalCode, formState.values.address?.city]
                })}
                value={formState.values.address?.country || null}
                onChange={handleSelectCountry}
              />
            </Grid>
          )}
        </Grid>
      </FormLine>
    </Question>
  );
};
