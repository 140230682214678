import React, { Fragment } from 'react';
import shortid from 'shortid';

import Skeleton from '@material-ui/lab/Skeleton';

export const SkeletonForm = ({ items = 4, width = '300px' }) => (
  <div style={{ minWidth: width }}>
    {new Array(items).fill().map(() => (
      <Fragment key={shortid.generate()}>
        <Skeleton height={20} width="50%" />
        <Skeleton height={40} variant="rect" width="100%" />
      </Fragment>
    ))}
  </div>
);
