import { format, parse } from 'date-fns';
import i18n from 'i18next';
import { BottleHelper } from './BottleHelper';
import { BrowserDetectionHelper } from './BrowserDetectionHelper';
import { DocumentHelper } from './DocumentHelper';
import { FilterHelper } from './FilterHelper';
import { FormHelper } from './FormHelper';
import { IndexedDBHelper } from './IndexedDBHelper';
import { InterventionHelper } from './InterventionHelper';
import { RequestHelper } from './RequestHelper';
import { StorageHelper } from './StorageHelper';
import { UserHelper } from './UserHelper';

export const pluralize: (
  string: string,
  length: number,
  defaultValue?: string
) => string = (string, length, defaultValue) => (
  length > 1 ? `${string}s` : (defaultValue || string)
);

export const frenchLocale = 'fr_FR';

const translationNotFound = 'errors.translationNotFound';

export const translate: (
  key: string | string[],
  options?: any
) => string = (key, options) => {
  if (i18n.exists(key)) {
    const translation = i18n.t(key, options);
    if (translation) {
      return translation;
    }
  }
  if (i18n.exists(translationNotFound)) {
    return i18n.t(translationNotFound);
  }
  return 'translationNotFound';
};

export const RandomNumber = () => {
  const { crypto, msCrypto } = window as any;
  const rand = crypto || msCrypto;
  // eslint-disable-next-line no-mixed-operators
  return rand.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
};

export const generateUUID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  // eslint-disable-next-line no-bitwise
  const r = RandomNumber() * 16 | 0;
  const v = c === 'x' ? r : ((r && 0x3) || 0x8);
  return v.toString(16);
});

export const JS_SIMPLE_DATE_FORMAT = 'yyyy-MM-dd';
export const JS_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const TIMESTAMP_FORMAT = 'yyyy-MM-dd_HH-mm-ss-SSS';
export const FULL_DATE_FORMAT = 'dd/MM/yyyy HH:mm:ss';
export const DATETIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const SHORT_DATE_FORMAT = 'MM/dd/yy';
export const SINGLE_DIGIT_SHORT_DATE_FORMAT = 'M/d/yy';

const DATE_FORMATS = [
  JS_SIMPLE_DATE_FORMAT,
  JS_DATE_FORMAT,
  TIMESTAMP_FORMAT,
  FULL_DATE_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  SHORT_DATE_FORMAT,
  SINGLE_DIGIT_SHORT_DATE_FORMAT
];

/**
 * Checks whether the specified value is a valid date or not
 * @param d The value to test
 * @returns true if the specified value is a valid date
 */
export const isValidDate = (d: any) => d instanceof Date && !Number.isNaN(d.getTime());

/**
 * Parses a string into a date
 * @param stringDate The string to parse into a date
 * @param formatString The first format to use when parsing, defaults to a basic 'dd/MM/yyyy' format
 * @returns The parsed date or an Invalid Date value if no format worked
 */
export const parseDate: (
  stringDate: string,
  formatString?: string
) => Date = (stringDate, formatString = JS_SIMPLE_DATE_FORMAT) => {
  if (!stringDate) return null;
  // Use the specified format to parse the date
  let parsedDate: Date = parse(stringDate, formatString, new Date());
  let i = 0;
  while (!isValidDate(parsedDate) && i < DATE_FORMATS.length) {
    parsedDate = parse(stringDate, DATE_FORMATS[i], new Date());
    i++;
  }
  return parsedDate;
};

export const formatDateStringISO: (
  date: string,
  targetFormat?: string
) => string = (date, targetFormat = DATE_FORMAT) => {
  if (!date) return '-';
  return format(new Date(date), targetFormat);
};

export const formatDateString: (
  date: string,
  currentFormat?: string,
  targetFormat?: string
) => string = (date, currentFormat = DATETIME_FORMAT, targetFormat = DATE_FORMAT) => {
  if (!date) return '-';
  try {
    const parsedDate = parseDate(date, currentFormat);
    return format(parsedDate, targetFormat);
  } catch (e) {
    return '-';
  }
};

export const formatDate: (
  date: Date,
  targetFormat?: string
) => string = (date, targetFormat = DATE_FORMAT) => {
  if (!date) return '-';
  try {
    return format(date, targetFormat);
  } catch (e) {
    return '-';
  }
};

export const deleteStoredFilters = () => {
  Object.entries(localStorage)
    .map(key => key[0])
    .filter(key => key.startsWith('filters_'))
    .forEach(filter => localStorage.removeItem(filter));
};

export const getSmallDescription = (description, size = 28) => {
  if (!description) return '';
  if (description.length <= size) return description;

  return `${description.substring(0, size)}...`;
};

export {
  BrowserDetectionHelper,
  BottleHelper,
  DocumentHelper,
  FilterHelper,
  FormHelper,
  IndexedDBHelper,
  InterventionHelper,
  RequestHelper,
  StorageHelper,
  UserHelper
};
