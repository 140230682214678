import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

/**
 * Base hook to wrap basic useEffect fetch (with no dependencies) and handle error popup
 * @param serviceRequest a Service function (GET, POST...) returning a json promise
 * @param initialState optional parameter to have an initial response
 * @returns {{isLoading: boolean, response, error}}
 */
export function useFetch(serviceRequest, initialState = null, reloadOn = [], displayError = true) {
  const { enqueueSnackbar } = useSnackbar();
  const [response, setResponse] = useState(initialState);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const request = await serviceRequest();
        setResponse(request);
        setIsLoading(false);
      } catch (err) {
        displayError && enqueueSnackbar(err.message || err, { variant: 'error' });
        setError(err);
        setIsLoading(false);
      }
    };

    fetchData();
  // eslint-disable-next-line
  }, reloadOn);

  return {
    response,
    error,
    isLoading
  };
}
