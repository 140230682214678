import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

export const SkeletonCard = () => (
  <Skeleton
    height={230}
    style={{
      flex: '1',
      padding: '2rem 1.5rem 4rem 1.5rem',
      borderRadius: '1.6rem',
      border: '1px solid rgb(213,225,239)',
      margin: '2rem 0 3rem 0'
    }}
    variant="rect"
  >
    <Skeleton height={20} width={200} />
    <Skeleton height={20} width={100} />
    <Skeleton height={100} style={{ margin: '0 auto' }} variant="circle" width={100} />
  </Skeleton>
);
