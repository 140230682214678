import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { translate } from 'utils/helpers';
import sanitizeHtml from 'sanitize-html';
import { ModalHeader } from './ModalHeader';

const SelectDeviceContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;

  .MuiToggleButton-root.Mui-selected:first-child,
  .MuiToggleButton-root.Mui-selected:first-child:hover {
    background-color: var(--red);
  }

  .MuiToggleButton-root.Mui-selected:last-child,
  .MuiToggleButton-root.Mui-selected:last-child:hover {
    background-color: var(--green);
  }

  @media (max-width: 768px) {
    img {
      max-height: 140px;
    }
  }
`;

export const SelectPrimaryDeviceModal = ({
  onConfirm, onClose
}) => {
  const handleValidateModal = useCallback(() => {
    onConfirm(true);
    onClose();
  }, [onConfirm, onClose]);

  const handleClose = useCallback(() => {
    onConfirm(false);
    onClose();
  }, [onConfirm, onClose]);

  return (
    <SelectDeviceContainer>
      <ModalHeader onClose={handleClose}>
        <FontAwesomeIcon icon={faMobileAlt} />
        {translate('modalSelectDevice.title')}
      </ModalHeader>

      <DialogContent style={{ maxWidth: 600 }}>
        <Grid alignItems="center" container direction="column" spacing={2}>
          <Grid item>
            <img
              alt={translate('modalSelectDevice.title')}
              src={`${process.env.PUBLIC_URL}/assets/images/icons/select-device.png`}
            />
          </Grid>
          <Grid item>
            <Typography
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('modalSelectDevice.message')) }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          {translate('modalSelectDevice.buttonCancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleValidateModal}
        >
          {translate('modalSelectDevice.buttonSave')}
        </Button>
      </DialogActions>
    </SelectDeviceContainer>
  );
};

SelectPrimaryDeviceModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
