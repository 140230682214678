import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

import { Button } from './Button';

export const ButtonBlue = styled(Button).attrs({
  backgroundColor: 'linear-gradient(270deg, var(--blue) 0%, var(--blue-dark) 100%)',
  boxShadowHover: '0 2px 8px 0 rgba(2,151,216,0), 0 2px 8px 0 rgba(2,151,216,0.63)',
  borderStyle: 'none'
})``;

export const ButtonBlueLight = styled(Button).attrs({
  color: 'var(--blue)',
  colorActive: 'var(--blue-dark)',
  backgroundColor: 'var(--white)',
  backgroundColorHover: 'var(--white)',
  borderColor: 'var(--blue-dark)',
  boxShadowHover: '0 2px 8px 0 rgba(2,151,216,0.63)'
})``;

export const ButtonBigDashed = styled(Button).attrs({
  backgroundColor: 'var(--white)',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderColor: 'var(--blue)',
  borderRadius: '0',
  backgroundColorActive: 'var(--white)',
  color: 'var(--blue)',
  padding: '3rem 0',
  width: '100%'
})``;

export const ButtonTurquoise = styled(Button).attrs({
  backgroundColor: 'var(--turquoise)',
  backgroundColorActive: 'var(--turquoise-dark)',
  backgroundColorHover: 'var(--turquoise)',
  borderColor: 'var(--turquoise)',
  boxShadowHover: '0 2px 8px 0 rgba(27,178,164,0), 0 2px 8px 0 rgba(27,178,164,0.63)'
})``;

export const ButtonText = styled(Button).attrs({
  backgroundColor: 'initial',
  backgroundColorActive: 'initial',
  borderColor: 'initial',
  borderRadius: '0',
  borderWidth: '0',
  fontSize: '1.4rem',
  margin: '0 10px',
  padding: '0',
  textTransform: 'initial'
})``;

export const ButtonDelete = styled(IconButton)`
  &:hover svg {
    color: var(--red);
  }
`;
