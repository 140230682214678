import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlackLayer, ButtonBlueLight, Text } from 'components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { translate } from 'utils/helpers';

const StyledInterventionMap = styled.div`
  position: relative;
  min-height: 150px;
  min-width: ${props => (props.isInsidePopup ? '0' : 'var(--wrapperWidthMin)')};
  margin: 2rem 0;
  overflow: hidden;

  a {
    display: block;
    position: relative;
    min-height: 200px;

    > div {
      background: rgba(0,0,0,0.04);
    }
  }

  img {
    display: block;
    min-width: ${props => (props.isInsidePopup ? '0' : '440px')};
    max-height: ${props => (props.isInsidePopup ? 'none' : '300px')};
    max-width: 100%;
    margin: 0 auto;
  }

  button {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-90px);
  }

  p {
    margin: 0.5rem 0;
  }
`;

export const InterventionMap = ({ address, isInteractive, isInsidePopup }) => {
  const ZOOM = '14';

  return (
    <Fragment>
      <StyledInterventionMap isInsidePopup={isInsidePopup}>
        <Text textAlign="center">
          {translate('pageInterventionDetail.mapDetail')}
        </Text>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${address}&zoom=${ZOOM}`}
          rel="noopener noreferrer"
          style={{ display: 'flex', width: '80%', margin: 'auto' }}
          target="_blank"
        >
          <iframe
            frameborder="0"
            height="400"
            id="gmap_canvas"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
            src={`https://maps.google.com/maps?q=${address}&z=${ZOOM}&ie=UTF8&output=embed`}
            width="100%"
          />
          {!isInteractive && (
            <Fragment>
              <BlackLayer />
              <ButtonBlueLight>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                {translate('pageInterventionDetail.mapButton')}
              </ButtonBlueLight>
            </Fragment>
          )}
        </a>
        <Text textAlign="center">
          {address}
        </Text>
      </StyledInterventionMap>
    </Fragment>
  );
};

InterventionMap.propTypes = {
  address: PropTypes.string.isRequired,
  isInsidePopup: PropTypes.bool,
  isInteractive: PropTypes.bool
};

InterventionMap.defaultProps = {
  isInsidePopup: false,
  isInteractive: false
};
