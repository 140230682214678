import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import { ModalProvider } from 'hooks';
import 'normalize.css';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import { App } from './App';
import './i18n';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Sentry.init({
  dsn: 'https://21f47c24bbc44ef78506e07bbd3927ac@sentry.io/5022133',
  denyUrls: [
    'http://localhost:3000'
  ]
});

const DATABILAN_THEME = createTheme({
  palette: {
    primary: {
      main: '#0279AC'
    },
    secondary: {
      main: '#148075'
    },
    error: {
      main: '#E6000B'
    }
  },
  typography: {
    htmlFontSize: 10
  }
});

const StyledSnackbarButton = styled.div`
  width: 20px;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
  }
`;

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <StyledSnackbarButton onClick={() => closeSnackbar(snackbarKey)}>
      X
    </StyledSnackbarButton>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={DATABILAN_THEME}>
      <SnackbarProvider
        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        autoHideDuration={100000}
        dense={isMobileOnly}
        disableWindowBlurListener
        hideIconVariant
        maxSnack={3}
        preventDuplicate
        style={{ fontSize: '1.4rem', pointerEvents: 'all' }}
      >
        <ModalProvider>
          <App />
        </ModalProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const triggerSkipWaiting = (SW, state) => {
  if (state === 'installed' || state === 'waiting') {
    SW.postMessage({ type: 'SKIP_WAITING' });
  }
};

const updateSW = (registration) => {
  const newSW = registration.installing || registration.waiting;

  if (newSW) {
    triggerSkipWaiting(newSW, newSW.state);
    newSW.addEventListener('statechange', (event) => {
      triggerSkipWaiting(newSW, event.target.state);

      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });
  }
};

const SWConfig = {
  onUpdate: (registration) => {
    updateSW(registration);

    registration.addEventListener('updatefound', () => updateSW(registration));
  }
};

serviceWorkerRegistration.register(SWConfig);
