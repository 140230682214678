import React, {
  Fragment, useEffect, useState
} from 'react';
import shortid from 'shortid';

import {
  Checkbox, FormControl, FormControlLabel, TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  Datepicker, FlexContainer,
  Question, SelectOperator, SubtitlePage
} from 'components';
import { useStores } from 'hooks';
import { OperatorSummary } from 'models';
import { InterventionFilter } from 'models/request/list/InterventionFilter';
import Select from 'react-select';
import { OperatorsService } from 'services';
import { INTERVENTION_STATUS } from 'utils/constants';
import {
  FilterHelper, formatDate, translate
} from 'utils/helpers';
import { SelectItem } from 'utils/types';

export const InterventionListFilters = ({
  currentFilters, setCurrentFilters
}) => {
  const { fluidStore } = useStores();
  const [operators, setOperators] = useState<OperatorSummary[]>([]);

  const statusList = Object.values(INTERVENTION_STATUS)
    .filter((status: any) => (status.key !== 'CANCELED' && status.key !== 'TO_SYNCHRONIZE'))
    .map(status => status);

  useEffect(() => {
    OperatorsService.getOptions().then(res => setOperators(res));
    // eslint-disable-next-line
  }, []);

  const handleChangeInput = (
    key: keyof InterventionFilter,
    value: any,
    label: string
  ) => {
    setCurrentFilters(FilterHelper.setFiltersValue(currentFilters, key, value, label));
  };

  const toOperatorSummary: (operator: SelectItem) => OperatorSummary = (operator) => (operator && {
    id: operator.value,
    fullName: operator.label
  });

  return (
    <Fragment>
      <section>
        <SubtitlePage>{translate('common.status')}</SubtitlePage>
        <FormControl>
          {statusList && statusList.map(status => (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={!!(FilterHelper.getFiltersValue(currentFilters, 'statuses') && FilterHelper.getFiltersValue(currentFilters, 'statuses').find(i => i.value === status.key))}
                  value={status.key}
                  onChange={(_, isChecked) => {
                    const currentStatuses: SelectItem[] = FilterHelper.getFiltersValue(currentFilters, 'statuses') || [];
                    if (isChecked) {
                      handleChangeInput('statuses', [
                        ...currentStatuses,
                        {
                          value: status.key,
                          label: translate(status.label)
                        }
                      ], 'statuses');
                    } else {
                      handleChangeInput('statuses', [...currentStatuses.filter(i => i.value !== status.key)], 'statuses');
                    }
                  }}
                />
              )}
              key={shortid.generate()}
              label={translate(status.label)}
            />
          ))}
        </FormControl>
      </section>
      <section>
        <SubtitlePage>{`${translate('common.intervention')} :`}</SubtitlePage>

        <FlexContainer flexDirection="column">
          <TextField
            label={translate('common.interventionNumber')}
            name="fileNumber"
            value={FilterHelper.getFiltersValue(currentFilters, 'fileNumber') || ''}
            onChange={event => handleChangeInput('fileNumber', event.target.value, event.target.value)}
          />
          <TextField
            label={translate('common.customerName')}
            name="customerName"
            value={FilterHelper.getFiltersValue(currentFilters, 'customerName') || ''}
            onChange={event => handleChangeInput('customerName', event.target.value, event.target.value)}
          />
          <TextField
            label={translate('common.equipmentModel')}
            name="equipmentModel"
            value={FilterHelper.getFiltersValue(currentFilters, 'equipmentModel') || ''}
            onChange={event => handleChangeInput('equipmentModel', event.target.value, event.target.value)}
          />

          <Question label={`${translate('common.fluidType')} :`}>
            <Select
              id="selectFluid"
              isClearable
              isSearchable
              noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
              options={fluidStore.fluidOptions}
              placeholder={translate('common.searchByName')}
              styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
              value={FilterHelper.getFiltersValue(currentFilters, 'fluidType')}
              onChange={(value) => handleChangeInput('fluidType', value, value?.label)}
            />
          </Question>

          <Question label={`${translate('pageInterventionPlanification.assignedOperator')} :`}>
            <SelectOperator
              options={operators}
              value={toOperatorSummary(FilterHelper.getFiltersValue(currentFilters, 'operator'))}
              onChange={(value) => handleChangeInput('operator', value && { value: value.id, label: value.fullName }, value?.fullName)}
            />
          </Question>
        </FlexContainer>

      </section>
      <section>
        <SubtitlePage>{translate('common.customPeriod')}</SubtitlePage>
        <FlexContainer flexDirection="column">
          <Datepicker
            dataCy="interventionListFIlterDateStart"
            disabled={!!FilterHelper.getFiltersValue(currentFilters, 'period')}
            label="common.startDate"
            maxDate={FilterHelper.getFiltersValue(currentFilters, 'endDate') || formatDate(new Date())}
            value={FilterHelper.getFiltersValue(currentFilters, 'startDate')}
            onChange={date => handleChangeInput('startDate', date, date)}
          />
          <Datepicker
            dataCy="interventionListFIlterDateEnd"
            disabled={!!FilterHelper.getFiltersValue(currentFilters, 'period')}
            label="common.endDate"
            maxDate={formatDate(new Date())}
            minDate={FilterHelper.getFiltersValue(currentFilters, 'startDate')}
            value={FilterHelper.getFiltersValue(currentFilters, 'endDate')}
            onChange={date => handleChangeInput('endDate', date, date)}
          />
        </FlexContainer>

        <SubtitlePage>{translate('common.definedPeriod')}</SubtitlePage>
        <ToggleButtonGroup
          aria-label="text alignment"
          exclusive
          style={{ margin: '20px 0px' }}
          value={FilterHelper.getFiltersValue(currentFilters, 'period')}
          onChange={(_, value) => {
            let label;
            switch (value) {
              case 'DAY':
                label = translate('button.today');
                break;
              case 'WEEK':
                label = translate('button.thisWeek');
                break;
              case 'MONTH':
                label = translate('button.thisMonth');
                break;
              case 'YEAR':
                label = translate('button.thisYear');
                break;
              default:
                label = '';
                break;
            }
            handleChangeInput('period', value, label);
          }}
        >
          <ToggleButton aria-label="left aligned" value="DAY">
            {translate('button.today')}
          </ToggleButton>
          <ToggleButton aria-label="centered" value="WEEK">
            {translate('button.thisWeek')}
          </ToggleButton>
          <ToggleButton aria-label="right aligned" value="MONTH">
            {translate('button.thisMonth')}
          </ToggleButton>
          <ToggleButton aria-label="justified" value="YEAR">
            {translate('button.thisYear')}
          </ToggleButton>
        </ToggleButtonGroup>

      </section>
    </Fragment>
  );
};
