import React from 'react';
import styled from 'styled-components';

const StyledRequiredSelectHack = styled.input`
  height: 1px;
  width: ${props => props.width};
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  cursor: default;
`;

export const RequiredSelectHack = ({ value = '', width = '100%' }: {value: any, width?: string}) => (
  <StyledRequiredSelectHack
    autoComplete="off"
    required
    tabIndex={-1}
    value={value}
    width={width}
    onChange={() => {}}
  />
);
