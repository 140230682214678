import { FormControl } from '@material-ui/core';
import { CustomTooltip, Text, Warn } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { translate } from 'utils/helpers';

const StyledQuestion = styled.article`
  margin-top: 1.5rem;

  &:first-child {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .MuiFormControl-root {
    display: flex;
    margin-bottom: 0.5rem;
    z-index: initial;

    &.MuiTextField-root {
      flex: 1;
    }
  }
`;

export const Question = ({
  label, color, icon, tooltip, children, required = false, isLoading = false, loadingMessage = ''
}) => (
  <StyledQuestion>
    <FormControl>
      <Text color={color} fontSize="1.6rem" fontWeight={600} margin="0 0 1rem 0">
        {label}
        {required && (<span style={{ color: 'red' }}>*</span>)}
        {icon}
        {tooltip && <CustomTooltip text={translate(tooltip)} />}
        {isLoading && <Warn style={{ display: 'inline' }}>{translate(loadingMessage ?? 'warnings.listReload')}</Warn>}
      </Text>
      {children}
    </FormControl>
  </StyledQuestion>
);

Question.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  tooltip: PropTypes.string
};

Question.defaultProps = {
  color: '',
  icon: null,
  required: false,
  tooltip: ''
};
