const GA_COOKIE = 'gaCookieConsent';

const setGACookie = (consent) => {
  const expirationDate = new Date();
  expirationDate.setMonth(expirationDate.getMonth() + 6);
  document.cookie = `${GA_COOKIE}=${consent}; expires=${expirationDate.toUTCString()}; path='/'`;
};

const getGAConsentCookie = () => document.cookie.split('; ').find((cookie) => cookie.startsWith(`${GA_COOKIE}=`));

const getGAConsent = () => getGAConsentCookie()?.split('=')[1] === 'true';

export const CookiesHelper = {
  setGACookie,
  getGAConsent,
  getGAConsentCookie
};
