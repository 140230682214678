/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { faAirConditioner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, InputAdornment, TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  CustomTooltip, FlexContainer, FormAddress, FormLine, Question, RequiredSelectHack, SkeletonForm, TextError
} from 'components';
import { Datepicker } from 'components/Datepicker/Datepicker';
import { useStores } from 'hooks';
import { ModalProps } from 'hooks/ModalProvider';
import { CircuitView, RefrigerationUnitView } from 'models/detail/RefrigerationUnitView';
import { useFormState } from 'react-use-form-state';
import { EquipmentsService } from 'services';
import shortid from 'shortid';
import { STOCK_ROLES_WITH_INTERVENTION_MANAGER } from 'utils/constants';
import {
  DocumentHelper, InterventionHelper,
  UserHelper,
  translate
} from 'utils/helpers';
import { SelectItem } from 'utils/types';
import { ModalHeader } from './ModalHeader';
import { CustomActionButtons } from './_CustomActionButtons';

export const AddEquipmentModal = ({
  onSubmit,
  onClose,
  defaultValues = new RefrigerationUnitView(),
  equipmentId,
  withPicture = false
}: ModalProps<RefrigerationUnitView> & {
  defaultValues?: RefrigerationUnitView,
  equipmentId?: string,
  withPicture?: boolean
}) => {
  const { fluidStore, userStore } = useStores();
  const [uploadError, setUploadError] = useState('');
  const [picturesAdded, setPicturesAdded] = useState(defaultValues.documentList?.documents);
  const [isLoading, setIsLoading] = useState(false);
  const [installationTypes, setInstallationTypes] = useState<SelectItem[]>([]);
  const [yearError, setYearError] = useState<boolean>(false);
  const [formState, { text, number }] = useFormState<RefrigerationUnitView>(defaultValues);

  const formattedfluidOptionsExtended = fluidStore.fluidExtendedOptions.map(fluid => ({
    value: fluid.id,
    label: fluid.name
  }));

  const checkYear = (year: string | number) => (Number(year) < 1990) || (Number(year) > new Date().getFullYear());

  const canValidate = equipmentId && !formState.values.validated && UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER);

  useEffect(() => {
    if (!equipmentId) {
      return;
    }
    setIsLoading(true);

    EquipmentsService.getEquipmentDetail(equipmentId)
      .then((resp) => {
        if (resp.documentList && resp.documentList.documents) {
          const pictures = resp.documentList.documents.map(document => ({
            name: document.name,
            base64Content: DocumentHelper.getDocumentWithBase64({ ...document, base64Content: document.content })
          }));
          setPicturesAdded(pictures);
        }
        Object.entries(resp).forEach(([key, value]) => formState.setField(key as any, value));
        const selectedFluid = formattedfluidOptionsExtended.find(f => f.value === resp.circuits[0]?.fluid?.id);
        formState.setField('fluid', selectedFluid);
        formState.setField('fluidWeight', resp.circuits[0]?.fluidCapacity);
        if (resp.address && typeof resp.address !== 'string') {
          formState.setField('address1', resp.address?.address1);
          formState.setField('address2', resp.address?.address2);
          formState.setField('city', resp.address?.city);
          formState.setField('country', resp.address?.country);
          formState.setField('postalCode', resp.address?.postalCode);
        }
        setYearError(resp.vehicleYear && checkYear(resp.vehicleYear));
      }).finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [equipmentId]);

  useEffect(() => {
    if (userStore.isOffline) {
      InterventionHelper.loadInstallationTypesStored()
        .then(types => setInstallationTypes(types));
    } else {
      EquipmentsService.getInstallationTypes()
        .then(types => setInstallationTypes(types));
    }
  }, [userStore.isOffline]);

  const handleSelectFluid = (selectedFluid: SelectItem) => formState.setField('fluid', selectedFluid);
  const handleSelectInstallationType = (selectedType: SelectItem) => formState.setField('installationType', selectedType);

  const handleAddPicture = event => (
    DocumentHelper.handleFileUpload({ callback: setPicturesAdded, callbackError: setUploadError, event })
  );

  const handleYearChange = event => {
    event.preventDefault();
    const year: string = event.target.value;
    if (year === '') {
      formState.setField('vehicleYear', null);
      setYearError(false);
    } else if (/^\d+$/.test(year)) {
      formState.setField('vehicleYear', Number(year));
      setYearError(checkYear(year));
    }
  };

  const handleValidateModal = (e) => {
    e.preventDefault();

    if (formState.values.installationType?.value === 'VEHICLE' && yearError) {
      return;
    }

    const selectedFluid = fluidStore.fluidExtendedOptions.find(fluid => fluid.id === formState.values.fluid.value);

    const circuit: CircuitView = {
      ...(formState.values.circuits && formState.values.circuits[0]) ?? {},
      fluid: { ...selectedFluid },
      fluidAmount: Number(formState.values.fluidWeight),
      fluidCapacity: Number(formState.values.fluidWeight)
    };

    onSubmit({
      ...formState.values,
      fluidWeight: Number(formState.values.fluidWeight),
      circuits: [circuit],
      documentList: {
        documents: DocumentHelper.removeAllBase64Prefix(picturesAdded)
      },
      vehicleYear: formState.values.vehicleYear && Number(formState.values.vehicleYear),
      address: formState.values.address1 ? {
        address1: formState.values.address1,
        address2: formState.values.address2,
        postalCode: formState.values.postalCode,
        city: formState.values.city,
        country: formState.values.country
      } : null
    });
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleValidateModal}>
        <ModalHeader onClose={onClose}>
          <FontAwesomeIcon icon={faAirConditioner} />
          {equipmentId ? translate('modalEquipment.editEquipment') : translate('modalEquipment.newEquipment')}
        </ModalHeader>

        {isLoading ? (
          <SkeletonForm />
        ) : (
          <>
            <DialogContent>
              <FlexContainer flexDirection="column">
                {equipmentId && (
                  <ToggleButtonGroup
                    exclusive
                    size="small"
                    style={{ marginBottom: '2rem' }}
                    value={formState.values.activated}
                    onChange={(event, value) => {
                      if (value === null) return;
                      formState.setField('activated', value);
                    }}
                  >
                    <ToggleButton aria-label={translate('common.activated')} value>
                      {translate('common.activated')}
                    </ToggleButton>
                    <ToggleButton aria-label={translate('common.deactivated')} value={false}>
                      {translate('common.deactivated')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                <Question label={translate('modalEquipment.fillFields')}>
                  <TextField
                      autoFocus
                      error={!!formState.errors.brand}
                      fullWidth
                      label={translate('common.brand')}
                      name="brand"
                      {...text('brand')}
                    />
                  <TextField
                    error={formState.errors.model && formState.errors.model !== null}
                    label={translate('common.model')}
                    name="model"
                    required
                    {...text('model')}
                  />
                  <TextField
                    error={formState.errors.serialNumber && formState.errors.serialNumber !== null}
                    label={translate('common.serialNumber')}
                    name="serialNumber"
                    required
                    {...text('serialNumber')}
                  />
                  {formattedfluidOptionsExtended && formattedfluidOptionsExtended.length > 0 && (
                    <Question label={translate('common.fluidType')}>
                      <Select
                        id="addEquipmentFluid"
                        isClearable
                        isSearchable
                        menuPortalTarget={document.body}
                        noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                        options={formattedfluidOptionsExtended}
                        placeholder={translate('common.searchByFluidType')}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                        value={formState.values.fluid || ''}
                        onChange={handleSelectFluid}
                      />
                      <RequiredSelectHack value={formState.values.fluid || ''} />
                    </Question>
                  )}
                  <TextField
                    autoComplete="new-password"
                    error={formState.errors.fluidWeight && formState.errors.fluidWeight !== null}
                    inputProps={{
                      step: '0.01',
                      min: 0
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{translate('common.weightUnit')}</InputAdornment>
                    }}
                    label={translate('common.fluidQuantity')}
                    name="fluidWeight"
                    required
                    type="number"
                    {...number('fluidWeight')}
                  />
                  <FormLine>
                    <TextField
                      autoComplete="new-password"
                      error={formState.errors.internalIdentification && formState.errors.internalIdentification !== null}
                      label={translate('common.internalIdentification')}
                      name="internalIdentification"
                      {...text('internalIdentification')}
                    />
                    <CustomTooltip size="sm" text={translate('common.searchByModelTruckNumber')} />
                  </FormLine>
                  <FormLine>
                    <Datepicker
                      disableFuture
                      label="common.lastControlDate"
                      name="lastControlDate"
                      value={formState.values.lastControlDate}
                      onChange={value => formState.setField('lastControlDate', value)}
                    />
                  </FormLine>
                  {installationTypes && installationTypes.length > 0 && (
                    <Question label={translate('common.installationType')}>
                      <Select
                        id="addInstallationType"
                        isClearable
                        isSearchable
                        menuPortalTarget={document.body}
                        noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                        options={installationTypes}
                        placeholder={translate('common.selectType')}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
                        value={formState.values.installationType || ''}
                        onChange={handleSelectInstallationType}
                      />
                    </Question>
                  )}
                  {formState.values.installationType?.value === 'VEHICLE' && (
                    <>
                      <FormLine>
                        <TextField
                          error={formState.errors.vehicleType && formState.errors.vehicleType !== null}
                          label={translate('common.vehicleType')}
                          name="vehicleType"
                          {...text('vehicleType')}
                        />
                      </FormLine>
                      <FormLine>
                        <TextField
                          error={formState.errors.vehicleBrand && formState.errors.vehicleBrand !== null}
                          label={translate('common.vehicleBrand')}
                          name="vehicleBrand"
                          {...text('vehicleBrand')}
                        />
                      </FormLine>
                      <FormLine>
                        <TextField
                          error={formState.errors.vehicleModel && formState.errors.vehicleModel !== null}
                          label={translate('common.vehicleModel')}
                          name="vehicleModel"
                          {...text('vehicleModel')}
                        />
                      </FormLine>
                      <FormLine>
                        <TextField
                          autoComplete="new-password"
                          error={yearError}
                          inputProps={{
                            step: '1',
                            min: 1990
                          }}
                          label={translate('common.vehicleYear')}
                          type="text"
                          value={formState.values.vehicleYear ?? ''}
                          onChange={e => handleYearChange(e)}
                        />
                      </FormLine>
                      <FormLine>
                        <TextField
                          error={formState.errors.vehicleImat && formState.errors.vehicleImat !== null}
                          label={translate('common.vehicleImat')}
                          name="vehicleImat"
                          {...text('vehicleImat')}
                        />
                      </FormLine>
                      <FormLine>
                        <TextField
                          error={formState.errors.parcNumber && formState.errors.parcNumber !== null}
                          label={translate('common.parcNumber')}
                          name="parcNumber"
                          {...text('parcNumber')}
                        />
                      </FormLine>
                    </>
                  )}
                  <FormAddress
                    formState={formState}
                    isRequired={false}
                    text={text}
                    withCountry
                  />
                  <TextField
                    label={translate('common.comment')}
                    multiline
                    name="comment"
                    {...text('comment')}
                  />
                  {withPicture && (
                    <Question label={translate('modalEquipment.equipmentPicture')}>
                      <input
                        accept="image/*"
                        placeholder={translate('modalEquipment.equipmentPictureAdd')}
                        type="file"
                        onChange={handleAddPicture}
                      />
                      {uploadError && <TextError>{uploadError}</TextError>}
                      {picturesAdded && picturesAdded.length > 0 && (
                        <FlexContainer className="modalPicturesContainer">
                          {picturesAdded.map((picture) => {
                            if (picture.base64Content && picture.base64Content.includes('data:image')) {
                              return <img alt={picture.name} key={shortid.generate()} src={picture.base64Content} />;
                            }

                            return <img alt={picture.name} key={shortid.generate()} src={DocumentHelper.getImageWithBase64(picture)} />;
                          })}
                        </FlexContainer>
                      )}
                    </Question>
                  )}
                </Question>
              </FlexContainer>
            </DialogContent>

            <CustomActionButtons
              forValidation={canValidate}
              isLoading={isLoading}
              onClose={onClose}
            />
          </>
        )}
      </form>
    </>
  );
};
