import React, { useEffect, useState } from 'react';

import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Card, CustomPieChart, TextError
} from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { StatsService } from 'services';
import {
  PAGE_ADMIN_TABS, PAGE_STOCKS_TABS, ROUTES,
  STOCK_ROLES_WITH_INTERVENTION_MANAGER
} from 'utils/constants';
import { translate, UserHelper } from 'utils/helpers';

export const CardValidations = observer(() => {
  const { userStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [allValidations, setAllValidations] = useState([]);

  const VALIDATION_LISTS = {
    LEGAL_ENTITY: {
      color: 'var(--green-dark)',
      url: {
        pathname: ROUTES.ADMINISTRATION,
        state: {
          filters: [{
            key: 'validated',
            booleanValue: false,
            label: translate('common.unvalidated')
          }]
        }
      }
    },
    EQUIPMENT: {
      color: 'var(--turquoise-dark)',
      url: {
        pathname: ROUTES.ADMINISTRATION,
        state: {
          filters: [{
            key: 'validated',
            booleanValue: false,
            label: translate('common.unvalidated')
          }]
        }
      }
    },
    TOOL: {
      color: 'var(--blue-dark)',
      url: {
        pathname: ROUTES.ADMINISTRATION_TOOLS,
        state: {
          tabSelected: PAGE_ADMIN_TABS.TOOLS.tabIndex,
          filters: [{
            key: 'validated',
            booleanValue: false,
            label: translate('common.unvalidated')
          }]
        }
      }
    },
    FLUID_BOTTLE: {
      color: 'var(--violet-dark)',
      url: {
        pathname: ROUTES.STOCKS_CONTAINERS,
        state: {
          tabSelected: PAGE_STOCKS_TABS.BOTTLES.tabIndex,
          filters: [{
            key: 'validated',
            booleanValue: false,
            label: translate('common.unvalidated')
          }]
        }
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    StatsService.getValidations().then((response) => {
      const CHART_DATA = [];

      if (response) {
        response.forEach((validation) => {
          if (validation.number > 0) {
            CHART_DATA.push({
              name: translate(`pageHome.cardValidations.${validation.type}`),
              value: validation.number,
              fill: VALIDATION_LISTS[validation.type].color,
              url: VALIDATION_LISTS[validation.type].url
            });
          }
        });
      }

      setAllValidations(CHART_DATA);
    }).finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, []);

  if (userStore.isOffline || !allValidations || !UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER)) {
    return null;
  }

  return (
    <Card
      dataTour="step-validations"
      title={translate('pageHome.cardValidations.title')}
    >
      {isLoading && <FontAwesomeIcon color="var(--blue)" icon={faSpinner} size="2x" spin />}
      {!isLoading && allValidations.length === 0
        ? <TextError>{translate('errors.noValidations')}</TextError>
        : !isLoading && <CustomPieChart chartData={allValidations} />
      }
    </Card>
  );
});
