import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@material-ui/core';
import React from 'react';

export const AdornmentLoader = () => (
  <InputAdornment position="end">
    <FontAwesomeIcon icon={faSpinner} spin />
  </InputAdornment>
);
