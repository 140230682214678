import {
  Button, DialogActions, DialogContent, TableCell
} from '@material-ui/core';
import { FontIcon, GenericTable } from 'components';
import i18n from 'i18next';
import { FluidVariationSummary } from 'models';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { FluidsService } from 'services';
import shortid from 'shortid';
import { formatDate, translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

const getListHeadersTable = () => [
  {
    name: 'type',
    label: translate('common.type'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {`${i18n.exists(`manipulations.${row.type}`) ? translate(`manipulations.${row.type}`) : translate(`mouvements.${row.type}`)}`}
      </TableCell>
    )
  }, {
    name: 'variationDate',
    label: translate('common.date'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.variationDate ? formatDate(row.variationDate) : '-'}
      </TableCell>
    )
  }, {
    name: 'variation',
    label: translate('common.variation'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.variation > 0 ? '+' : ''}
        {`${row.variation} ${translate('common.weightUnit')}`}
      </TableCell>
    )
  }, {
    name: 'userName',
    label: translate('common.userName'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.userName || '-'}
      </TableCell>
    )
  }, {
    name: 'comment',
    label: translate('common.comment'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.comment || '-'}
      </TableCell>
    )
  }
];

export const BottleVariationsModal = ({
  onClose, bottle
}) => {
  const [variationList, setVariationList] = useState([]);

  useEffect(() => {
    FluidsService.getFluidVariations(bottle.id)
      .then((response) => {
        setVariationList(response);
      });
  }, [bottle]);

  return (
    <Fragment>
      <ModalHeader onClose={onClose}>
        <FontIcon icon="icon-container" />
        {`${translate('pageBottleDetail.title')} - ${translate('common.bottle')} ${bottle.identifier}`}
      </ModalHeader>

      <DialogContent>
        <GenericTable<FluidVariationSummary>
          dataCy="variationList"
          headers={getListHeadersTable()}
          rows={variationList}
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {translate('button.close')}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

BottleVariationsModal.propTypes = {
  bottle: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired
};
