import { API_URL, APP_NAME } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const resourceUrl = `${API_URL}resource-tutorials`;

// POST
const getResource: (resourceIdentifier: string) => Promise<any> = resourceIdentifier => RequestHelper.GET(`${resourceUrl}/${APP_NAME}_${resourceIdentifier}`);

export const ResourceTutorialsService = {
  getResource
};
