import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { TableCell } from '@material-ui/core';
import {
  BottleFilledIcon, Card, CustomTooltip, FlexContainer, GenericTable, SkeletonCard, TextError
} from 'components';
import { CardTitle } from 'components/Card/Card';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { BottleView } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import shortid from 'shortid';
import styled from 'styled-components';
import { APPLICATION_ROLES, BOTTLE_TYPES } from 'utils/constants';
import {
  BottleHelper, translate, UserHelper
} from 'utils/helpers';
import { HeaderType } from 'utils/types';

const TableContainer = styled.div`
  max-height: 400px;
  overflow: auto;
`;

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;
  z-index: var(--zindexSmall);

  & > div {
    min-width: 250px;

    &:not(:last-of-type) {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

const getListHeadersTable: () => HeaderType<BottleView>[] = () => [
  {
    name: 'type',
    label: translate('common.type'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {BottleHelper.getTranslatedType({
          type: row.bottleType.key,
          isNewFluid: row.isNewFluid
        })}
      </TableCell>
    )
  }, {
    name: 'identifier',
    label: translate('common.identifier'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.identifier}
        {row.hasWarning && (
          <CustomTooltip
            icon={faInfoCircle}
            size="xs"
            text={translate('warnings.bottle.modifiedCanHaveLeak')}
          />
        )}
      </TableCell>
    )
  }, {
    name: 'fluidLabel',
    label: translate('common.fluid'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.fluid ? row.fluid.label : '-'}
      </TableCell>
    )
  }, {
    name: 'theoricalAmount',
    label: translate('common.fluidQuantityCapacity'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <FlexContainer>
          <BottleFilledIcon
            bottleType={row.bottleType}
            maximumCapacity={row.maximumCapacity}
            theoricalAmount={row.theoricalAmount}
          />
          &nbsp;
          {`${row.theoricalAmount || 0}${translate('common.weightUnit')}${row.maximumCapacity ? ` (${translate('common.capacity')} : ${row.maximumCapacity}${translate('common.weightUnit')})` : ''}`}
        </FlexContainer>
      </TableCell>
    )
  }
];

export const CardBottleList = observer(() => {
  const { fluidStore, userStore } = useStores();
  const history = useHistory();
  const [bottleTypeFilter, setBottleTypeFilter] = useState(null);
  const [bottleFluidFilter, setBottleFluidFilter] = useState(null);
  const [bottleList, setBottleList] = useState<BottleView[]>([]);

  useEffect(() => {
    fluidStore.loadFluidBottleExtendedOptions();
  }, [fluidStore]);

  useEffect(() => {
    const bottles = fluidStore.bottleExtendedOptions;
    if (bottles) {
      let newBottleList = [...bottles];

      if (bottleFluidFilter && bottleTypeFilter) {
        newBottleList = [...bottles]
          .filter(bottle => bottleFluidFilter && bottleFluidFilter.value === (bottle.fluid && bottle.fluid.key))
          .filter(bottle => bottleTypeFilter && bottleTypeFilter.value === (bottle.bottleType && bottle.bottleType.key));
      } else if (bottleFluidFilter) {
        newBottleList = [...bottles].filter(bottle => bottleFluidFilter && bottleFluidFilter.value === (bottle.fluid && bottle.fluid.key));
      } else if (bottleTypeFilter) {
        newBottleList = [...bottles].filter(bottle => bottleTypeFilter && bottleTypeFilter.value === (bottle.bottleType && bottle.bottleType.key));
      }

      setBottleList(newBottleList);
    }
  }, [fluidStore.bottleExtendedOptions, bottleTypeFilter, bottleFluidFilter]);

  const handleChangeBottleType = useCallback(value => setBottleTypeFilter(value), []);

  const handleChangeBottleFluid = useCallback(value => setBottleFluidFilter(value), []);

  const handleOnRowClick = useCallback((row: BottleView) => {
    if (UserHelper.hasAccessRight([
      APPLICATION_ROLES.SUPER_ADMIN,
      APPLICATION_ROLES.ADMIN,
      APPLICATION_ROLES.DISTRIBUTOR_ADMIN,
      APPLICATION_ROLES.DISTRIBUTOR_STOCK_MANAGER,
      APPLICATION_ROLES.DISTRIBUTOR_GENERAL_MANAGER,
      APPLICATION_ROLES.INTERVENTION_MANAGER])) {
      return history.push(`/bouteilles/${row.id}`);
    }

    return null;
  }, [history]);

  if (fluidStore.isLoadingBottles) {
    return <SkeletonCard />;
  }

  if (userStore.isOffline) {
    return null;
  }

  return (
    <Card
      background="var(--white)"
      dataTour="step-bottles"
      maxWidth="none"
      titleComponent={(
        <FlexContainer alignItems="flex-start" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          <CardTitle>{translate('pageHome.cardBottleList.title')}</CardTitle>
          <SelectContainer>
            <Select
              id="selectBottleTypeFilter"
              isClearable
              menuPlacement={isMobileOnly ? 'top' : 'bottom'}
              options={BOTTLE_TYPES.map(type => ({
                value: type.key,
                label: BottleHelper.getTranslatedType({ type: type.key })
              }))}
              placeholder={translate('common.selectType')}
              value={bottleTypeFilter || ''}
              onChange={handleChangeBottleType}
            />
            <Select
              id="selectBottleFluidFilter"
              isClearable
              menuPlacement={isMobileOnly ? 'top' : 'bottom'}
              noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
              options={fluidStore.fluidOptions}
              placeholder={translate('common.selectFluid')}
              value={bottleFluidFilter || ''}
              onChange={handleChangeBottleFluid}
            />
          </SelectContainer>
        </FlexContainer>
      )}
    >
      <TableContainer>
        {bottleList && bottleList.length > 0 ? (
          <GenericTable<BottleView>
            dataCy="cardBottleList"
            headers={getListHeadersTable()}
            rows={bottleList.map(bottle => ({
              ...bottle,
              value: bottle.id,
              label: bottle.identifier
            }))}
            onRowClick={handleOnRowClick}
          />
        ) : <TextError>{translate('errors.noBottle')}</TextError>}
      </TableContainer>
    </Card>
  );
});
