import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledBlackLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, ${props => props.opacity});
`;

export const BlackLayer = ({ opacity }) => (
  <StyledBlackLayer opacity={opacity} />
);

BlackLayer.propTypes = {
  opacity: PropTypes.number
};

BlackLayer.defaultProps = {
  opacity: 0.12
};
