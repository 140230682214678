import PropTypes from 'prop-types';
import React from 'react';

import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconButton, Tooltip } from '@material-ui/core';

export const CustomTooltip = ({
  text, size, color, icon
}) => (
  <Tooltip
    disableFocusListener
    title={text}
  >
    <IconButton aria-label="informations" size={size === 'xs' ? 'small' : 'medium'}>
      <FontAwesomeIcon color={color} icon={icon} size={size} />
    </IconButton>
  </Tooltip>
);

CustomTooltip.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.shape({}),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]).isRequired,
  size: PropTypes.string
};

CustomTooltip.defaultProps = {
  color: 'var(--blue)',
  icon: faQuestionCircle,
  size: null
};
