import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledFontIcon = styled.span`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  margin-top: ${props => props.marginTop};
  text-decoration: none;
`;

export const FontIcon = ({
  color, fontSize, icon, marginLeft, marginRight, marginTop
}) => (
  (icon === 'icon-container-03' || icon === 'icon-container-13' || icon === 'icon-container-23')
    ? (
      <StyledFontIcon
        className={icon}
        color={color}
        fontSize={fontSize}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginTop={marginTop}
      >
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="path4" />
        <span className="path5" />
      </StyledFontIcon>
    )
    : (
      <StyledFontIcon
        className={icon}
        color={color}
        fontSize={fontSize}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginTop={marginTop}
      />
    )
);

FontIcon.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  icon: PropTypes.oneOf([
    'icon-stocks',
    'icon-create-intervention',
    'icon-hazard',
    'icon-leak-detector',
    'icon-container',
    'icon-container-03',
    'icon-container-13',
    'icon-container-23',
    'icon-assistant',
    'icon-intervention',
    'icon-scan',
    'icon-send-by-letter',
    'icon-send-by-mail'
  ]).isRequired,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  marginTop: PropTypes.string
};

FontIcon.defaultProps = {
  color: 'inherit',
  fontSize: '2rem',
  marginLeft: null,
  marginRight: null,
  marginTop: null
};
