import { ListItem } from '@material-ui/core';
import { FormElement } from 'components';
import React, { useCallback } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { translate } from 'utils/helpers';

export const StyledListItem = styled(({ children, ...props }) => <ListItem {...props}>{children}</ListItem>)`
  cursor: pointer;

  &:hover {
    ${props => !props.disabled && css`
      background-color: #DEEBFF
    `}
  }
`;

const StyledRequiredSelectHack = styled.input`
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  cursor: default;
`;

export const SelectField = ({
  closeMenuOnSelect = true,
  components,
  isClearable = true,
  isDisabled = false,
  isMulti = false,
  isSearchable = true,
  label = '',
  name,
  noOptionsMessage,
  onChange,
  options = [],
  placeholder = 'common.selectOption',
  required = false,
  value,
  width = '100%'
}: {
  closeMenuOnSelect?: boolean;
  components?,
  id?: string;
  isClearable?: boolean;
  isDisabled?: boolean,
  isMulti?: boolean,
  isSearchable?: boolean,
  label: string;
  name: string;
  noOptionsMessage?;
  onChange;
  options?: any[];
  placeholder?: string;
  required?: boolean;
  value;
  width?: string;
}) => {
  const displayNoOptions = useCallback(() => translate('warnings.noOptionsAvailable'), []);

  return (
    <FormElement label={label && translate(label)} required={required} shrinked>
      <Select
        closeMenuOnSelect={closeMenuOnSelect}
        components={components}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        menuPortalTarget={document.body}
        menuPosition='fixed'
        name={name}
        noOptionsMessage={noOptionsMessage || displayNoOptions}
        options={options}
        placeholder={placeholder && translate(placeholder)}
        required={required}
        styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
        value={value || {}}
        onChange={onChange}
      />
      {required && (
        <StyledRequiredSelectHack
          autoComplete="off"
          defaultValue={value || {}}
          required
          tabIndex={-1}
          width={width}
        />
      )}
    </FormElement>
  );
};
