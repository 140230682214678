import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import Media from 'react-media';
import styled from 'styled-components';

import {
  faArrowLeft, faBars, faGlobe, faSpinner, faSync
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faMobileAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Fab, Grid, IconButton, Tooltip
} from '@material-ui/core';
import { ButtonText, Text } from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { isChrome, isFirefox, isMobileOnly } from 'react-device-detect';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UsersService } from 'services';
import shortid from 'shortid';
import { DISTRIBUTOR_ROLES, MENU_ITEMS, ROUTES } from 'utils/constants';
import {
  BrowserDetectionHelper, InterventionHelper,
  translate, UserHelper
} from 'utils/helpers';
import { SelectItem } from 'utils/types';
import { StructureLevelOption } from 'utils/types/StructureLevelOption';
import { MenuItem } from './MenuItem';
import { SideMenu } from './SideMenu/SideMenu';

const HeaderContainer = styled.div`
  position: relative;
  height: var(--header-height);
  background: var(--tecnea-blue) url('/assets/images/bg/header-decoration.png');
  background-position: var(--header-item-width) center;
  background-repeat: no-repeat;
  overflow: hidden;

  @media (max-width: 820px) {
    div p {
      display: none;
    }
  }
`;

const UserName = styled.span`
  display: inline-block;
  max-width: 140px;
  color: var(--white);
  margin-right: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 820px) {
    max-width: 110px;
  }
`;

const CustomGridItem = styled(Grid)`
  position: relative;
  width: 250px;
  height: var(--header-height);
  padding: 0 1rem;
  background: var(--white);

  img {
    width: 90%;
    max-height: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -120px;
    border-left: 60px solid var(--white);
    border-right: 60px solid transparent;
    border-bottom: 60px solid transparent;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const HeaderTitle = styled.h1`
  margin: 0 auto;

  img {
    height: 25px;
  }

  @media (max-width: 768px) {
    img {
      margin-left: 1rem;
    }

    button {
      padding: 6px;
    }
  }
`;

const StyledButtonMenu = styled(Fab).attrs({
  size: 'small'
})`
  position: relative;

  .buttonMenuIcon {
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: var(--blue);
    background: var(--white);

    svg {
      margin-bottom: 2px;
      color: var(--blue);
    }
  }
`;

const HeaderMenu = styled.aside`
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);

  button {
    flex: 1;

    &:not(:last-of-type) {
      border-right: 1px solid #e6e6e6;
    }
  }
`;

const ButtonChangeLanguage = styled(IconButton)`
  &:disabled {
    svg {
      display: none;
    }
  }

  span {
    font-size: 1.7rem;
    color: var(--white);
  }
`;

const ROUTES_WITHOUT_BACK_ARROW = [
  '',
  '/accueil',
  '/interventions',
  '/administration/detenteurs',
  '/administration/outillages',
  '/administration/fournisseurs-clients',
  '/administration/utilisateurs',
  '/administration/etablissements',
  '/administration/track-dechets',
  '/stocks/stats',
  '/stocks/initialisation',
  '/stocks/receptions',
  '/stocks/fluides',
  '/stocks/bouteilles',
  '/stocks/validation',
  '/stocks/bilan'
];

export const Header = observer(({
  deviceId, downloadInterventionsAndLists, isDBopen, isDownloadingInterventions
}: {
  deviceId: string;
  downloadInterventionsAndLists: (mobileId: any) => void;
  isDBopen: boolean;
  isDownloadingInterventions: boolean;
}) => {
  const { i18nStore, userStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const { open } = useModal();
  const history = useHistory();
  const { pathname } = useLocation();

  const [toggleMenu, setToggleMenu] = useState(false);
  const [isLevelUpdating, setLevelUpdating] = useState(false);

  const displayBackArrow = pathname.indexOf('/inspection') !== -1 ? false : !ROUTES_WITHOUT_BACK_ARROW.includes(pathname);

  const handleUpdateLevel = (selectedLevel: StructureLevelOption) => {
    // Only update if the level is different from the current one
    if (userStore.currentLevel && userStore.currentLevel.value !== selectedLevel.value) {
      setLevelUpdating(true);
      UsersService.updateLevel(selectedLevel).then(() => {
        history.push('/accueil');
        userStore.keycloak.updateToken(999999)
          .then((refreshed) => {
            refreshed && userStore.keycloak.login();
          })
          .catch(() => enqueueSnackbar(translate('errors.noRefreshToken'), { variant: 'error' }));
      });
    }
  };

  const updateUserLevel = useCallback(() => {
    isDBopen && InterventionHelper.getStoredInterventionList().then((intList) => {
      if (userStore.accessibleLevels.length > 1 && !localStorage.getItem('isLevelSet') && intList.length === 0) {
        open({
          type: 'UPDATE_USER_LEVEL',
          defaultLevel: userStore.currentLevel,
          levelOptions: userStore.accessibleLevels,
          onConfirm: handleUpdateLevel
        });
      }
    });
    // eslint-disable-next-line
  }, [isDBopen, userStore.accessibleLevels, open]);

  const displayPopupBrowserModal = useCallback(() => open({
    type: 'BROWSER_POPUP',
    title: translate('warnings.warning'),
    primaryText: translate('modalBrowser.popupWarningBrowserPrimary'),
    secondaryText: translate('modalBrowser.popupWarningBrowserSecondary'),
    onConfirm: updateUserLevel
    // eslint-disable-next-line
  }), [open]);

  useEffect(() => {
    if (userStore.isIdentified && (!isChrome || !isFirefox)) {
      BrowserDetectionHelper.modalDisplayControl(displayPopupBrowserModal);
    } else {
      updateUserLevel();
    }
    // eslint-disable-next-line
  }, [displayPopupBrowserModal, userStore.isIdentified]);

  const handleDownloadInterventionsAndLists = useCallback((e) => {
    e.preventDefault();
    downloadInterventionsAndLists(deviceId);
  }, [downloadInterventionsAndLists, deviceId]);

  const handleChangeLanguage = useCallback((language: SelectItem) => {
    if (language) {
      if (language.value !== localStorage.getItem('i18nextLng')) {
        // Update the locale and refresh the user. The page reload will automatically load the new translations.
        localStorage.setItem('i18nextLng', language.value);
        UsersService.updateLocale(language.value).then(() => {
          userStore.keycloak.updateToken(999999)
            .then((refreshed) => {
              refreshed && userStore.keycloak.login();
            })
            .catch(() => enqueueSnackbar(translate('errors.noRefreshToken'), { variant: 'error' }));
        })
          .catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }));
      } else {
        // If the language isn't changed, reload the translations just in case
        i18nStore.loadLanguage(language.value);
      }
    }
  }, [userStore.keycloak, enqueueSnackbar, i18nStore]);

  const displayModalLanguage = useCallback(() => open({
    type: 'CHANGE_LANGUAGE',
    onConfirm: (language: SelectItem) => handleChangeLanguage(language),
    defaultValues: i18nStore.currentLanguage
  }), [handleChangeLanguage, i18nStore.currentLanguage, open]);

  const isIntervention = useCallback(() => pathname.indexOf('/inspection') === -1, [pathname]);

  const ButtonUpdateLevel = ({ label }) => {
    if (userStore.isOffline) return label;
    if (userStore.accessibleLevels.length <= 1) return label;

    const onClick = () => open({
      type: 'UPDATE_USER_LEVEL',
      defaultLevel: userStore.currentLevel,
      levelOptions: userStore.accessibleLevels,
      onConfirm: handleUpdateLevel
    });

    return (
      <ButtonText style={{ margin: 0 }} onClick={onClick}>
        {label}
      </ButtonText>
    );
  };

  return (
    <header>
      <HeaderContainer>
        <Grid alignItems="center" container justifyContent="space-between" wrap="nowrap">
          <CustomGridItem item>
            <Link to={ROUTES.HOME}>
              <Grid alignItems="center" container wrap="nowrap">
                {displayBackArrow && (
                  <IconButton
                    aria-label="back"
                    color="primary"
                    edge="start"
                    style={{ marginRight: '1rem' }}
                    onClick={() => { setToggleMenu(false); history.goBack(); }}
                  >
                    <FontAwesomeIcon color="var(--grey-dark)" icon={faArrowLeft} />
                  </IconButton>
                )}
                <img alt="TECNEA" src={`${process.env.PUBLIC_URL}/assets/images/icons/companies/tecnea.svg`} />
              </Grid>
            </Link>
          </CustomGridItem>
          <Grid item>
            <Link to={ROUTES.HOME} onClick={() => setToggleMenu(false)}>
              <HeaderTitle>
                <img alt="Databilan" src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
                {!userStore.isOffline && deviceId && (
                  <Tooltip disableFocusListener title={translate('pageHome.downloadInterventionsTooltip')}>
                    <span>
                      <IconButton disabled={isDownloadingInterventions} onClick={handleDownloadInterventionsAndLists}>
                        <FontAwesomeIcon color="var(--white)" icon={faMobileAlt} />
                        <FontAwesomeIcon color="var(--white)" icon={faSync} spin={isDownloadingInterventions} transform="shrink-8 up-5" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </HeaderTitle>
            </Link>
          </Grid>
          <Grid item style={{ paddingRight: '1rem', minWidth: 'var(--header-item-width)' }}>
            <Grid container style={{ height: 'var(--header-height)', alignItems: 'center' }} wrap="nowrap">
              {UserHelper.hasAccessRight(DISTRIBUTOR_ROLES) && (
                <Grid item>
                  <Media
                    query="(min-width: 1000px)"
                    render={() => (
                      <Text color="var(--white)" display="inline-block" fontWeight="bold" margin="0 1rem 0 0" verticalAlign="middle">
                        {translate('common.distributorSpace')}
                      </Text>
                    )}
                  />
                </Grid>
              )
              }
              {i18nStore.languageList.length > 1 && (
                <Grid item style={{ paddingRight: isMobileOnly ? 0 : '2rem' }}>
                  <Tooltip disableFocusListener title={translate('modalChangeLanguage.tooltip', { language: i18nStore.currentLanguage.toUpperCase() })}>
                    <span>
                      <ButtonChangeLanguage onClick={displayModalLanguage}>
                        <FontAwesomeIcon color="#ffffff" icon={faGlobe} size="sm" />
                        <span className="mr1 ml1">{i18nStore.currentLanguage.split('_')[0].toUpperCase()}</span>
                        <FontAwesomeIcon color="#ffffff" icon={faChevronDown} size="xs" />
                      </ButtonChangeLanguage>
                    </span>
                  </Tooltip>
                </Grid>
              )}
              <Grid item>
                <Media
                  query="(min-width: 640px)"
                  render={() => (
                    <Text color="var(--white)" display="inline-block" margin="0 1rem 0 0" verticalAlign="middle">
                      {userStore.currentUser && <UserName>{userStore.getUserName()}</UserName>}
                      {isIntervention() ? (
                        <Fragment>
                          <br />
                          {isLevelUpdating ? (
                            <FontAwesomeIcon color="var(--white)" icon={faSpinner} size="sm" spin />
                          ) : (
                            <ButtonUpdateLevel label={userStore.getLevelName()} />
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <br />
                          {userStore.getLevelName()}
                        </Fragment>
                      )}
                    </Text>
                  )}
                />
                <StyledButtonMenu color="primary" data-cy="headerMenu" data-tour="step-header-user" onClick={() => setToggleMenu(!toggleMenu)}>
                  <span>{userStore.getUserInitials()}</span>
                  <span className="buttonMenuIcon">
                    <FontAwesomeIcon icon={faBars} size="xs" />
                  </span>
                </StyledButtonMenu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <SideMenu isOpen={toggleMenu} setToggleMenu={setToggleMenu} />
      </HeaderContainer>
      {isIntervention() && (
        <Media
          query="(min-width: 1081px)"
          render={() => (
            <HeaderMenu>
              {MENU_ITEMS.map((item, index) => <MenuItem dataTour={`step-header-menu-${index}`} key={shortid.generate()} {...item} />)}
            </HeaderMenu>
          )}
        />
      )}
    </header>
  );
});
