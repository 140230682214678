import { faSignOutAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useStores } from 'hooks';
import React from 'react';
import { Link } from 'react-router-dom';
import { deleteStoredFilters, translate } from 'utils/helpers';

export const Logout = () => {
  const { userStore } = useStores();
  const logOutUser = () => {
    userStore.disconnectUser();
    localStorage.removeItem('isLevelSet');
    deleteStoredFilters();
  };

  return (
    <ListItem
      button
      component={Link}
      data-cy="headerMenuLogout"
      to=""
      onClick={() => logOutUser()}
    >
      <ListItemIcon><FontAwesomeIcon icon={faSignOutAlt} size="lg" /></ListItemIcon>
      <ListItemText primary={translate('menu.disconnect')} />
    </ListItem>
  );
};
