import React from 'react';
import styled from 'styled-components';

import { ROUTES } from 'utils/constants';

import { Card, FlexContainer, Text } from 'components';
import { useStores } from 'hooks';

const CardProfileText = styled.div`
  color: var(--black);
  font-family: var(--fontRoboto);
`;

export const CardProfile = () => {
  const { userStore } = useStores();
  return (
    <Card buttonCyData="goToProfile" hasArrow link={ROUTES.USERS.DETAIL}>
      <FlexContainer alignItems="flex-start">

        <CardProfileText>
          <Text fontSize="1.8rem" fontWeight={600} margin="0 0 0.5rem 0" textTransform="uppercase">
            {userStore.getUserName()}
          </Text>
          <Text fontSize="1.4rem" margin="0">
            {userStore.currentUser.person?.contact?.contactEmails}
          </Text>
        </CardProfileText>
      </FlexContainer>
    </Card>
  );
};
