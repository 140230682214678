import { FluidMovementSummary, FluidMovementView } from 'models';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

const fluidsMovementsUrl = `${API_URL}fluid-movements`;
const pendingMovementsUrl = `${API_URL}td-receptions`;

// GET
const getFluidMovement: (movementId: string) => Promise<FluidMovementView> = (movementId) => RequestHelper.GET(`${fluidsMovementsUrl}/${movementId}`);

const getBsd = (hashId: string) => RequestHelper.GET(`${fluidsMovementsUrl}/${hashId}/bsd`);

const getOperationOptions = () => RequestHelper.GET(`${pendingMovementsUrl}/operation-codes`);

// POST
const getFluidsMovementsList: (
  params,
  page: number
) => Promise<FluidMovementSummary> = (
  params = {},
  page = 1
) => RequestHelper.POST(`${fluidsMovementsUrl}/filter?page=${page}`, params);

const getPendingMovementsList: (
  params,
  page: number
) => Promise<FluidMovementSummary> = (
  params = {},
  page = 1
) => RequestHelper.POST(`${pendingMovementsUrl}/filter?page=${page}`, params);

const validatePendingMovement = (hashId, params) => RequestHelper.PUT(`${pendingMovementsUrl}/${hashId}/receive`, params);

const createFluidMovement = fluidMovement => RequestHelper.POST(`${fluidsMovementsUrl}`, fluidMovement);

const updateFluidMovement: (
  hashId: string,
  fluidMovement: FluidMovementView
) => Promise<void> = (hashId, fluidMovement) => RequestHelper.PUT(`${fluidsMovementsUrl}/${hashId}`, fluidMovement);

const importFluidMovement = document => RequestHelper.POST(`${fluidsMovementsUrl}/import`, document);

const importSales = document => RequestHelper.POST(`${fluidsMovementsUrl}/import/sales`, document);

const importPurchases = document => RequestHelper.POST(`${fluidsMovementsUrl}/import/purchases`, document);

const exportFluidMovement = params => RequestHelper.POST(`${fluidsMovementsUrl}/export`, params);

const exportFluidMovementByEmail = (params, signal) => RequestHelper.POST(`${fluidsMovementsUrl}/notif-export`, params, undefined, signal);

// DELETE
const deleteFluidMovement = hashId => RequestHelper.DELETE(`${fluidsMovementsUrl}/${hashId}`);

export const FluidMovementsService = {
  getFluidMovement,
  getBsd,
  getOperationOptions,
  getFluidsMovementsList,
  getPendingMovementsList,
  validatePendingMovement,
  createFluidMovement,
  updateFluidMovement,
  importFluidMovement,
  exportFluidMovement,
  exportFluidMovementByEmail,
  deleteFluidMovement,
  importSales,
  importPurchases
};
