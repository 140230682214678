import { CountrySelectItem, CountryView } from 'models';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const countriesUrl = `${API_URL}countries`;

const getCountryList: (limited?: boolean) => Promise<CountrySelectItem[]> = (limited = false) => RequestHelper.GET(`${countriesUrl}?limited=${limited}`);

const getCountryDetails: () => Promise<CountryView[]> = () => RequestHelper.GET(`${countriesUrl}/extended-options`);

const getCountryByCode: (key: string) => Promise<CountryView> = key => RequestHelper.GET(`${countriesUrl}/${key}`);

const getLanguageList: () => Promise<SelectItem[]> = () => RequestHelper.GET(`${countriesUrl}/languages`);

export const CountriesService = {
  getCountryList,
  getCountryDetails,
  getLanguageList,
  getCountryByCode
};
