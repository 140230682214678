import { faTools } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  Datepicker, FlexContainer, SelectField, SelectLabel, SkeletonForm, TextError
} from 'components';
import { differenceInMonths } from 'date-fns';
import { ModalProps, ModalState } from 'hooks/ModalProvider';
import { EquipmentView } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { ToolsService } from 'services';
import shortid from 'shortid';
import { STOCK_ROLES_WITH_INTERVENTION_MANAGER, TOOLS_MONTHS_EXPIRATION_MAX, TOOL_TYPES } from 'utils/constants';
import {
  DocumentHelper,
  UserHelper,
  formatDate, parseDate, translate
} from 'utils/helpers';
import { ModalHeader } from './ModalHeader';
import { CustomActionButtons } from './_CustomActionButtons';

export const getDatabilanToolTypeOptions = () => [
  { value: TOOL_TYPES.LEAK_DETECTOR, label: translate('common.toolType.LEAK_DETECTOR') },
  { value: TOOL_TYPES.SCALE, label: translate('common.toolType.SCALE') }
];

export const AddToolModal = ({
  onSubmit,
  onClose,
  defaultValues = new EquipmentView(),
  forIntervention = false,
  openSecondaryModal,
  toolId,
  withPicture = false
}: ModalProps<EquipmentView> & {
  defaultValues?: EquipmentView,
  forIntervention: boolean,
  openSecondaryModal: (options: ModalState) => void,
  toolId?: string,
  withPicture?: boolean
}) => {
  const [dateError, setDateError] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [picturesAdded, setPicturesAdded] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formState, { text, raw }] = useFormState<EquipmentView>(defaultValues);

  const canValidate = toolId && !formState.values.validated && UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER);

  useEffect(() => {
    if (!toolId) {
      return;
    }
    setIsLoading(true);

    ToolsService.getTool(toolId)
      .then((resp) => {
        if (resp.documentList && resp.documentList.documents) {
          const pictures = resp.documentList.documents.map(document => ({
            name: document.name,
            base64Content: DocumentHelper.getDocumentWithBase64({ ...document, base64Content: document.content })
          }));
          setPicturesAdded(pictures);
        }
        Object.entries(resp).forEach(([key, value]) => formState.setField(key as any, value));
        if (resp.address && typeof resp.address !== 'string') {
          formState.setField('address1', resp.address?.address1);
          formState.setField('address2', resp.address?.address2);
          formState.setField('city', resp.address?.city);
          formState.setField('country', resp.address?.country);
          formState.setField('postalCode', resp.address?.postalCode);
        }
      }).finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [toolId]);

  const handleAddPicture = event => (
    DocumentHelper.handleFileUpload({ callback: setPicturesAdded, callbackError: setUploadError, event })
  );

  const triggerConfirm = useCallback(() => {
    onSubmit({
      ...formState.values,
      documentList: {
        documents: DocumentHelper.removeAllBase64Prefix(picturesAdded)
      }
    });
  }, [formState.values, picturesAdded, onSubmit]);

  const isToolTooOld = (date: Date) => {
    const monthsOld = differenceInMonths(new Date(), new Date(date));
    return monthsOld > TOOLS_MONTHS_EXPIRATION_MAX;
  };

  const handleValidateModal = (e) => {
    e.preventDefault();

    if (!dateError) {
      if (isToolTooOld(parseDate(formState.values.lastControlDate))) {
        openSecondaryModal({
          type: 'WARNING',
          text: translate('warnings.toolList.toolTooOld'),
          buttonCancel: translate('button.cancel'),
          buttonConfirm: translate('button.confirmAndCreate'),
          onConfirm: () => triggerConfirm()
        });
      } else {
        triggerConfirm();
      }
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleValidateModal}>
        <ModalHeader onClose={onClose}>
          <FontAwesomeIcon icon={faTools} />
          {(toolId || forIntervention) ? translate('modalTool.editTool') : translate('modalTool.newTool')}
        </ModalHeader>

        {isLoading ? (
          <SkeletonForm />
        ) : (
          <>
            <DialogContent style={{ width: '400px' }}>
              <FlexContainer flexDirection="column">
                {toolId && (
                  <ToggleButtonGroup
                    aria-label={translate('modalTool.toolStatus')}
                    exclusive
                    size="small"
                    style={{ marginBottom: '2rem' }}
                    value={formState.values.activated}
                    onChange={(event, value) => {
                      if (value === null) return;
                      formState.setField('activated', value);
                    }}
                  >
                    <ToggleButton aria-label={translate('common.activated')} value>
                      {translate('common.activated')}
                    </ToggleButton>
                    <ToggleButton aria-label={translate('common.deactivated')} value={false}>
                      {translate('common.deactivated')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}

                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <SelectField
                      id="toolType"
                      isDisabled={!!toolId || forIntervention}
                      label="common.toolTypes"
                      name="legalStatus"
                      options={getDatabilanToolTypeOptions()}
                      required
                      {...raw('type')}
                    />
                    <TextField
                      error={!!formState.errors.brand}
                      fullWidth
                      label={translate('common.brand')}
                      name="brand"
                      {...text('brand')}
                    />
                    <TextField
                      error={!!formState.errors.model}
                      fullWidth
                      label={translate('common.model')}
                      name="model"
                      required
                      {...text('model')}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!formState.errors.serialNumber}
                      fullWidth
                      label={translate('common.serialNumber')}
                      name="serialNumber"
                      required
                      {...text('serialNumber')}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      error={!!formState.errors.internalIdentification}
                      fullWidth
                      label={translate('common.internalIdentification')}
                      name="internalIdentification"
                      {...text('internalIdentification')}
                    />
                  </Grid>

                  <Grid item>
                    <Datepicker
                      disableFuture
                      error={Boolean(dateError)}
                      label="common.controlDate"
                      maxDate={formatDate(new Date())}
                      maxDateMessage="modalTool.errorMaxDate"
                      name="lastCheckupDate"
                      required
                      value={formState.values.lastControlDate}
                      onChange={value => formState.setField('lastControlDate', value)}
                      onError={error => setDateError(error)}
                    />
                  </Grid>
                  {withPicture && (
                    <Grid item>
                      <SelectLabel>{translate('modalTool.toolPicture')}</SelectLabel>
                      <input
                        accept="image/*"
                        placeholder={translate('modalTool.toolPictureAdd')}
                        type="file"
                        onChange={handleAddPicture}
                      />
                      {uploadError && <TextError>{uploadError}</TextError>}
                      {picturesAdded && picturesAdded.length > 0 && (
                        <FlexContainer className="modalPicturesContainer">
                          {picturesAdded.map(picture => (
                            <img alt={picture.name} key={shortid.generate()} src={picture.base64Content} />
                        ))}
                        </FlexContainer>
                      )}
                    </Grid>
                  )}
                </Grid>
              </FlexContainer>
            </DialogContent>

            <CustomActionButtons
              forValidation={canValidate}
              isLoading={isLoading}
              onClose={onClose}
            />
          </>
      )}
      </form>
    </>
  );
};
