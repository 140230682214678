import React from 'react';
import styled from 'styled-components';
import { translate } from 'utils/helpers';

const DEFAULT_MAX_LENGTH = 100;

const LengthCounter = styled.span`
  text-align: right;
  color: ${props => (props.isFull ? 'var(--red)' : 'var(--green)')};
`;

const InputCounter = ({
  maxLength = DEFAULT_MAX_LENGTH, value, children
}) => {
  const inputLengthLeft = value ? Number(maxLength - value.length) : maxLength;

  return (
    <>
      {children}
      <LengthCounter isFull={inputLengthLeft === 0}>
        {`${inputLengthLeft} ${translate('common.caractersLeft')}`}
      </LengthCounter>
    </>
  );
};

export default InputCounter;
