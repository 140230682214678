import { faWandMagic } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Grid
} from '@material-ui/core';
import { Video } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

export const DisplayTourModal = ({
  onClose, videos, openTour
}) => {
  const { isLandscape } = useMobileOrientation();
  const contentMinWidth = isMobileOnly ? 300 : 700;
  const contentPadding = isMobileOnly ? '8px' : '16px 24px';
  const handleOpenTour = () => {
    openTour();
    onClose();
  };

  return (
    <>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faWandMagic} />
        {`${translate('common.tutorials')}`}
      </ModalHeader>

      <DialogContent style={{ minWidth: `${contentMinWidth}px`, padding: contentPadding }}>
        {openTour && (
          <Button
            color="primary"
            style={{ display: 'block', margin: '0 auto 2rem auto' }}
            variant="contained"
            onClick={handleOpenTour}
          >
            {translate('button.openTour')}
          </Button>
        )}

        <Grid container direction="column" spacing={2}>
          {videos && videos.map((video, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item key={index}>
              <Video {...video} />
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      {(!isMobileOnly || (isMobileOnly && !isLandscape)) && (
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {translate('button.close')}
          </Button>
        </DialogActions>
      )}
    </>
  );
};

DisplayTourModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  openTour: PropTypes.func,
  videos: PropTypes.arrayOf(PropTypes.shape({}))
};

DisplayTourModal.defaultProps = {
  openTour: null,
  videos: null
};
