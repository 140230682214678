import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import { Wrapper } from 'components';

export const SkeletonDetailUser = () => (
  <Wrapper>
    <Skeleton height={130} style={{ margin: '1rem auto' }} variant="circle" width={130} />
    <Skeleton height={40} style={{ margin: '2rem auto' }} width={200} />
    <Skeleton height={200} style={{ marginBottom: '2rem' }} variant="rect" />
    <Skeleton height={100} style={{ marginBottom: '2rem' }} variant="rect" />
    <Skeleton height={150} variant="rect" />
  </Wrapper>
);
