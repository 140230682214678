import { Dialog, Slide } from '@material-ui/core';
import { AddToolModal } from 'components/Modals/AddToolModal';
import { CreateSupplierOrRecipientModal } from 'components/Modals/CreateSupplierOrRecipientModal';
import { useStores } from 'hooks';
import { ModalState } from 'hooks/ModalProvider';
import React, { useCallback, useEffect } from 'react';
import { AddEquipmentModal } from './AddEquipmentModal';
import { AddEquipmentOwnerModal } from './AddEquipmentOwnerModal';
import { AddFluidManipulationModal } from './AddFluidManipulationModal';
import { AddLeakModal } from './AddLeakModal';
import { AddSignatureModal } from './AddSignatureModal';
import { BottleVariationsModal } from './BottleVariationsModal';
import { BrowserPopupModal } from './BrowserPopupModal';
import { ChangeLanguageModal } from './ChangeLanguageModal';
import { CheckPendingMovementModal } from './CheckPendingMovementModal';
import { ConfirmModal } from './ConfirmModal';
import { CookiesModal } from './CookiesModal';
import { CreateBottleModal } from './CreateBottleModal';
import { CreateFluidMovementModal } from './CreateFluidMovementModal';
import { CreateInstitutionGroupModal } from './CreateInstitutionGroupModal';
import { CreateInstitutionModal } from './CreateInstitutionModal';
import { CreateInterventionModal } from './CreateInterventionModal';
import { CreateUserModal } from './CreateUserModal';
import { DeleteBottleModal } from './DeleteBottleModal';
import DisplayPDFMobileModal from './DisplayPDFMobileModal';
import { DisplayPDFModal } from './DisplayPDFModal';
import { DisplayPictureModal } from './DisplayPictureModal';
import { DisplayTourModal } from './DisplayTourModal';
import { ErrorModal } from './ErrorModal';
import { ImportModal } from './ImportModal';
import { MapModal } from './MapModal';
import { ReassignOperatorModal } from './ReassignOperatorModal';
import { SelectPrimaryDeviceModal } from './SelectPrimaryDeviceModal';
import { SendInterventionModal } from './SendInterventionModal';
import { UpdateInterventionDateModal } from './UpdateInterventionDateModal';
import { UpdateInterventionNumberModal } from './UpdateInterventionNumberModal';
import { UpdateRolesModal } from './UpdateRolesModal';
import { UpdateUserLevelModal } from './UpdateUserLevelModal';
import { UpdateUserModal } from './UpdateUserModal';
import { WaitingConfirmationModal } from './WaitingConfirmationModal';
import { WarningModal } from './WarningModal';

const Transition: any = React.forwardRef((props: any, ref) => <Slide direction="up" ref={ref} {...props} />);

const MODAL_TYPES = {
  ADD_EQUIPMENT: AddEquipmentModal,
  ADD_EQUIPMENT_OWNER: AddEquipmentOwnerModal,
  ADD_FLUID_MANIPULATION: AddFluidManipulationModal,
  ADD_TOOL: AddToolModal,
  ADD_LEAK: AddLeakModal,
  ADD_SIGNATURE: AddSignatureModal,
  BOTTLE_VARIATIONS: BottleVariationsModal,
  BROWSER_POPUP: BrowserPopupModal,
  CHANGE_LANGUAGE: ChangeLanguageModal,
  CHECK_PENDING_MOVEMENT: CheckPendingMovementModal,
  CREATE_BOTTLE: CreateBottleModal,
  CREATE_INSTITUTION: CreateInstitutionModal,
  CREATE_INSTITUTION_GROUP: CreateInstitutionGroupModal,
  CREATE_FLUID_MOVEMENT: CreateFluidMovementModal,
  CREATE_INTERVENTION: CreateInterventionModal,
  CREATE_SUPPLIER_OR_RECIPIENT: CreateSupplierOrRecipientModal,
  CREATE_USER: CreateUserModal,
  DELETE_BOTTLE: DeleteBottleModal,
  DISPLAY_PDF: DisplayPDFModal,
  DISPLAY_PDF_MOBILE: DisplayPDFMobileModal,
  DISPLAY_PICTURE: DisplayPictureModal,
  DISPLAY_TOUR: DisplayTourModal,
  IMPORT: ImportModal,
  MAP: MapModal,
  REASSIGN_OPERATOR: ReassignOperatorModal,
  SEND_INTERVENTION: SendInterventionModal,
  UPDATE_INTERVENTION_NUMBER: UpdateInterventionNumberModal,
  UPDATE_INTERVENTION_DATE: UpdateInterventionDateModal,
  UPDATE_USER_LEVEL: UpdateUserLevelModal,
  UPDATE_USER: UpdateUserModal,
  UPDATE_ROLES: UpdateRolesModal,
  COOKIES_MODAL: CookiesModal,
  CONFIRM: ConfirmModal,
  WAITING_CONFIRMATION: WaitingConfirmationModal,
  ERROR: ErrorModal,
  WARNING: WarningModal,
  SELECT_PRIMARY_DEVICE: SelectPrimaryDeviceModal
};

export const Modal = ({
  closeModal,
  closeSecondaryModal,
  isOpen,
  isSecondaryOpen,
  modalState,
  secondaryModalState
}: {
  closeModal: () => void,
  closeSecondaryModal: () => void,
  isOpen: boolean,
  isSecondaryOpen: boolean,
  modalState: ModalState,
  secondaryModalState: ModalState
}) => {
  const {
    type: primaryType,
    onClose: onClosePrimary,
    onSubmit: onSubmitPrimary,
    disableClose: disableClosePrimary = true,
    closeOnSubmit: closePrimaryOnSubmit = false,
    ...restPrimary
  } = modalState || {};

  const {
    type: secondaryType,
    onClose: onCloseSecondary,
    onSubmit: onSubmitSecondary,
    disableClose: disableCloseSecondary = true,
    closeOnSubmit: closeSecondaryOnSubmit = false,
    ...restScondary
  } = secondaryModalState || {};
  const { i18nStore } = useStores();

  const isFullscreen = document.getElementsByTagName('body')[0].clientWidth <= 768;

  const CurrentModal = primaryType && MODAL_TYPES[primaryType];
  const CurrentSecondaryModal = secondaryType && MODAL_TYPES[secondaryType];

  const handleClose = (primary: boolean, event?: any) => {
    if (primary) {
      onClosePrimary && onClosePrimary(event);
      closeModal();
    } else {
      onCloseSecondary && onCloseSecondary(event);
      closeSecondaryModal();
    }
  };

  const handleSubmit = (primary: boolean, value?: any) => {
    if (primary) {
      onSubmitPrimary && onSubmitPrimary(value);
      if (closePrimaryOnSubmit) {
        handleClose(true);
      }
    } else {
      onSubmitSecondary && onSubmitSecondary(value);
      if (closeSecondaryOnSubmit) {
        handleClose(false);
      }
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      if (isSecondaryOpen) {
        onCloseSecondary && onCloseSecondary(event);
        closeSecondaryModal();
      } else {
        onClosePrimary && onClosePrimary(event);
        closeModal();
      }
    }
  }, [closeModal, closeSecondaryModal, isSecondaryOpen, onClosePrimary, onCloseSecondary]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  if (i18nStore.isLoading) {
    return null;
  }

  return (
    <>
      <Dialog
        closeAfterTransition
        disableEscapeKeyDown={disableClosePrimary}
        fullScreen={isFullscreen}
        keepMounted
        maxWidth="lg"
        open={isOpen}
        TransitionComponent={Transition}
        transitionDuration={300}
        onClose={(event, reason) => {
          // disableBackdropClick is now deprecated, onClose reason needs to be used
          if (disableClosePrimary && reason === 'backdropClick') {
            return;
          }
          handleClose(true, event);
        }}
      >
        {CurrentModal && (
          <CurrentModal
            onClose={(e) => handleClose(true, e)}
            onSubmit={(value) => handleSubmit(true, value)}
            {...restPrimary}
          />
        )}
      </Dialog>
      <Dialog
        closeAfterTransition
        disableEscapeKeyDown={disableCloseSecondary}
        fullScreen={false}
        maxWidth="lg"
        open={isSecondaryOpen}
        TransitionComponent={Transition}
        transitionDuration={300}
        onClose={(event, reason) => {
          // disableBackdropClick is now deprecated, onClose reason needs to be used
          if (disableCloseSecondary && reason === 'backdropClick') {
            return;
          }
          handleClose(false, event);
        }}
      >
        {CurrentSecondaryModal && (
          <CurrentSecondaryModal
            onClose={(e) => handleClose(false, e)}
            onSubmit={(value) => handleSubmit(false, value)}
            {...restScondary}
          />
        )}
      </Dialog>
    </>
  );
};
