import React from 'react';
import Media from 'react-media';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';
import styled from 'styled-components';

import { MENU_ITEMS } from 'utils/constants';

import { MenuItem } from 'components';

const Container = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  min-width: var(--wrapperWidthMin);
  display: flex;
  align-items: center;
  background: var(--grey-lighter);
  z-index: var(--zindexBig);
  box-shadow: 0 -1px 4px 0 rgba(0,0,0,0.1);

  button {
    flex: 1;
  }
`;

// Don't display the footer on the form inspection page
export const FooterMenu = withRouter(({ location }) => location.pathname.indexOf('/inspection') === -1 && (
  <Media
    query="(max-width: 1080px)"
    render={() => (
      <Container>
        {MENU_ITEMS.map((item, index) => (
          <MenuItem
            dataTour={`step-header-menu-${index}`}
            fontSize="3rem"
            isFooter
            key={shortid.generate()}
            {...item}
          />
        ))}
      </Container>
    )}
  />
));
