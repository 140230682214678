import {
  action, makeObservable, observable, runInAction
} from 'mobx';
import { BottleView, FluidView } from 'models';
import { FluidsService } from 'services';
import { SelectItem } from 'utils/types';

class FluidStore {
  fluidOptions: SelectItem[];
  fluidExtendedOptions: FluidView[];
  isLoadingFluids: boolean;

  bottleOptions: SelectItem[];
  bottleExtendedOptions: BottleView[];
  isLoadingBottles: boolean;

  constructor() {
    this.fluidOptions = [];
    this.fluidExtendedOptions = [];
    this.isLoadingFluids = false;
    this.bottleOptions = [];
    this.bottleExtendedOptions = [];
    this.isLoadingBottles = false;

    makeObservable(this, {
      fluidOptions: observable,
      fluidExtendedOptions: observable,
      isLoadingFluids: observable,
      bottleOptions: observable,
      bottleExtendedOptions: observable,
      isLoadingBottles: observable,
      loadFluidOptions: action,
      loadFluidExtendedOptions: action,
      loadFluidBottleOptions: action,
      loadFluidBottleExtendedOptions: action
    });
  }

  loadFluidOptions() {
    FluidsService.getFluidOptions()
      .then(fluidOptions => runInAction(() => {
        this.fluidOptions = fluidOptions;
      }));
  }

  loadFluidExtendedOptions() {
    this.isLoadingFluids = true;
    FluidsService.getFluidOptionsExtended()
      .then(fluidOptions => runInAction(() => {
        this.fluidExtendedOptions = fluidOptions;
        this.isLoadingFluids = false;
      }));
  }

  loadFluidBottleOptions() {
    FluidsService.getBottleOptions({})
      .then(bottleOptions => runInAction(() => {
        this.bottleOptions = bottleOptions;
      }));
  }

  loadFluidBottleExtendedOptions(params = {}) {
    this.isLoadingBottles = true;
    FluidsService.getBottleOptionsExtended(params)
      .then(bottleOptions => runInAction(() => {
        this.bottleExtendedOptions = bottleOptions;
        this.isLoadingBottles = false;
      }));
  }
}

export const fluidStore = new FluidStore();
