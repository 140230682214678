/* eslint-disable dot-notation */
import React, {
  useCallback, useEffect, useState
} from 'react';

import {
  faAirConditioner, faSave, faShareSquare, faSpinner, faUserSlash
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Divider, Grid, Step, StepButton, Stepper, Typography
} from '@material-ui/core';
import {
  ButtonBlue, ButtonBlueLight, ButtonTurquoise,
  CustomTooltip, SkeletonMain, SmallWrapper, Text, TitlePage
} from 'components';
import { subYears } from 'date-fns';
import { useSnackbar } from 'notistack';
import { Magnifier } from 'react-image-magnifiers'; // https://www.npmjs.com/package/react-image-magnifiers
import { useFormState } from 'react-use-form-state'; // https://github.com/wsmd/react-use-form-state
import { FluidsService, InterventionsService } from 'services';
import styled from 'styled-components';
import { COUNTRY_CODE, INTERVENTION_FORM_STEPS, ROUTES } from 'utils/constants';
import {
  FormHelper, InterventionHelper, parseDate, translate, UserHelper
} from 'utils/helpers';

import { useModal } from 'hooks';
import { ModalState } from 'hooks/ModalProvider';
import { observer } from 'mobx-react-lite';
import { InterventionView } from 'models';
import { EquipmentView, getIdentifier } from 'models/detail/EquipmentView';
import { FormFrontView, getCountryCodeFromForm } from 'models/detail/FormFrontView';
import { InterventionFrontView } from 'models/detail/InterventionFrontView';
import { InterventionStatusView } from 'models/detail/InterventionStatusView';
import { getFluidWeight } from 'models/detail/RefrigerationUnitView';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { userStore } from 'stores';
import {
  Control, Equipement, EquipementOwner, Fluids, InterventionType, Leaks, Signatures
} from './Sections';

export const FormLine: any = styled.article`
  display: flex;
  align-items: center;

  & > div {
    flex: 1;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`;

const StyledInterventionForm = styled.section`
  position: relative;

  @media (max-width: 768px) {
    header {
      position: sticky;
      top: 0;
      border-bottom: 1px solid var(--grey-light);
    }

    footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;
      z-index: var(--zindexBig);
      background: var(--white);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

      .submitButton {
        width: 100%;
      }
    }
  }

  @media (min-width: 769px) {
    footer {
      margin-top: 4rem;
    }
  }

  header {
    width: 100%;
    padding: 1rem 0;
    margin-bottom: 1rem;
    background: #ffffff;
    z-index: var(--zindexBig);

    h2 {
      margin: 0;
      color: var(--blue);
    }
  }

  .MuiStepper-root {
    padding-left: 0;
  }
`;

const CerfaProgress = styled.div`
  width: 150px;
  margin: 0 auto 1rem auto;

  @media (min-width: 1300px) {
    width: 200px;
    position: absolute;
    left: 10px;
    top: 20px;
  }
`;

const InterventionFormElement = styled.form`
  padding: 1rem 0;
`;

export const InterventionForm = observer(() => {
  const { open } = useModal();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const location: any = useLocation();
  const [formCurrentStep, setFormCurrentStep] = useState(-1);
  const [completedSteps, setCompletedSteps] = useState([0]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const {
    currentStep = 0,
    intervention,
    needToBeStored = false,
    outOfSyncStatus,
    storedInterventionForm
  }: {
    currentStep: number,
    intervention: InterventionView,
    needToBeStored: boolean,
    outOfSyncStatus: InterventionStatusView,
    storedInterventionForm: FormFrontView
  } = location.state || {};

  const { id: interventionHashId } = useParams<any>();
  const numberOfSections = INTERVENTION_FORM_STEPS.length;

  const newAppointment = InterventionHelper.getFormattedDateTime(new Date());

  useEffect(() => {
    // If we don't have any intervention detail, redirect to the detail or listing
    if (!intervention && (!needToBeStored && !storedInterventionForm)) {
      if (interventionHashId) {
        history.push(`/interventions/${interventionHashId}/detail`);
      } else {
        history.push('/interventions');
      }
    }

    // Instanciate formCurrentStep depending from where we come :
    // last step (signatures) if the intervention is already in the database
    // stored step if the intervention is from the IndexedDB
    const currentCompletedSteps = [];

    const setupCurrentCompletedSteps = (maxStep) => {
      for (let i = 0; i <= maxStep; i += 1) {
        currentCompletedSteps.push(i);
      }
      setCompletedSteps(currentCompletedSteps);
    };

    if (storedInterventionForm) {
      setFormCurrentStep(storedInterventionForm.currentStep || 0);
      setupCurrentCompletedSteps(storedInterventionForm.currentStep || 0);
    } else if (intervention) {
      setFormCurrentStep(currentStep !== undefined ? currentStep : 0);
      currentStep > 0 && setupCurrentCompletedSteps(currentStep);
    }
  }, [interventionHashId, intervention, storedInterventionForm, needToBeStored, currentStep, history]);

  // Instanciate the intervention form depending from where we come :
  // with a stored intervention: return it
  // interventionDetail : do a mapping from the backend model to the frontend form model
  // new intervention : return default form with new fileNumber et appointment
  const getDefaultForm: () => FormFrontView = useCallback(() => {
    if (storedInterventionForm) {
      return storedInterventionForm;
    }

    if (intervention) return FormHelper.BACKEND_MAPPER(intervention);

    return {
      ...new FormFrontView(),
      fileNumber: interventionHashId,
      appointment: newAppointment
    };
  }, [intervention, storedInterventionForm, interventionHashId, newAppointment]);

  const [formState, {
    text, checkbox, textarea
  }] = useFormState<FormFrontView>(getDefaultForm());

  const getCountryCode: () => string = useCallback(
    () => getCountryCodeFromForm(formState.values, UserHelper.getDefaultCountry()?.key)
    // eslint-disable-next-line
  , [formState.values]);

  const isFrance: () => boolean = useCallback(() => getCountryCode() === COUNTRY_CODE.FR, [getCountryCode]);
  const isGB: () => boolean = useCallback(() => getCountryCode() === COUNTRY_CODE.GB, [getCountryCode]);

  const interventionTypeValues = [
    formState.values.assembly,
    formState.values.commissioning,
    formState.values.modification,
    formState.values.maintenance,
    formState.values.periodicControl,
    formState.values.nonPeriodicControl,
    formState.values.dismantling,
    formState.values.ui_other
  ];

  // Save the intervention in IndexedDB each time the form OR the formCurrentStep changes
  useEffect(() => {
    if (needToBeStored) {
      try {
        const newIntervention: FormFrontView = {
          ...formState.values,
          currentStep: formCurrentStep,
          inputFluidTotal: Number(formState.values.inputFluidTotal),
          outputFluidTotal: Number(formState.values.outputFluidTotal)
        };
        InterventionHelper.saveInterventionFormStored(interventionHashId, newIntervention);
      } catch (error) {
        enqueueSnackbar(error, { variant: 'warning' });
      }
    }
    // eslint-disable-next-line
  }, [formState, formCurrentStep, needToBeStored, interventionHashId]);

  const handleOutOfSync = useCallback(() => {
    open({
      type: 'WARNING',
      text: translate('warnings.intervention.outOfSync', {
        reason: InterventionHelper.getOutOfSyncReason(outOfSyncStatus)
      }),
      buttonCancel: translate('button.cancel'),
      buttonConfirm: translate('button.deleteIntervention'),
      onConfirm: () => InterventionHelper.deleteInterventionStored(interventionHashId)
        .then(() => {
          enqueueSnackbar(translate('confirms.intervention.delete'), { variant: 'success' });
          history.push(ROUTES.INTERVENTION.LIST);
        })
    });
  }, [interventionHashId, history, open, enqueueSnackbar, outOfSyncStatus]);

  const goToNextStep = () => {
    setFormCurrentStep(formCurrentStep + 1);
    return setCompletedSteps([...completedSteps, formCurrentStep + 1]);
  };

  const checkEquipmentMandatory = () => {
    let errorMessage = '';

    if (formState.values.equipment && !formState.values.equipment.id) {
      // If a new equipment was created, make sure the mandatory fields are set
      if (!formState.values.equipment.model) {
        errorMessage += `- ${translate('errors.equipmentMandatoryModel')} <br />`;
      }
      if (!formState.values.equipment.serialNumber) {
        errorMessage += `- ${translate('errors.equipmentMandatorySerialNumber')} <br />`;
      }
      if (!formState.values.equipment.fluid) {
        errorMessage += `- ${translate('errors.equipmentMandatoryFluid')} <br />`;
      }
      if (formState.values.equipment.fluidWeight === null) {
        errorMessage += `- ${translate('errors.equipmentMandatoryFluidWeight')} <br />`;
      }
    } else if (!formState.values.equipment) {
      // Make sure an equipment is set
      errorMessage = translate('errors.equipmentMandatory');
    }

    if (errorMessage) {
      return enqueueSnackbar(errorMessage, { variant: 'error' });
    }

    return goToNextStep();
  };

  const checkToolMinDate = () => {
    const minDate = formState.values.interventionDate ? parseDate(formState.values.interventionDate) : new Date();
    const toolMinDate = subYears(minDate, 1);
    let requiredTool: EquipmentView = null;
    if (isFrance() && formState.values.leakDetector) {
      requiredTool = formState.values.leakDetector;
    } else if (isGB() && formState.values.scale) {
      requiredTool = formState.values.scale;
    }
    // For other countries, no tool is mandatory
    if (requiredTool) {
      const currentToolDate = parseDate(requiredTool.lastControlDate || requiredTool.acquisitionDate);

      if (currentToolDate < toolMinDate) {
        return open({
          type: 'WARNING',
          text: translate('warnings.intervention.toolControlDate'),
          buttonCancel: translate('button.cancel'),
          buttonAction: translate('button.edit'),
          onConfirm: () => {
            goToNextStep();
          },
          onAction: () => open({
            type: 'ADD_TOOL',
            onSubmit: (form: EquipmentView) => {
              formState.setField(isFrance() ? 'leakDetector' : 'scale', form);
              enqueueSnackbar(translate('confirms.toolList.update'), { variant: 'success' });
            },
            defaultValues: {
              ...requiredTool
            },
            closeOnSubmit: true,
            forIntervention: true,
            openSecondaryModal: (options: ModalState) => open(options, true)
          })
        });
      }
    }

    return goToNextStep();
  };

  const checkMaintenanceType = () => {
    // If a leak has been repaired, make sure a maintenance type is checked and go to next step
    if (isFrance()
      && formState.values.leaks.length > 0
      && formState.values.leaks.some(leak => leak.leakRepaired)
      && !formState.values.maintenance) {
      formState.setField('maintenance', true);
      return open({
        type: 'WARNING',
        text: translate('warnings.intervention.addedMaintenanceType'),
        onConfirm: () => {
          goToNextStep();
        }
      });
    }
    // If the intervention is only a control, skip the fluid step
    if ([
      formState.values.assembly,
      formState.values.commissioning,
      formState.values.modification,
      formState.values.maintenance,
      formState.values.dismantling,
      formState.values.ui_other
    ].every(type => type === false)) {
      // Go directly to step 6
      setFormCurrentStep(5);
      return setCompletedSteps([...completedSteps, 5]);
    }

    // Go to next step
    return goToNextStep();
  };

  const checkFluidManipulations = () => {
    const fluidManipulationField: any = FormHelper.BUILD_FLUID_MANIPULATIONS({
      inputList: formState.values.fluidManipulationsInput,
      outputList: formState.values.fluidManipulationsOutput,
      inputTotal: formState.values.inputFluidTotal,
      outputTotal: formState.values.outputFluidTotal
    });

    return FluidsService.checkFluidManipulations(fluidManipulationField).then(() => {
      goToNextStep();
    }).catch(() => {
      if (userStore.isOffline) {
        open({
          type: 'WARNING',
          text: translate('errors.NETWORK_VALIDATION_IMPOSSIBLE'),
          onConfirm: () => goToNextStep()
        });
      }
    });
  };

  const checkFluidsValidity = () => {
    let warningMessage = '';

    if (formState.values.equipment) {
      // CHECKS ON FLUIDS MANIPULATIONS OUTPUT
      if (!formState.values.assembly) {
        if (Number(formState.values.outputFluidTotal) < getFluidWeight(formState.values.equipment)
          && formState.values.leaks.length === 0 && formState.values.fluidManipulationsOutput.length > 0) {
          warningMessage += `${translate('warnings.intervention.fluidsNoLeaks')} <br /><br />`;
        } else if (Number(formState.values.outputFluidTotal) > getFluidWeight(formState.values.equipment)) {
          warningMessage += `${translate(
            'warnings.intervention.fluidsOutputMoreThanCapacity',
            { outputFluidTotal: formState.values.outputFluidTotal, fluidWeight: getFluidWeight(formState.values.equipment) }
          )}<br /><br />`;
        }

        if (formState.values.fluidManipulationsOutput.length > 0) {
          // Display warning if the fluid in the manipulation is different from the one in the equipment
          if (formState.values.equipment && formState.values.equipment.fluid
            && formState.values.fluidManipulationsOutput.find(manip => manip.fluid.label !== formState.values.equipment.fluid.label)) {
            const manipWithError = formState.values.fluidManipulationsOutput.find(manip => manip.fluid.label !== formState.values.equipment.fluid.label);
            warningMessage += `${translate(
              'warnings.intervention.fluidsOutputWrongFluid',
              { label: manipWithError.fluid.label, identifier: manipWithError.fluidBottle.identifier, name: formState.values.equipment.fluid.label }
            )} <br /><br />`;
          }
        }
      }

      // CHECKS ON FLUIDS MANIPULATIONS INPUT
      if (!formState.values.dismantling && formState.values.fluidManipulationsInput.length > 0) {
        if (Number(formState.values.inputFluidTotal) < getFluidWeight(formState.values.equipment)) {
          warningMessage += `${translate(
            'warnings.intervention.fluidsInputLessThanCapacity',
            { inputFluidTotal: formState.values.inputFluidTotal, fluidWeight: getFluidWeight(formState.values.equipment) }
          )} <br /><br />`;
        } else if (Number(formState.values.inputFluidTotal) > getFluidWeight(formState.values.equipment)) {
          warningMessage += `${translate(
            'warnings.intervention.fluidsInputMoreThanCapacity',
            { inputFluidTotal: formState.values.inputFluidTotal, fluidWeight: getFluidWeight(formState.values.equipment) }
          )} <br /><br />`;
        }

        if (formState.values.leaks.length > 0 && formState.values.leaks.some(leak => !leak.leakRepaired)) {
          warningMessage += `${translate('warnings.intervention.fluidsInputNoLeakAdded')} <br /><br />`;
        }

        if (formState.values.equipment && formState.values.equipment.fluid
          && formState.values.fluidManipulationsInput.find(manip => manip.fluid.label !== formState.values.equipment.fluid.label)) {
          // Display warning if the fluid in the manipulation is different from the one in the equipment
          const manipWithError = formState.values.fluidManipulationsInput.find(manip => manip.fluid.label !== formState.values.equipment.fluid.label);
          warningMessage += `${translate(
            'warnings.intervention.fluidsInputWrongFluid',
            { label: manipWithError.fluid.label, name: formState.values.equipment.fluid.label }
          )} <br /><br />`;
        }
      }
    }

    if (warningMessage) {
      return open({
        buttonCancel: translate('button.cancel'),
        type: 'WARNING',
        text: warningMessage,
        onConfirm: () => checkFluidManipulations()
      });
    }

    return checkFluidManipulations();
  };

  const handleStepPrevious = () => {
    // Specific check for step 4 fluid manipulation :
    // we skip step 3 and 4 if no control is checked
    if (formCurrentStep === 4) {
      if (!formState.values.periodicControl && !formState.values.nonPeriodicControl) {
        // Go directly to step 2
        setFormCurrentStep(1);
        return;
      }
    }
    if (formCurrentStep === 5) {
      if ([
        formState.values.assembly,
        formState.values.commissioning,
        formState.values.modification,
        formState.values.maintenance,
        formState.values.periodicControl,
        formState.values.dismantling,
        formState.values.ui_other
      ].every(type => type === false)) {
        // Go directly to step 4
        setFormCurrentStep(3);
      }
    }
    setFormCurrentStep(formCurrentStep - 1);
  };

  const handleStepNext = () => {
    if (document.forms && document.forms['interventionForm']) {
      const isCurrentStepValid = document.forms['interventionForm'].reportValidity();

      if (isCurrentStepValid && formCurrentStep < numberOfSections) {
        if (formCurrentStep === 0) {
          return checkEquipmentMandatory();
        }

        // Specific check for step 2 intervention type :
        // we skip step 3 and 4 if no control is checked
        if (formCurrentStep === 1) {
          if (!formState.values.periodicControl && !formState.values.nonPeriodicControl) {
            // Go directly to step 5
            setFormCurrentStep(4);
            return setCompletedSteps([...completedSteps, 2, 3, 4]);
          }

          return goToNextStep();
        }

        if (formCurrentStep === 2) {
          return checkToolMinDate();
        }

        // If a repaired leak has been added, check the type maintenance
        if (formCurrentStep === 3) {
          return checkMaintenanceType();
        }

        // Perform multiple check on the fluids step
        if (formCurrentStep === 4) {
          return checkFluidsValidity();
        }

        // Go to next step if everything else if ok
        goToNextStep();
      }
    }

    return null;
  };

  const getWarningMessage = () => {
    let warningMessage = '';

    if (!formState.values.equipmentOwner) {
      warningMessage += `<li>${translate('warnings.intervention.mandatoryEquipmentOwner')}</li>`;
    }
    if (!formState.values.equipment) {
      warningMessage += `<li>${translate('warnings.intervention.mandatoryEquipment')}</li>`;
    }
    if (!interventionTypeValues.some(type => type === true)) {
      warningMessage += `<li>${translate('warnings.intervention.mandatoryInterventionType')}</li>`;
    }
    if (isFrance() && !formState.values.leakDetector && formState.values.nonPeriodicControl) {
      warningMessage += `<li>${translate('warnings.intervention.mandatoryLeakDetector')}</li>`;
    }
    if (isGB() && !formState.values.scale && formState.values.nonPeriodicControl) {
      warningMessage += `<li>${translate('warnings.intervention.mandatoryScale')}</li>`;
    }

    return warningMessage;
  };

  const saveIntervention = useCallback(async () => {
    if (!needToBeStored) {
      // If the intervention isn't saved, use the current data
      if (userStore.isOffline) {
        // A server intervention cannot be updated in offline mode
        enqueueSnackbar(translate('common.userIsOffline'), { variant: 'error', autoHideDuration: 10000 });
      } else {
        const completedIntervention: InterventionView = {
          ...intervention,
          fileNumber: decodeURIComponent(formState.values.fileNumber),
          form: FormHelper.BUILD(formState.values)
        };
        setIsSubmitting(true);
        InterventionsService.finishIntervention(interventionHashId, completedIntervention)
          .then(async (interventionFromServer) => {
            await InterventionHelper.deleteInterventionStored(interventionHashId);
            enqueueSnackbar(translate('confirms.intervention.finishedOnline'), { variant: 'success', autoHideDuration: 10000 });
            history.push(`/interventions/${interventionFromServer.hashId}/detail`);
          })
          .catch(() => {
            setIsSubmitting(false);
          });
      }
    } else {
      // If the intervention is already stored
      const interventionDetailStored = await InterventionHelper.loadInterventionDetailStored(interventionHashId);
      // Prepare the updated data
      const completedIntervention: InterventionFrontView = {
        ...interventionDetailStored,
        fileNumber: decodeURIComponent(formState.values.fileNumber),
        observations: undefined
      };
      const completedForm: FormFrontView = {
        ...formState.values,
        currentStep: formCurrentStep,
        inputFluidTotal: Number(formState.values.inputFluidTotal),
        outputFluidTotal: Number(formState.values.outputFluidTotal)
      };

      if (userStore.isOffline) {
        // Save the data to send it to the server once the app is online
        InterventionHelper.saveInterventionDetailStored(interventionHashId, {
          ...completedIntervention,
          isWaitingToSubmit: true
        });
        InterventionHelper.saveInterventionFormStored(interventionHashId, completedForm);
        enqueueSnackbar(translate('confirms.intervention.finishedOffline'), { variant: 'success', autoHideDuration: 10000 });
        history.push(ROUTES.INTERVENTION.LIST);
      } else {
        setIsSubmitting(true);
        InterventionsService.finishIntervention(interventionHashId, {
          ...completedIntervention,
          form: FormHelper.BUILD(formState.values)
        }).then(async (interventionFromServer) => {
          await InterventionHelper.deleteInterventionStored(interventionHashId);
          enqueueSnackbar(translate('confirms.intervention.finishedOnline'), { variant: 'success', autoHideDuration: 10000 });
          history.push(`/interventions/${interventionFromServer.hashId}/detail`);
        }).catch(() => {
          setIsSubmitting(false);
        });
      }
    }
  }, [enqueueSnackbar, formState, history, interventionHashId, formCurrentStep, intervention, needToBeStored]);

  const saveInterventionProgress = () => {
    setIsSaving(true);

    InterventionHelper.loadInterventionDetailStored(interventionHashId)
      .then((int) => {
        const currentIntervention: InterventionView = {
          ...int,
          fileNumber: decodeURIComponent(formState.values.fileNumber),
          form: FormHelper.BUILD(formState.values)
        };

        const currentHashId = currentIntervention.hashId
          || currentIntervention.form.hashId
          || currentIntervention.fileNumber
          || decodeURIComponent(formState.values.fileNumber);

        return InterventionsService.updateIntervention(currentHashId, currentIntervention)
          .then(() => enqueueSnackbar(translate('confirms.intervention.saved'), { variant: 'success' }))
          .catch((error) => enqueueSnackbar(error, { variant: 'error' }))
          .finally(() => setIsSaving(false));
      });
  };

  const handleInterventionEnd = () => {
    // Prevent form submit if some of the required fields are not filled
    const warningContent = getWarningMessage();
    if (warningContent) {
      return open({
        type: 'WARNING',
        title: translate('common.mandatoryField'),
        text: `<div>${translate('warnings.intervention.mandatoryTitle')} :<ul>${warningContent}</ul></div>`
      });
    }

    return saveIntervention();
  };

  const handleInterventionQuit = () => {
    history.push(ROUTES.INTERVENTION.LIST);
  };

  if ((!storedInterventionForm && !intervention) || formCurrentStep < 0) {
    return <SkeletonMain />;
  }

  const cerfaVersionForSteps = InterventionHelper.getCerfaVersion(formState.values.interventionDate);

  return (
    <StyledInterventionForm>
      <SmallWrapper>
        <header>
          <Grid alignItems="center" container justifyContent="space-between" spacing={1}>
            <Grid item>
              <h2>{`${translate('common.intervention')} n°${formState.values.fileNumber}`}</h2>
              {formState.values.equipment && (
                <Typography>
                  <FontAwesomeIcon className="mr1" icon={faAirConditioner} />
                  {`${translate('common.equipment')} : ${getIdentifier(formState.values.equipment)}`}
                </Typography>
              )}
            </Grid>
            {!userStore.isOffline && needToBeStored && (
              <Grid item>
                <ButtonTurquoise disabled={isSaving || outOfSyncStatus} onClick={saveInterventionProgress}>
                  <FontAwesomeIcon icon={isSaving ? faSpinner : faSave} spin={isSaving} />
                  {translate('button.save')}
                </ButtonTurquoise>
              </Grid>
            )}
          </Grid>
        </header>

        {outOfSyncStatus && (
          <Button style={{ display: 'block', margin: '0 auto 1rem auto' }} onClick={handleOutOfSync}>
            <Grid container spacing={1} style={{ color: 'var(--orange)' }}>
              <Grid item>
                <FontAwesomeIcon icon={faUserSlash} />
              </Grid>
              <Grid item>
                {translate('pageInterventionDetail.outOfSync')}
              </Grid>
            </Grid>
          </Button>
        )}

        {!userStore.isOffline && isFrance() && (
          <CerfaProgress>
            <Magnifier
              imageAlt="CERFA"
              imageSrc={`${process.env.PUBLIC_URL}/assets/images/cerfa_progress/${cerfaVersionForSteps}/step${formCurrentStep}.jpg`}
              largeImageSrc={`${process.env.PUBLIC_URL}/assets/images/cerfa_progress/${cerfaVersionForSteps}/step${formCurrentStep}-big.jpg`}
            />
          </CerfaProgress>
        )}

        <Stepper activeStep={formCurrentStep} alternativeLabel style={{ overflowX: 'auto' }}>
          {INTERVENTION_FORM_STEPS
            .filter((section, index) => {
              // If there is no control, remove the control steps
              if (!formState.values.nonPeriodicControl && !formState.values.periodicControl && (index === 2 || index === 3)) {
                return false;
              }
              // If the type is only a control, remove the fluid step
              if ([
                formState.values.assembly,
                formState.values.commissioning,
                formState.values.modification,
                formState.values.maintenance,
                formState.values.dismantling,
                formState.values.ui_other
              ].every(type => type === false) && index === 4) {
                return false;
              }
              return true;
            })
            .map((section, index) => (
              <Step completed={completedSteps.includes(index)} disabled={!completedSteps.includes(index)} key={section.id}>
                <StepButton onClick={() => setFormCurrentStep(index)}>
                  {translate(section.label)}
                </StepButton>
              </Step>
            ))}
        </Stepper>

        <InterventionFormElement name="interventionForm">
          {INTERVENTION_FORM_STEPS[formCurrentStep] && (
            <article>
              <TitlePage data-cy="interventionFormStepTitle">
                {translate(INTERVENTION_FORM_STEPS[formCurrentStep].title)}
                {INTERVENTION_FORM_STEPS[formCurrentStep].tooltip
                  && <CustomTooltip text={translate(INTERVENTION_FORM_STEPS[formCurrentStep].tooltip)} />}
              </TitlePage>
              {[
                (
                  <>
                    <EquipementOwner formState={formState} />
                    <Equipement formState={formState} textarea={textarea} />
                  </>
                ),
                <InterventionType checkbox={checkbox} countryCode={getCountryCode()} formState={formState} interventionTypeValues={interventionTypeValues} key="interventionType" text={text} textarea={textarea} />,
                <Control countryCode={getCountryCode()} formState={formState} key="control" text={text} />,
                <Leaks formState={formState} key = "leaks" text={text} />,
                <Fluids countryCode={getCountryCode()} formState={formState} key = "fluids" text={text} />,
                <Signatures countryCode={getCountryCode()} formState={formState} key = "signatures" />
              ][formCurrentStep]}
            </article>
          )}

          <Divider style={{ margin: '1rem 0' }} />

          {/* Dont show previous & next buttons on the last step */}
          {formCurrentStep !== (numberOfSections - 1) && (
            <Grid alignItems="center" container justifyContent="space-between">
              {formCurrentStep === 0 ? (
                <Button variant="text" onClick={handleInterventionQuit}>
                  {translate('button.quit')}
                </Button>
              ) : (
                <ButtonBlueLight data-cy="interventionFormPrev" disabled={formCurrentStep === 0} onClick={handleStepPrevious}>
                  {translate('button.previous')}
                </ButtonBlueLight>
              )}
              <ButtonBlue data-cy="interventionFormNext" onClick={handleStepNext}>
                {translate('button.next')}
              </ButtonBlue>
            </Grid>
          )}

          {/* Instead show the final button to send the form */}
          <div id="saveInterventionForm">
            {formCurrentStep === (numberOfSections - 1) && (
              (!formState.values.operatorSignatureField.name) ? (
                <Grid alignItems="center" container justifyContent="space-between">
                  <ButtonBlueLight data-cy="interventionFormPrev" onClick={handleStepPrevious}>
                    {translate('button.previous')}
                  </ButtonBlueLight>
                </Grid>
              ) : (
                <Grid alignItems="center" container direction="column" justifyContent="center">
                  <Grid item>
                    <Grid alignItems="center" container justifyContent="space-between">
                      {!userStore.isOffline && (
                        <img
                          alt={translate('interventionForm.general.thanks')}
                          height="40px"
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/thumbUp.png`}
                        />
                      )}
                      <Text fontSize="2rem" fontWeight={600} margin="0 0 0 2rem">
                        {translate('interventionForm.general.thanks')}
                      </Text>
                    </Grid>
                  </Grid>
                  <ButtonTurquoise
                    className="submitButton"
                    data-cy="inspectionValidate"
                    disabled={isSubmitting || outOfSyncStatus}
                    margin="1rem 0 0 0"
                    onClick={handleInterventionEnd}
                  >
                    <FontAwesomeIcon icon={isSubmitting ? faSpinner : faShareSquare} spin={isSubmitting} />
                    <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('interventionForm.general.finishAndSend')) }} />
                  </ButtonTurquoise>
                  {/* <>
                      <Typography
                        align="center"
                        dangerouslySetInnerHTML={{ __html: translate('interventionForm.general.offlineFinish') }}
                        style={{ marginTop: '1rem' }}
                      />
                      <Link style={{ margin: '10px 0 40px 0' }} to={ROUTES.INTERVENTION.LIST}>
                        {translate('button.backInterventionList')}
                      </Link>
                    </> */}
                </Grid>
              )
            )}
          </div>
        </InterventionFormElement>
      </SmallWrapper>
    </StyledInterventionForm>
  );
});
