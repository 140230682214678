import {
  faAirConditioner, faCalendarCheck, faFilePdf, faMapMarkerAlt, faTag, faTint, faTrashAlt, faUserTie
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, Tooltip, Typography
} from '@material-ui/core';
import {
  ButtonDelete, FontIcon, InterventionStatus, Text
} from 'components';
import { useModal } from 'hooks';
import { InterventionEntry } from 'models/list/InterventionList';
import React from 'react';
import Media from 'react-media';
import { InterventionsService } from 'services';
import styled from 'styled-components';
import { APPLICATION_ROLES, StatusList } from 'utils/constants';
import {
  DocumentHelper, InterventionHelper, parseDate, translate, UserHelper
} from 'utils/helpers';

const InterventionListItemDetailsContainer = styled((props) => <Grid {...props} />)`
  width: 100%;
  max-width: ${props => props.maxwidth};
  margin: 0 auto !important;

  section {
    flex: 1;
    max-width: 60%;
  }

  p {
    max-width: 100%;
    margin: 0 0 0.5rem 0;
    text-overflow: ellipsis;
    overflow: hidden;

    &:first-child {
      margin-bottom: 1rem;
    }

    svg {
      margin-right: 1rem;
    }
  }

  button {
    svg {
      transition: all var(--transitionTime);
    }
  }
`;

const ButtonPDF = styled(IconButton)`
  &:hover svg {
    color: var(--blue);
  }

  @media(max-width: 560px) {
    left: initial;
    top: initial !important;
    bottom: 0;
    right: 0;
  }
`;

export const InterventionListItemDetails = ({
  INTERVENTION_STATUS,
  deleteAction = () => { },
  intervention,
  isDeletable = false,
  isExportable = () => { },
  maxwidth = '1000px',
  operatorFullName = '',
  tooltipCheckText = '',
  tooltipDeleteText = ''
}: {
  INTERVENTION_STATUS: StatusList,
  deleteAction?: (e, hashId: string, isFromStorage: boolean) => void,
  intervention: InterventionEntry,
  isDeletable?: boolean,
  isExportable?,
  maxwidth?: string,
  operatorFullName?: string,
  tooltipCheckText?: string,
  tooltipDeleteText?: string
}) => {
  const { open } = useModal();
  const callbackClickAction = (e) => {
    e.preventDefault();
    e.stopPropagation();

    InterventionsService.getInterventionCerfa(intervention.hashId).then(response => (
      DocumentHelper.displayPDF({
        displayModal: modalType => open({
          type: modalType,
          title: response.name,
          pdf: {
            name: response.name,
            base64Content: DocumentHelper.getPDFWithBase64(response)
          }
        })
      })
    ));
  };

  const callbackDeleteAction = (e) => {
    deleteAction(e, intervention.hashId, false);
  };

  return (
    <InterventionListItemDetailsContainer
      alignItems="center"
      container
      justifyContent="space-between"
      maxwidth={maxwidth}
      spacing={1}
      wrap="nowrap"
    >
      <Media
        query="(min-width: 561px)"
        render={() => (
          <Grid item xs={2}>
            <FontIcon color="var(--blue)" fontSize="6rem" icon="icon-intervention" />
          </Grid>
        )}
      />

      <Grid item xs={7}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <Text data-cy="interventionListItemDetailName" fontSize="2rem" fontWeight={600}>
              {intervention.customerName || translate('errors.noCustomer')}
            </Text>
          </Grid>
          <Grid item>
            <p>
              <FontAwesomeIcon icon={faCalendarCheck} />
              {InterventionHelper.displayAppointment(intervention.appointment, parseDate(intervention.interventionDate))}
            </p>
          </Grid>
          {intervention.interventionAddress && (
            <Grid item>
              <p>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                {intervention.interventionAddress}
              </p>
            </Grid>
          )}
          {intervention.fileNumber && (
            <Grid item>
              <p>
                <FontAwesomeIcon icon={faTag} />
                {intervention.fileNumber}
              </p>
            </Grid>
          )}
          {intervention.equipmentIdentifier && (
            <Grid item zeroMinWidth>
              <Typography noWrap>
                <FontAwesomeIcon icon={faAirConditioner} />
                {intervention.equipmentIdentifier}
              </Typography>
            </Grid>
          )}
          {intervention.fluid && (
            <Grid item>
              <p>
                <FontAwesomeIcon icon={faTint} />
                {intervention.fluid}
              </p>
            </Grid>
          )}
          {UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.INTERVENTION_MANAGER]) && operatorFullName && (
            <Grid item>
              <p>
                <FontAwesomeIcon icon={faUserTie} />
                {operatorFullName}
              </p>
            </Grid>
          )}
        </Grid>
      </Grid>

      {intervention.status && (
        <Grid container direction="column" item style={{ gridTemplateRows: '5fr 2fr', display: 'grid', alignItems: 'start' }} wrap="nowrap" xs={3}>
          <Grid container item justifyContent='flex-end' wrap='nowrap'>
            {isExportable(intervention.status) && (
              <Tooltip title={tooltipCheckText}>
                <ButtonPDF edge="start" onClick={callbackClickAction}>
                  <FontAwesomeIcon
                    color="var(--blue)"
                    icon={faFilePdf}
                  />
                </ButtonPDF>
              </Tooltip>
            )}

            {isDeletable && (
              <Tooltip title={tooltipDeleteText}>
                <ButtonDelete edge="end" onClick={callbackDeleteAction}>
                  <FontAwesomeIcon color="var(--grey)" icon={faTrashAlt} size="xs" />
                </ButtonDelete>
              </Tooltip>
            )}
          </Grid>
          <Grid item>
            <InterventionStatus
              INTERVENTION_STATUS={INTERVENTION_STATUS}
              outOfSync={intervention.isOutOfSync}
              status={intervention.status}
            />
          </Grid>
        </Grid>
      )}
    </InterventionListItemDetailsContainer>
  );
};
