import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import shortid from 'shortid';
import styled, { css } from 'styled-components';

import { translate } from 'utils/helpers';

const StyledInterventionType = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: ${props => (props.isBig ? '100%' : '26px')};
  min-width: ${props => (props.isBig ? '100%' : '26px')};
  line-height: 1rem;
`;

const TypeContainer = styled.p`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isEven ? 'flex-start' : 'flex-end')};
  margin: 0 !important;
  text-align: ${props => (props.isEven ? 'left' : 'right')};
  line-height: 1.6rem;
  width: 50%;

  span {
    ${props => props.isEven && css`margin-right: 1rem;`}
    ${props => !props.isEven && css`margin-left: 1rem;`}
  }
`;

const Type = styled.span`
  display: inline-block;
  width: ${props => (props.isBig ? '3rem' : '1rem')};
  min-width: ${props => (props.isBig ? '3rem' : '1rem')};
  height: ${props => (props.isBig ? '3rem' : '1rem')};
  min-height: ${props => (props.isBig ? '3rem' : '1rem')};
  margin: ${props => (props.isBig ? '0.5rem' : '0.1rem')};
  background-color: ${props => (props.isSelected ? 'var(--blue)' : '#DDE8FB')};
`;

const categoryOrder = {
  assembly: 1,
  periodicControl: 2,
  commissioning: 3,
  nonPeriodicControl: 4,
  modification: 5,
  dismantling: 6,
  maintenance: 7,
  other: 8
};

const sortCategories = (a, b) => (
  categoryOrder[a] - categoryOrder[b]
);

export const InterventionType = ({ types, displayLabel }) => (
  <StyledInterventionType isBig={displayLabel}>
    {Object.keys(types)
      .filter(type => type !== 'hashId')
      .sort(sortCategories)
      .map((type, index) => {
        const isEven = Boolean(index % 2);
        const isSelected = types[type];

        return (
          <TypeContainer isEven={isEven} key={shortid.generate()}>
            {!isEven && displayLabel && isSelected && translate(`interventionTypes.${type}`)}
            <Type isBig={displayLabel} isSelected={isSelected} />
            {isEven && displayLabel && isSelected && (
              <Fragment>
                {translate(`interventionTypes.${type}`)}
                {types[type] && types[type] !== true && ` (${types[type]})`}
              </Fragment>
            )}
          </TypeContainer>
        );
      })}
  </StyledInterventionType>
);

InterventionType.propTypes = {
  types: PropTypes.shape({}).isRequired,
  displayLabel: PropTypes.bool
};

InterventionType.defaultProps = {
  displayLabel: false
};
