import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import {
  faEdit,
  faFileExcel,
  faFileImport,
  faFilePdf,
  faSpinner,
  faSync,
  faTint,
  faTintSlash,
  faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Grid, IconButton, TableCell, Tooltip
} from '@material-ui/core';
import {
  ButtonBlue,
  ButtonBlueLight,
  ButtonDelete,
  ButtonText,
  CustomIcon,
  FlexContainer,
  FontIcon,
  GenericListContainer,
  GenericListFilters,
  GenericTable,
  ReactTour,
  SkeletonTableSmall,
  SubtitlePage,
  Text,
  TextError
} from 'components';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { DocumentsService, FluidMovementsService, FluidsService } from 'services';
import shortid from 'shortid';
import {
  DocumentHelper, FilterHelper, formatDateString, translate, UserHelper
} from 'utils/helpers';

import {
  useEffectAfterRender, useExcelImport, useModal, useStores
} from 'hooks';
import { observer } from 'mobx-react-lite';
import { FluidMovementSummaryItem } from 'models';
import { FluidMovementFilter } from 'models/request/list/FluidMovementFilter';
import { useSnackbar } from 'notistack';
import sanitizeHtml from 'sanitize-html';
import { DISTRIBUTOR_ROLES } from 'utils/constants';
import { FLUID_MOVEMENT_LIST } from 'utils/fluid_movement_constants';
import { HeaderType } from 'utils/types';
import { FilterItem } from 'utils/types/FilterItem';
import { FluidMovementsListFilters } from './FluidMovementsListFilters';

const FluidMovementsListItemAmount = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${props => (props.color)};

  p {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 1rem;
  }
`;

const getTextColor = (movementType: string) => FLUID_MOVEMENT_LIST.find(movement => movement.key === movementType).color;

const getIcon = (movementType: string) => FLUID_MOVEMENT_LIST.find(movement => movement.key === movementType).icon;

const getMovementName = (movementType: string, isDistributor: boolean) => {
  const movement = FLUID_MOVEMENT_LIST.find(mov => mov.key === movementType);
  if (isDistributor && movement.labelShortDistributor) {
    return translate(movement.labelShortDistributor);
  }
  return translate(movement.labelShort);
};

const getFluidMovementListHeaderType: (
  isDistributor: boolean
) => HeaderType<FluidMovementSummaryItem> = isDistributor => ({
  name: 'movementType',
  label: translate('common.type'),
  template: row => (
    <TableCell key={shortid.generate()}>
      <Media query="(max-width: 768px)">
        {matches => (matches
          ? (
            <Tooltip title={(row.movementType && getMovementName(row.movementType, isDistributor)) || '-'}>
              <FluidMovementsListItemAmount color={getTextColor(row.movementType)}>
                {getIcon(row.movementType)}
              </FluidMovementsListItemAmount>
            </Tooltip>
          ) : (
            <FluidMovementsListItemAmount color={getTextColor(row.movementType)}>
              {getIcon(row.movementType)}
              {row.movementType ? (
                <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(getMovementName(row.movementType, isDistributor)) }} />
              ) : '-'}
            </FluidMovementsListItemAmount>
          )
        )}
      </Media>
    </TableCell>
  )
});

const getFluidMovementListHeaderDate: () => HeaderType<FluidMovementSummaryItem> = () => ({
  name: 'date',
  label: translate('common.movementDate'),
  template: row => (
    <TableCell key={shortid.generate()}>
      {row.movementDate ? formatDateString(row.movementDate) : '-'}
    </TableCell>
  )
});

const displayFluid = (row: FluidMovementSummaryItem) => {
  if (row.fluid && row.fluid.label) {
    return row.fluid.label;
  }
  return '-';
};

const getFluidMovementListHeaderFluid: () => HeaderType<FluidMovementSummaryItem> = () => ({
  name: 'fluid',
  label: translate('common.fluid'),
  template: row => (
    <TableCell key={shortid.generate()}>
      {displayFluid(row)}
      {' '}
      {row.movementType === 'INPUT' && row.isNewFluid && `(${translate('fluids.newFluid')})`}
      {row.movementType === 'INPUT' && !row.isNewFluid && `(${translate('fluids.regeneratedFluid')})`}
    </TableCell>
  )
});

const getFluidMovementListHeaderBottle: () => HeaderType<FluidMovementSummaryItem> = () => ({
  name: 'bottle',
  label: translate('common.bottle'),
  template: row => (
    <TableCell key={shortid.generate()}>
      {row.bottle ? (
        <Link to={`/bouteilles/${row.bottle.key}`} onClick={e => e.stopPropagation()}>
          {row.bottle.label}
        </Link>
      ) : '-'}
    </TableCell>
  )
});

const getFluidMovementListHeaderQuantity: () => HeaderType<FluidMovementSummaryItem> = () => ({
  name: 'quantity',
  label: translate('common.quantity'),
  template: row => (
    row.movementType && (
      <TableCell key={shortid.generate()}>
        {row.movementType === 'INPUT'
          ? (
            <FlexContainer flexDirection="column" style={{ color: getTextColor(row.movementType) }}>
              <span>{`${translate('common.theoric')}: ${row.theoricalAmount || 0}${translate('common.weightUnit')}`}</span>
              <span>{`${translate('common.measured')}: ${row.measuredAmount || 0}${translate('common.weightUnit')}`}</span>
            </FlexContainer>
          )
          : (
            <FlexContainer flexDirection="column" style={{ color: getTextColor(row.movementType) }}>
              <span>{`${row.measuredAmount || 0}${translate('common.weightUnit')}`}</span>
            </FlexContainer>
          )
        }
      </TableCell>
    )
  )
});

const getFluidMovementListHeaderBsd = (displayBsd: (row: FluidMovementSummaryItem) => void) => ({
  name: 'bsd',
  label: translate('common.followUpDocument'),
  template: (row: FluidMovementSummaryItem) => (
    <TableCell key={shortid.generate()}>
      {row.movementType === 'OUTPUT' && row.bottle && (row.hasInterventions || row.bsffIdentifier) && (
        <ButtonText
          color="var(--blue)"
          underlineHover
          onClick={(e: { stopPropagation: () => void; }) => {
            e.stopPropagation();
            displayBsd(row);
          }}
        >
          <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '0.5rem' }} />
          {translate('common.BSD')}
        </ButtonText>
      )}
    </TableCell>
  )
});

const getFluidMovementListHeaderActions: (
  editAction: (row: FluidMovementSummaryItem) => void,
  deleteAction: (row: FluidMovementSummaryItem) => void
) => HeaderType<FluidMovementSummaryItem> = (editAction, deleteAction) => ({
  name: 'actions',
  label: translate('common.actions'),
  template: row => (
    <TableCell key={shortid.generate()}>
      <Grid container wrap="nowrap">
        {row.canEdit && (
          <Grid item>
            <IconButton onClick={() => editAction(row)}>
              <FontAwesomeIcon color="var(--blue)" icon={faEdit} size="sm" />
            </IconButton>
          </Grid>
        )}
        {row.canDelete && (
          <Grid item>
            <ButtonDelete onClick={() => deleteAction(row)}>
              <FontAwesomeIcon icon={faTrashAlt} size="sm" />
            </ButtonDelete>
          </Grid>
        )}
      </Grid>
    </TableCell>
  )
});

const getFluidMovementsListHeadersDesktop: (
  displayBsd: (row: FluidMovementSummaryItem) => void,
  editAction: (row: FluidMovementSummaryItem) => void,
  deleteAction: (row: FluidMovementSummaryItem) => void,
  isDistributor: boolean
) => HeaderType<FluidMovementSummaryItem>[] = (displayBsd, editAction, deleteAction, isDistributor) => ([
  getFluidMovementListHeaderType(isDistributor),
  getFluidMovementListHeaderDate(),
  getFluidMovementListHeaderFluid(),
  getFluidMovementListHeaderBottle(),
  getFluidMovementListHeaderQuantity(),
  getFluidMovementListHeaderBsd(displayBsd),
  getFluidMovementListHeaderActions(editAction, deleteAction)
]);

const fluidMovementsListHeadersMobile: (
  editAction: (row: FluidMovementSummaryItem) => void,
  deleteAction: (row: FluidMovementSummaryItem) => void,
  isDistributor: boolean
) => HeaderType<FluidMovementSummaryItem>[] = (editAction, deleteAction, isDistributor) => [
  getFluidMovementListHeaderType(isDistributor),
  getFluidMovementListHeaderFluid(),
  getFluidMovementListHeaderQuantity(),
  getFluidMovementListHeaderActions(editAction, deleteAction)
];

const EXPORT_MAIL_SIZE = 1000;

export const FluidMovementsList = observer(() => {
  const { close, open } = useModal();
  const { userStore, fluidStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const filterKey = 'fluidMovementList';
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [fluidMovementsList, setFluidMovementsList] = useState([]);
  const [fluidMovementsListSize, setFluidMovementsListSize] = useState(0);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<FilterItem<FluidMovementFilter>[]>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const isDistributor = UserHelper.hasAccessRight(DISTRIBUTOR_ROLES);

  const getFluidMovementList = useCallback(({ pageChange = false } = {}) => {
    setIsLoading(true);
    FluidMovementsService.getFluidsMovementsList(FilterHelper.buildFilterForm(filters, search), currentPage)
      .then((response) => {
        if (response) {
          let allMovements = response.content;
          if (pageChange && currentPage > 1) {
            allMovements = [].concat(...fluidMovementsList, response.content);
          }
          setFluidMovementsList(allMovements);
          setMaxPage(response.totalPages);
          setFluidMovementsListSize(response.totalElements);
        }
      })
      .catch((error) => enqueueSnackbar(error.message || error, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [currentPage, enqueueSnackbar, fluidMovementsList, filters, search]);

  const reloadList = useCallback(() => {
    if (currentPage === 1) getFluidMovementList({ pageChange: false });
    else setCurrentPage(1);
  }, [currentPage, getFluidMovementList]);

  const reloadStoredData = useCallback(() => {
    fluidStore.loadFluidBottleExtendedOptions();
  }, [fluidStore]);

  useEffectAfterRender(() => {
    reloadList();
    // eslint-disable-next-line
  }, [filters, search]);

  useEffectAfterRender(() => {
    getFluidMovementList({ pageChange: currentPage !== 1 });
    // eslint-disable-next-line
  }, [currentPage]);

  const displayPDFModal = (response: { name: any; }) => DocumentHelper.displayPDF({
    displayModal: (modalType: any) => open({
      type: modalType,
      title: response.name,
      pdf: {
        name: response.name,
        base64Content: DocumentHelper.getPDFWithBase64(response)
      },
      onConfirm: reloadList
    })
  });

  const displayBsd = (row: { hashId: string; }) => FluidMovementsService.getBsd(row.hashId)
    .then(bsd => displayPDFModal(bsd));

  const createFluidMovement = useCallback((form) => {
    if (form.hashId) {
      return FluidMovementsService.updateFluidMovement(form.hashId, form).then(() => {
        close();
        enqueueSnackbar(translate('confirms.movement.update'), { variant: 'success' });
        reloadList();
        reloadStoredData();
      }).catch(error => enqueueSnackbar(<span dangerouslySetInnerHTML={
        { __html: sanitizeHtml(error) }
      }/>, { variant: 'error' }));
    }

    if (form.measuredAmount === 0 && form.theoricalAmount === 0
      && form.movementType && form.movementType.key === 'OUTPUT') {
      return open({
        type: 'WARNING',
        text: translate('warnings.movement.emptyRetrievalBottle'),
        buttonCancel: translate('button.cancel'),
        onConfirm: () => (
          FluidMovementsService.createFluidMovement(form).then(() => {
            close();
            enqueueSnackbar(translate('confirms.movement.create'), { variant: 'success' });
            reloadList();
            reloadStoredData();
          }).catch(error => enqueueSnackbar(<span dangerouslySetInnerHTML={
            { __html: sanitizeHtml(error) }
          }/>, { variant: 'error' }))
        )
      });
    }

    return FluidMovementsService.createFluidMovement(form)
      .then(() => {
        close();
        enqueueSnackbar(translate('confirms.movement.create'), { variant: 'success' });
        reloadList();
        reloadStoredData();
      }).catch(error => enqueueSnackbar(<span dangerouslySetInnerHTML={
        { __html: sanitizeHtml(error) }
      }/>, { variant: 'error' }));
  }, [reloadList, enqueueSnackbar, open, close, reloadStoredData]);

  const createBottle = useCallback((form) => {
    if (form.hashId) {
      return FluidsService.updateBottle(form.hashId, form).then(() => {
        close();
        reloadStoredData();
        enqueueSnackbar(translate('confirms.bottle.update'), { variant: 'success' });
        fluidStore.loadFluidBottleExtendedOptions();
      });
    }

    return FluidsService.createBottle(form).then(() => {
      close();
      reloadStoredData();
      enqueueSnackbar(translate('confirms.bottle.create'), { variant: 'success' });
      fluidStore.loadFluidBottleExtendedOptions();
    });
    // eslint-disable-next-line
  }, [enqueueSnackbar, reloadStoredData]);

  const handleExportFluidMovements = useCallback(() => {
    setIsExporting(true);

    if (Number(fluidMovementsListSize) > EXPORT_MAIL_SIZE) {
      const timeout = new Promise((res: any) => {
        setTimeout(() => res(), 1000);
      });
      const controller = new AbortController();
      const { signal } = controller;
      Promise.race([FluidMovementsService.exportFluidMovementByEmail(FilterHelper.buildFilterForm(filters, search), signal), timeout])
        .then(() => {
          enqueueSnackbar(
              translate('confirms.movement.exportByEmail').replace('<br />', '\n'),
            { variant: 'success', autoHideDuration: 5000 }
          );
          controller.abort();
        })
        .catch((error) => enqueueSnackbar(
          (error.message || error || translate('errors.export')).replace('<br />', '\n'),
          { variant: 'error', autoHideDuration: 5000 }
        ))
        .finally(() => {
          setIsExporting(false);
        });
    } else {
      FluidMovementsService.exportFluidMovement(FilterHelper.buildFilterForm(filters, search)).then((resp) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = DocumentHelper.getExcelWithBase64(resp);
        downloadLink.download = resp.name;
        downloadLink.click();
      })
        .catch((error) => enqueueSnackbar(error, { variant: 'error', autoHideDuration: 5000 }))
        .finally(() => setIsExporting(false));
    }
  }, [fluidMovementsListSize, enqueueSnackbar, filters, search]);

  const {
    isImporting: isImportingSales,
    importFunc: importSales
  } = useExcelImport(FluidMovementsService.importSales, reloadList);

  const {
    isImporting: isImportingPurchases,
    importFunc: importPurchases
  } = useExcelImport(FluidMovementsService.importPurchases, reloadList);

  const {
    isImporting: isImportingMovements,
    importFunc: importMovements
  } = useExcelImport(FluidMovementsService.importFluidMovement, null);

  const editAction = useCallback(row => open({
    type: 'CREATE_FLUID_MOVEMENT',
    onSubmit: createFluidMovement,
    movementId: row.hashId
  }), [createFluidMovement, open]);

  const deleteAction = useCallback((row) => {
    open({
      type: 'WARNING',
      text: translate('warnings.movement.delete'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => {
        FluidMovementsService.deleteFluidMovement(row.hashId)
          .then(() => {
            enqueueSnackbar(translate('confirms.movement.deleted'), { variant: 'success' });
            reloadList();
            reloadStoredData();
          });
      }
    });
  }, [enqueueSnackbar, reloadList, open, reloadStoredData]);

  const FluidMovementListTable = ({ listHeaders }) => (
    <GenericTable<FluidMovementSummaryItem>
      dataCy="fluidMovementsList"
      dataTour="step-fluids-list"
      hasMore={currentPage < maxPage}
      headers={listHeaders}
      loadMore={() => !isLoading && setCurrentPage(currentPage + 1)}
      rows={fluidMovementsList}
      total={fluidMovementsListSize}
    />
  );

  const renderButtonContainer = () => (
    <div>
      {userStore.isViewingInstitution() && (
        <>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <ButtonBlue
                data-cy="createFluidMovement"
                data-tour="step-fluids-create"
                onClick={() => open({
                  type: 'CREATE_FLUID_MOVEMENT', onSubmit: createFluidMovement
                })}
              >
                <CustomIcon icon={<FontAwesomeIcon icon={faTint} />} />
                {translate('pageFluidMovementList.buttonCreate')}
              </ButtonBlue>
            </div>
            {isDistributor ? (
              <Grid alignItems="flex-end" container item justifyContent="flex-end" xs={6}>
                <Button onClick={() => DocumentsService.getImportFile('importPurchases')}>
                  <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                    {translate('common.exampleFile')}
                  </Text>
                </Button>
                <br />
                <ButtonBlueLight data-type="sales" onClick={importPurchases}>
                  <FontAwesomeIcon icon={isImportingPurchases ? faSpinner : faFileImport} spin={isImportingPurchases} />
                  {translate(isImportingPurchases ? 'button.importing' : 'pageStocks.importPurchases')}
                </ButtonBlueLight>
              </Grid>
            ) : (
              <div>
                <ButtonBlue
                  data-cy="createFluidBottle"
                  data-tour="step-bottle-create"
                  onClick={() => open({
                    type: 'CREATE_BOTTLE',
                    onSubmit: createBottle
                  })}
                >
                  <CustomIcon icon={<FontIcon fontSize="2.4rem" icon="icon-container" />} />
                  {translate('pageBottleList.buttonCreate')}
                </ButtonBlue>
              </div>
            )}
          </div>
          <br />
        </>
      )}
      <Media
        query="(min-width: 769px)"
        render={() => (
          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              <ButtonBlueLight
                data-cy="createNewEquipmentOwner"
                data-tour="step-equipmentOwner-create"
                disabled={isExporting}
                onClick={handleExportFluidMovements}
              >
                <FontAwesomeIcon icon={isExporting ? faSpinner : faFileExcel} spin={isExporting} />
                {translate('pageFluidMovementList.buttonExport')}
              </ButtonBlueLight>
            </Grid>
            {isDistributor ? (
              <Grid alignItems="flex-end" container item justifyContent="flex-end" xs={6}>
                <Button onClick={() => DocumentsService.getImportFile('importSales')}>
                  <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                    {translate('common.exampleFile')}
                  </Text>
                </Button>
                <br />
                <ButtonBlueLight data-type="sales" onClick={importSales}>
                  <FontAwesomeIcon icon={isImportingSales ? faSpinner : faFileImport} spin={isImportingSales} />
                  {translate(isImportingSales ? 'button.importing' : 'pageStocks.importSales')}
                </ButtonBlueLight>
              </Grid>
            ) : (
              <Grid alignItems="flex-end" container item justifyContent="flex-end" xs={6}>
                <Button onClick={() => DocumentsService.getImportFile('importPurchases')}>
                  <Text color="var(--blue)" display="inline-block" fontWeight={600} margin="0">
                    {translate('common.exampleFile')}
                  </Text>
                </Button>
                <br />
                <ButtonBlueLight data-type="sales" onClick={importPurchases}>
                  <FontAwesomeIcon icon={isImportingPurchases ? faSpinner : faFileImport} spin={isImportingPurchases} />
                  {translate(isImportingPurchases ? 'button.importing' : 'pageStocks.importPurchases')}
                </ButtonBlueLight>
              </Grid>
            )}
          </Grid>
        )}
      />
      <br />
      <br />
    </div >
  );

  return (
    <GenericListContainer>
      <SubtitlePage>
        {translate('pageFluidMovementList.title')}
        <IconButton className="ml1" onClick={reloadList}>
          <FontAwesomeIcon color="var(--blue)" icon={faSync} spin={isLoading} />
        </IconButton>
      </SubtitlePage>

      {renderButtonContainer()}

      <GenericListFilters
        ComponentFilter={
          ({ currentFilters, setCurrentFilters }) => <FluidMovementsListFilters currentFilters={currentFilters} setCurrentFilters={setCurrentFilters} />
        }
        disabled={false}
        filterKey={filterKey}
        filters={filters}
        isVisible
        placeholder={translate('pageFluidMovementList.searchPlaceholder')}
        search={search}
        setFilters={setFilters}
        setSearch={setSearch}
        withSidePanel
      />

      {!isLoading && fluidMovementsList && fluidMovementsList.length === 0
        ? (
          <FlexContainer alignItems="center" flexDirection="column">
            <FontAwesomeIcon color="var(--grey)" icon={faTintSlash} size="3x" />
            <TextError>{translate('errors.noFluidMovement')}</TextError>
            {userStore.isViewingInstitution() && (
              <ButtonBlue
                data-cy="createFluidMovement"
                onClick={() => open({
                  type: 'CREATE_FLUID_MOVEMENT', onSubmit: createFluidMovement
                })}
              >
                <CustomIcon icon={<FontAwesomeIcon icon={faTint} />} />
                {translate('pageFluidMovementList.buttonCreate')}
              </ButtonBlue>
            )}
          </FlexContainer>
        ) : (
          <Media query="(max-width: 768px)">
            {matches => (matches
              ? <FluidMovementListTable listHeaders={fluidMovementsListHeadersMobile(editAction, deleteAction, isDistributor)} />
              : <FluidMovementListTable listHeaders={getFluidMovementsListHeadersDesktop(displayBsd, editAction, deleteAction, isDistributor)} />
            )}
          </Media>
        )
      }

      {isLoading && currentPage === 1 && <SkeletonTableSmall />}
      <ReactTour steps={[]} />
    </GenericListContainer>
  );
});
