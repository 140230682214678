import { SelectItem } from 'utils/types';
import { EquipmentView } from './EquipmentView';
import { FluidView } from './FluidView';

export class RefrigerationUnitView extends EquipmentView {
  nextControlDate: string;
  cadastre: string;
  installationType: SelectItem;
  vehicleType: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleYear: number;
  vehicleImat: string;
  parcNumber: string;
  hasLeakDetectionSystem: boolean;
  circuits: CircuitView[];

  // Front fields
  fluid: SelectItem;
  fluidWeight: number;
}

export const getFluidWeight: (unit: RefrigerationUnitView) => number = (unit) => {
  if (!unit) {
    return 0;
  }
  if (unit.fluidWeight) {
    return unit.fluidWeight;
  }
  if (!unit.circuits || unit.circuits.length < 1) {
    return 0;
  }
  return unit.circuits[0].fluidCapacity;
};

export interface CircuitView {
  description?: string;
  fluid?: FluidView;
  fluidAmount?: number;
  fluidCapacity?: number;
  id?: string;
}
