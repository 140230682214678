import React from 'react';
import styled from 'styled-components';

const StyledSelectCreateContainer = styled.section`
  display: flex;

  div:first-child {
    flex: 1;
  }

  & > div > button {
    margin-left: 2rem;
  }
`;

export const SelectCreateContainer = ({ children }) => (
  <StyledSelectCreateContainer>
    {children}
  </StyledSelectCreateContainer>
);
