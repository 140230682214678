import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledItemOption = styled.section`
  display: flex;
  flex: 1;
  align-items: center;

  ${props => props.isInsideSelect && css`
    padding: ${(props.noPadding ? 0 : '1rem 2rem')};
    cursor: pointer;

    &:hover {
      background-color: var(--grey-lighter);
    }
  `}

  & > svg {
    margin-right: 1rem;
  }

  article {
    flex: 1;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-left: 1rem;

    svg {
      margin-right: 1rem;
    }
  }

  p {
    margin: 0.5rem 0;
  }

  img {
    width: 50px;
    max-width: 50px;
    margin-right: 0.5rem;
  }
`;

export const ItemOption = ({
  children, isInsideSelect = false, noPadding = false, ...rest
}) => (
  <StyledItemOption {...rest} isInsideSelect={isInsideSelect} noPadding={noPadding}>
    {children}
  </StyledItemOption>
);

ItemOption.propTypes = {
  isInsideSelect: PropTypes.bool,
  noPadding: PropTypes.bool
};

ItemOption.defaultProps = {
  isInsideSelect: false,
  noPadding: false
};
