import { Grid, TextField } from '@material-ui/core';
import { Question } from 'components/Intervention';
import { LegalEntityFilter } from 'models';
import React, { useMemo } from 'react';
import { FilterHelper, translate, UserHelper } from 'utils/helpers';
import { ACCESS_TO_RECIPIENTS_ROLES } from '../../../utils/constants';

export const SupplierOrRecipientListFilter = ({
  currentFilters, setCurrentFilters
}) => {
  const hasAccessToRecipients = useMemo(() => UserHelper.hasAccessRight(ACCESS_TO_RECIPIENTS_ROLES), []);

  const handleChangeInput = (
    key: keyof LegalEntityFilter,
    value: any,
    label: string
  ) => {
    setCurrentFilters(FilterHelper.setFiltersValue(currentFilters, key, value, label));
  };

  return (
    <Grid container direction="column">
      <Question label={hasAccessToRecipients
        ? translate('pageSupplierOrRecipientList.findSupplierOrRecipient')
        : translate('pageSupplierOrRecipientList.findSupplier')}
      >
        <TextField
          autoComplete="off"
          label={translate('common.designation')}
          name="firstName"
          value={FilterHelper.getFiltersValue(currentFilters, 'firstName') || ''}
          onChange={event => handleChangeInput('firstName', event.target.value, event.target.value)}
        />
        <TextField
          autoComplete="off"
          inputProps={{ maxLength: 14 }}
          label={translate('common.siret')}
          name="siret"
          value={FilterHelper.getFiltersValue(currentFilters, 'siret') || ''}
          onChange={event => handleChangeInput('siret', event.target.value, event.target.value)}
        />
      </Question>
    </Grid>
  );
};
