import {
  BottleSummary, BottleView, Document, FluidManipulationFieldView, FluidVariationSummary, FluidView
} from 'models';
import { BottleFilter } from 'models/request/list/BottleFilter';
import { API_URL } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';
import { SelectItem } from 'utils/types';

const fluidsUrl = `${API_URL}fluids`;
const fluidsBottleUrl = `${API_URL}fluid-bottles`;
const fluidsManipulationsUrl = `${API_URL}fluid-manipulations`;

// GET
const getBottle: (
  bottleId: string
) => Promise<BottleView> = (
  bottleId
) => RequestHelper.GET(`${fluidsBottleUrl}/${bottleId}`);

const getBsd: (
  bottleId: string
) => Promise<Document> = bottleId => RequestHelper.GET(`${fluidsBottleUrl}/${bottleId}/bsd`);

const getFluidVariations: (
  bottleId: string
) => Promise<FluidVariationSummary[]> = bottleId => RequestHelper.GET(`${fluidsBottleUrl}/${bottleId}/variations`);

// POST

// FLUIDS BOTTLE
const getBottleList: (
  filter: BottleFilter,
  page: number
) => Promise<BottleSummary> = (
  filter = {},
  page = 1
) => RequestHelper.POST(`${fluidsBottleUrl}/filter?page=${page}`, filter);

const getBottleOptions: (
  filter: BottleFilter
) => Promise<SelectItem[]> = (
  filter = {}
) => RequestHelper.POST(`${fluidsBottleUrl}/options`, filter);

const getBottleOptionsExtended: (
  filter: BottleFilter
) => Promise<BottleView[]> = (
  filter = {}
) => RequestHelper.POST(`${fluidsBottleUrl}/extended-options`, filter);

const createBottle: (
  form: BottleView
) => Promise<void> = form => RequestHelper.POST(`${fluidsBottleUrl}`, form);

const importBottles: (
  document: Document
) => Promise<void> = document => RequestHelper.POST(`${fluidsBottleUrl}/import`, document);

const exportBottles: (
  filter: BottleFilter
) => Promise<Document> = (
  filter = {}
) => RequestHelper.POST(`${fluidsBottleUrl}/export`, filter);

const exportBottlesByEmail: (
  filter: BottleFilter,
  signal
) => Promise<void> = (
  filter = {},
  signal = null
) => RequestHelper.POST(`${fluidsBottleUrl}/notif-export`, filter, undefined, signal);

// FLUIDS OPTIONS
const getFluidOptions: () => Promise<SelectItem[]> = () => RequestHelper.GET(`${fluidsUrl}/options`);

const getFluidOptionsExtended: () => Promise<FluidView[]> = () => RequestHelper.GET(`${fluidsUrl}/extended-options`);

// FLUIDS MANIPULATIONS
const checkFluidManipulations: (
  fluidManipulationField: FluidManipulationFieldView
) => Promise<void> = (
  fluidManipulationField
) => RequestHelper.POST(`${fluidsManipulationsUrl}/validate`, fluidManipulationField);

const importFluidManipulations: (
  document: Document
) => Promise<void> = document => RequestHelper.POST(`${fluidsManipulationsUrl}/import`, document);

// PUT
const updateBottle: (
  bottleId: string,
  form: BottleView
) => Promise<void> = (
  bottleId,
  form
) => RequestHelper.PUT(`${fluidsBottleUrl}/${bottleId}`, form);

// DELETE
const deleteBottle: (
  bottleId: string
) => Promise<void> = bottleId => RequestHelper.DELETE(`${fluidsBottleUrl}/${bottleId}`);

export const FluidsService = {
  createBottle,
  exportBottles,
  exportBottlesByEmail,
  getBottle,
  getBsd,
  getFluidVariations,
  getBottleList,
  getBottleOptions,
  getBottleOptionsExtended,
  getFluidOptions,
  getFluidOptionsExtended,
  checkFluidManipulations,
  importFluidManipulations,
  importBottles,
  updateBottle,
  deleteBottle
};
