import React, {
  useCallback,
  useEffect, useState
} from 'react';

import styled from 'styled-components';

import { TextField } from '@material-ui/core';
import {
  ButtonBlue, ButtonBlueLight, DateTimepicker, FlexContainer, InputCounter, Question,
  SelectOperator, SkeletonDetailIntervention, SmallWrapper, TitlePage
} from 'components';
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-use-form-state'; // https://github.com/wsmd/react-use-form-state
import { InterventionsService, OperatorsService } from 'services';
import {
  DATETIME_FORMAT,
  formatDateString,
  IndexedDBHelper,
  InterventionHelper, translate
} from 'utils/helpers';

import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { CountryView, InterventionView, OperatorSummary } from 'models';
import { getDateFromAppointment } from 'models/detail/InterventionAppointment';
import { InterventionFrontView } from 'models/detail/InterventionFrontView';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { KEYCLOAK_STORE } from 'utils/helpers/IndexedDBHelper';
import { Equipement, EquipementOwner } from '../InterventionForm/Sections';

const StyledInterventionPlanification = styled.section`
  footer {
    margin-top: 4rem;
  }
`;

const COMMENT_LENGTH_MAX = 500;

export const InterventionPlanification = observer(() => {
  const { userStore } = useStores();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [availableOperators, setAVailableOperators] = useState<OperatorSummary[]>([]);

  const { id } = useParams<{id: string}>();
  const { state } = useLocation<{country: CountryView, isNew: boolean}>();

  const [formState, { text, textarea }] = useFormState<InterventionFrontView>(new InterventionFrontView());

  const isId = (idValue: string) => idValue && idValue.includes('==');

  useEffect(() => {
    if (isId(id)) {
      setIsLoading(true);
      InterventionsService.getIntervention(id)
        .then((response) => {
          if (response) {
            const interventionMapped = InterventionHelper.BACKEND_MAPPER(response);
            Object.entries(interventionMapped).forEach(([key, value]) => formState.setField(key as any, value));
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      formState.setField('fileNumber', id);
      formState.setField('country', state?.country);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    OperatorsService.getOptions().then((response) => {
      if (response) {
        setAVailableOperators(response);
      }
    });
  }, []);

  const downloadInterventionsPending = useCallback((mobileId) => {
    if (mobileId && !userStore.isOffline) {
      return InterventionsService.getInterventionsPending(mobileId).then((interventions) => {
        if (interventions.length === 0) {
          return null;
        }

        const saveAllInterventions = Promise.all(interventions.map((intervention) => {
          const { hashId } = intervention;
          return InterventionHelper.saveInterventionDetailAndForm(hashId, intervention);
        }));

        return saveAllInterventions.then(() => {
          userStore.setRefreshInterventions(true);
          enqueueSnackbar(translate('common.interventionDowloaded', { nbInterventions: interventions.length }), { variant: 'success' });
        });
      })
        .catch(error => enqueueSnackbar(error?.message || error, { variant: 'error' }))
        .finally(() => {
          IndexedDBHelper.updateData({
            store: KEYCLOAK_STORE,
            key: `lastAutomaticInterventionDownload_${userStore.currentUser?.accountId}`,
            data: new Date()
          });
        });
    }

    return Promise.resolve();
    // eslint-disable-next-line
  }, [userStore.currentUser, userStore.isOffline, enqueueSnackbar]);

  const goToInterventionDetail = (hashId) => {
    downloadInterventionsPending(userStore.deviceId).then(() => {
      enqueueSnackbar(translate('confirms.intervention.planified'), { variant: 'success' });
      history.push(`/interventions/${hashId}/detail`);
    });
  };

  const handleCancel = () => {
    if (isId(id)) {
      history.push(`/interventions/${id}/detail`);
    } else {
      history.push('/interventions');
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newIntervention: InterventionView = {
      ...InterventionHelper.BUILD_PLANIFICATION(formState.values),
      hashId: isId(id) ? id : ''
    };

    if (isId(id)) {
      InterventionsService.planIntervention(id, newIntervention).then(() => {
        goToInterventionDetail(id);
      });
    } else {
      InterventionsService.planIntervention(null, newIntervention).then((i) => {
        goToInterventionDetail(i.hashId);
      });
    }
  };

  if (isLoading) {
    return <SkeletonDetailIntervention />;
  }

  return (
    <StyledInterventionPlanification>
      <form autoComplete="off" name="interventionPlanification" onSubmit={handleFormSubmit}>
        <SmallWrapper>
          <TitlePage>
            {isId(id)
              ? translate('pageInterventionPlanification.editIntervention')
              : translate('pageInterventionPlanification.planIntervention')}
          </TitlePage>

          <Question label={`${translate('common.interventionNumber')} :`}>
            <TextField
              error={formState.errors.fileNumber && formState.errors.fileNumber !== null}
              inputProps={{ maxLength: 32 }}
              label={translate('common.cerfaNumber')}
              name="fileNumber"
              required
              {...text('fileNumber')}
            />
          </Question>

          <Question label={`${translate('common.interventionDate')} :`}>
            <DateTimepicker
              disablePast
              error={!!formState.errors.appointment}
              label="common.interventionDate"
              name="interventionDate"
              required
              value={getDateFromAppointment(formState.values.appointment)}
              onChange={value => {
                if (value) {
                  const dateTime = formatDateString(value, DATETIME_FORMAT, DATETIME_FORMAT).split(' ');
                  formState.setField('appointment', {
                    date: dateTime[0],
                    time: dateTime[1]
                  });
                } else {
                  formState.setField('appointment', null);
                }
              }}
            />
          </Question>

          <EquipementOwner
            formState={formState}
            isRequired={!formState.values.equipment}
          />

          <Equipement
            formState={formState}
            isRequired={!formState.values.equipmentOwner}
            textarea={textarea}
          />

          <Question label={`${translate('pageInterventionPlanification.assignedOperator')} :`}>
            <SelectOperator
              isRequired
              options={availableOperators}
              value={formState.values.operator}
              onChange={value => formState.setField('operator', value)}
            />
          </Question>

          <Question label={`${translate('common.comment')} :`}>
            <InputCounter maxLength={COMMENT_LENGTH_MAX} value={formState.values.comment}>
              <TextField
                inputProps={{
                  maxLength: COMMENT_LENGTH_MAX
                }}
                label={translate('pageInterventionPlanification.commentLabel')}
                maxRows={6}
                minRows={3}
                multiline
                name="comment"
                {...text('comment')}
              />
            </InputCounter>
          </Question>

          <FlexContainer alignItems="center" as="footer" justifyContent="space-between">
            <ButtonBlueLight onClick={handleCancel}>
              {translate('button.cancel')}
            </ButtonBlueLight>
            <ButtonBlue data-cy="planificationSubmit" type="submit">
              {translate('button.planIntervention')}
            </ButtonBlue>
          </FlexContainer>
        </SmallWrapper>
      </form>
    </StyledInterventionPlanification>
  );
});
