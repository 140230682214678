import { faGlobeEurope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogContent, Grid, Typography } from '@material-ui/core';
import { SelectLanguage } from 'components/Form';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';
import { CustomActionButtons } from './_CustomActionButtons';

export const ChangeLanguageModal = observer(({
  onConfirm, onClose, defaultValues
}) => {
  const { i18nStore } = useStores();
  const languages = i18nStore.languageList;
  const [language, setLanguage] = useState(defaultValues ? languages.find(lg => lg.value.toUpperCase().indexOf(defaultValues.toUpperCase()) !== -1) : null);

  const handleSelectLanguage = useCallback(value => setLanguage(value), []);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    onConfirm(language);
    onClose();
  }, [onClose, onConfirm, language]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faGlobeEurope} />
        <Typography component="span">
          {translate('modalChangeLanguage.title')}
        </Typography>
      </ModalHeader>

      <DialogContent>
        <Grid container direction="column">
          <SelectLanguage
            options={languages}
            required
            value={language || null}
            onChange={handleSelectLanguage}
          />
        </Grid>
      </DialogContent>

      <CustomActionButtons
        isValidated={defaultValues.validated}
        onClose={onClose}
      />
    </form>
  );
});
