import { faDownload, faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent
} from '@material-ui/core';
import { FlexContainer } from 'components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

const DisplayPDFTitle = styled(FlexContainer).attrs({
  justifyContent: 'space-between'
})`
  a {
    color: var(--white);
  }
`;

const PDFContainer = styled.object`
  min-width: 60vw;
  min-height: 70vh;
  max-width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

export const DisplayPDFModal = ({
  onClose, onConfirm, title, pdf
}) => (
  <Fragment>
    <ModalHeader onClose={onClose}>
      <DisplayPDFTitle>
        <span>
          <FontAwesomeIcon icon={faFilePdf} />
          {title}
        </span>
        <a download={pdf.name} href={pdf.base64Content} rel="noopener noreferrer" target="_blank">
          <FontAwesomeIcon icon={faDownload} />
          {translate('button.download')}
        </a>
      </DisplayPDFTitle>
    </ModalHeader>

    <DialogContent>
      <PDFContainer data={pdf.base64Content} type="application/pdf">
        {translate('modalDisplayPDF.noDisplay')}
      </PDFContainer>
    </DialogContent>

    <DialogActions>
      <Button color="primary" onClick={() => {
        if (onConfirm) {
          onConfirm();
        }
        return onClose();
      }}>
        {translate('button.close')}
      </Button>
    </DialogActions>
  </Fragment>
);

DisplayPDFModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  pdf: PropTypes.shape({
    base64Content: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  title: PropTypes.string
};

DisplayPDFModal.defaultProps = {
  onConfirm: null,
  title: 'PDF'
};
