import React from 'react';

import { Grid } from '@material-ui/core';
import { Question } from 'components/Intervention';
import { FilterHelper, translate } from 'utils/helpers';

import Select from 'react-select';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { EquipmentFilter } from 'models/request/list/EquipmentFilter';
import shortid from 'shortid';
import { getDatabilanToolTypeOptions } from '../../Modals/AddToolModal';

export const ToolListFilter = ({
  currentFilters, setCurrentFilters
}) => {
  const handleChangeInput = (
    key: keyof EquipmentFilter,
    value: any,
    label: string
  ) => {
    setCurrentFilters(FilterHelper.setFiltersValue(currentFilters, key, value, label));
  };

  return (
    <Grid container direction="column">
      <Question label={`${translate('common.toolTypes')} :`}>
        <Select
          id="toolType"
          isClearable
          isSearchable
          noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
          options={getDatabilanToolTypeOptions()}
          placeholder={translate('common.searchByName')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
          value={FilterHelper.getFiltersValue(currentFilters, 'type')}
          onChange={(value) => handleChangeInput('type', value, value?.label) }
        />
      </Question>

      <Question label={translate('common.validated')}>
        <ToggleButtonGroup
          aria-label={translate('common.validated')}
          exclusive
          size="medium"
          value={FilterHelper.getFiltersValue(currentFilters, 'validated')}
          onChange={(_, value) => {
            const label = (value !== null && value && translate('common.validated')) || translate('common.unvalidated');
            handleChangeInput('validated', value, label);
          }}
        >
          <ToggleButton
            aria-label={translate('common.yes')}
            key={shortid.generate()}
            value={true}
          >
            {translate('common.yes')}
          </ToggleButton>
          <ToggleButton
            aria-label={translate('common.no')}
            key={shortid.generate()}
            value={false}
          >
            {translate('common.no')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Question>

      <Question label={translate('common.activated')}>
        <ToggleButtonGroup
          aria-label={translate('common.activated')}
          exclusive
          size="medium"
          value={FilterHelper.getFiltersValue(currentFilters, 'activated')}
          onChange={(_, value) => {
            const label = (value !== null && value && translate('common.activated')) || translate('common.deactivated');
            handleChangeInput('activated', value, label);
          }}
        >
          <ToggleButton
            aria-label={translate('common.yes')}
            key={shortid.generate()}
            value={true}
          >
            {translate('common.yes')}
          </ToggleButton>
          <ToggleButton
            aria-label={translate('common.no')}
            key={shortid.generate()}
            value={false}
          >
            {translate('common.no')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Question>
    </Grid>
  );
};
