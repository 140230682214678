import React from 'react';
import styled from 'styled-components';

import { Card, FlexContainer, FontIcon } from 'components';

const CardLeaksTitle = styled.h2`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.8rem;

  span {
    margin-right: 0.5rem;
    font-size: 5.2rem;
  }
`;

const CardLeaksSubtitle = styled.h3`
  margin: 0;
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--grey-light);
`;

export const CardLeaks = () => (
  <Card
    background="linear-gradient(270deg, rgba(42,34,78,1) 0%, rgba(19,15,38,1) 100%)"
    buttonLink="/interventions"
    buttonText="Voir les fuites"
    dataTour="step-leaks"
    hasArrow
  >
    <FlexContainer alignItems="center" justifyContent="flex-start">
      <FontIcon fontSize="6rem" icon="icon-intervention" marginRight="2rem" />
      <div>
        <CardLeaksTitle>
          <span>3</span>
          fuites
          <br />
          à réparer
        </CardLeaksTitle>
        <CardLeaksSubtitle>
          {'10 jours passés sur l\'une des fuites'}
        </CardLeaksSubtitle>
      </div>
    </FlexContainer>
  </Card>
);
