import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { ItemOption } from 'components';
import { Flag } from 'components/Flag';
import { useStores } from 'hooks';
import { CountrySelectItem, CountryView } from 'models';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { CountriesService } from 'services';
import { InterventionHelper, UserHelper } from 'utils/helpers';
import { SelectField } from '..';

const displayImg = (data) => (navigator.onLine ? (
  <Flag
    alt={data.countryCode ?? data.value}
    countryCode={data.countryCode ?? data.value}
    isInsideSelect
    style={{ margin: '3px 10px' }} />
) : (
  <span style={{ width: '20px', height: '15px' }} />
));

const CustomOption = ({ data, innerProps }) => (
  <ItemOption {...innerProps} isInsideSelect noPadding>
    {displayImg(data)}
    <Typography>
      {data.label}
    </Typography>
  </ItemOption>
);

const CustomValue = ({ data, innerProps }) => (
  <ItemOption {...innerProps}>
    {displayImg(data)}
    <Typography style={{ marginLeft: '0.8rem' }}>
      {data.label}
    </Typography>
  </ItemOption>
);

export const SelectCountryDetail = ({
  value, onChange, required = false
}: {
  onChange: (value: CountryView) => void,
  required?: boolean,
  value: CountryView
}) => {
  const { userStore } = useStores();
  const [isFetchingCountries, setIsFetchingCountries] = useState(false);
  const [countriesSelectItems, setCountriesSelectItems] = useState<CountrySelectItem[]>([]);
  const [countryDetails, setCountryDetails] = useState<CountryView[]>([]);

  const handleChange = useCallback((countryItem: CountrySelectItem) => {
    const matchingCountry = countryDetails.find(country => country.id.toString() === countryItem.value.toString());
    onChange(matchingCountry);
  }, [onChange, countryDetails]);

  const setCountries = useCallback((countries: CountryView[]) => {
    const countriesOptions = InterventionHelper.MAP_COUNTRIES(countries);
    setCountriesSelectItems(countriesOptions);
    setCountryDetails(countries);
    if (value) {
      onChange(value);
    } else {
      const defaultCountry = UserHelper.getDefaultCountry();
      onChange(defaultCountry);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsFetchingCountries(true);
    if (userStore.isOffline) {
      InterventionHelper.loadCountriesStored()
        .then(setCountries)
        .finally(() => setIsFetchingCountries(false));
    } else {
      CountriesService.getCountryDetails()
        .then(setCountries)
        .finally(() => setIsFetchingCountries(false));
    }
  }, [userStore.isOffline, setCountries]);

  return (
    <>
      {isFetchingCountries ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <SelectField
          closeMenuOnSelect={undefined}
          components={{ Option: CustomOption, SingleValue: CustomValue }}
          id="selectCountry"
          isClearable={false}
          label="common.selectCountry"
          name="country"
          noOptionsMessage={undefined}
          options={countriesSelectItems}
          placeholder={undefined}
          required={required}
          value={InterventionHelper.MAP_COUNTRY(value)}
          width={undefined}
          onChange={handleChange} />
      )}
    </>
  );
};
