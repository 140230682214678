/* eslint-disable react/jsx-no-bind */
import {
  faChevronLeft, faChevronRight, faDownload, faFilePdf
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogContent, Grid
} from '@material-ui/core';
import { SkeletonPagePDF } from 'components';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useState
} from 'react';
import { Document as DocumentPDF, Page, pdfjs } from 'react-pdf'; // Docs : https://www.npmjs.com/package/react-pdf
import styled, { css } from 'styled-components';
import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DisplayPDFMobileModalContainer = styled.div`
  position: relative;

  .react-pdf__Page,
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }

  .MuiDialogContent-root {
    height: ${props => props.height};
    background: var(--grey-lighter);
  }

  .MuiDialogActions-root {
    background: var(--grey-lighter);
    border-top: 1px solid var(--grey-light);
  }
`;

const PaginationArrow = styled.aside`
  position: absolute;
  top: 64px;
  bottom: 64px;
  width: 50px;
  text-align: center;
  z-index: 10;

  ${props => props.left && css`left: 0;`}
  ${props => props.right && css`right: 0;`}

  button {
    height: 100%;
    width: 100%;
    min-width: auto;
  }
`;

const DisplayPDFMobileModal = ({
  onClose, title, pdf
}) => {
  const [pageTotal, setPageTotal] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfProps, setPdfProps] = useState({});

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === pageTotal;
  const headerHeight = `${document.getElementById('modal-header')?.clientHeight}px`;
  const containerHeight = `calc(100vh - ${headerHeight})`;

  const handlePageMinus = useCallback(() => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }, [pageNumber]);

  const handlePagePlus = useCallback(() => {
    if (pageNumber < pageTotal) {
      setPageNumber(pageNumber + 1);
    }
  }, [pageNumber, pageTotal]);

  useEffect(() => {
    const screenRatio = window.innerWidth / window.innerHeight;
    const margins = 160;
    const smallMargins = 40;
    const customWidth = Number(window.innerWidth - smallMargins);
    const customHeight = Number(window.innerHeight - margins);

    if (screenRatio < 0.65) {
      setPdfProps({ width: customWidth / 2 });
    } else {
      setPdfProps({ height: customHeight / 2 });
    }
  }, []);

  useEffect(() => {
    const pageRef = document.getElementsByClassName('react-pdf__Page');

    if (pageRef && pageRef.item(0)) {
      pageRef.item(0).style.width = `${pdfProps.width}px`;
    }
  }, [pdfProps]);

  const handleFileSuccess = useCallback(({ numPages }) => setPageTotal(numPages), []);

  return (
    <DisplayPDFMobileModalContainer height={containerHeight} id="fullscreen">
      <ModalHeader onClose={onClose}>
        <Grid
          alignItems="flex-start"
          container
          direction="column"
        >
          <Grid item xs>
            <FontAwesomeIcon icon={faFilePdf} />
            {title}
          </Grid>
          <Grid item>
            <a
              download={pdf.name}
              href={pdf.base64Content}
              rel="noopener noreferrer"
              style={{ color: 'var(--white)' }}
              target="_blank"
            >
              <FontAwesomeIcon icon={faDownload} />
              {translate('button.download')}
            </a>
          </Grid>
        </Grid>
      </ModalHeader>

      <DialogContent>
        {pageTotal > 1 && (
          <PaginationArrow left>
            <Button color="primary" disabled={isFirstPage} onClick={handlePageMinus}>
              <FontAwesomeIcon icon={faChevronLeft} size="2x" />
            </Button>
          </PaginationArrow>
        )}

        <DocumentPDF
          externalLinkTarget="_blank"
          file={pdf.base64Content}
          loading={<SkeletonPagePDF />}
          onLoadSuccess={handleFileSuccess}
        >
          <Page
            loading={<SkeletonPagePDF />}
            pageNumber={pageNumber}
            scale="2"
            {...pdfProps}
          />
        </DocumentPDF>

        {pageTotal > 1 && (
          <PaginationArrow right>
            <Button color="primary" disabled={isLastPage} onClick={handlePagePlus}>
              <FontAwesomeIcon icon={faChevronRight} size="2x" />
            </Button>
          </PaginationArrow>
        )}
      </DialogContent>
    </DisplayPDFMobileModalContainer>
  );
};

DisplayPDFMobileModal.propTypes = {
  onClose: PropTypes.func,
  pdf: PropTypes.shape({}).isRequired,
  title: PropTypes.string
};

DisplayPDFMobileModal.defaultProps = {
  onClose: null,
  title: 'PDF'
};

export default DisplayPDFMobileModal;
