import { EmailValidator } from 'commons-validator-js';
import { FluidManipulationEntryView, FluidManipulationFieldView, InterventionView } from 'models';
import { getName } from 'models/detail/EntityView';
import { getIdentifier } from 'models/detail/EquipmentView';
import { FormFrontView, getAddressFromForm, getDateFromForm } from 'models/detail/FormFrontView';
import { FormView } from 'models/detail/FormView';
import { getAddressFromIntervention } from 'models/detail/InterventionView';
import { InterventionEntry } from 'models/list/InterventionList';
import { useEffect, useState } from 'react';
import { StateValues } from 'react-use-form-state';
import { FLUID_MANIPULATION_LIST_INPUT, FLUID_MANIPULATION_LIST_OUTPUT } from 'utils/constants';
import { translate } from 'utils/helpers';
import { DocumentHelper } from './DocumentHelper';
import { InterventionHelper } from './InterventionHelper';

const SELECT_ADAPTOR_TO_FRONT = ({ selectItem, translationKey = '' }) => selectItem && ({
  ...selectItem,
  value: selectItem.key,
  label: translationKey ? translate(`${translationKey}.${selectItem.label}`) : selectItem.label,
  origin: selectItem.label
});

const SELECT_ADAPTOR_TO_FRONT_MULTI = ({ selectItems, translationKey = '' }) => selectItems.map(selectItem => ({
  ...selectItem,
  value: selectItem.key,
  label: translationKey ? translate(`${translationKey}.${selectItem.label}`) : selectItem.label,
  origin: selectItem.label
}));

const SELECT_ADAPTOR_TO_BACK = selectItem => selectItem && ({
  ...selectItem,
  key: selectItem.value
});

const SELECT_ADAPTOR_TO_BACK_MULTI = selectItems => selectItems.map(selectItem => ({
  ...selectItem,
  key: selectItem.value
}));

const isFieldRequired = ({ fieldsToCheck = [] }) => fieldsToCheck.some(field => field);

const BUILD_FLUID_MANIPULATIONS: (params: {
  inputList: FluidManipulationEntryView[],
  inputTotal: string | number,
  outputList: FluidManipulationEntryView[],
  outputTotal: string | number
}) => FluidManipulationFieldView = ({
  inputList,
  inputTotal,
  outputList,
  outputTotal
}) => {
  let fluidManipulationsInputMapped = [];
  let fluidManipulationsOutputMapped = [];

  if (inputList && inputList.length > 0) {
    fluidManipulationsInputMapped = inputList.map(manip => ({
      ...manip,
      fluid: SELECT_ADAPTOR_TO_BACK(manip.fluid),
      fluidBottle: SELECT_ADAPTOR_TO_BACK(manip.fluidBottle),
      manipulationType: SELECT_ADAPTOR_TO_BACK(manip.manipulationType)
    }));
  }

  if (outputList && outputList.length > 0) {
    fluidManipulationsOutputMapped = outputList.map(manip => ({
      ...manip,
      fluid: SELECT_ADAPTOR_TO_BACK(manip.fluid),
      fluidBottle: SELECT_ADAPTOR_TO_BACK(manip.fluidBottle),
      manipulationType: SELECT_ADAPTOR_TO_BACK(manip.manipulationType)
    }));
  }

  return {
    inputFluidTotal: Number(inputTotal),
    outputFluidTotal: Number(outputTotal),
    fluidManipulations: fluidManipulationsInputMapped.concat(fluidManipulationsOutputMapped)
  };
};

const FRONT_LIST_MAPPER: (
  form: FormFrontView,
  isOutOfSync?:boolean
) => InterventionEntry = (
  form,
  isOutOfSync = false
) => {
  if (!form) {
    return null;
  }
  return {
    ...form,
    canDelete: true,
    customerName: getName(form.equipmentOwner),
    equipmentIdentifier: getIdentifier(form.equipment),
    fileNumber: form.fileNumber || '',
    fluid: form.circuit?.fluid.name,
    interventionAddress: getAddressFromForm(form),
    interventionDate: getDateFromForm(form),
    isOutOfSync,
    operatorFullName: getName(form.operator),
    status: form.status || ''
  };
};

const BACK_LIST_MAPPER: (
  intervention: InterventionView
) => InterventionEntry = (
  intervention
) => {
  if (!intervention || !intervention.form) {
    return null;
  }
  return {
    ...intervention,
    canDelete: false,
    customerName: getName(intervention.form.equipmentOwnerField?.entity),
    equipmentIdentifier: getIdentifier(intervention.form.equipmentField?.equipment),
    fluid: intervention.form.equipmentField?.circuit?.fluid.name,
    operatorFullName: intervention.operator?.fullName,
    interventionAddress: getAddressFromIntervention(intervention)
  };
};

const BUILD: (
  form: FormFrontView | StateValues<FormFrontView>
) => FormView = (
  form
) => {
  if (!form) {
    return null;
  }
  const builtForm: FormView = {
    ...form,
    interventionDate: getDateFromForm(form as FormFrontView),
    operatorField: {
      entity: form.operator
    },
    equipmentOwnerField: {
      entity: {
        ...form.equipmentOwner,
        // Removing entity types to avoid errors with old data
        entityTypes: []
      }
    },
    equipmentField: {
      equipment: form.equipment && {
        ...form.equipment,
        hasLeakDetectionSystem: form.equipment.hasLeakDetectionSystem || form.leakDetectionSystem || false,
        type: null
      },
      circuit: form.circuit
    },
    interventionCategory: {
      assembly: form.assembly,
      commissioning: form.commissioning,
      modification: form.modification,
      maintenance: form.maintenance,
      periodicControl: form.periodicControl,
      nonPeriodicControl: form.nonPeriodicControl,
      dismantling: form.dismantling,
      other: form.other || ''
    },
    leakDetectorField: {
      tool: form.leakDetector
    },
    scaleField: {
      tool: form.scale
    },
    leakField: {
      leakFound: form.leakFound,
      leaks: form.leaks && form.leaks.length > 0 ? form.leaks.map(leak => ({
        ...leak,
        documents: DocumentHelper.getDocumentsFormatted({ documents: leak.documents, withBase64: false })
      })) : []
    },
    fluidManipulationField: BUILD_FLUID_MANIPULATIONS({
      inputList: form.fluidManipulationsInput,
      outputList: form.fluidManipulationsOutput,
      inputTotal: form.inputFluidTotal,
      outputTotal: form.outputFluidTotal
    }),
    operatorSignatureField: {
      ...form.operatorSignatureField,
      document: {
        ...form.operatorSignatureField?.document,
        base64Content: (form.operatorSignatureField?.document?.base64Content && DocumentHelper.getDocumentWithoutBase64(form.operatorSignatureField?.document)) || ''
      }
    },
    customerSignatureField: {
      ...form.customerSignatureField,
      document: {
        ...form.customerSignatureField?.document,
        base64Content: (form.customerSignatureField?.document?.base64Content && DocumentHelper.getDocumentWithoutBase64(form.customerSignatureField?.document)) || ''
      }
    }
  };

  return builtForm;
};

const BACKEND_MAPPER: (
  intervention: InterventionView
) => FormFrontView = (
  intervention
) => {
  const inputList = FLUID_MANIPULATION_LIST_INPUT.map(manip => manip.value);
  const outputList = FLUID_MANIPULATION_LIST_OUTPUT.map(manip => manip.value);

  const fluidManipulationsInputMapped = intervention.form.fluidManipulationField?.fluidManipulations.filter(manip => inputList.indexOf(manip.manipulationType.key) > -1) || [];
  const fluidManipulationsOutputMapped = intervention.form.fluidManipulationField?.fluidManipulations.filter(manip => outputList.indexOf(manip.manipulationType.key) > -1) || [];

  const frontendForm: FormFrontView = {
    ...intervention.form,
    ...intervention.form.interventionCategory,
    hashId: intervention.hashId,
    appointment: intervention.appointment || InterventionHelper.getFormattedDateTime(),
    fileNumber: intervention.fileNumber,
    status: intervention.status,
    country: intervention.country,
    equipment: intervention.form.equipmentField ? {
      ...intervention.form.equipmentField.equipment,
      circuits: [intervention.form.equipmentField.circuit]
    } : null,
    circuit: intervention.form.equipmentField?.circuit,
    equipmentOwner: intervention.form.equipmentOwnerField?.entity,
    operator: intervention.form.operatorField?.entity,
    ui_other: intervention.form.interventionCategory ? Boolean(intervention.form.interventionCategory.other) : false,
    leakDetector: intervention.form.leakDetectorField?.tool,
    scale: intervention.form.scaleField?.tool,
    leakDetectionSystem: intervention.form.leakDetectionSystem || intervention.form.equipmentField?.equipment?.hasLeakDetectionSystem || false,
    leakFound: intervention.form.leakField?.leakFound || false,
    leaks: intervention.form.leakField?.leaks?.length > 0 ? intervention.form.leakField.leaks.map(leak => ({
      ...leak,
      documents: DocumentHelper.getDocumentsFormatted({ documents: leak.documents })
    })) : [],

    didFluidManipulationInput: fluidManipulationsInputMapped.length > 0,
    didFluidManipulationOutput: fluidManipulationsOutputMapped.length > 0,
    inputFluidTotal: intervention.form.fluidManipulationField?.inputFluidTotal,
    outputFluidTotal: intervention.form.fluidManipulationField?.outputFluidTotal,
    fluidManipulationsInput: fluidManipulationsInputMapped.map((manip) => ({
      ...manip,
      fluid: SELECT_ADAPTOR_TO_FRONT({ selectItem: manip.fluid }),
      fluidBottle: SELECT_ADAPTOR_TO_FRONT({ selectItem: manip.fluidBottle }),
      manipulationType: SELECT_ADAPTOR_TO_FRONT({ selectItem: manip.manipulationType, translationKey: 'manipulations' })
    })),
    fluidManipulationsOutput: fluidManipulationsOutputMapped.map((manip) => ({
      ...manip,
      fluid: SELECT_ADAPTOR_TO_FRONT({ selectItem: manip.fluid }),
      fluidBottle: SELECT_ADAPTOR_TO_FRONT({ selectItem: manip.fluidBottle }),
      manipulationType: SELECT_ADAPTOR_TO_FRONT({ selectItem: manip.manipulationType, translationKey: 'manipulations' })
    })),

    observations: intervention.form.observations || '',
    operatorSignatureField: {
      ...intervention.form.operatorSignatureField,
      interventionDate: intervention.form.interventionDate,
      document: {
        ...intervention.form.operatorSignatureField?.document,
        base64Content: (intervention.form.operatorSignatureField?.document?.base64Content && DocumentHelper.getImageWithBase64(intervention.form.operatorSignatureField?.document)) || ''
      }
    },
    customerSignatureField: {
      ...intervention.form.customerSignatureField,
      document: {
        ...intervention.form.customerSignatureField?.document,
        base64Content: (intervention.form.customerSignatureField?.document?.base64Content && DocumentHelper.getImageWithBase64(intervention.form.customerSignatureField?.document)) || ''
      }
    }
  };

  return frontendForm;
};

const useDebounce = (value, delay = 2000) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const isEmailValid = email => {
  if (!email) return true;
  const emailValidator = new EmailValidator();
  return emailValidator.isValid(email);
};

const validateEmail = email => {
  const isValid = isEmailValid(email);
  if (!isValid) {
    return translate('modalUser.invalidEmail');
  }
  return null;
};

const validateEmails = emails => {
  if (!emails) {
    return null;
  }
  const invalidEmails = emails.split(';')
      .map(email => (isEmailValid(email) ? null : email))
      .filter(email => email !== null);

  if (invalidEmails?.length) {
    return `${translate('common.contactEmails.invalid')} ${invalidEmails.join(', ')}`;
  }
  return null;
};

const validateSiret = (siret: string, siretMinLength: number = 14, onlyNumbers: boolean = true): string | boolean => {
  if (!siret?.trim()) return translate('warnings.intervention.siretIsMissing');
  if (siret.length < siretMinLength) return translate('warnings.intervention.siretTooShort', { length: siretMinLength });
  if (onlyNumbers && !/^\d+$/.test(siret)) return translate('warnings.intervention.siretNumbersOnly');

  return true;
};

export const checkRegexMismatch = (rule, value) => {
  // If no rule is specified, the regex is always considered a match
  if (!rule) {
    return false;
  }
  // If no value is specified, the check is ignored to avoid an instant rejection
  if (!value) {
    return false;
  }
  return !new RegExp(`^${rule}$`).test(value);
};

export const FormHelper = {
  BUILD,
  FRONT_LIST_MAPPER,
  BACK_LIST_MAPPER,
  BUILD_FLUID_MANIPULATIONS,
  BACKEND_MAPPER,
  SELECT_ADAPTOR_TO_FRONT,
  SELECT_ADAPTOR_TO_FRONT_MULTI,
  SELECT_ADAPTOR_TO_BACK,
  SELECT_ADAPTOR_TO_BACK_MULTI,
  useDebounce,
  isFieldRequired,
  validateEmail,
  validateEmails,
  validateSiret,
  checkRegexMismatch
};
