import {
  Button, DialogActions, DialogContent
} from '@material-ui/core';
import { Datepicker } from 'components/Datepicker/Datepicker';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormState } from 'react-use-form-state';
import { translate } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';

export const UpdateInterventionDateModal = ({
  onClose, onConfirm, closeOnSubmit
}) => {
  const [formState] = useFormState({
    date: new Date()
  });

  const handleValidateModal = (e) => {
    e.preventDefault();
    onConfirm(formState.values.date);

    if (closeOnSubmit) {
      onClose();
    }
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        {translate('modalUpdateInterventionDate.title')}
      </ModalHeader>

      <DialogContent style={{ minWidth: '600px' }}>
        <Datepicker
          error={formState.errors.date && formState.errors.date !== null}
          label="common.interventionDate"
          name="date"
          required
          value={formState.values.date}
          onChange={value => formState.setField('date', value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};

UpdateInterventionDateModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

UpdateInterventionDateModal.defaultProps = {
  closeOnSubmit: true
};
