import {
  Grid,
  Table, TableCell, TableHead, TableRow, Typography
} from '@material-ui/core';
import { CustomTooltip, SkeletonTable } from 'components';
import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroller'; // Docs : https://www.npmjs.com/package/react-infinite-scroller
import styled from 'styled-components';
import { translate } from 'utils/helpers';
import { HeaderType } from 'utils/types';

const GenericTableContainer = styled.div`
  position: relative;
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-x: auto;
`;

const TotalRows = styled(Typography)`
  font-size: 1.4rem;
  color: var(--grey-dark);
  text-align: right;

  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const TableRowComponent = ({
  hover, row, onRowClick, headers, cursor
}) => {
  const handleClick = useCallback(() => onRowClick(row), [onRowClick, row]);

  return (
    <TableRow
      hover={hover}
      style={{ cursor }}
      onClick={handleClick}
    >
      {headers.map(column => (
        column.template(row)
      ))}
    </TableRow>
  );
};

export const GenericTable: <T>(_: {
  dataCy?: string,
  dataTour?: string,
  displayTotal?: boolean,
  hasMore?: boolean,
  headers: HeaderType<T>[],
  hover?: boolean,
  id?: string,
  loadMore?: any,
  onRowClick?: (row: T) => void,
  rows?: T[],
  stickyHeader?: boolean,
  total?: number
}) => JSX.Element = ({
  dataCy = '',
  dataTour = '',
  displayTotal = true,
  hasMore = false,
  headers,
  hover = true,
  id = null,
  loadMore,
  onRowClick,
  rows = [],
  stickyHeader = true,
  total = 0
}) => {
  const handleRowClick = useCallback(row => onRowClick && onRowClick(row), [onRowClick]);

  if (rows.length === 0) return null;

  const headerWithToolTip = ((header) => (
    <Grid
        container
        direction="row"
        justifyContent="flex-start"
        wrap="nowrap"
    >
      <Grid alignContent="center" item>
        {header.label}
      </Grid>
      <Grid item>
        <CustomTooltip icon={header.toolTipIcon} text={header.toolTipLabel} />
      </Grid>
    </Grid>
  ));

  return (
    <GenericTableContainer data-cy={dataCy} data-tour={dataTour}>
      {displayTotal && total > 0 && (
        <TotalRows gutterBottom>
          {`${rows.length} / ${total || 0} ${translate('common.totalDisplayed')}`}
        </TotalRows>
      )}

      <Table
        size="small"
        stickyHeader={stickyHeader}
      >
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableCell key={header.name} style={{ width: header.width || 'initial' }}>
                {header.toolTipLabel
                    ? headerWithToolTip(header)
                    : header.label
                }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <InfiniteScroll
          element="tbody"
          hasMore={hasMore}
          id={id}
          initialLoad={false}
          loader={loadMore && <SkeletonTable data-cy="skeletonTable" key={0} />}
          loadMore={page => loadMore && hasMore && loadMore(page)}
          pageStart={1}
          style={{ minHeight: '1200px' }}
          threshold={100}
        >
          {rows.map((row, i) => (
            <TableRowComponent
              cursor={onRowClick ? 'pointer' : 'initial'}
              headers={headers}
              hover={isMobile ? false : hover}
              key={i}
              row={row}
              onRowClick={handleRowClick}
            />
          ))}
        </InfiniteScroll>
      </Table>
    </GenericTableContainer>
  );
};
