import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Grid } from '@material-ui/core';
import {
  GenericListFilters,
  ReactTour,
  SelectField,
  SkeletonSection,
  SubtitlePage,
  Wrapper
} from 'components/index';
import TreeNodeButtons from 'components/StructureLevelTree/TreeNodeButtons';
import TreeNodeContent from 'components/StructureLevelTree/TreeNodeContent';
import { useFetch, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import SortableTree, { changeNodeAtPath, getVisibleNodeCount, map } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { ActivityService, CompanyService, StructureLevelService } from 'services';
import styled from 'styled-components';
import { translate } from 'utils/helpers';

const StyledSelectLoader = styled.div`
  height: 56px;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const minNodeSize = 5;

const generateTitle = node => <TreeNodeContent node={node} />;

const StructureLevelTree = () => {
  const { userStore } = useStores();
  const { treeRootCompany } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const [isTreeLoading, setIsTreeLoading] = useState(true);
  const [activity, setActivity] = useState(null);
  const [parentCompany, setParentCompany] = useState(null);
  const [tree, setTree] = useState([]);
  const { response: activities, isLoading: isActivityLoading } = useFetch(ActivityService.getDatabilanActivities, []);
  const [modifiedTree, setModifiedTree] = useState(null);
  const [search, setSearch] = useState('');
  const [nodeSize, setNodeSize] = useState(minNodeSize);
  const getNodeKey = data => data.node.key;
  const displayedTree = modifiedTree || tree;

  const [companyId, setCompanyId] = useState(treeRootCompany.value);

  const formatTree = useCallback(notFormattedTree => map({
    treeData: notFormattedTree,
    getNodeKey: node => node.value,
    callback: ({ node }) => ({ ...node, title: generateTitle(node), expanded: true }),
    ignoreCollapsed: false
    // eslint-disable-next-line
  }), []);

  const updateNode = useCallback(({ treeData, newNode, path }) => formatTree(changeNodeAtPath({
    treeData, path, newNode, getNodeKey, ignoreCollapsed: false
  })), [formatTree]);

  const searchMethod = ({ node, searchQuery }) => searchQuery && node?.label?.toLowerCase().includes(searchQuery.toLowerCase());

  const getParentCompany = useCallback(() => {
    CompanyService.getParentCompany(companyId)
      .then(resp => setParentCompany(resp))
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  }, [companyId, enqueueSnackbar]);

  const reloadCompanyTree = useCallback(() => {
    if (activity) {
      setIsTreeLoading(true);
      CompanyService.getCompanyTree(companyId, activity.value)
        .then(resp => {
          setTree(formatTree([resp]));
          setModifiedTree(null);
          getParentCompany();
          userStore.refreshScope();
        })
        .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
        .finally(() => setIsTreeLoading(false));
    }
    // eslint-disable-next-line
  }, [companyId, enqueueSnackbar, formatTree, activity, getParentCompany]);

  const setChildCompany = useCallback(selectedCompany => {
    setCompanyId(selectedCompany.value);
  }, []);

  const onMoveNode = useCallback(data => {
    if (data.path.toString() !== data.prevPath.toString()) {
      setIsTreeLoading(true);
      const moveData = {
        movedEntityId: data.node.value,
        movedEntityType: data.node.type,
        newParentEntityId: data.nextParentNode.value,
        newParentEntityType: data.nextParentNode.type,
        activity: activity.value
      };
      StructureLevelService.moveStructureLevel(moveData)
        .then(resp => {
          enqueueSnackbar(translate('confirms.structureLevelTree.moved'), { variant: 'success' });
          const newNode = formatTree([{ ...resp, expanded: data.node.expanded, canEdit: data.node.canEdit }])[0];
          setTree(updateNode({ treeData: data.treeData, newNode, path: data.path }));
          userStore.refreshScope();
        })
        .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
        .finally(() => {
          setIsTreeLoading(false);
          setModifiedTree(null);
        });
    }
    // eslint-disable-next-line
  }, [enqueueSnackbar, formatTree, updateNode, activity]);

  const onTreeUpdated = () => {
    setIsTreeLoading(false);
    setModifiedTree(null);
  };

  const onCreate = ({
    newNode, confirmLabel, parentNode, parentPath
  }) => {
    const newChildren = [newNode, ...parentNode.children];
    const newParent = { ...parentNode, children: newChildren };
    setTree(updateNode({ treeData: displayedTree, newNode: newParent, path: parentPath }));
    userStore.refreshScope();
    enqueueSnackbar(confirmLabel, { variant: 'success' });
  };

  const onUpdate = ({ newNode, confirmLabel, path }) => {
    setTree(updateNode({ treeData: displayedTree, newNode, path }));
    userStore.refreshScope();
    enqueueSnackbar(confirmLabel, { variant: 'success' });
  };

  const onDelete = ({ node }) => {
    enqueueSnackbar(translate('confirms.structureLevel.delete', { name: node.label }), { variant: 'success' });
    reloadCompanyTree();
  };

  const displayParentCompanyBlock = () => Boolean(parentCompany) && (
  <Grid container direction="row" item justifyContent="center" spacing={4}>
    <Grid item>
      <Card style={{
            height: 56, padding: 10, display: 'flex', border: 'solid #bbb 1px'
          }}
          >
        <Grid alignItems="center" container direction="row" spacing={3}>
          <Grid item>
            <TreeNodeContent node={parentCompany.parentCompany} />
          </Grid>
          <Grid item>
            <TreeNodeButtons
                  activity={activity}
                  companyId={companyId}
                  node={parentCompany.parentCompany}
                  setLoading={setIsTreeLoading}
                  onCreate={reloadCompanyTree}
                  onParentCompanyChanged={reloadCompanyTree}
                  onTreeUpdated={onTreeUpdated}
                />
          </Grid>
        </Grid>
      </Card>
    </Grid>
    <Grid item xs={4}>
      <SelectField
            isClearable={false}
            label="common.selectChildCompany"
            name="select-child-company"
            options={parentCompany.childCompanies}
            value={parentCompany.childCompanies.find(childCompany => childCompany.value === companyId)}
            width="100%"
            onChange={setChildCompany}
          />
    </Grid>
  </Grid>
  );

  useEffect(() => {
    activities && activities[0] && setActivity(activities[0]);
  }, [activities]);

  useEffect(() => {
    reloadCompanyTree();
  }, [reloadCompanyTree, activity]);

  useEffect(() => {
    setNodeSize(Math.max(getVisibleNodeCount({ treeData: displayedTree }), minNodeSize));
  }, [displayedTree]);

  return (
    <Wrapper>
      <SubtitlePage>
        {translate('pageInstitutionList.title')}
      </SubtitlePage>
      <Grid container direction="column" spacing={1}>
        {displayParentCompanyBlock()}
        <Grid alignItems="center" container direction="row" item spacing={4}>
          <Grid item style={{ marginBottom: '1rem' }} xs={8}>
            <GenericListFilters
              disabled={false}
              isVisible={true}
              search={search}
              setSearch={setSearch}
            />
          </Grid>
          <Grid item xs>
            {(isActivityLoading
              ? (
                <StyledSelectLoader>
                  <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                </StyledSelectLoader>
              ) : (
                <SelectField
                  isClearable={false}
                  label="common.selectActivity"
                  name="select-activity"
                  options={activities}
                  value={activity}
                  onChange={setActivity}
                />
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <div style={{ height: `${(nodeSize * 62) + 5}px`, position: 'relative', marginLeft: 12 }}>
        <SortableTree
          canDrag={node => node.node.canEdit}
          canDrop={data => Boolean(data.nextParent)}
          generateNodeProps={({ node, path }) => ({
            buttons: [
              <TreeNodeButtons
                activity={activity}
                companyId={companyId}
                key="button"
                node={node}
                path={path}
                reloadParentCompany={reloadCompanyTree}
                setLoading={setIsTreeLoading}
                onCreate={onCreate}
                onDelete={onDelete}
                onTreeUpdated={onTreeUpdated}
                onUpdate={onUpdate}
              />
            ]
          })}
          getNodeKey={getNodeKey}
          placeholderRenderer={() => <></>}
          searchMethod={searchMethod}
          searchQuery={search}
          treeData={displayedTree}
          onChange={setModifiedTree}
          onMoveNode={onMoveNode}
          onVisibilityToggle={data => setTree(data.treeData)}
        />
        {isTreeLoading && <SkeletonSection backgroundColor="rgba(0,0,0,0)" style={{ backdropFilter: 'blur(3px)' }} />}
      </div>
      <ReactTour steps={[]} />
    </Wrapper>
  );
};

export default StructureLevelTree;
