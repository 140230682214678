import { faCalendarPlus, faUserPlus, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, TableCell } from '@material-ui/core';
import {
  ButtonBlue,
  ButtonFloating,
  FlexContainer,
  GenericListContainer,
  GenericListFilters,
  GenericTable,
  ReactTour,
  SkeletonTableSmall,
  SubtitlePage,
  TextError
} from 'components';
import { useEffectAfterRender, useInfiniteLoading, useModal } from 'hooks';
import { UserEntry } from 'models/list/UserList';
import React, { useCallback, useState } from 'react';
import Media from 'react-media';
import { useHistory } from 'react-router-dom';
import { UsersService } from 'services';

import shortid from 'shortid';
import { FilterHelper, formatDate, translate } from 'utils/helpers';

const getListHeaders = () => [
  {
    name: 'fullName',
    label: translate('common.lastName'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row?.fullName || '-'}
      </TableCell>
    )
  }, {
    name: 'email',
    label: translate('common.email'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row?.email || '-'}
      </TableCell>
    )
  }, {
    name: 'creationDate',
    label: translate('common.creationDate'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.creationDate && (
          <span>
            <FontAwesomeIcon icon={faCalendarPlus} style={{ marginRight: '1rem' }} />
            {formatDate(row.creationDate)}
          </span>
        )}
      </TableCell>
    )
  }, {
    name: 'company',
    label: translate('common.company'),
    sortOn: 'label',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.companyName || '-'}
      </TableCell>
    )
  }, {
    name: 'accountActivated',
    label: translate('common.userAccount'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.hasAccount ? translate('common.yes') : translate('common.no')}
        {(row.hasAccount && !row.accountActivated) && ` (${translate('common.deactivated')})`}
      </TableCell>
    )
  }
];

export const UserList = () => {
  const history = useHistory();
  const { open } = useModal();
  const filterKey = 'userList';

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(FilterHelper.getFilters(filterKey) || []);

  const getUserList = useCallback(page => UsersService.getList({
    freeSearch: search
  }, page), [search]);

  const {
    items,
    hasMore,
    isLoading,
    totalSize,
    reloadList,
    loadMore
  } = useInfiniteLoading(getUserList);

  useEffectAfterRender(() => {
    reloadList();
  }, [filters, search]);

  const handleRowClick = useCallback(row => history.push(`/utilisateurs/${row.personId}`), [history]);

  const displayModalCreateUser = useCallback(() => open({
    type: 'CREATE_USER',
    onConfirm: createdUserId => history.push(`/utilisateurs/${createdUserId}`),
    closeOnSubmit: true
  }), [history, open]);

  const renderButtonContainer = useCallback(() => (
    <div className="buttonsContainer">
      <ButtonBlue
        data-cy="createNewUser"
        data-tour="step-admin-userCreate"
        onClick={displayModalCreateUser}
      >
        <FontAwesomeIcon icon={faUserPlus} />
        {translate('button.create')}
      </ButtonBlue>
    </div>
  ), [displayModalCreateUser]);

  const renderButtonMobile = useCallback(() => (
    <ButtonFloating dataCy="createNewUser" onClick={displayModalCreateUser}>
      <Fab aria-label={translate('button.createUser')} color="primary">
        <FontAwesomeIcon icon={faUserPlus} size="lg" />
      </Fab>
    </ButtonFloating>
  ), [displayModalCreateUser]);

  return (
    <GenericListContainer>
      <SubtitlePage>
        {translate('pageUserList.title')}
        <Media
          query="(min-width: 1081px)"
          render={renderButtonContainer}
        />
      </SubtitlePage>
      <GenericListFilters
        disabled={false}
        filterKey={filterKey}
        filters={filters}
        isVisible
        search={search}
        setFilters={setFilters}
        setSearch={setSearch}
      />

      <div data-tour="step-admin-userList">
        {!isLoading && items.length === 0
          ? (
            <FlexContainer alignItems="center" flexDirection="column">
              <FontAwesomeIcon color="var(--grey)" icon={faUsers} size="3x" />
              <TextError>{translate('errors.noUser')}</TextError>
              <ButtonBlue data-cy="createNewUser" onClick={displayModalCreateUser}>
                <FontAwesomeIcon icon={faUserPlus} />
                {translate('button.createUser')}
              </ButtonBlue>
            </FlexContainer>
          ) : (
            <GenericTable<UserEntry>
              dataCy="userList"
              hasMore={hasMore}
              headers={getListHeaders()}
              loadMore={loadMore}
              rows={items}
              total={totalSize}
              onRowClick={handleRowClick}
            />
          )
        }
      </div>

      {isLoading && <SkeletonTableSmall />}

      <Media
        query="(max-width: 1080px)"
        render={renderButtonMobile}
      />

      <ReactTour steps={[]} />

    </GenericListContainer>
  );
};
