/* eslint-disable camelcase */
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, TextField } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid/Grid';
import { SelectCountry } from 'components';
import { AdornmentLoader } from 'components/Form/Adornment/Adornment';
import { useStores } from 'hooks';
import { multipleField } from 'hooks/multipleField';
import { CountryView, EntityView } from 'models';
import { getIdentifier, getIdentifiers } from 'models/detail/CountryFormView';
import { IdentifierView, RuleView } from 'models/detail/IdentifierView';
import { useSnackbar } from 'notistack';
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Args, BaseInputProps, FormState, InputInitializer, StateErrors
} from 'react-use-form-state';
import { CompanyService, CountriesService } from 'services';
import { i18nStore } from 'stores';
import { STRUCTURE_TYPE } from 'utils/constants';
import { InterventionHelper, translate, UserHelper } from 'utils/helpers';
import { checkRegexMismatch } from 'utils/helpers/FormHelper';
import { CustomTooltip } from '../../Tooltips/Tooltips';
import FormElement from '../FormElement/FormElement';

const FormCountryIdentifier = ({
  columnSize = 6,
  formState,
  identifierFields,
  setIdentifierFields,
  text
}: {
  columnSize?: GridSize,
  formState: FormState<EntityView, StateErrors<EntityView, string>>,
  identifierFields: IdentifierView[],
  setIdentifierFields: (identifierFields: IdentifierView[]) => void,
  text: InputInitializer<EntityView, Args<keyof EntityView, void>, BaseInputProps<EntityView>>
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentLanguage } = i18nStore;
  const { userStore } = useStores();
  const [isSearchingSiret, setIsSearchingSiret] = useState(false);
  const [isFetchingCountries, setIsFetchingCountries] = useState(false);
  const [countriesDetails, setCountriesDetails] = useState<CountryView[]>([]);
  const [institutionIdentifier, setInstitutionIdentifier] = useState(null);
  const [institutionIdentifierRule, setInstitutionIdentifierRule] = useState<RuleView>(null);

  const setCountries = useCallback((countries: CountryView[]) => {
    setCountriesDetails(countries);
    const countriesOptions = InterventionHelper.MAP_COUNTRIES(countries);
    if (formState.values.country) {
      formState.setField('country', formState.values.country);
    } else {
      const defaultCountry = UserHelper.getDefaultCountry();
      formState.setField('country', countriesOptions.find(country => country.value === defaultCountry.id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsFetchingCountries(true);
    if (userStore.isOffline) {
      InterventionHelper.loadCountriesStored()
        .then(setCountries)
        .finally(() => setIsFetchingCountries(false));
    } else {
      CountriesService.getCountryDetails()
        .then(setCountries)
        .finally(() => setIsFetchingCountries(false));
    }
  }, [userStore.isOffline, setCountries]);

  const initValueIdentifierField = useCallback((identifierField: IdentifierView) => {
    const defaultIdentifier = getIdentifiers(formState.values).find(identifier => identifier.idField === identifierField.idField);
    const value = identifierField.value ? identifierField.value : ((identifierField.primary && getIdentifier(formState.values)) || '');
    return defaultIdentifier ? {
      ...identifierField,
      value: defaultIdentifier.value || value,
      idValue: defaultIdentifier.idValue,
      suffix: defaultIdentifier.suffix
    } : {
      ...identifierField,
      value
    };
    // eslint-disable-next-line
  }, [formState.values.institutionIdentifiers, formState.values.identifiers]);

  const initValueIdentifierFields = useCallback((identifierFieldsToInit: IdentifierView[]) => {
    if (getIdentifiers(formState.values)) {
      const initializedIdentifierValues = identifierFieldsToInit.map(identifierField => initValueIdentifierField(identifierField));
      setIdentifierFields(initializedIdentifierValues);
    } else {
      setIdentifierFields(identifierFieldsToInit);
    }
    // eslint-disable-next-line
  }, [formState.values.institutionIdentifiers, formState.values.identifiers, initValueIdentifierField, setIdentifierFields]);

  const updateIdentifierFields = useCallback(() => {
    if (!formState.values.country) {
      initValueIdentifierFields([]);
      setInstitutionIdentifierRule(null);
      return;
    }
    const currentIdentifierFields = countriesDetails.find(country => country.id.toString() === formState.values.country.value.toString());
    if (!currentIdentifierFields || !currentIdentifierFields.identifiers) {
      initValueIdentifierFields([]);
      setInstitutionIdentifierRule(null);
      return;
    }
    const identifiers = currentIdentifierFields.identifiers.filter(identifier => identifier.type === STRUCTURE_TYPE.INSTITUTION_MODEL);
    initValueIdentifierFields(identifiers);
    setInstitutionIdentifierRule(identifiers.find(identifier => identifier.primary)?.rule);
  }, [formState.values.country, initValueIdentifierFields, countriesDetails]);

  const handleIdentifierValueChange = identifierField => {
    if (identifierField.primary) {
      setInstitutionIdentifier(identifierField.value);
    }
    setIdentifierFields(identifierFields.map(identifier => (identifier.idField === identifierField.idField ? identifierField : identifier)));
  };

  const handleChangeSelectedCountry = useCallback(countryItem => {
    formState.setField('address', {
      ...formState.values.address,
      country: countryItem
    });
    formState.setField('country', countryItem);
  }, [formState]);

  useEffect(() => {
    updateIdentifierFields();
  }, [formState.values.country, updateIdentifierFields]);

  const resetInstitutionFields = useCallback(() => {
    formState.clearField('institutionName');
    formState.clearField('address1');
    formState.clearField('postalCode');
    formState.clearField('city');
    formState.clearField('address2');
  }, [formState]);

  const handleIdentifierSuffixChange = (identifierField: IdentifierView) => {
    if (identifierField.primary) {
      setIdentifierFields(identifierFields.map(identifier => (identifier.idField === identifierField.idField ? identifierField : identifier)));
    }
  };

  const getInstitutionBySiret = useCallback(() => {
    setIsSearchingSiret(true);
    CompanyService.getInstitutionBySiret(institutionIdentifier)
      .then(response => {
        if (response.header && response.header.statut === 200) {
          resetInstitutionFields();
          const tempInstitution = response.etablissement.uniteLegale.denominationUniteLegale;
          const tempAddress = response.etablissement.adresseEtablissement.codePostalEtablissement;
          formState.setField('institutionName', tempInstitution);
          if (tempAddress) {
            formState.setField('address1', `${response.etablissement.adresseEtablissement.numeroVoieEtablissement || ''} ${response.etablissement.adresseEtablissement.typeVoieEtablissement || ''} ${response.etablissement.adresseEtablissement.libelleVoieEtablissement || ''}`);
            formState.setField('address2', response.etablissement.adresseEtablissement.complementAdresseEtablissement || '');
            formState.setField('postalCode', tempAddress);
            formState.setField('city', response.etablissement.adresseEtablissement.libelleCommuneEtablissement || '');
          }
        }
      })
      .catch(() => enqueueSnackbar(translate('errors.noMatchingSiret'), { variant: 'warning' }))
      .finally(() => setIsSearchingSiret(false));
  }, [institutionIdentifier, formState, resetInstitutionFields, enqueueSnackbar]);

  useEffect(() => {
    // Launch siret search only if the siret is complete and the country is France
    if (institutionIdentifier
      && institutionIdentifier.match(`^${institutionIdentifierRule?.format}$`)
      && (
        formState.values.country
        && formState.values.country.countryCode === 'fr'
      )
    ) {
      getInstitutionBySiret();
    }
    // eslint-disable-next-line
  }, [institutionIdentifier]);

  return (
    <>
      <FormElement label={translate('common.country')}>
        <SelectCountry
          required
          value={formState.values.country || null}
          onChange={handleChangeSelectedCountry}
        />
      </FormElement>

      <FormElement>
        <Grid container>
          {isFetchingCountries
            ? (
              <Grid item style={{ paddingLeft: '10px' }}>
                <FontAwesomeIcon icon={faSpinner} spin />
                {translate('common.loading')}
              </Grid>
            ) : (identifierFields.filter(identifier => identifier.type === STRUCTURE_TYPE.INSTITUTION_MODEL)
              .map(identifierField => (
                <Grid container item key={identifierField.idField}>
                  <Grid
                    item
                    sm={columnSize}
                    xs={12}
                  >
                    <TextField
                      error={checkRegexMismatch(identifierField?.rule?.format, identifierField?.value)}
                      helperText={(identifierField.rule?.format && identifierField.rule?.translations?.find(val => val.code === currentLanguage)?.label) ?? ''}
                      inputProps={identifierField.rule?.format ? { pattern: identifierField.rule?.format } : {}}
                      label={identifierField.name}
                      name={identifierField.name}
                      required={identifierField.required}
                      value={identifierField.value ?? ''}
                      onChange={value => handleIdentifierValueChange({
                        ...identifierField,
                        value: value.target.value
                      })}
                    />
                  </Grid>
                  {formState.values.isPublic && identifierField.primary && (
                    <Grid item sm={columnSize} xs={12}>
                      <Grid container>
                        <TextField
                          label={translate('common.identifierSuffix')}
                          name="identifierSuffix"
                          required
                          value={identifierField.suffix ?? ''}
                          onChange={value => handleIdentifierSuffixChange({ ...identifierField, suffix: value.target.value })}
                        />
                        <CustomTooltip text={translate('common.identifierSuffixTooltip')} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ))
            )
          }
          <Grid item sm={columnSize} xs={12}>
            <TextField
              autoComplete="new-password"
              error={!!formState.errors.institutionName}
              InputProps={{
                endAdornment: (isSearchingSiret && <AdornmentLoader />)
              }}
              label={translate('common.institutionName')}
              name="institutionName"
              required
              {...multipleField(text, formState, ['institutionName', 'firstName'])}
            />
          </Grid>
        </Grid>
        {/* This input is only used for forcing disabling autocomplete in Chrome browser */}
        <input name="fakeInputForForcingDisablingAutocompleteChrome3" style={{ display: 'none' }}
          type="text" />
      </FormElement>
    </>
  );
};

export default FormCountryIdentifier;
