import {
  action, makeObservable, observable, runInAction
} from 'mobx';
import { CountriesService } from 'services';
import { SelectItem } from 'utils/types';

class CountryStore {
  public countryList: SelectItem[];

  constructor() {
    this.countryList = [];

    makeObservable(this, {
      countryList: observable,
      loadCountryList: action
    });
  }

  loadCountryList() {
    CountriesService.getCountryList()
      .then(countries => runInAction(() => {
        this.countryList = countries;
      }));
  }
}

export const countryStore = new CountryStore();
