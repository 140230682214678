import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import frLocale from 'date-fns/locale/fr';
import React from 'react';
import {
  DATETIME_FORMAT, DATE_FORMAT, formatDate, isValidDate, parseDate, translate
} from 'utils/helpers';

export const Datepicker = ({
  dataCy = '',
  disableFuture = false,
  disablePast = false,
  disabled = false,
  error = false,
  format = DATE_FORMAT,
  label = 'common.date',
  maxDate,
  maxDateMessage = 'warnings.date.maxDate',
  minDate,
  minDateMessage = 'warnings.date.minDate',
  name = '',
  onChange,
  onError = () => { },
  required = false,
  style,
  value
}: {
  dataCy?: string,
  disableFuture?: boolean,
  disablePast?: boolean,
  disabled?: boolean,
  error?: boolean,
  format?: string,
  label?: string,
  maxDate?: string,
  maxDateMessage?: string,
  minDate?: string,
  minDateMessage?: string,
  name?: string,
  onChange: (date: string) => void,
  onError?: (e: any) => void,
  required?: boolean,
  style?: any,
  value: string
}) => (
  <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
    <KeyboardDatePicker
      autoOk
      cancelLabel={translate('button.cancel')}
      clearable
      clearLabel={translate('button.delete')}
      data-cy={dataCy}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      error={error}
      format={DATE_FORMAT}
      invalidDateMessage={translate('errors.invalidDate')}
      KeyboardButtonProps={{
        'aria-label': translate(label)
      }}
      label={translate(label)}
      maxDate={maxDate && parseDate(maxDate, format)}
      maxDateMessage={translate(maxDateMessage)}
      minDate={minDate && parseDate(minDate, format)}
      minDateMessage={translate(minDateMessage)}
      name={name}
      required={required}
      style={style}
      value={parseDate(value, format)}
      onChange={date => (isValidDate(date) ? onChange(formatDate(date, format)) : onChange(''))}
      onError={e => onError(e)}
    />
  </MuiPickersUtilsProvider>
);

export const DateTimepicker = ({
  ampm = false,
  dataCy = '',
  disableFuture = false,
  disablePast = false,
  disabled = false,
  error = false,
  format = DATETIME_FORMAT,
  label = 'common.date',
  maxDate,
  maxDateMessage = 'warnings.date.maxDate',
  minDate,
  minDateMessage = 'warnings.date.minDate',
  name = '',
  onChange,
  required = false,
  style,
  value
}: {
  ampm?: boolean,
  dataCy?: string,
  disableFuture?: boolean,
  disablePast?: boolean,
  disabled?: boolean,
  error?: boolean,
  format?: string,
  label?: string,
  maxDate?: string,
  maxDateMessage?: string,
  minDate?: string,
  minDateMessage?: string,
  name?: string,
  onChange: (date: string) => void,
  required?: boolean,
  style?: any,
  value: string
}) => (
  <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
    <KeyboardDateTimePicker
      ampm={ampm}
      autoOk
      cancelLabel={translate('button.cancel')}
      clearable
      clearLabel={translate('button.delete')}
      data-cy={dataCy}
      disabled={disabled}
      disableFuture={disableFuture}
      disablePast={disablePast}
      error={error}
      format={DATETIME_FORMAT}
      invalidDateMessage={translate('errors.invalidDate')}
      KeyboardButtonProps={{
        'aria-label': translate(label)
      }}
      label={translate(label)}
      maxDate={maxDate && parseDate(maxDate, format)}
      maxDateMessage={translate(maxDateMessage)}
      minDate={minDate && parseDate(minDate, format)}
      minDateMessage={translate(minDateMessage)}
      name={name}
      required={required}
      style={style}
      value={parseDate(value, format)}
      onChange={date => onChange(formatDate(date, format))}
    />
  </MuiPickersUtilsProvider>
);
