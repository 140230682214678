import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import { Wrapper } from 'components';

import { CustomSkeletonList } from './SkeletonList';

export const SkeletonDetailIntervention = () => (
  <Wrapper>
    <CustomSkeletonList />
    <Skeleton style={{ borderRadius: '3rem', margin: '1rem auto' }} variant="rect" width={300} />
    <Skeleton height={10} width="100%" />
    <Skeleton height={200} variant="rect" width="100%" />
  </Wrapper>
);
