import { isMobile } from 'react-device-detect';

// const MAX_FILE_SIZE = 20; // in Mo

const MAX_WIDTH = 800;
const MAX_HEIGHT = 800;

const resizeImage = reader => new Promise((resolve) => {
  const img = document.createElement('img');
  img.src = reader.result;
  img.crossOrigin = 'anonymous';

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    // eslint-disable-next-line prefer-destructuring
    let width = img.width;
    // eslint-disable-next-line prefer-destructuring
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }

    canvas.width = width;
    canvas.height = height;
    const ctxx = canvas.getContext('2d');
    ctxx.drawImage(img, 0, 0, width, height);

    resolve(canvas.toDataURL());
  };
});

const handleSingleFileUpload = ({
  callback, callbackError = null, event
}) => {
  const file = event.target.files[0];
  const reader = new FileReader();
  const readerOnLoad = async (readerLoaded, currentFileLoaded) => {
    callbackError && callbackError('');
    return callback({
      base64Content: readerLoaded.result,
      name: currentFileLoaded.name
    });
  };
  reader.readAsDataURL(file);
  reader.onload = () => readerOnLoad(reader, file);
};

const handleFileUpload = ({
  callback, callbackError = null, event, needResize = true
}) => {
  const allDocumentsUploaded = [];
  const allFiles = event.target.files;
  if (allFiles) {
    const readerOnLoad = async (readerLoaded, currentFileLoaded) => {
      const isImage = currentFileLoaded.type.includes('image');
      if (!needResize || !isImage) {
        allDocumentsUploaded.push({
          base64Content: readerLoaded.result,
          name: currentFileLoaded.name
        });

        if (allDocumentsUploaded.length === allFiles.length) {
          callbackError && callbackError('');
          return callback(allDocumentsUploaded);
        }

        return callbackError('Erreur dans le nombre de fichiers uploadés.');
      }

      const imgResized = await resizeImage(readerLoaded);

      allDocumentsUploaded.push({
        base64Content: imgResized,
        name: currentFileLoaded.name
      });

      return callback(allDocumentsUploaded);
    };

    for (let i = 0; i < allFiles.length; i += 1) {
      const reader = new FileReader();
      const currentFile = allFiles[i];
      reader.readAsDataURL(currentFile);
      reader.onload = () => readerOnLoad(reader, currentFile);
    }
  }
};

const getExtension = (document) => {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(document.name)[1];
};

const getDocumentWithBase64 = (document) => {
  const extension = getExtension(document);
  switch (extension) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return `data:image/${extension};base64,${document.base64Content}`;
    case 'pdf':
      return `data:application/pdf;base64,${document.base64Content}`;
    case 'xlsx':
    case 'xls':
      return `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${document.base64Content}`;
    case 'rar':
    case 'zip':
      return `data:application/zip;base64,${document.base64Content}`;
    // Default to a png
    default:
      return `data:image/png;base64,${document.base64Content}`;
  }
};

const getImageWithBase64 = document => (
  `data:image/png;base64,${document.base64Content}`
);

const getPDFWithBase64 = document => (
  `data:application/pdf;base64,${document.base64Content}`
);

const getExcelWithBase64 = document => (
  `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${document.base64Content}`
);

const getZipWithBase64 = document => (
  `data:application/zip;base64,${document.base64Content}`
);

const getDocumentWithoutBase64 = document => (
  document.base64Content.split(',')[1]
);

const removeAllBase64Prefix = documentList => {
  if (!documentList) {
    return [];
  }
  return documentList.map(document => ({ ...document, base64Content: getDocumentWithoutBase64(document) }));
};

const getDocumentsFormatted = ({ documents, withBase64 = true }) => (
  documents && documents.length > 0 ? documents.map(doc => ({
    ...doc,
    base64Content: withBase64 ? getImageWithBase64(doc) : getDocumentWithoutBase64(doc)
  })) : []
);

const getDatasWithDocumentsFormatted = ({ datas, withBase64 = true }) => datas.map(data => ({
  ...data,
  documents: getDocumentsFormatted({ documents: data.documents, withBase64 })
}));

const displayPDF = ({ displayModal }) => {
  if (isMobile) {
    return displayModal('DISPLAY_PDF_MOBILE');
  }

  return displayModal('DISPLAY_PDF');
};

export const DocumentHelper = {
  getExtension,
  handleFileUpload,
  handleSingleFileUpload,
  getImageWithBase64,
  getPDFWithBase64,
  getExcelWithBase64,
  getZipWithBase64,
  getDocumentWithBase64,
  getDocumentWithoutBase64,
  getDocumentsFormatted,
  getDatasWithDocumentsFormatted,
  removeAllBase64Prefix,
  displayPDF
};
