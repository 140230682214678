import { IDBPDatabase, openDB } from 'idb'; // Docs : https://www.npmjs.com/package/idb

const DB_NAME = 'Databilan';
// TODO RECURRENT: Increment version when adding new questions to the form
// or when the model of the form changes
const DB_VERSION = 1;

export const INTERVENTION_STORE = 'interventions';
export const REFERENCE_DATA_STORE = 'referenceData';
export const KEYCLOAK_STORE = 'keycloak';

const STORES = [{
  name: INTERVENTION_STORE
}, {
  name: REFERENCE_DATA_STORE
}, {
  name: KEYCLOAK_STORE
}];

let DB: IDBPDatabase<any>;

const openDatabase: () => Promise<IDBPDatabase<any>> = async () => {
  DB = await openDB(DB_NAME, DB_VERSION, {
    upgrade(upgradeDB) {
      if (upgradeDB.objectStoreNames && upgradeDB.objectStoreNames.contains(INTERVENTION_STORE)) {
        upgradeDB.deleteObjectStore(INTERVENTION_STORE);
      }

      STORES.forEach((store) => {
        if (upgradeDB.objectStoreNames && !upgradeDB.objectStoreNames.contains(store.name)) {
          upgradeDB.createObjectStore(store.name);
        }
      });
    }
  })
    .catch((error) => { throw error; });

  return DB;
};

const getData: (params: {
  key: string,
  store: string
}) => Promise<any> = ({
  store,
  key
}) => (
  DB.get(store, key)
    .then(data => data)
    .catch((error) => { throw error; })
);

const getAllData: (params: {
  range: IDBKeyRange,
  store: string
}) => Promise<any[]> = ({
  store,
  range
}) => (
  DB.getAll(store, range)
    .then(data => data)
    .catch((error) => { throw error; })
);

const saveData: (params: {
  data: any,
  key: string,
  store: string
}) => Promise<void> = ({
  store,
  key,
  data
}) => (
  DB.add(store, JSON.parse(JSON.stringify(data)), key)
    .catch((error) => { throw error; })
);

const updateData: (params: {
  data: any,
  key: string,
  store: string
}) => Promise<void> = ({
  store,
  key,
  data
}) => (
  DB.put(store, JSON.parse(JSON.stringify(data)), key)
    .catch((error) => { throw error; })
);

const getAllFromStore: (params: {
  store: string
}) => Promise<any[]> = ({
  store
}) => (
  DB.getAll(store)
    .catch((error) => { throw error; })
);

const deleteData: (params: {
  key: string,
  store: string
}) => Promise<void> = ({
  store,
  key
}) => (
  DB.delete(store, key)
    .catch((error) => { throw error; })
);

const deleteAllData: (params: {
  range: IDBKeyRange,
  store: string
}) => Promise<void> = ({
  store,
  range
}) => (
  DB.delete(store, range)
    .catch((error) => { throw error; })
);

const clearStore: (params: {
  store: string
}) => Promise<void> = ({
  store
}) => (
  DB.clear(store)
    .catch((error) => { throw error; })
);

export const IndexedDBHelper = {
  openDatabase,
  getData,
  getAllData,
  saveData,
  updateData,
  getAllFromStore,
  deleteData,
  deleteAllData,
  clearStore
};
