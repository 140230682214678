import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Button, DialogActions, DialogContent
} from '@material-ui/core';

import { faFileImport } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DocumentHelper, translate } from 'utils/helpers';

import { Text } from 'components';
import { ModalHeader } from './ModalHeader';

const StyledFileUpload = styled.input`
  width: 100%;
`;

export const ImportModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues
}) => {
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(null);

  const handleValidateModal = (e) => {
    e.preventDefault();

    if (!document) {
      return setError(translate('errors.noImportFile'));
    }

    onConfirm({
      ...document[0],
      base64Content: DocumentHelper.getDocumentWithoutBase64(document[0])
    });

    if (closeOnSubmit) {
      return onClose();
    }

    return null;
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faFileImport} />
        {translate('modalImport.import')}
      </ModalHeader>

      <DialogContent>
        {defaultValues && defaultValues.message && (
          <Text color="var(--green)">{defaultValues.message}</Text>
        )}

        {error && <Text color="var(--red)" fontWeight={600} margin="0 0 1rem 0">{error}</Text>}

        <StyledFileUpload
          accept=".xlsx, .xls, .csv"
          placeholder={translate('modalImport.import')}
          type="file"
          width="100%"
          onChange={event => DocumentHelper.handleFileUpload({ callback: setDocument, event, needResize: false })}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" disabled={!document} type="submit">{translate('button.import')}</Button>
      </DialogActions>
    </form>
  );
};

ImportModal.propTypes = {
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

ImportModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: null
};
