import { faBuilding, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, TextField
} from '@material-ui/core';
import {
  CustomTooltip, FlexContainer, FormAddress, FormCountryIdentifier, FormLine, SelectField, SkeletonForm
} from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { translate, UserHelper } from 'utils/helpers';

import { APPLICATION_ROLES, getLegalEntityTypeTranslationKey, LEGAL_ENTITY_TYPES } from 'utils/constants';

import { ModalProps } from 'hooks/ModalProvider';
import { EntityView } from 'models';
import { LegalEntityService } from 'services';
import { RequiredSelectHack } from '../RequiredSelectHack/RequiredSelectHack';
import { ModalHeader } from './ModalHeader';

export const CreateSupplierOrRecipientModal = ({
  entityId, onClose, onSubmit
}: ModalProps<EntityView> & {
  entityId: string;
}) => {
  const [formState, { text }] = useFormState<EntityView>(new EntityView());
  const [isLoading, setLoading] = useState(false);
  const [identifierFields, setIdentifierFields] = useState([]);
  const canHandleRecipient = UserHelper.hasAccessRight([APPLICATION_ROLES.DISTRIBUTOR_ADMIN, APPLICATION_ROLES.SUPER_ADMIN]);

  const legalEntityTypeToSelectItem = entityType => ({
    value: entityType,
    label: translate(getLegalEntityTypeTranslationKey(entityType))
  });

  useEffect(() => {
    if (entityId) {
      setLoading(true);
      LegalEntityService.getLegalEntity(entityId, [LEGAL_ENTITY_TYPES.SUPPLIER, LEGAL_ENTITY_TYPES.RECIPIENT])
        .then(response => {
          Object.entries(response).forEach(([key, value]) => formState.setField(key as any, value));
          if (response.address && typeof response.address !== 'string') {
            formState.setField('address1', response.address?.address1);
            formState.setField('address2', response.address?.address2);
            formState.setField('city', response.address?.city);
            formState.setField('country', response.address?.country);
            formState.setField('postalCode', response.address?.postalCode);
          }
        })
        .finally(() => { setLoading(false); });
    } else {
      formState.reset();
    }
    if (!canHandleRecipient) {
      formState.setField('entityTypes', [{ value: LEGAL_ENTITY_TYPES.SUPPLIER, label: LEGAL_ENTITY_TYPES.SUPPLIER }]);
    }
    // eslint-disable-next-line
  }, [canHandleRecipient, entityId]);

  useEffect(() => {
    if (!formState.values.entityTypes?.some(item => item.value === LEGAL_ENTITY_TYPES.RECIPIENT)) {
      formState.setField('attestationNumber', '');
    }
    // eslint-disable-next-line
  }, [formState.values.entityTypes]);

  const handleValidateModal = (e) => {
    e.preventDefault();
    const possibleTypes = [LEGAL_ENTITY_TYPES.SUPPLIER];
    if (canHandleRecipient) {
      possibleTypes.push(LEGAL_ENTITY_TYPES.RECIPIENT);
    }
    onSubmit({
      ...formState.values,
      institutionIdentifiers: identifierFields,
      possibleTypes
    });
  };

  const popupMessage = useMemo(() => {
    if (entityId) {
      return canHandleRecipient ? 'modalSupplierOrRecipient.editSupplierOrRecipient' : 'modalSupplierOrRecipient.editSupplier';
    }
    return canHandleRecipient ? 'modalSupplierOrRecipient.newSupplierOrRecipient' : 'modalSupplierOrRecipient.newSupplier';
  }, [canHandleRecipient, entityId]);

  return (
    <form autoComplete="off" onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FlexContainer alignItems="center" justifyContent="space-between">
          <div>
            <FontAwesomeIcon icon={faBuilding} />
            {translate(popupMessage)}
          </div>
        </FlexContainer>
      </ModalHeader>

      {isLoading ? (
        <SkeletonForm />
      ) : (
        <>
          <DialogContent style={{ minWidth: '700px' }}>
            <FlexContainer flexDirection="column">
              {canHandleRecipient && (
                <>
                  <FormLine>
                    <div style={{ flexGrow: 10 }}>
                      <SelectField
                        closeMenuOnSelect={undefined}
                        id="entity-type"
                        isClearable={undefined}
                        isMulti
                        isSearchable
                        label="common.legalEntityType"
                        name="entity-type"
                        noOptionsMessage={undefined}
                        options={[legalEntityTypeToSelectItem(LEGAL_ENTITY_TYPES.SUPPLIER), legalEntityTypeToSelectItem(LEGAL_ENTITY_TYPES.RECIPIENT)]}
                        placeholder={undefined}
                        required
                        value={formState.values.entityTypes ?? []}
                        width={undefined}
                        onChange={value => formState.setField('entityTypes', value)} />
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                      <CustomTooltip
                        icon={faInfoCircle}
                        text={translate('modalSupplierOrRecipient.informationFluidProcessingPlant')}
                      />
                    </div>
                  </FormLine>
                  <RequiredSelectHack value={formState.values.entityTypes ?? ''} />
                </>
              )}

              <FormCountryIdentifier
                columnSize={12}
                formState={formState}
                identifierFields={identifierFields}
                setIdentifierFields={setIdentifierFields}
                text={text}
              />

              {formState.values.entityTypes?.some(item => item.value === LEGAL_ENTITY_TYPES.RECIPIENT) && (
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.attestationNumber && formState.errors.attestationNumber !== null}
                  label={translate('common.attestationNumber')}
                  name="attestationNumber"
                  required
                  {...text('attestationNumber')}
                />
              )
              }

              <FormAddress
                formState={formState}
                isRequired
                text={text}
                withCountry={false}
              />
              {/* This input is only used for forcing disabling autocomplete in chrome browser */}
              <input name="fakeInputForForcingDisablingAutocompleteChromes" style={{ display: 'none' }} type="text" />
            </FlexContainer>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>{translate('button.cancel')}</Button>
            <Button color="primary" type="submit">{translate('button.save')}</Button>
          </DialogActions>
        </>
      )}
    </form>
  );
};
