import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledFlexContainer = styled.section`
  display: flex;
  flex: ${props => props.flex};
  flex-wrap: ${props => props.flexWrap};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  width: ${props => props.width};
`;

export const FlexContainer = ({ children, ...props }) => (
  <StyledFlexContainer {...props}>{children}</StyledFlexContainer>
);

FlexContainer.propTypes = {
  flex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  flexWrap: PropTypes.string,
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  width: PropTypes.string
};

FlexContainer.defaultProps = {
  alignItems: 'initial',
  flex: 'initial',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'initial',
  width: 'initial'
};
