import { Modal } from 'components';
import React, {
  createContext, useCallback, useContext, useState
} from 'react';

interface OtherProps {
  [prop: string]: any;
}

export type ModalProps<T> = OtherProps & {
  /** When true, the modal will close immediately after the submit action without waiting. */
  closeOnSubmit?: boolean;
  /** When true, the modal will only be closed using the close or cancel actions. */
  disableClose?: boolean;
  /** The action to call when the close action is used (in addition to closing the modal). */
  onClose?: (event?: any) => any;
  /** The action to call when the submit action is used. This action is responsible for closing the modal afterwards. */
  onSubmit?: (value: T) => any;
}

export type ModalState = ModalProps<any> & {
  /** The type of the modal */
  type: string;
}
interface ModalContextType {
  close: (secondary?: boolean) => void,
  open: (state: ModalState, secondary?: boolean) => void
}

export const ModalContext = createContext<ModalContextType>({
  // eslint-disable-next-line no-console
  open: (temp) => console.log('Context not updated yet!', temp),
  // eslint-disable-next-line no-console
  close: () => console.log('Context not updated yet!')
});

export const useModal = () => useContext<ModalContextType>(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const [isSecondaryOpen, setSecondaryOpen] = useState(false);
  const [modalState, setModalState] = useState<ModalState | undefined>(undefined);
  const [secondaryModalState, setSecondaryModalState] = useState<ModalState | undefined>(undefined);

  const openModal = useCallback((options: ModalState, secondary?: boolean) => {
    if (secondary) {
      setSecondaryOpen(true);
      setSecondaryModalState(options);
    } else {
      setOpen(true);
      setModalState(options);
    }
  }, []);

  const closeModal = (secondary?: boolean) => {
    if (secondary) {
      setSecondaryOpen(false);
      setSecondaryModalState(undefined);
    } else {
      // Close all modals when closing the primary modal
      setOpen(false);
      setSecondaryOpen(false);
      setModalState(undefined);
      setSecondaryModalState(undefined);
    }
  };

  return (
    <>
      <ModalContext.Provider value={{ open: openModal, close: closeModal }}>
        {children}
      </ModalContext.Provider>

      <Modal
        closeModal={closeModal}
        closeSecondaryModal={() => closeModal(true)}
        isOpen={isOpen}
        isSecondaryOpen={isSecondaryOpen}
        modalState={modalState}
        secondaryModalState={secondaryModalState} />
    </>
  );
};
