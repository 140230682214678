import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4'; // DOCS: https://www.npmjs.com/package/react-ga4
import { useHistory } from 'react-router-dom';
import { APP_VERSION, GA4_TAG_ID } from 'utils/constants';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';

const GoogleAnalytics = () => {
  const history = useHistory();
  const { location } = history;
  const [gaCookiesActivated, setGaCookiesActivated] = useState(false);

  useEffect(() => {
    if (gaCookiesActivated) {
      ReactGA.initialize(GA4_TAG_ID);
    }
  }, [gaCookiesActivated]);

  useEffect(() => {
    if (!gaCookiesActivated) {
      return;
    }
    // Transform current url from ie: '/stocks/stats' to 'stocks-stats'
    const urlTitle = location.pathname.split('/').join('-').substring(1);

    document.title = `Databilan ${urlTitle}`;

    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname}${location.search}`,
      title: `Databilan ${urlTitle}`,
      appVersion: APP_VERSION
    });
  }, [location, gaCookiesActivated]);

  // Set an interval to check Google Analytics consent
  useEffect(() => {
    setInterval(() => {
      setGaCookiesActivated(CookiesHelper.getGAConsent());
    }, 1000 * 60);
  }, []);

  return null;
};

export default GoogleAnalytics;
