import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Grid, IconButton, Tooltip
} from '@material-ui/core';
import { DropdownButton } from 'components/Buttons/DropdownButton';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { InstitutionGroupService, InstitutionsService } from 'services';
import shortid from 'shortid';
import styled from 'styled-components';
import { APPLICATION_ROLES, STRUCTURE_LEVEL_ICON, STRUCTURE_TYPE } from 'utils/constants';
import { DocumentHelper, translate, UserHelper } from 'utils/helpers';

const DeleteButton = styled(IconButton)`
  &:hover {
    color: var(--error-color);
  }
`;

const TreeNodeButtons = ({
  node, path, activity, setLoading, onCreate, onUpdate, onDelete, onTreeUpdated, companyId
}: any) => {
  const { open } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const onError = useCallback(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }), [enqueueSnackbar]);
  const nodeInstitutionGroupId = node.type === STRUCTURE_TYPE.INSTITUTION_GROUP ? node.value : undefined;
  const hasBackOfficeAdminAccess = UserHelper.hasAccessRight([APPLICATION_ROLES.TECNEA_ADMIN]);

  const showDetailModal = props => open({ ...props, closeOnSubmit: true, key: shortid.generate() });

  const handleEditNode = modalProps => {
    const loadBeforeModal = () => {
      setLoading(true);
      modalProps.getDetails(node.value)
        .then(result => showDetailModal({ ...modalProps, defaultValues: result }))
        .catch(onError)
        .finally(onTreeUpdated);
    };
    return node.value ? loadBeforeModal() : showDetailModal({ ...modalProps, defaultValues: {} });
  };

  const handleDeleteNode = () => {
    const switchNodeDeleteFunc = {
      INSTITUTION_MODEL: () => InstitutionsService.deleteInstitution(node.value),
      INSTITUTION_GROUP: () => InstitutionGroupService.deleteInstitutionGroup(node.value)
    };
    setLoading(true);
    open({
      type: 'WARNING',
      title: translate('warnings.structureLevel.delete.title'),
      text: translate('warnings.structureLevel.delete.text'),
      buttonConfirm: translate('button.confirm'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => switchNodeDeleteFunc[node.type]()
        .then(() => onDelete({ node }))
        .catch(error => {
          onError(error);
          setLoading(false);
        }),
      onCancel: () => setLoading(false)
    });
  };

  const handleCreateNode = useCallback(({ serviceFunc, confirmKey, isNew }) => {
    setLoading(true);
    return serviceFunc()
      .then(newNode => {
        const confirmLabel = translate(`confirms.${confirmKey}.${isNew ? 'create' : 'update'}`);
        isNew
          ? onCreate({
            confirmLabel, newNode, parentNode: node, parentPath: path
          })
          : onUpdate({ confirmLabel, newNode, path });
      })
      .catch(onError)
      .finally(onTreeUpdated);
  }, [node, onCreate, onUpdate, onTreeUpdated, onError, path, setLoading]);

  const createInstitutionGroupFunc = useCallback(result => {
    const serviceFunc = () => (result.id
      ? InstitutionGroupService.updateInstitutionGroup(result.id, { ...result, parentInstitutionGroupId: null, parentCompanyId: null }, activity.value)
      : InstitutionGroupService.createInstitutionGroup(result, activity.value));
    return handleCreateNode({
      serviceFunc, confirmKey: 'institutionGroup', isNew: !result.id
    });
  }, [handleCreateNode, activity.value]);

  const createInstitutionFunc = useCallback(result => {
    const formattedResult = { ...result, documents: result.documents?.map(doc => DocumentHelper.getDocumentWithoutBase64(doc)) };
    return (formattedResult.id
      ? InstitutionsService.updateInstitution(formattedResult.id, formattedResult)
        .then(() => handleCreateNode({
          serviceFunc: () => InstitutionsService.getInstitutionTree(formattedResult.id, activity.value),
          confirmKey: 'institutionList',
          isNew: !formattedResult.id
        }))
        .catch(onError)
      : InstitutionsService.createInstitution(formattedResult)
        .then(resp => handleCreateNode({
          serviceFunc: () => InstitutionsService.getInstitutionTree(resp, activity.value),
          confirmKey: 'institutionList',
          isNew: !formattedResult.id
        })).catch(onError));
  }, [handleCreateNode, onError, activity.value]);

  const nodeModalParams = {
    INSTITUTION_MODEL: {
      type: 'CREATE_INSTITUTION',
      getDetails: InstitutionsService.getInstitutionModel,
      onConfirm: createInstitutionFunc,
      parentInstitutionGroupId: nodeInstitutionGroupId,
      parentCompanyId: companyId
    },
    INSTITUTION_GROUP: {
      type: 'CREATE_INSTITUTION_GROUP',
      getDetails: InstitutionGroupService.getInstitutionGroup,
      onConfirm: createInstitutionGroupFunc,
      parentInstitutionGroupId: nodeInstitutionGroupId,
      parentCompanyId: companyId,
      defaultActivity: activity
    }
  };

  const getItemButton = (type, label) => (
    <Grid item>
      <Button
        startIcon={<FontAwesomeIcon fixedWidth icon={STRUCTURE_LEVEL_ICON[type]} />}
        onClick={() => showDetailModal(nodeModalParams[type])}
      >
        {label}
      </Button>
    </Grid>
  );

  return node.canEdit && (
    <div style={{ marginLeft: '10px' }}>
      {(node.type !== STRUCTURE_TYPE.COMPANY) && (
        <Tooltip title={translate('button.viewEdit')}>
          <span>
            <IconButton
            color="primary"
            style={{ padding: 5 }}
            onClick={() => {
              handleEditNode(nodeModalParams[node.type]);
            }}
          >
              <FontAwesomeIcon fixedWidth icon={faEdit} size="xs" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {(Boolean(node.createChildOptions?.length) || node.canCreateParentCompany) && (
        <DropdownButton
          buttonStyle={{ padding: 5 }}
          color="primary"
          icon={faPlus}
          iconSize="xs"
          isIconOnly
          placement="bottom"
          popperMargin="6px"
          tooltip={translate('common.create')}
        >
          <Grid container direction="column" spacing={2}>
            {node.createChildOptions.includes(STRUCTURE_TYPE.COMPANY)
                && getItemButton(STRUCTURE_TYPE.COMPANY, translate('common.company'))}
            {node.createChildOptions.includes(STRUCTURE_TYPE.INSTITUTION_GROUP)
                && getItemButton(STRUCTURE_TYPE.INSTITUTION_GROUP, translate('common.institutionGroup'))}
            {node.createChildOptions.includes(STRUCTURE_TYPE.INSTITUTION_MODEL)
                && getItemButton(STRUCTURE_TYPE.INSTITUTION_MODEL, translate('common.institution'))}
          </Grid>
        </DropdownButton>
      )}
      {hasBackOfficeAdminAccess && (node.type !== STRUCTURE_TYPE.COMPANY && node.type !== STRUCTURE_TYPE.PARENT_COMPANY) && (
        <Tooltip title={translate(node.canDelete ? 'button.delete' : 'institutionGroupTree.tree.tooltip.cannotRemove')}>
          <span>
            <DeleteButton
              color="primary"
              disabled={!node.canDelete}
              style={{ padding: 5 }}
              onClick={handleDeleteNode}
            >
              <FontAwesomeIcon fixedWidth icon={faTrashAlt} size="xs" />
            </DeleteButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default TreeNodeButtons;
