import {
  Button, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { translate } from 'utils/helpers';
import sanitizeHtml from 'sanitize-html';

export const WaitingConfirmationModal = ({
  onClose, onConfirm, content, title
}) => (
  <Fragment>
    <DialogTitle style={{ backgroundColor: 'var(--orange)' }}>
      {title}
    </DialogTitle>

    <DialogContent
      dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }}
      style={{ maxWidth: '650px' }}
    />

    <DialogActions>
      <Button color="primary" onClick={() => onClose()}>
        {translate('button.cancel')}
      </Button>
      <Button color="primary" onClick={() => { onConfirm(); return onClose(); }}>
        {translate('button.confirm')}
      </Button>
    </DialogActions>
  </Fragment>
);

WaitingConfirmationModal.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

WaitingConfirmationModal.defaultProps = {
  content: '',
  onConfirm: null,
  title: null
};
