/* eslint-disable react/jsx-one-expression-per-line */
import { Button } from '@material-ui/core';
import React, { Fragment } from 'react';

export const COMMON_STEPS = {
  done: ({ goTo }) => (
    <Fragment>
      <span className="textEnhanced">Merci de votre attention !</span>
      <br />
      <br />
      Vous pouvez accéder au guide à tout moment en cliquant sur ce bouton.
      <br />
      <br />
      Chaque page possède des explications qui lui sont propres.

      <br />
      <br />
      <Button color="primary" size="small" variant="text" onClick={() => goTo(0)}>
        Revenir au début du tour.
      </Button>
    </Fragment>
  )
};
