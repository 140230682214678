import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils/helpers';

export const DropdownButton = ({
  color, label, icon, disabled, placement, children, isLoading,
  isIconOnly, iconSize, popperMargin, tooltip, buttonStyle
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef();

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const button = (isIconOnly)
    ? (
      <span ref={anchorRef}>
        <IconButton
          color={color}
          disabled={disabled || isLoading}
          style={buttonStyle}
          onClick={handleToggle}
        >
          <FontAwesomeIcon fixedWidth icon={isLoading ? faSpinner : icon} size={iconSize} spin={isLoading} />
        </IconButton>
      </span>
    )
    : (
      <Button
        color={color}
        disabled={disabled || isLoading}
        icon={faAngleDown}
        ref={anchorRef}
        startIcon={icon && <FontAwesomeIcon icon={isLoading ? faSpinner : icon} spin={isLoading} />}
        style={buttonStyle}
        variant="contained"
        onClick={handleToggle}
      >
        {translate(label)}
      </Button>
    );

  return (
    <>
      {tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button }
      <Popper
        anchorEl={anchorRef.current}
        open={isOpen}
        placement={placement}
        style={{ zIndex: 110 }}
        transition
        onClick={handleClose}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'top center' }}
          >
            <Paper style={{ padding: '1rem', margin: popperMargin }}>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

DropdownButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  iconSize: PropTypes.string,
  isIconOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  placement: PropTypes.string,
  popperMargin: PropTypes.string,
  buttonStyle: PropTypes.shape({})
};

DropdownButton.defaultProps = {
  buttonStyle: {},
  color: 'primary',
  disabled: false,
  iconSize: 'xs',
  isIconOnly: false,
  isLoading: false,
  label: '',
  placement: 'bottom-end',
  popperMargin: '0'
};
