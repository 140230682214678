import { Typography } from '@material-ui/core';
import { ItemOption } from 'components/Form/ItemOption/ItemOption';
import { RequiredSelectHack } from 'components/RequiredSelectHack/RequiredSelectHack';
import { OperatorSummary } from 'models';
import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';
import styled from 'styled-components';
import { translate } from 'utils/helpers';

const StyledOperatorName = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-right: 10px;
  color: var(--white);
  background: var(--blue);
  border-radius: 50%;
`;

const CustomOption = ({
  data,
  innerProps
}: {
  data: OperatorSummary,
  innerProps
}) => {
  const initials: string[] = data.fullName?.split(' ');
  return (
    <ItemOption {...innerProps} isInsideSelect>
      {initials && initials.length > 1
        && <StyledOperatorName>
          {`${initials[0].charAt(0)}${initials[1].charAt(0)}`}
        </StyledOperatorName>
      }
      <Typography>
        {data.fullName}
      </Typography>
    </ItemOption>
  );
};

const SelectOperator = ({
  isLoading,
  isRequired = false,
  onChange,
  options,
  value
}: {
  isLoading: boolean,
  isRequired?: boolean,
  onChange: (value: OperatorSummary) => void,
  options: OperatorSummary[],
  value: OperatorSummary
}) => (
  <>
    <Select
      components={{ Option: CustomOption, SingleValue: CustomOption }}
      id="selectOperator"
      isClearable
      isLoading={isLoading}
      isSearchable
      menuPortalTarget={document.body}
      noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
      options={options}
      placeholder={translate('common.searchByName')}
      styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }), container: () => ({ minWidth: isMobileOnly ? 200 : 500 }) }}
      value={value}
      onChange={onChange}
    />

    {isRequired && (
      <RequiredSelectHack value={value || ''} />
    )}
  </>
);

SelectOperator.propTypes = {
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

SelectOperator.defaultProps = {
  isLoading: false,
  value: null
};

export default SelectOperator;
