import React, { useCallback, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  faEye, faEyeSlash, faSave, faSpinner
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar, Divider, Grid, InputAdornment, TableCell, TextField, Typography
} from '@material-ui/core';
import {
  ButtonBlue, GenericListContainer, GenericTable, ReactTour, SkeletonTableSmall, SubtitlePage, TextAddress
} from 'components';
import { TrackDechetsInfos } from 'models';
import { InstitutionEntry } from 'models/list/InstitutionList';
import { Trans } from 'react-i18next';
import { TrackDechetsService } from 'services';
import shortid from 'shortid';
import { translate } from 'utils/helpers';

const ConnexionStatus = ({ status }) => {
  let color;
  let messageCode;

  switch (status) {
    case 'PARTIAL_KO':
      color = 'var(--orange)';
      messageCode = 'pageTrackDechets.messagePartialKO';
      break;
    case 'PARTIAL_OK':
      color = 'var(--blue)';
      messageCode = 'pageTrackDechets.messagePartialOK';
      break;
    case 'KO':
      color = 'var(--red)';
      messageCode = 'pageTrackDechets.messageKO';
      break;
    case 'OK':
    default:
      color = 'var(--green)';
      messageCode = 'pageTrackDechets.messageOK';
      break;
  }
  return (
    <>
      <Grid item>
        <Avatar style={{ backgroundColor: color }}>{status.substring(0, 2)}</Avatar>
      </Grid>
      <Grid item>
        <Typography><Trans i18nKey={messageCode} /></Typography>
      </Grid>
    </>
  );
};

export const TrackDechets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [trackDechetsInfos, setTrackDechetsInfos] = useState(new TrackDechetsInfos());
  const [apiKey, setApiKey] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const listHeaders = [
    {
      name: 'name',
      label: translate('common.lastName'),
      template: row => (
        <TableCell key={shortid.generate()}>
          {row.name}
        </TableCell>
      )
    },
    {
      name: 'siret',
      label: translate('common.siret'),
      template: row => (
        <TableCell key={shortid.generate()}>
          {row.siret || '-'}
        </TableCell>
      )
    }, {
      name: 'attestationNumber',
      label: translate('common.attestationNumber'),
      template: row => (
        <TableCell key={shortid.generate()}>
          {row.attestationNumber || '-'}
        </TableCell>
      )
    }, {
      name: 'address',
      label: translate('common.address'),
      template: row => (
        <TableCell key={shortid.generate()}>
          {row.address && row.address.city ? (
            <TextAddress address={row.address} />
          ) : '-'}
        </TableCell>
      )
    }
  ];

  useEffect(() => {
    // Load the current key and the connection status
    setIsLoading(true);
    TrackDechetsService.getTrackDechetsInfos()
      .then((response) => {
        setTrackDechetsInfos(response);
        setApiKey(response.apiKey);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleChangeApiKey = useCallback(e => setApiKey(e.target.value), []);

  const handleApiConnection = useCallback((e) => {
    // Update the key and refresh the connection status
    e.preventDefault();
    setIsLoading(true);
    TrackDechetsService.setTrackDechetsKey(apiKey)
    .then((response) => {
      setTrackDechetsInfos(response);
      setApiKey(response.apiKey);
    })
    .finally(() => setIsLoading(false));
  }, [apiKey]);

  const TrackDechetsInfosTable = ({ listHeadersTable }) => (
    <GenericTable<InstitutionEntry>
      dataCy="institutionsList"
      hasMore={false}
      headers={listHeadersTable}
      rows={trackDechetsInfos.missingInstitutions}
    />
  );

  return (
    <>
      <GenericListContainer>
        <SubtitlePage>
          {translate('pageTrackDechets.title')}
        </SubtitlePage>

        <Grid container direction="column" spacing={3}>
          <Grid item>
            <form onSubmit={handleApiConnection}>
              <Grid
                alignItems="center"
                container
                direction="column"
              >
                <TextField
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={isPasswordVisible ? faEye : faEyeSlash} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
                      </InputAdornment>
                    )
                  }}
                  label={translate('pageTrackDechets.apiKey')}
                  name="apiKey"
                  style={{ minHeight: '70px', minWidth: '280px' }}
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={apiKey}
                  variant="outlined"
                  onChange={handleChangeApiKey}
                />
                <ButtonBlue disabled={isLoading} type="submit">
                  <FontAwesomeIcon icon={isLoading ? faSpinner : faSave} spin={isLoading} />
                  {translate('button.save')}
                </ButtonBlue>
              </Grid>
            </form>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          {!isLoading
            && (
              <Grid item>
                <Grid alignItems="center" container justifyContent="center" spacing={2}>
                  <ConnexionStatus status={trackDechetsInfos.status} />
                </Grid>
              </Grid>
            )}
        </Grid>

        {isLoading && <SkeletonTableSmall />}

        {!isLoading
          && trackDechetsInfos.missingInstitutions
          && trackDechetsInfos.missingInstitutions.length > 0
          && (
            <TrackDechetsInfosTable listHeadersTable={listHeaders} />
          )}
        <ReactTour steps={[]} />
      </GenericListContainer>
    </>
  );
};
