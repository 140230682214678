/* eslint-disable react/jsx-no-bind */
import {
  faFilePdf, faSync, faTag, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, IconButton, TableCell, Tooltip, Typography
} from '@material-ui/core';
import {
  ButtonBlue, ButtonDelete, FlexContainer, FontIcon,
  GenericTable, InterventionStatus, SkeletonList, SubtitlePage, TextAddress, TextError, Wrapper
} from 'components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import shortid from 'shortid';
import styled from 'styled-components';
import {
  DocumentHelper, InterventionHelper, parseDate, translate, UserHelper
} from 'utils/helpers';

import { useModal, useStores } from 'hooks';
import { ModalState } from 'hooks/ModalProvider';
import { InterventionCategory } from 'models';
import { getIdentifier } from 'models/detail/EquipmentView';
import { FormFrontView, getAddressFromForm } from 'models/detail/FormFrontView';
import { InterventionEntry } from 'models/list/InterventionList';
import { InterventionsService } from 'services';
import { StatusList } from 'utils/constants';
import { HeaderType } from 'utils/types';

const CustomExportTemplate = ({
  displayModal,
  isExportable,
  row
}: {
  displayModal: (state: ModalState) => void,
  isExportable: (interventionStatus: string) => boolean,
  row: InterventionEntry
}) => {
  const callbackClickAction = (e) => {
    e.preventDefault();
    e.stopPropagation();

    InterventionsService.getInterventionCerfa(row.hashId).then(response => (
      DocumentHelper.displayPDF({
        displayModal: modalType => displayModal({
          type: modalType,
          title: response.name,
          pdf: {
            name: response.name,
            base64Content: DocumentHelper.getPDFWithBase64(response)
          }
        })
      })
    ));
  };

  return (
    <TableCell>
      {isExportable && isExportable(row.status) && (
        <Tooltip title={translate('pageInterventionList.tooltipExportCerfa')}>
          <IconButton onClick={callbackClickAction}>
            <FontAwesomeIcon
              color="var(--blue)"
              icon={faFilePdf}
            />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
};

const getListHeadersTable: (_: {
  INTERVENTION_STATUS: StatusList,
  deleteAction: (e, hashId: string, identifier: string, isFromStorage: boolean) => void,
  displayModal: (state: ModalState) => void,
  isExportable?: (interventionStatus: string) => boolean,
  isFromStorage: boolean
}) => HeaderType<InterventionEntry>[] = ({
  INTERVENTION_STATUS,
  deleteAction,
  displayModal,
  isExportable,
  isFromStorage = false
}) => ([
  {
    name: 'customerName',
    label: translate('common.customer'),
    width: '200px',
    template: row => (
      <TableCell key={shortid.generate()}>
        <p data-cy="interventionListItemDetailName"><b>{row.customerName || translate('errors.noCustomer')}</b></p>
        <p>
          <FontAwesomeIcon icon={faTag} />
          {row.fileNumber}
        </p>
      </TableCell>
    )
  }, {
    name: 'address',
    label: translate('common.address'),
    width: '200px',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.interventionAddress ? (
          <TextAddress address={row.interventionAddress} variant="body1" />
        ) : '-'}
      </TableCell>
    )
  }, {
    name: 'appointment',
    label: translate('common.date'),
    width: '130px',
    template: row => (
      <TableCell key={shortid.generate()}>
        <p>
          {InterventionHelper.displayAppointment(row.appointment, parseDate(row.interventionDate))}
        </p>
      </TableCell>
    )
  }, {
    name: 'equipmentIdentifier',
    label: translate('common.equipment'),
    width: '200px',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.equipmentIdentifier ? (
          <Grid container direction='column'>
            <Grid item>
              {row.equipmentIdentifier}
            </Grid>
            <Grid item>
              <p>
                {row.fluid}
              </p>
            </Grid>
          </Grid>
        ) : '-'}
      </TableCell>
    )
  }, {
    name: 'status',
    label: translate('common.status'),
    width: '140px',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.status ? (
          <InterventionStatus
            flexDirection="row"
            INTERVENTION_STATUS={INTERVENTION_STATUS}
            outOfSync={row.isOutOfSync}
            status={row.status}
          />
        ) : '-'}
      </TableCell>
    )
  }, {
    name: 'export',
    label: translate('common.cerfa'),
    template: row => <CustomExportTemplate displayModal={displayModal} isExportable={isExportable} key={shortid.generate()} row={row} />
  }, {
    name: 'delete',
    label: translate('button.delete'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {(isFromStorage || row.canDelete) && (
          <Tooltip title={translate('pageInterventionList.tooltipDelete')}>
            <ButtonDelete onClick={e => deleteAction(e, row.hashId, row.fileNumber, isFromStorage)}>
              <FontAwesomeIcon color="var(--grey)" icon={faTrashAlt} />
            </ButtonDelete>
          </Tooltip>
        )}
      </TableCell>
    )
  }
]);

const ListTableContainer = styled.div`
  td {
    & > svg {
      margin-right: 0.5rem;
    }
  }
`;

export const ListTableMode = ({
  INTERVENTION_STATUS,
  buildInterventionCategory,
  currentPage,
  deleteAction,
  displayModal,
  displayModalCreateIntervention,
  filterStoredInterventionOnSearch,
  interventionList,
  isExportable,
  isLoading,
  lastAutomaticDownload,
  loadMore,
  maxPage,
  reloadList,
  storageUsage,
  storedInterventionList,
  total
}: {
  INTERVENTION_STATUS: StatusList,
  buildInterventionCategory: (form: FormFrontView) => InterventionCategory,
  currentPage: number,
  deleteAction: (e, hashId: string, identifier: string, isFromStorage: boolean) => void,
  displayModal: (state: ModalState) => void,
  displayModalCreateIntervention: () => void,
  filterStoredInterventionOnSearch: (form: FormFrontView) => boolean,
  interventionList: InterventionEntry[],
  isExportable: (interventionStatus: string) => boolean,
  isLoading: boolean,
  lastAutomaticDownload: string,
  loadMore: () => void,
  maxPage: number,
  reloadList: () => void,
  storageUsage: {
    interventionsRemaining: number,
    interventionsStored: number,
    percentageUsed: string
  },
  storedInterventionList: FormFrontView[],
  total: number
}) => {
  const history = useHistory();
  const { userStore } = useStores();
  const { open } = useModal();

  return (
    <ListTableContainer>
      {storedInterventionList.length > 0 && (
        <>
          <Grid alignItems="center" container direction="column">
            <Grid item>
              <Grid alignItems="center" container justifyContent="center" spacing={2}>
                <Grid item>
                  <SubtitlePage>{translate('pageInterventionList.interventionsOngoing')}</SubtitlePage>
                </Grid>
                {storageUsage && (
                  <Grid item>
                    <Typography>
                      {`${translate('pageInterventionList.percentageUsed', { percent: storageUsage.percentageUsed })}`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {lastAutomaticDownload && (
              <Grid item>
                <Typography style={{ marginTop: '-10px' }} variant="body2">
                  {`${translate('pageInterventionList.lastAutomaticDownload', { lastAutomaticDownload })}`}
                </Typography>
              </Grid>
            )}
          </Grid>

          <GenericTable<InterventionEntry>
            dataCy="interventionListStored"
            dataTour="step-intervention-list-local"
            headers={getListHeadersTable({
              deleteAction, isFromStorage: true, INTERVENTION_STATUS, displayModal: open
            })}
            rows={storedInterventionList
              .filter(intervention => filterStoredInterventionOnSearch(intervention))
              .map((storedIntervention) => {
                if (storedIntervention) {
                  let customerName = '';
                  let equipmentIdentifier = '';
                  let fluid = '';
                  const interventionAddress = getAddressFromForm(storedIntervention);
                  const {
                    equipment, circuit, equipmentOwner, status = 'READY'
                  } = storedIntervention;
                  const interventionCategory = buildInterventionCategory(storedIntervention);
                  if (equipmentOwner) {
                    customerName = `${equipmentOwner.firstName || ''} ${equipmentOwner.lastName || ''}`;
                  }
                  if (equipment) {
                    equipmentIdentifier = getIdentifier(equipment);

                    if (equipment.fluid) {
                      fluid = equipment.fluid.label;
                    }
                  }
                  if (circuit) {
                    fluid = circuit.fluid?.name;
                  }

                  const entry: InterventionEntry = {
                    ...storedIntervention,
                    customerName,
                    equipmentIdentifier,
                    fluid,
                    interventionAddress,
                    interventionCategory,
                    status,
                    canDelete: true
                  };

                  return entry;
                }

                return null;
              })}
            total={total}
            onRowClick={(row) => {
              history.push({
                pathname: `/interventions/${row.hashId || row.fileNumber}/detail`,
                state: {
                  isNew: !row.hashId
                }
              });
            }}
          />
        </>
      )}

      {!isLoading && (!interventionList || interventionList.length === 0)
        ? (
          <FlexContainer alignItems="center" flexDirection="column">
            <FontIcon color="var(--grey)" fontSize="6rem" icon="icon-intervention" marginTop="1rem" />
            {storedInterventionList.length === 0 && <TextError>{translate('errors.noIntervention')}</TextError>}

            {userStore.isViewingInstitution() && UserHelper.hasAccessRightCreateIntervention() && (
              <ButtonBlue data-cy="createNewIntervention" onClick={displayModalCreateIntervention}>
                <FontIcon fontSize="2rem" icon="icon-create-intervention" />
                {translate('pageHome.buttonCreate')}
              </ButtonBlue>
            )}
          </FlexContainer>
        ) : (
          <>
            <SubtitlePage>
              {translate('pageInterventionList.interventionsOthers')}
              <IconButton className="ml1" onClick={reloadList}>
                <FontAwesomeIcon color="var(--blue)" icon={faSync} spin={isLoading} />
              </IconButton>
              {/* <CustomTooltip text={translate('pageInterventionList.interventionsOthersTooltip')} /> */}
            </SubtitlePage>
            <GenericTable<InterventionEntry>
              dataCy="interventionList"
              dataTour="step-intervention-list-database"
              hasMore={currentPage < maxPage}
              headers={getListHeadersTable({
                deleteAction, isExportable, isFromStorage: false, displayModal, INTERVENTION_STATUS
              })}
              loadMore={loadMore}
              rows={interventionList && interventionList
                // Hide started interventions that are in the IndexedDB from the main list
                .filter((intervention) => {
                  const storedIdList = storedInterventionList.map(i => i.hashId || i.fileNumber);
                  return (!storedIdList.includes(intervention.hashId) && !storedIdList.includes(intervention.fileNumber));
                })
                .map(intervention => ({
                  isDeletable: intervention.canDelete,
                  tooltipDeleteText: translate('pageInterventionList.tooltipDelete'),
                  tooltipCheckText: translate('pageInterventionList.tooltipExportCerfa'),
                  ...intervention
                }))
              }
              total={total}
              onRowClick={(row) => {
                history.push(`/interventions/${row.hashId}/detail`);
              }}
            />
          </>
        )
      }

      {isLoading && <Wrapper><SkeletonList /></Wrapper>}
    </ListTableContainer>
  );
};
