import { faBalanceScale, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faCookieBite } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Grid } from '@material-ui/core';
import { Wrapper } from 'components';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ASSISTANCE_EMAIL, ROUTES } from 'utils/constants';
import { translate } from 'utils/helpers';

const StyledFooter = styled.footer`
  margin-top: 40px;
  text-align: center;

  img {
    max-width: 100%;
  }

  @media (max-width: 1081px) {
    padding-bottom: 80px;

    .desktop {
      display: none;
    }

    .supralog {
      margin-top: 10px;
    }
  }

  @media (min-width: 1080px) {
    .mobile {
      display: none;
    }

    .supralog {
      margin-bottom: 30px;
    }
  }
`;

const StyledDivider = styled(Divider)`
  display: inline-block;
  width: 2px;
  background-color: #7e7e7e !important;
  height: 14px !important;
`;

export const Footer = withRouter(({ location }) => location.pathname.indexOf('/inspection') === -1 && (
  <Wrapper>
    <StyledFooter>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid
            alignItems="center"
            container
            direction={isMobileOnly ? 'column' : 'row'}
            justifyContent="center"
            spacing={isMobileOnly ? 1 : 2}
          >
            <Grid item>
              <Link to={ROUTES.RGPD}>
                <FontAwesomeIcon className="mr1" icon={faBalanceScale} />
                {translate('common.personalData')}
              </Link>
            </Grid>

            {!isMobileOnly && (
              <Grid item>
                <StyledDivider orientation="vertical" />
              </Grid>
            )}

            <Grid item>
              <Link to={ROUTES.COOKIES}>
                <FontAwesomeIcon className="mr1" icon={faCookieBite} />
                {translate('link.rgpd.cookies')}
              </Link>
            </Grid>

            {!isMobileOnly && (
              <Grid item>
                <StyledDivider orientation="vertical" />
              </Grid>
            )}

            <Grid item>
              <a href={`mailto:${ASSISTANCE_EMAIL}`}>
                <FontAwesomeIcon className="mr1" icon={faEnvelope} />
                {`${translate('common.assistance')} (${ASSISTANCE_EMAIL})`}
              </a>
            </Grid>

            {/* <Grid item>
              <StyledDivider orientation="vertical" />
            </Grid>

            <Grid item>
              <a
                href={`${DATATECNEA_URL}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIconStyled icon={faLink} />
                {translate('common.datatecneaLink')}
              </a>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item>
          <img
            alt="TECNEA_GROUP"
            src={`${process.env.PUBLIC_URL}/assets/images/icons/companies/TECNEA_GROUP.png`}
          />
        </Grid>
        {/* <FooterSupralog /> */}
      </Grid>

      <img
        alt={translate('menu.logoAlt')}
        className="supralog"
        src={`${process.env.PUBLIC_URL}/assets/images/bg/${isMobileOnly ? 'footer-mobile' : 'footer'}.png`}
      />
    </StyledFooter>
  </Wrapper>
));
