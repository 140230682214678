import {
  faAirConditioner, faPlus, faTag
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, TextField, Typography } from '@material-ui/core';
import {
  ButtonBigDashed,
  ButtonBlueLight,
  FlexContainer,
  InputCounter,
  ItemOption,
  ItemSelected,
  RequiredSelectHack,
  SelectCreateContainer,
  Text,
  TextAddress,
  TextNoOptionsAvailable
} from 'components';
import debounce from 'debounce-promise';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { EntityView } from 'models';
import { getIdentifier } from 'models/detail/EquipmentView';
import { CircuitView, RefrigerationUnitView } from 'models/detail/RefrigerationUnitView';
import { RefrigerationUnitSummaryItem } from 'models/list/RefrigerationUnitSummary';
import { useSnackbar } from 'notistack';

import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import Media from 'react-media';

import AsyncSelect from 'react-select/async';
import {
  Args, FormState, InputInitializer, StateErrors
} from 'react-use-form-state';
import { EquipmentsService } from 'services';
import shortid from 'shortid';
import { DocumentHelper, InterventionHelper, translate } from 'utils/helpers';

import { Question } from '../Question/Question';

const OBSERVATIONS_MAX_LENGTH = 300;

const EquipmentOption = ({
  unit,
  isInsideSelect = false,
  openModal,
  ...rest
}: {
  isInsideSelect?: boolean,
  openModal,
  rest?,
  unit: RefrigerationUnitView
}) => (
  <ItemOption {...rest} isInsideSelect={isInsideSelect}>
    {!isInsideSelect && <FontAwesomeIcon icon={faAirConditioner} size="2x" />}
    <article data-cy="equipmentSelected" style={{ maxWidth: unit?.documentList?.documents?.length > 0 ? '70%' : '100%' }}>
      <Text fontSize="2rem">{getIdentifier(unit)}</Text>
      <p>
        <FontAwesomeIcon icon={faTag} />
        {(unit.circuits && unit.circuits[0]?.fluid?.name) || translate('errors.noFluid')}
          &nbsp;-&nbsp;
        {(unit.circuits && unit.circuits[0]?.fluidCapacity) || '0'}
          &nbsp;{translate('common.weightUnit')}
      </p>
      {unit.address?.address1 && (
        <TextAddress address={unit.address} variant="body1" withIcon />
      )}
    </article>
    {unit?.documentList?.documents?.length > 0 && (
    <FlexContainer alignItems="center">
      {unit.documentList.documents.map(picture => {
        let src;
        if (picture.base64Content && picture.base64Content.includes('data:image')) {
          src = picture.base64Content;
        } else {
          src = DocumentHelper.getImageWithBase64(picture);
        }
        return (
          <img
              alt={picture.name}
              key={shortid.generate()}
              src={src}
              onClick={() => openModal({ type: 'DISPLAY_PICTURE', ...picture })}
              onKeyDown={() => openModal({ type: 'DISPLAY_PICTURE', ...picture })}
            />
          );
      })}
    </FlexContainer>
      )}
  </ItemOption>
  );

/**
 * Subtype of InterventionFrontView and FormFrontView used to manage the equipment for an intervention
 */
interface FormStateEquipmentView {
  circuit?: CircuitView;
  equipment?: RefrigerationUnitView;
  equipmentOwner?: EntityView;
  observations?:string;
}

export const Equipment = observer(({
  formState,
  isRequired = true,
  textarea
}: {
  formState: FormState<FormStateEquipmentView, StateErrors<FormStateEquipmentView, string>>,
  isRequired?: boolean,
  textarea: InputInitializer<FormStateEquipmentView, Args<keyof FormStateEquipmentView, void>, any>
}) => {
  const { userStore } = useStores();
  const { open, close } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [availableEquipments, setAvailableEquipments] = useState<RefrigerationUnitSummaryItem[]>([]);
  const [ownerFilteredEquipments, setOwnerFilteredEquipments] = useState<RefrigerationUnitSummaryItem[]>([]);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    // If the equipments are already stored, use the stored list
    InterventionHelper.loadEquipmentsStored()
      .then((storedEquipments) => {
        setAvailableEquipments(storedEquipments ?? []);
      });
    // Reload the list in the background
    if (!userStore.isOffline) {
      setIsLoading(true);
      EquipmentsService.getEquipmentList({})
        .then((equipments) => {
          const mappedEquipments = DocumentHelper.getDatasWithDocumentsFormatted({ datas: equipments });
          setAvailableEquipments(mappedEquipments);
        })
        .finally(() => setIsLoading(false));
    }
  }, [userStore.isOffline]);

  useEffect(() => {
    // When the equipment owner is changed, filter the equipment list accordingly
    let filteredEquipments = availableEquipments;
    if (formState.values.equipmentOwner && formState.values.equipmentOwner.id) {
      filteredEquipments = filteredEquipments.filter(equip => (equip.ownerEntityId === formState.values.equipmentOwner.id) || !equip.ownerEntityId);
    } else if (formState.values.equipmentOwner && !formState.values.equipmentOwner.id) {
      filteredEquipments = filteredEquipments.filter(equip => !equip.ownerEntityId);
    }
    setOwnerFilteredEquipments(filteredEquipments.slice(0, 150));
  }, [formState.values.equipmentOwner, availableEquipments]);

  useEffect(() => {
    // Whenever the formState is updated, if both equipmentOwner and equipment are selected
    // make sure that the equipment belongs to the equipmentOwner
    if (formState.values.equipmentOwner && formState.values.equipmentOwner.id
      && formState.values.equipment && formState.values.equipment.id) {
      if (!formState.values.equipment.ownerEntityId) return;

      const doesEquipmentBelongToEquipmentOwner = formState.values.equipment.ownerEntityId === formState.values.equipmentOwner.id;
      if (!doesEquipmentBelongToEquipmentOwner) {
        // enqueueSnackbar(translate('interventionForm.equipment.mismatchEquipment'), { variant: 'warning' });
        // formState.setField('equipment', null);
      }
    }
  }, [enqueueSnackbar, formState]);

  const handleForm = useCallback((form: RefrigerationUnitView) => {
    formState.setField('equipment', form);
    formState.setField('circuit', form.circuits && form.circuits[0]);
    close();
  }, [close, formState]);

  const softIncludes = (a: string, b: string) => {
    if (!a || !b) {
      return false;
    }
    return a.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase()
      .includes(b.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase());
  };

  const getAsyncOptions = useCallback(inputValue => new Promise((resolve) => {
    // If there are no available equipments, return an empty list
    if (!availableEquipments) {
      resolve([]);
      return;
    }

    // Filter the equipments on the equipment owner
    let filteredEquipments = availableEquipments;
    if (formState.values.equipmentOwner && formState.values.equipmentOwner.id) {
      filteredEquipments = filteredEquipments.filter(equip => (equip.ownerEntityId === formState.values.equipmentOwner.id) || !equip.ownerEntityId);
    } else if (formState.values.equipmentOwner && !formState.values.equipmentOwner.id) {
      filteredEquipments = filteredEquipments.filter(equip => !equip.ownerEntityId);
    }

    // If the search input is empty, return the first 100 results
    if (!inputValue) {
      resolve(filteredEquipments.slice(0, 100));
      return;
    }
    const a = filteredEquipments.filter(e => softIncludes(e.internalIdentification, inputValue)
      || softIncludes(e.comment, inputValue)
      || softIncludes(e.model, inputValue)
      || softIncludes(e.serialNumber, inputValue)
      || softIncludes(e.circuits && e.circuits[0]?.fluid?.name, inputValue));
    resolve(a.slice(0, 100));
  }), [availableEquipments, formState.values.equipmentOwner]);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const CustomOption = ({
    data,
    innerProps
  }: {
    data: RefrigerationUnitView,
    innerProps
  }) => (
    <EquipmentOption isInsideSelect openModal={open} unit={data} {...innerProps}/>
  );

  const displayNoOptionAvailable = () => {
    if (searchInput) {
      return (
        <Fragment>
          <TextNoOptionsAvailable>{translate('errors.noEquipmentInput', { searchInput })}</TextNoOptionsAvailable>
          <ButtonBigDashed
            data-cy="addEquipment"
            onClick={() => open({
              type: 'ADD_EQUIPMENT',
              onSubmit: handleForm,
              defaultValues: {
                model: searchInput
              }
            })}
          >
            <FontAwesomeIcon icon={faPlus} />
            {translate('button.createEquipment')}
          </ButtonBigDashed>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <TextNoOptionsAvailable>{translate('errors.noEquipment')}</TextNoOptionsAvailable>
        <ButtonBigDashed
          data-cy="addEquipment"
          onClick={() => open({
            type: 'ADD_EQUIPMENT',
            onSubmit: handleForm
          })}
        >
          <FontAwesomeIcon icon={faPlus} />
          {translate('button.createEquipment')}
        </ButtonBigDashed>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Question
        isLoading={isLoading}
        label={translate('interventionForm.equipment.questionEquipment')}
        loadingMessage='interventionForm.equipment.loadingEquipments'
        tooltip="interventionFormSteps.equipmentTooltip"
      >
        {formState.values.equipment ? (
          <Fragment>
            <ItemSelected
              deleteAction={() => formState.setField('equipment', null)}
              editAction={!formState.values.equipment.id ? () => open({
                type: 'ADD_EQUIPMENT',
                onSubmit: handleForm,
                defaultValues: formState.values.equipment
              }) : null}
            >
              <EquipmentOption openModal={open} unit={formState.values.equipment} />
            </ItemSelected>
            <InputCounter maxLength={OBSERVATIONS_MAX_LENGTH} value={formState.values.observations}>
              <TextField
                inputProps={{
                  maxLength: OBSERVATIONS_MAX_LENGTH
                }}
                label={translate('interventionForm.equipment.observationEquipment')}
                maxRows={3}
                minRows={3}
                multiline
                name="observations"
                placeholder={translate('interventionForm.equipment.observationEquipmentLabel')}
                {...textarea('observations')}
              />
            </InputCounter>
          </Fragment>
        ) : (
          <>
            <Typography gutterBottom>{translate('interventionForm.equipment.selectEquipmentsPlaceholder')}</Typography>
            <Typography gutterBottom>{translate('interventionForm.equipment.selectEquipmentsPlaceholder2')}</Typography>
            <SelectCreateContainer>
              <div>
                <AsyncSelect
                  components={{ Option: CustomOption }}
                  defaultOptions={ownerFilteredEquipments}
                  id="selectEquipment"
                  isClearable
                  isLoading={(!availableEquipments || availableEquipments.length === 0) && isLoading}
                  loadingMessage={() => translate('interventionForm.equipment.loadingEquipments')}
                  loadOptions={inputValue => debouncedLoadOptions(inputValue)}
                  menuPlacement={isMobileOnly ? 'top' : 'bottom'}
                  noOptionsMessage={displayNoOptionAvailable}
                  placeholder={translate('common.searchByModelSerialNumber')}
                  value={formState.values.equipment || ''}
                  onChange={handleForm}
                  onInputChange={value => setSearchInput(value)}
                />
                {isRequired && <RequiredSelectHack value={formState.values.equipment || ''} />}
              </div>
              <div>
                <Media query="(max-width: 768px)">
                  {matches => (matches
                    ? (
                      <Fab
                        color="primary"
                        size="small"
                        onClick={() => open({
                          type: 'ADD_EQUIPMENT',
                          onSubmit: handleForm
                        })}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Fab>
                    ) : (
                      <ButtonBlueLight
                        data-cy="addEquipment"
                        onClick={() => open({
                          type: 'ADD_EQUIPMENT',
                          onSubmit: handleForm
                        })}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {translate('button.createEquipment')}
                      </ButtonBlueLight>
                    )
                  )}
                </Media>
              </div>
            </SelectCreateContainer>
          </>
        )}
      </Question>
    </Fragment>
  );
});
