import { faUserSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { StatusList } from 'utils/constants';
import { translate } from 'utils/helpers';

const StyledInterventionStatus = styled((props) => <Grid {...props} />)`
  min-width: ${props => props.minwidth};
  color: ${props => props.color};
  text-align: center;
`;

export const InterventionStatus = ({
  INTERVENTION_STATUS,
  flexDirection = 'column',
  outOfSync = false,
  status
}: {
  INTERVENTION_STATUS: StatusList,
  flexDirection?: string,
  outOfSync?: boolean,
  status: string
}) => (
  <Grid container direction="column" spacing={flexDirection === 'column' ? 1 : 0}>
    {outOfSync ? (
      <Grid item>
        <StyledInterventionStatus
          alignItems="center"
          color="var(--orange)"
          container
          direction={flexDirection}
          justifyContent={flexDirection === 'row' ? 'flex-start' : 'center'}
          minwidth={flexDirection === 'row' ? '140px' : 'auto'}
          spacing={1}
          wrap="nowrap"
        >
          <Grid item>
            <FontAwesomeIcon icon={faUserSlash} />
          </Grid>
          <Grid item>
            {translate('pageInterventionDetail.outOfSync')}
          </Grid>
        </StyledInterventionStatus>
      </Grid>
    ) : INTERVENTION_STATUS && INTERVENTION_STATUS[status] && (
      <Grid item>
        <StyledInterventionStatus
          alignItems="center"
          color={INTERVENTION_STATUS[status].color}
          container
          direction={flexDirection}
          justifyContent={flexDirection === 'row' ? 'flex-start' : 'center'}
          minwidth="80px"
          spacing={1}
          wrap="nowrap"
        >
          <Grid item>
            {INTERVENTION_STATUS[status].icon && <FontAwesomeIcon icon={INTERVENTION_STATUS[status].icon} />}
          </Grid>
          <Grid item>
            {INTERVENTION_STATUS[status].label}
          </Grid>
        </StyledInterventionStatus>
      </Grid>
    )}
  </Grid>
);
