import React, { Fragment, useEffect, useState } from 'react';

import {
  faBalanceScale,
  faCalendarAlt, faPlus
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  ButtonBigDashed,
  ButtonBlueLight, FontIcon,
  ItemOption,
  ItemSelected,
  RequiredSelectHack,
  SelectCreateContainer,
  Text,
  TextNoOptionsAvailable,
  Translate
} from 'components';
import { useModal, useStores } from 'hooks';
import { ModalState } from 'hooks/ModalProvider';
import { observer } from 'mobx-react-lite';
import { EntityView, EquipmentSummaryItem, EquipmentView } from 'models';
import { getIdentifier } from 'models/detail/EquipmentView';
import { CircuitView, RefrigerationUnitView } from 'models/detail/RefrigerationUnitView';
import { isMobileOnly } from 'react-device-detect';
import Media from 'react-media';
import Select from 'react-select';
import {
  Args, FormState, InputInitializer, StateErrors
} from 'react-use-form-state';
import { ToolsService } from 'services';
import styled from 'styled-components';
import { COUNTRY_CODE, TOOL_TYPES } from 'utils/constants';
import {
  DocumentHelper,
  InterventionHelper,
  formatDateString,
  translate
} from 'utils/helpers';
import { Question } from '../Question/Question';

const FLUID_CATEGORIES = {
  HCFC: 'HCFC',
  HFC: 'HFC/PFC',
  HFO: 'HFO',
  PFC: 'HFC/PFC',
  CFC: 'CFC'
};

const FLUID_AMOUNT_KG = {
  NONE: '0',
  LOW: '2kg<Q<30kg',
  MEDIUM: '30kg<Q<300kg',
  HIGH: 'Q>300kg'
};

const FLUID_AMOUNT_TN = {
  NONE: '0',
  LOW: '5t<teqCO2<50t',
  MEDIUM: '50t<teqCO2<500t',
  HIGH: 'teqCO2>500t'
};

const FLUID_CONTROL_FREQUENCY_WITH_DETECTOR = {
  NONE: 'noControlRequired',
  LOW: 'month24',
  MEDIUM: 'month12',
  HIGH: 'month6'
};

const FLUID_CONTROL_FREQUENCY_WITHOUT_DETECTOR = {
  NONE: 'noControlRequired',
  LOW: 'month12',
  MEDIUM: 'month6',
  HIGH: 'month3'
};

const DataContainer = styled.p`
  span {
    font-weight: 600;
  }
`;

const ToolOption = ({
  tool,
  toolType,
  isInsideSelect,
  ...rest
}: {
  isInsideSelect?: boolean
  rest?,
  tool: EquipmentView,
  toolType: string
}) => {
  let icon: JSX.Element;
  if (toolType === TOOL_TYPES.SCALE) {
    icon = <FontAwesomeIcon icon={faBalanceScale} size="lg" style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }} />;
  } else if (toolType === TOOL_TYPES.LEAK_DETECTOR) {
    icon = <FontIcon fontSize="4rem" icon="icon-leak-detector" />;
  } else {
    icon = <></>;
  }

  return (
    <ItemOption {...rest} isInsideSelect={isInsideSelect}>
      {icon}
      <article data-cy="toolSelected">
        <Text fontSize="2rem">{getIdentifier(tool)}</Text>
        {tool.lastControlDate && (
          <p>
            <FontAwesomeIcon icon={faCalendarAlt} />
            {formatDateString(tool.lastControlDate)}
          </p>
        )}
      </article>
    </ItemOption>
  );
};

const ToolSelection = ({
  deleteAction,
  editAction,
  tool,
  toolType
}: {
  deleteAction,
  editAction,
  tool: EquipmentView,
  toolType: string
}) => (
  <Fragment>
    <ItemSelected
      deleteAction={deleteAction} editAction={tool.id ? null : editAction}
    >
      <ToolOption tool={tool} toolType={toolType} />
    </ItemSelected>
  </Fragment>
);

interface FormStateControlView {
  circuit?: CircuitView;
  controlFrequency?: string;
  equipment?: RefrigerationUnitView;
  equipmentOwner?: EntityView;
  fluidAmount?: string;
  fluidCategory?: string;
  leakDetectionSystem?: boolean;
  leakDetector?: EquipmentView;
  observations?:string;
  scale?: EquipmentView;
}

export const Control = observer(({
  countryCode,
  formState,
  text
}: {
  countryCode: string,
  formState: FormState<FormStateControlView, StateErrors<FormStateControlView, string>>,
  text: InputInitializer<FormStateControlView, Args<keyof FormStateControlView, void>, any>
}) => {
  const { userStore } = useStores();
  const { open, close } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [availableLeakDetectors, setAvailableLeakDetectors] = useState([]);
  const [availableScales, setAvailableScales] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const isGB = countryCode === COUNTRY_CODE.GB;
  const isCanada = countryCode === COUNTRY_CODE.CA;
  const isFrance = countryCode === COUNTRY_CODE.FR;

  useEffect(() => {
    if (userStore.isOffline) {
      InterventionHelper.loadLeakDetectorsStored()
        .then(LD => setAvailableLeakDetectors(LD));
      InterventionHelper.loadScalesStored()
        .then(S => setAvailableScales(S));
    } else {
      setIsLoading(true);
      Promise.all([ToolsService.getLeakDetectors(), ToolsService.getScales()])
        .then(([leakDetectors, scales]) => {
          const mappedLD = DocumentHelper.getDatasWithDocumentsFormatted({ datas: leakDetectors?.content || [] });
          setAvailableLeakDetectors(mappedLD);
          const mappedScales = DocumentHelper.getDatasWithDocumentsFormatted({ datas: scales?.content || [] });
          setAvailableScales(mappedScales);
        })
        .finally(() => setIsLoading(false));
    }
  }, [userStore.isOffline]);

  const isHCFC = () => (
    formState.values.circuit
    && formState.values.circuit.fluid
    && formState.values.circuit.fluid.fluidClass
    && FLUID_CATEGORIES[formState.values.circuit.fluid.fluidClass] === FLUID_CATEGORIES.HCFC
  );

  const isHFO = () => (
      formState.values.circuit
      && formState.values.circuit.fluid
      && formState.values.circuit.fluid.fluidClass
      && FLUID_CATEGORIES[formState.values.circuit.fluid.fluidClass] === FLUID_CATEGORIES.HFO
  );

  const teqCO2 = (formState.values.circuit && formState.values.circuit.fluid)
    ? Number([
      Number(formState.values.circuit.fluidCapacity * (formState.values.circuit.fluid.co2Coef)).toString(),
      Number(formState.values.circuit.fluidCapacity * (formState.values.circuit.fluid.co2Coef)).toFixed(2)
    ].reduce((a, b) => (a.length <= b.length ? a : b)))
    : 0;

  useEffect(() => {
    formState.setField('leakDetectionSystem', !!formState.values.equipment?.hasLeakDetectionSystem);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Setup default values
    let fluidAmountIndex = 'NONE';
    let fluidAmountMapping: string;

    if (formState.values.circuit && formState.values.circuit.fluid && formState.values.circuit.fluid.fluidClass) {
      const fluidAmountCapacity = formState.values.circuit.fluidCapacity;
      // Default fluidCategory
      formState.setField('fluidCategory', FLUID_CATEGORIES[formState.values.circuit.fluid.fluidClass]);
      // Default fluidAmount
      if (isHCFC()) {
        if (fluidAmountCapacity >= 2 && fluidAmountCapacity < 30) {
          fluidAmountMapping = FLUID_AMOUNT_KG.LOW;
          fluidAmountIndex = 'LOW';
        } else if (fluidAmountCapacity >= 30 && fluidAmountCapacity < 300) {
          fluidAmountMapping = FLUID_AMOUNT_KG.MEDIUM;
          fluidAmountIndex = 'MEDIUM';
        } else if (fluidAmountCapacity >= 300) {
          fluidAmountMapping = FLUID_AMOUNT_KG.HIGH;
          fluidAmountIndex = 'HIGH';
        } else {
          fluidAmountMapping = FLUID_AMOUNT_KG.NONE;
        }
        formState.setField('fluidAmount', fluidAmountMapping);
      } else if (isHFO()) {
       if (fluidAmountCapacity >= 1 && fluidAmountCapacity < 10) {
         fluidAmountMapping = FLUID_AMOUNT_KG.LOW;
         fluidAmountIndex = 'LOW';
       } else if (fluidAmountCapacity >= 10 && fluidAmountCapacity < 100) {
         fluidAmountMapping = FLUID_AMOUNT_KG.MEDIUM;
         fluidAmountIndex = 'MEDIUM';
       } else if (fluidAmountCapacity >= 100) {
         fluidAmountMapping = FLUID_AMOUNT_KG.HIGH;
         fluidAmountIndex = 'HIGH';
       } else {
         fluidAmountMapping = FLUID_AMOUNT_KG.NONE;
       }
       formState.setField('fluidAmount', fluidAmountMapping);
      } else {
        if (teqCO2 >= 5 && teqCO2 < 50) {
          fluidAmountMapping = FLUID_AMOUNT_TN.LOW;
          fluidAmountIndex = 'LOW';
        } else if (teqCO2 >= 50 && teqCO2 < 500) {
          fluidAmountMapping = FLUID_AMOUNT_TN.MEDIUM;
          fluidAmountIndex = 'MEDIUM';
        } else if (teqCO2 >= 500) {
          fluidAmountMapping = FLUID_AMOUNT_TN.HIGH;
          fluidAmountIndex = 'HIGH';
        } else {
          fluidAmountMapping = FLUID_AMOUNT_TN.NONE;
        }
        formState.setField('fluidAmount', fluidAmountMapping);
      }
    }

    // Default controlFrequency
    if (formState.values.leakDetectionSystem && !isHCFC()) {
      formState.setField('controlFrequency', translate(`common.${FLUID_CONTROL_FREQUENCY_WITH_DETECTOR[fluidAmountIndex]}`));
    } else {
      formState.setField('controlFrequency', translate(`common.${FLUID_CONTROL_FREQUENCY_WITHOUT_DETECTOR[fluidAmountIndex]}`));
    }
    // eslint-disable-next-line
  }, [formState.values.circuit, formState.values.leakDetectionSystem]);

  const handleAddScale = form => {
    close();
    formState.setField('scale', form);
  };

  const handleAddLeakDetector = form => {
    close();
    formState.setField('leakDetector', form);
  };

  const ScaleOption = ({
    data,
    innerProps
  }: {
    data: EquipmentView,
    innerProps
  }) => (
    <ToolOption isInsideSelect tool={data} toolType={TOOL_TYPES.SCALE} {...innerProps} />
  );

  const LeakDetectorOption = ({
    data,
    innerProps
  }: {
    data: EquipmentView,
    innerProps
  }) => (
    <ToolOption isInsideSelect tool={data} toolType={TOOL_TYPES.LEAK_DETECTOR} {...innerProps} />
  );

  const CustomFilter: (
    option: {
      data: EquipmentSummaryItem
    },
    search: string
  ) => boolean = (
    option,
    search = ''
  ) => (option.data.model && option.data.model.toLowerCase().includes(search.toLowerCase()))
    || (option.data.brand && option.data.brand.toLowerCase().includes(search.toLowerCase()))
    || (option.data.serialNumber && option.data.serialNumber.toLowerCase().includes(search.toLowerCase()))
    || (option.data.internalIdentification && option.data.internalIdentification.toLowerCase().includes(search.toLowerCase()));

  const displayNoDetectorAvailable = () => (
    <Fragment>
      <TextNoOptionsAvailable>{translate('errors.noTool')}</TextNoOptionsAvailable>
      <ButtonBigDashed
        data-cy="addTool"
        onClick={() => open({
          type: 'ADD_TOOL',
          onSubmit: handleAddLeakDetector,
          defaultValues: {
            ...new EquipmentView(),
            model: searchInput,
            type: {
              value: TOOL_TYPES.LEAK_DETECTOR,
              label: translate('common.toolType.LEAK_DETECTOR')
            }
          },
          forIntervention: true,
          openSecondaryModal: (options: ModalState) => open(options, true)
        })}
      >
        <FontAwesomeIcon icon={faPlus} />
        {translate('button.createTool')}
      </ButtonBigDashed>
    </Fragment>
  );

  const displayNoScaleAvailable = () => (
    <Fragment>
      <TextNoOptionsAvailable>
        {translate('errors.noToolInput', { searchInput })}
      </TextNoOptionsAvailable>
      <ButtonBigDashed
        data-cy="addTool"
        onClick={() => open({
          type: 'ADD_TOOL',
          onSubmit: handleAddScale,
          defaultValues: {
            ...new EquipmentView(),
            model: searchInput,
            type: {
              value: TOOL_TYPES.SCALE,
              label: translate('common.toolType.SCALE')
            }
          },
          forIntervention: true,
          openSecondaryModal: (options: ModalState) => open(options, true)
        })}
      >
        <FontAwesomeIcon icon={faPlus} />
        {translate('button.createTool')}
      </ButtonBigDashed>
    </Fragment>
  );

  function leakDetectorSelector() {
    return !isCanada && (
      <Fragment>
        <Question label={translate('interventionForm.control.questionChooseDetector')} required={isFrance}>
          <SelectCreateContainer>
            <div>
              <Select
                components={{ Option: LeakDetectorOption }}
                filterOption={CustomFilter}
                id="selectLeakDetector"
                isClearable
                isLoading={isLoading}
                isSearchable
                menuPlacement={isMobileOnly ? 'top' : 'bottom'}
                noOptionsMessage={displayNoDetectorAvailable}
                options={availableLeakDetectors}
                placeholder={translate('common.searchByModelBrand')}
                value={formState.values.leakDetector || ''}
                onChange={handleAddLeakDetector}
                onInputChange={value => setSearchInput(value)}
              />
              {
                isFrance && <RequiredSelectHack value={formState.values.leakDetector || ''} />
              }
            </div>
            <div>
              <Media query="(max-width: 768px)">
                {matches => (matches
                  ? (
                    <Fab
                      color="primary"
                      size="small"
                      onClick={() => open({
                        type: 'ADD_TOOL',
                        onSubmit: handleAddLeakDetector,
                        defaultValues: {
                          ...new EquipmentView(),
                          type: {
                            value: TOOL_TYPES.LEAK_DETECTOR,
                            label: translate('common.toolType.LEAK_DETECTOR')
                          }
                        },
                        forIntervention: true,
                        openSecondaryModal: (options: ModalState) => open(options, true)
                      })}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Fab>
                  ) : (
                    <ButtonBlueLight
                      data-cy="addLeakDetector"
                      onClick={() => open({
                        type: 'ADD_TOOL',
                        onSubmit: handleAddLeakDetector,
                        defaultValues: {
                          ...new EquipmentView(),
                          type: {
                            value: TOOL_TYPES.LEAK_DETECTOR,
                            label: translate('common.toolType.LEAK_DETECTOR')
                          }
                        },
                        forIntervention: true,
                        openSecondaryModal: (options: ModalState) => open(options, true)
                      })}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {translate('button.createLeakDetector')}
                    </ButtonBlueLight>
                  )
                )}
              </Media>
            </div>
          </SelectCreateContainer>
        </Question>
      </Fragment>
    );
  }

  function scaleSelector() {
    return isGB && (
      <Fragment>
        <Question label={translate('interventionForm.control.questionChooseScale')} required={isGB}>
          <SelectCreateContainer>
            <div>
              <Select
                components={{ Option: ScaleOption }}
                filterOption={CustomFilter}
                id="selectScale"
                isClearable
                isLoading={isLoading}
                isSearchable
                menuPlacement={isMobileOnly ? 'top' : 'bottom'}
                noOptionsMessage={displayNoScaleAvailable}
                options={availableScales}
                placeholder={translate('common.searchByModelBrand')}
                value={formState.values.scale || ''}
                onChange={handleAddScale}
                onInputChange={value => setSearchInput(value)}
              />
              {
                isGB && <RequiredSelectHack value={formState.values.scale || ''} />
              }
            </div>
            <div>
              <Media query="(max-width: 768px)">
                {matches => (matches
                  ? (
                    <Fab
                      color="primary"
                      size="small"
                      onClick={() => open({
                        type: 'ADD_TOOL',
                        onSubmit: handleAddScale,
                        defaultValues: {
                          ...new EquipmentView(),
                          type: {
                            value: TOOL_TYPES.SCALE,
                            label: translate('common.toolType.SCALE')
                          }
                        },
                        forIntervention: true,
                        openSecondaryModal: (options: ModalState) => open(options, true)
                      })}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Fab>
                  ) : (
                    <ButtonBlueLight
                      data-cy="addScale"
                      onClick={() => open({
                        type: 'ADD_TOOL',
                        onSubmit: handleAddScale,
                        defaultValues: {
                          ...new EquipmentView(),
                          type: {
                            value: TOOL_TYPES.SCALE,
                            label: translate('common.toolType.SCALE')
                          }
                        },
                        forIntervention: true,
                        openSecondaryModal: (options: ModalState) => open(options, true)
                      })}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {translate('button.createScale')}
                    </ButtonBlueLight>
                  )
                )}
              </Media>
            </div>
          </SelectCreateContainer>
        </Question>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {!formState.values.leakDetector
        ? leakDetectorSelector()
        : (
          <Question label={translate('interventionForm.control.leakDetectorSection')}>
            <ToolSelection
              deleteAction={() => formState.setField('leakDetector', null)}
              editAction={() => open({
                type: 'ADD_TOOL',
                onSubmit: handleAddLeakDetector,
                defaultValues: formState.values.leakDetector,
                forIntervention: true,
                openSecondaryModal: (options: ModalState) => open(options, true)
              })}
              tool={formState.values.leakDetector}
              toolType={TOOL_TYPES.LEAK_DETECTOR}
            />
          </Question>
        )}
      {!formState.values.scale
        ? scaleSelector()
        : (
          <Question label={translate('interventionForm.control.scaleSection')}>
            <ToolSelection
              deleteAction={() => formState.setField('scale', null)}
              editAction={() => open({
                type: 'ADD_TOOL',
                onSubmit: handleAddScale,
                defaultValues: formState.values.scale,
                forIntervention: true,
                openSecondaryModal: (options: ModalState) => open(options, true)
              })}
              tool={formState.values.scale}
              toolType={TOOL_TYPES.SCALE}
            />
          </Question>
        )}
      <Question color="var(--green)" label={translate('interventionForm.control.questionValidateData')}>
        {!isCanada && (
          <>
            <Translate component="p" text="interventionForm.control.hasLeakDetector" />
            <ToggleButtonGroup
              aria-label={translate('interventionForm.control.hasLeakDetector')}
              exclusive
              name="leakDetectionSystem"
              size="medium"
              {...text('leakDetectionSystem')}
              onChange={(event, value) => {
                if (value === null) return;
                formState.setField('leakDetectionSystem', value);
                formState.setField('equipment', {
                  ...formState.values.equipment,
                  hasLeakDetectionSystem: value
                });
              }}
            >
              <ToggleButton aria-label={translate('common.yes')} value>{translate('common.yes')}</ToggleButton>
              <ToggleButton aria-label={translate('common.no')} value={false}>{translate('common.no')}</ToggleButton>
            </ToggleButtonGroup>
          </>
        )}

        {formState.values.circuit.fluid.fluidClass && (
          <Fragment>
            <DataContainer>
              {`${translate('interventionForm.control.fluidCategory')}: `}
              <span>{formState.values.circuit.fluid.fluidClass}</span>
            </DataContainer>

            <DataContainer>
              {`${translate('interventionForm.control.fluidWeight')}: `}
              <span>
                {formState.values.circuit.fluidCapacity}
                &nbsp;{translate('common.weightUnit')} -&nbsp;
                {teqCO2}
                &nbsp;teqCO2
              </span>
            </DataContainer>

            {!isCanada && (
              <DataContainer>
                {`${translate('interventionForm.control.controlFrequency')}: `}
                <span>{formState.values.controlFrequency}</span>
              </DataContainer>
            )}
          </Fragment>
        )}
      </Question>
    </Fragment>
  );
});
