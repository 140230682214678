import {
  FormControl, FormHelperText, FormLabel
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledFormElement = styled.article`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .MuiFormControl-root {
    display: flex;
    flex: 1;
    margin-bottom: 1rem;
    z-index: initial;

    &.MuiTextField-root {
      flex: 1;
    }

    ${props => props.shrinked && css`
      legend {
        position: absolute;
        top: -5px;
        left: 10px;
        padding: 0 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        color: var(--grey) !important;
        font-weight: 400 !important;
        background: white;
        z-index: 5;
      }
    `}
  }
`;

const StyledFormLabel = styled(FormLabel)`
  max-width: 90%;
  color: var(--black) !important;
  font-weight: 600 !important;
  margin-bottom: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FormElement = ({
  label, icon, required, helperText,
  error, children, style, shrinked
}) => (
  <StyledFormElement shrinked={shrinked} style={style}>
    <FormControl component="fieldset" error={error} required={required}>
      {label && (
      <StyledFormLabel component="legend">
        {label}
        {icon}
      </StyledFormLabel>
        )}
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  </StyledFormElement>
  );

FormElement.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  required: PropTypes.bool,
  shrinked: PropTypes.bool,
  style: PropTypes.shape({})
};

FormElement.defaultProps = {
  error: null,
  helperText: '',
  icon: null,
  label: '',
  required: false,
  shrinked: false,
  style: null
};

export default FormElement;
