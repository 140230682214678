import React from 'react';
import styled from 'styled-components';

import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonContainer = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem;
  border-top: 1px solid var(--grey-lighter);
  border-bottom: 1px solid var(--grey-lighter);

  div {
    margin-right: 5%;
  }
`;

export const SkeletonTable = () => (
  <SkeletonContainer>
    <td><Skeleton height={20} variant="rect" width="25%" /></td>
    <td><Skeleton height={20} variant="rect" width="25%" /></td>
    <td><Skeleton height={20} variant="rect" width="25%" /></td>
    <td><Skeleton height={20} variant="rect" width="25%" /></td>
  </SkeletonContainer>
);
