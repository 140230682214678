import i18next from 'i18next'; // Docs https://react.i18next.com
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const i18n = i18next
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    fallbackLng: 'fr',

    keySeparator: '.', // to have multiple level of translations
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
