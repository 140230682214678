import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent
} from '@material-ui/core';
import { Question, RequiredSelectHack } from 'components';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import Select from 'react-select';
import { useFormState } from 'react-use-form-state';
import { translate } from 'utils/helpers';
import { StructureLevelOption } from 'utils/types/StructureLevelOption';
import {
  StructureLevelSelectOption,
  StructureLevelSelectValue
} from '../Form/ItemOption/StructureLevelSelectOption';
import { ModalHeader } from './ModalHeader';

export const UpdateUserLevelModal = ({
  onClose, onConfirm, defaultLevel, levelOptions
}: {
  defaultLevel: StructureLevelOption,
  levelOptions: StructureLevelOption[],
  onClose: () => void,
  onConfirm: (selectedLevel: StructureLevelOption) => void
}) => {
  const [formState] = useFormState({
    level: levelOptions.find(insti => insti.value === defaultLevel.value)
  });

  const handleCloseModal = () => {
    localStorage.setItem('isLevelSet', 'true');
    onClose();
  };

  const handleValidateModal = (e) => {
    e.preventDefault();
    onConfirm(formState.values.level);
    handleCloseModal();
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding} />
        {translate('modalInstitutionUpdate.title')}
      </ModalHeader>

      <DialogContent>
        <Question label={translate('modalInstitutionUpdate.selectInstitution')}>
          <Select
            components={{ Option: StructureLevelSelectOption, SingleValue: StructureLevelSelectValue }}
            id="selectLevel"
            isClearable
            isSearchable
            menuPlacement={isMobileOnly ? 'top' : 'bottom'}
            menuPortalTarget={document.body}
            noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
            options={levelOptions}
            placeholder={translate('common.searchByInstitution')}
            styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }), container: () => ({ minWidth: isMobileOnly ? 200 : 500 }) }}
            value={formState.values.level || ''}
            onChange={value => formState.setField('level', value)}
          />
          <RequiredSelectHack value={formState.values.level || ''} />
        </Question>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseModal}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};
