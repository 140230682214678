import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledWarn = styled.div`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: var(--orange);
  max-width: ${props => props.maxWidth};
`;

export const Warn = ({ children = null, ...props }) => (
  <StyledWarn {...props}>{children}</StyledWarn>
);

Warn.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  maxWidth: PropTypes.string
};

Warn.defaultProps = {
  children: null,
  fontSize: '1.5rem',
  fontWeight: 'normal',
  maxWidth: 'initial'
};
