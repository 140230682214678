import { Grid, TextField } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Datepicker, Question } from 'components';
import { useStores } from 'hooks';
import { FluidMovementFilter } from 'models/request/list/FluidMovementFilter';
import React from 'react';
import Select from 'react-select';
import shortid from 'shortid';
import { BOTTLE_TYPES_FILTERS, DISTRIBUTOR_ROLES } from 'utils/constants';
import { FLUID_MOVEMENT_LIST } from 'utils/fluid_movement_constants';
import {
  FilterHelper, formatDate, translate, UserHelper
} from 'utils/helpers';

export const FluidMovementsListFilters = ({
  currentFilters, setCurrentFilters
}) => {
  const { fluidStore } = useStores();
  const isDistributor = UserHelper.hasAccessRight(DISTRIBUTOR_ROLES);

  const handleChangeInput = (
    key: keyof FluidMovementFilter,
    value: any,
    label: string
  ) => {
    setCurrentFilters(FilterHelper.setFiltersValue(currentFilters, key, value, label));
  };

  return (
    <section>
      <Question label={translate('modalFluidMovement.bottleIdentifier')}>
        <TextField
          label={translate('common.searchByIdentifier')}
          name="bottleIdentifier"
          value={FilterHelper.getFiltersValue(currentFilters, 'bottleIdentifier') || ''}
          onChange={event => handleChangeInput('bottleIdentifier', event.target.value, event.target.value)}
        />
      </Question>

      <Question label={translate('common.bsffIdentifier')}>
        <TextField
          label={translate('common.searchByIdentifier')}
          name="bsffIdentifier"
          value={FilterHelper.getFiltersValue(currentFilters, 'bsffIdentifier') || ''}
          onChange={event => handleChangeInput('bsffIdentifier', event.target.value, event.target.value)}
        />
      </Question>

      <Question label={translate('common.fluidType')}>
        <Select
          id="fluidType"
          isClearable
          isSearchable
          menuPortalTarget={document.body}
          noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
          options={fluidStore.fluidOptions}
          placeholder={translate('common.selectType')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
          value={FilterHelper.getFiltersValue(currentFilters, 'fluidType')}
          onChange={(value) => handleChangeInput('fluidType', value, value?.label)}
        />
      </Question>

      <Question label={translate('common.movementType')}>
        <Select
          id="movementType"
          isClearable
          isSearchable
          menuPortalTarget={document.body}
          noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
          options={FLUID_MOVEMENT_LIST
            .filter(movement => movement.availableToOperator || isDistributor)
            .filter(movement => ['INPUT', 'OUTPUT'].includes(movement.group))
            .map(mov => ({
              ...mov,
              value: mov.key,
              label: translate((isDistributor && mov.labelShortDistributor) ? mov.labelShortDistributor : mov.labelShort)
            }))}
          placeholder={translate('common.selectType')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
          value={FilterHelper.getFiltersValue(currentFilters, 'movementType')}
          onChange={(value) => handleChangeInput('movementType', value, value?.label)}
        />
      </Question>

      {FilterHelper.getFiltersValue(currentFilters, 'movementType')?.value === 'OUTPUT' && (
        <Question label={translate('common.bottleRetrievalConfirmed')}>
          <ToggleButtonGroup
            aria-label={translate('common.bottleRetrievalConfirmed')}
            exclusive
            size="medium"
            style={{ marginBottom: '2rem' }}
            value={FilterHelper.getFiltersValue(currentFilters, 'retrievalConfirmed')}
            onChange={(_, value) => {
              const label = (value !== null && value && translate('common.yes')) || translate('common.no');
              handleChangeInput('retrievalConfirmed', value, label);
            }}
          >
            <ToggleButton
              key={shortid.generate()}
              value={true}
            >
              {translate('common.yes')}
            </ToggleButton>
            <ToggleButton
              key={shortid.generate()}
              value={false}
            >
              {translate('common.no')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Question>
      )}

      <Question label={translate('common.bottleType')}>
        <Select
          id="addBottle"
          isClearable
          isSearchable
          menuPortalTarget={document.body}
          noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
          options={BOTTLE_TYPES_FILTERS.map(type => ({
            value: type.key,
            label: translate(`bottles.${type.key}`)
          }))}
          placeholder={translate('common.selectType')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 2000 }) }}
          value={FilterHelper.getFiltersValue(currentFilters, 'bottleType')}
          onChange={(value) => handleChangeInput('bottleType', value, value?.label)}
        />
      </Question>

      <Question label={translate('common.date')}>
        <Grid container direction="column">
          <Datepicker
            label="common.startDate"
            maxDate={FilterHelper.getFiltersValue(currentFilters, 'endDate') || formatDate(new Date())}
            value={FilterHelper.getFiltersValue(currentFilters, 'startDate')}
            onChange={date => handleChangeInput('startDate', date, date)}
          />
          <Datepicker
            label="common.endDate"
            maxDate={formatDate(new Date())}
            minDate={FilterHelper.getFiltersValue(currentFilters, 'startDate')}
            value={FilterHelper.getFiltersValue(currentFilters, 'endDate')}
            onChange={date => handleChangeInput('endDate', date, date)}
          />
        </Grid>
      </Question>
    </section>
  );
};
