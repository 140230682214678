import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import { translate } from 'utils/helpers';
import sanitizeHtml from 'sanitize-html';

export const WarningModal = ({
  onClose, onConfirm, onCancel, onAction, text, title, buttonCancel, buttonConfirm, buttonAction, confirmOnly
}) => (
  <Fragment>
    <DialogTitle className={confirmOnly ? '' : 'warningModal'}>
      {!confirmOnly && <FontAwesomeIcon icon={faExclamationTriangle} />}
      {title || translate('warnings.warning')}
    </DialogTitle>

    <DialogContent dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} style={{ maxWidth: '500px' }} />

    <DialogActions>
      {buttonCancel && (
        <Button onClick={() => { onCancel(); onClose(); }}>{buttonCancel}</Button>
      )}
      {buttonAction && (
        <Button onClick={() => { onClose(); onAction(); }}>{buttonAction}</Button>
      )}
      <Button color="primary" id="warningModalConfirm" onClick={onConfirm ? () => { onConfirm(); onClose(); } : () => onClose()}>
        {buttonConfirm || translate('confirms.confirm')}
      </Button>
    </DialogActions>
  </Fragment>
);

WarningModal.propTypes = {
  buttonAction: PropTypes.string,
  buttonCancel: PropTypes.string,
  buttonConfirm: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  title: PropTypes.string
};

WarningModal.defaultProps = {
  buttonAction: '',
  buttonCancel: '',
  buttonConfirm: '',
  onAction: null,
  onCancel: () => { },
  onConfirm: null,
  text: '',
  title: ''
};
