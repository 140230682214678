import { DATETIME_FORMAT, formatDateString } from 'utils/helpers';

export class InterventionAppointment {
  date: string = '';
  time: string = '';
}

export const getDateFromAppointment: (
  appointment: InterventionAppointment
) => string = (
  appointment
) => {
  if (!appointment || !appointment.date) {
    return '';
  }
  return formatDateString(`${appointment.date} ${appointment.time}`, DATETIME_FORMAT, DATETIME_FORMAT);
};
