import { printAddress } from './CountryFormView';
import { CountryView } from './CountryView';
import { EntityFieldView } from './EntityFieldView';
import { EquipmentFieldView } from './EquipmentFieldView';
import { getAddressFromForm } from './FormFrontView';
import { FormView } from './FormView';
import { InterventionAppointment } from './InterventionAppointment';
import { InterventionStatusView } from './InterventionStatusView';
import { OperatorSummary } from './OperatorSummary';

export class InterventionView {
  hashId?: string = '';
  identifier?: string = '';
  fileNumber?: string = '';
  comment?: string = '';
  status?: string;
  country?: CountryView;
  operator?: OperatorSummary = new OperatorSummary();
  appointment?: InterventionAppointment = new InterventionAppointment();
  customerField?: EntityFieldView;
  equipmentField?: EquipmentFieldView;
  form: FormView = new FormView();
  canEdit?: boolean = true;
  canPlan?: boolean = false;
  canReassign?: boolean = false;

  // Front fields
  observations?: string = '';
  currentStep?: number = 0;
  isWaitingToSubmit?: boolean = false;
  isOutOfSync?: boolean = false;
  outOfSyncStatus?: InterventionStatusView;
}

export const getAddressFromIntervention: (
  intervention: InterventionView,
  withCountry?: boolean
) => string = (
  intervention,
  withCountry
) => {
  let address = '';
  if (!intervention) {
    return address;
  }
  // Try equipment adress first
  const equipment = intervention.equipmentField?.equipment;
  if (equipment) {
    address = printAddress(equipment, withCountry);
  }
  // Default to owner adress if equipment adress is not defined
  const equipmentOwner = intervention.customerField?.entity;
  if (!address && equipmentOwner) {
    address = printAddress(equipmentOwner, withCountry);
  }
  // Default to the form data
  if (!address) {
    address = getAddressFromForm(intervention.form, withCountry);
  }
  return address;
};
