import { StateValues } from 'react-use-form-state';
import { COUNTRY_CODE } from 'utils/constants';
import { formatDate } from 'utils/helpers';
import { printAddress } from './CountryFormView';
import { CountryView } from './CountryView';
import { EntityView } from './EntityView';
import { EquipmentView } from './EquipmentView';
import { FluidManipulationEntryView } from './FluidManipulationEntryView';
import { InterventionAppointment } from './InterventionAppointment';
import { InterventionStatusView } from './InterventionStatusView';
import { LeakEntryView } from './LeakEntryView';
import { CircuitView, RefrigerationUnitView } from './RefrigerationUnitView';
import { SignatureFieldView } from './SignatureFieldView';

export class FormFrontView {
  hashId?: string = '';
  operator?: EntityView;
  equipmentOwner?: EntityView;
  attestationNumber?: string = '';
  equipment?: RefrigerationUnitView;
  circuit?: CircuitView;
  assembly?: boolean = false;
  commissioning?: boolean = false;
  modification?: boolean = false;
  maintenance?: boolean = false;
  periodicControl?: boolean = false;
  nonPeriodicControl?: boolean = false;
  dismantling?: boolean = false;
  other?: string = '';
  observations?:string = '';
  leakDetector?: EquipmentView;
  scale?: EquipmentView;
  leakDetectionSystem?: boolean = false;
  fluidCategory?: string = '';
  fluidAmount?: string = '';
  controlFrequency?: string = '';
  leakFound?: boolean = false;
  leaks?: LeakEntryView[] = [];
  inputFluidTotal?: number = 0;
  outputFluidTotal?: number = 0;
  fluidManipulationsInput?: FluidManipulationEntryView[] = [];
  fluidManipulationsOutput?: FluidManipulationEntryView[] = [];
  operatorSignatureField?: SignatureFieldView;
  customerSignatureField?: SignatureFieldView;
  interventionDate?: string;
  country?: CountryView;

  // Front fields
  // eslint-disable-next-line camelcase
  ui_other?: boolean = false;
  currentStep?: number = 0;
  didFluidManipulationInput?: boolean = false;
  didFluidManipulationOutput?: boolean = false;

  appointment?: InterventionAppointment;
  fileNumber?: string = '';
  status?: string = '';

  isOutOfSync?: boolean;
  outOfSyncStatus?: InterventionStatusView;
}

export const getAddressFromForm: (
  form: FormFrontView,
  withCountry?: boolean
) => string = (
  form,
  withCountry = false
) => {
  let address = '';
  if (!form) {
    return address;
  }
  const { equipment, equipmentOwner } = form;
  // Try equipment adress first
  if (equipment) {
    address = printAddress(equipment, withCountry);
  }
  // Default to owner adress if equipment adress is not defined
  if (!address && equipmentOwner) {
    address = printAddress(equipmentOwner, withCountry);
  }
  return address;
};

export const getDateFromForm: (
  form: FormFrontView
) => string = (
  form
) => {
  let date = null;
  if (!form) {
    return date;
  }
  // Try to use the date specified by the user during the signature
  if (form.operatorSignatureField?.interventionDate) {
    date = form.operatorSignatureField?.interventionDate;
  }
  // Default to the current intervention date if it exists
  if (!date && form.interventionDate) {
    date = form.interventionDate;
  }
  // Default to the current date
  if (!date) {
    date = formatDate(new Date());
  }
  return date;
};

export const getCountryCodeFromForm: (
  form: StateValues<FormFrontView>,
  defaultCode?: string
) => string = (
  form,
  defaultCode
) => {
  if (form) {
    const { equipment, equipmentOwner, country } = form;
    if (country?.key) {
      return country.key;
    }
    if (typeof equipment?.address !== 'string' && equipment?.address?.country?.countryCode) {
      return equipment.address.country.countryCode;
    }
    if (typeof equipmentOwner?.address !== 'string' && equipmentOwner?.address?.country?.countryCode) {
      return equipmentOwner.address.country.countryCode;
    }
  }
  if (defaultCode) {
    return defaultCode;
  }
  return COUNTRY_CODE.FR;
};
