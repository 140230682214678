import React, { Fragment, useEffect } from 'react';

import styled from 'styled-components';

import shortid from 'shortid';

import { faPlus, faTint } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Paper } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { BottleHelper, pluralize, translate } from 'utils/helpers';

import {
  ButtonBlueLight, FlexContainer, FontIcon, ItemSelected, Text
} from 'components';

import { useModal } from 'hooks';
import { ModalState } from 'hooks/ModalProvider';
import { observer } from 'mobx-react-lite';
import { FluidManipulationEntryView } from 'models';
import { ManipulationCategory } from 'models/detail/FluidManipulationEntryView';
import { CircuitView } from 'models/detail/RefrigerationUnitView';
import {
  Args, FormState, InputInitializer, StateErrors
} from 'react-use-form-state';
import { SelectItem } from 'utils/types';
import { Question } from '../Question/Question';

const FluidsContainer = styled.section`
  padding-top: 1.6rem;

  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  &:first-of-type {
    margin-top: 3rem;
  }
`;

const FluidSectionHeader = styled.p`
  margin: 0 1rem 1rem 1rem;
  color: var(--turquoise);
  font-size: 1.8rem;
  font-weight: 600;
`;

const ManipItemContainer = styled.div`
  margin: 0 1rem 1rem 1rem;
  background: var(--white);
`;

const GreenBackground = styled.div`
  margin-top: 2rem;
  padding: 1rem 2rem;
  text-align: right;
  background-color: #E4F6F4;
  border-bottom: 4px solid var(--turquoise);
`;

const TotalFluid = styled.span`
  color: var(--turquoise);
`;

const ManipItem = ({
  countryCode,
  expectedFluid,
  formState,
  handleForm,
  manip,
  manipulationType,
  openModal,
  removeFluidManipulation
}: {
  countryCode: string,
  expectedFluid: SelectItem,
  formState: FormState<FormStateFluidsView, StateErrors<FormStateFluidsView, string>>,
  handleForm: (form: FluidManipulationEntryView) => void,
  manip: FluidManipulationEntryView,
  manipulationType: ManipulationCategory,
  openModal: (state: ModalState) => void,
  removeFluidManipulation: (id: string, manipulationType: ManipulationCategory) => void
}) => (
  <ItemSelected
    deleteAction={() => removeFluidManipulation(manip.fluidBottle.id, manipulationType)}
    editAction={() => openModal({
      type: 'ADD_FLUID_MANIPULATION',
      onSubmit: handleForm,
      currentEntry: manip,
      expectedAmount: formState.values.circuit && formState.values.circuit.fluidCapacity,
      expectedFluid,
      fluidManipulationsOutput: formState.values.fluidManipulationsOutput,
      fluidManipulationsInput: formState.values.fluidManipulationsInput,
      manipulationType,
      countryCode
    })}
  >
    <FlexContainer alignItems="flex-start" flex="1" flexDirection="column">
      <Text fontSize="1.8rem" margin="0 0 0.5rem 0">
        {translate(`manipulations.${manip.manipulationType.value}`)}
      </Text>
      <FlexContainer justifyContent="flex-end">
        <Text color="var(--grey)" display="inline-block" margin="0">
          <FontIcon icon="icon-container" marginLeft="-0.5rem" marginRight="0.7rem" />
        </Text>
        <Text color="var(--grey)" display="inline-block" margin="0">
          {(manip.fluidBottle.bottleType && manip.fluidBottle.bottleType.label) ? (
            BottleHelper.getTranslatedType({
              type: manip.fluidBottle.bottleType.key,
              isNewFluid: manip.fluidBottle.isNewFluid,
              identifier: manip.fluidBottle.identifier
            })
          ) : (
            `${translate('bottles.CREATED_BOTTLE')} ${manip.fluidBottle.identifier ? `(${manip.fluidBottle.identifier})` : ''}`
          )}
        </Text>
      </FlexContainer>
      <Text color="var(--grey)" margin="0">
        <FontAwesomeIcon icon={faTint} style={{ marginRight: '1rem' }} />
        {`${manip.fluid.label} / ${manip.fluidAmount}${translate('common.weightUnit')}`}
      </Text>
    </FlexContainer>
  </ItemSelected>
);

interface FormStateFluidsView {
  assembly?: boolean;
  circuit?: CircuitView;
  commissioning?: boolean;
  didFluidManipulationInput?: boolean;
  didFluidManipulationOutput?: boolean;
  dismantling?: boolean;
  fluidManipulationsInput?: FluidManipulationEntryView[];
  fluidManipulationsOutput?: FluidManipulationEntryView[];
  inputFluidTotal?: number;
  maintenance?: boolean;
  modification?: boolean;
  nonPeriodicControl?: boolean;
  outputFluidTotal?: number;
  periodicControl?: boolean;
  // eslint-disable-next-line camelcase
  ui_other?: boolean;
}

export const Fluids = observer(({
  countryCode,
  formState,
  text
}: {
  countryCode: string,
  formState: FormState<FormStateFluidsView, StateErrors<FormStateFluidsView, string>>,
  text: InputInitializer<FormStateFluidsView, Args<keyof FormStateFluidsView, void>, any>
}) => {
  const { open, close } = useModal();
  const manipulationTypeData: {
    [key in ManipulationCategory] : {
      button: keyof FormStateFluidsView,
      list: FluidManipulationEntryView[],
      listId: keyof FormStateFluidsView
    }
  } = {
    input: {
      list: formState.values.fluidManipulationsInput,
      button: 'didFluidManipulationInput',
      listId: 'fluidManipulationsInput'
    },
    output: {
      list: formState.values.fluidManipulationsOutput,
      button: 'didFluidManipulationOutput',
      listId: 'fluidManipulationsOutput'
    }
  };

  const canDoInput = [
    formState.values.assembly,
    formState.values.commissioning,
    formState.values.modification,
    formState.values.maintenance,
    formState.values.periodicControl,
    formState.values.ui_other
  ].some(type => type);

  const canDoOutput = [
    formState.values.commissioning,
    formState.values.modification,
    formState.values.maintenance,
    formState.values.periodicControl,
    formState.values.dismantling,
    formState.values.ui_other
  ].some(type => type);

  const handleForm = (form: FluidManipulationEntryView) => {
    const currentFluidManipulations: FluidManipulationEntryView[] = manipulationTypeData[form.type].list.map((manip: FluidManipulationEntryView) => manip);
    const existingManipIndex = currentFluidManipulations.findIndex(manip => manip.fluidBottle.id === form.fluidBottle.id);

    if (existingManipIndex > -1) {
      currentFluidManipulations[existingManipIndex] = form;
    } else {
      currentFluidManipulations.push({
        ...form
      });
    }

    formState.setField(manipulationTypeData[form.type].listId, currentFluidManipulations);
    close();
  };

  const removeFluidManipulation = (id: string, manipulationType: ManipulationCategory) => {
    const currentFluidManipulations = manipulationTypeData[manipulationType].list.filter(manip => manip.fluidBottle.id !== id);
    formState.setField(manipulationTypeData[manipulationType].listId, currentFluidManipulations);

    (currentFluidManipulations.length === 0) && formState.setField(manipulationTypeData[manipulationType].button, false);
  };

  const resetFluidManipulations = ({ manipulationType }) => {
    const messageSingle = translate('interventionForm.fluids.resetManipSingle');
    const messageMulti = translate('interventionForm.fluids.resetManipMulti');

    const fluidManipulationList = manipulationTypeData[manipulationType].list;

    open({
      type: 'WARNING',
      text: fluidManipulationList.length > 1 ? messageMulti : messageSingle,
      buttonConfirm: translate('button.accept'),
      buttonCancel: translate('button.cancel'),
      onConfirm: () => formState.setField(manipulationTypeData[manipulationType].listId, []),
      onClose: fluidManipulationList.length > 0 ? (() => formState.setField(manipulationTypeData[manipulationType].button, true)) : () => null
    });
  };

  const inputFluidTotal = Number(formState.values.fluidManipulationsInput.reduce((acc, manip) => acc + Number(manip.fluidAmount), 0)).toFixed(2);
  const outputFluidTotal = Number(formState.values.fluidManipulationsOutput.reduce((acc, manip) => acc + Number(manip.fluidAmount), 0)).toFixed(2);

  const defaultFluidSelected = formState.values.circuit ? {
    label: formState.values.circuit.fluid && formState.values.circuit.fluid.name,
    value: formState.values.circuit.fluid && formState.values.circuit.fluid.id
  } : null;

  useEffect(() => {
    formState.setField('outputFluidTotal', Number(outputFluidTotal));
    formState.setField('inputFluidTotal', Number(inputFluidTotal));
    // eslint-disable-next-line
  }, [outputFluidTotal, inputFluidTotal]);

  return (
    <Fragment>
      {/*
        FLUIDS OUTPUT QUESTION
      */}
      {canDoOutput && (
        <Question label={translate('interventionForm.fluids.questionOutput')}>
          <FlexContainer alignItems="center" flexWrap="wrap" justifyContent="space-between">
            <ToggleButtonGroup
              aria-label={translate('interventionForm.fluids.questionOutput')}
              exclusive
              name="didFluidManipulationOutput"
              size="medium"
              {...text('didFluidManipulationOutput')}
              style={{ marginBottom: '1rem' }}
              onChange={(event, value) => {
                if (value === null) return;
                formState.setField('didFluidManipulationOutput', value);
                value === false && formState.values.fluidManipulationsOutput.length > 0 && resetFluidManipulations({ manipulationType: 'output' });
              }}
            >
              <ToggleButton
                aria-label={translate('common.yes')}
                data-cy="didFluidManipulationOutput"
                value
                onClick={() => open({
                  type: 'ADD_FLUID_MANIPULATION',
                  onSubmit: handleForm,
                  onClose: () => formState.values.fluidManipulationsOutput.length === 0 && formState.setField('didFluidManipulationOutput', false),
                  expectedAmount: formState.values.circuit && formState.values.circuit.fluidCapacity,
                  expectedFluid: defaultFluidSelected,
                  fluidManipulationsOutput: formState.values.fluidManipulationsOutput,
                  manipulationType: 'output',
                  countryCode
                })}
              >
                {translate('common.yes')}
              </ToggleButton>
              <ToggleButton
                aria-label={translate('common.no')}
                value={false}
              >
                {translate('common.no')}
              </ToggleButton>
            </ToggleButtonGroup>

            {formState.values.didFluidManipulationOutput && (
              <ButtonBlueLight onClick={() => open({
                type: 'ADD_FLUID_MANIPULATION',
                onSubmit: handleForm,
                onClose: () => formState.values.fluidManipulationsOutput.length === 0 && formState.setField('didFluidManipulationOutput', false),
                expectedAmount: formState.values.circuit && formState.values.circuit.fluidCapacity,
                expectedFluid: defaultFluidSelected,
                fluidManipulationsOutput: formState.values.fluidManipulationsOutput,
                manipulationType: 'output',
                countryCode
              })}
              >
                <FontAwesomeIcon icon={faPlus} />
                {translate('interventionForm.fluids.addOutput')}
              </ButtonBlueLight>
            )}
          </FlexContainer>
        </Question>
      )}

      {/*
        FLUIDS INPUT QUESTION
      */}
      {canDoInput && (
        <Question label={translate('interventionForm.fluids.questionInput')}>
          <FlexContainer alignItems="center" flexWrap="wrap" justifyContent="space-between">
            <ToggleButtonGroup
              aria-label={translate('interventionForm.fluids.questionInput')}
              exclusive
              name="didFluidManipulationInput"
              size="medium"
              {...text('didFluidManipulationInput')}
              style={{ marginBottom: '1rem' }}
              onChange={(event, value) => {
                if (value === null) return;
                formState.setField('didFluidManipulationInput', value);
                value === false && formState.values.fluidManipulationsInput.length > 0 && resetFluidManipulations({ manipulationType: 'input' });
              }}
            >
              <ToggleButton
                aria-label={translate('common.yes')}
                data-cy="didFluidManipulationInput"
                value
                onClick={() => open({
                  type: 'ADD_FLUID_MANIPULATION',
                  onSubmit: handleForm,
                  onClose: () => formState.values.fluidManipulationsInput.length === 0 && formState.setField('didFluidManipulationInput', false),
                  expectedAmount: formState.values.circuit && formState.values.circuit.fluidCapacity,
                  expectedFluid: defaultFluidSelected,
                  fluidManipulationsOutput: formState.values.fluidManipulationsOutput,
                  fluidManipulationsInput: formState.values.fluidManipulationsInput,
                  manipulationType: 'input',
                  countryCode
                })}
              >
                {translate('common.yes')}
              </ToggleButton>
              <ToggleButton
                aria-label={translate('common.no')}
                value={false}
              >
                {translate('common.no')}
              </ToggleButton>
            </ToggleButtonGroup>

            {formState.values.didFluidManipulationInput && (
              <ButtonBlueLight onClick={() => open({
                type: 'ADD_FLUID_MANIPULATION',
                onSubmit: handleForm,
                onClose: () => formState.values.fluidManipulationsInput.length === 0 && formState.setField('didFluidManipulationInput', false),
                expectedAmount: formState.values.circuit && formState.values.circuit.fluidCapacity,
                expectedFluid: defaultFluidSelected,
                fluidManipulationsOutput: formState.values.fluidManipulationsOutput,
                fluidManipulationsInput: formState.values.fluidManipulationsInput,
                manipulationType: 'input',
                countryCode
              })}
              >
                <FontAwesomeIcon icon={faPlus} />
                {translate('interventionForm.fluids.addInput')}
              </ButtonBlueLight>
            )}
          </FlexContainer>
        </Question>
      )}

      {/*
        FLUIDS OUTPUT LIST
      */}
      <Fragment>
        {formState.values.fluidManipulationsOutput && formState.values.fluidManipulationsOutput.length > 0 && (
          <FluidsContainer>
            <FluidSectionHeader>
              {translate('interventionForm.fluids.allOutputDone', {
                total: formState.values.fluidManipulationsOutput.length,
                output: pluralize('récupération', formState.values.fluidManipulationsOutput.length)
              })}
            </FluidSectionHeader>
            {formState.values.fluidManipulationsOutput
              .map((manip, index) => (
                <ManipItemContainer data-cy={`outputAdded${index}`} key={shortid.generate()}>
                  <Paper>
                    <ManipItem
                      countryCode={countryCode}
                      expectedFluid={defaultFluidSelected}
                      formState={formState}
                      handleForm={handleForm}
                      manip={manip}
                      manipulationType="output"
                      openModal={open}
                      removeFluidManipulation={removeFluidManipulation}
                    />
                  </Paper>
                </ManipItemContainer>
              ))}

            <GreenBackground>
              <Text fontWeight={600} margin="0.5rem" textTransform="uppercase">
                {`${translate('interventionForm.fluids.outputTotal')} : `}
                <TotalFluid data-cy="fluidsTotalOutput">
                  {`${outputFluidTotal || formState.values.outputFluidTotal}${translate('common.weightUnit')}`}
                </TotalFluid>
              </Text>
            </GreenBackground>
          </FluidsContainer>
        )}
      </Fragment>

      {/*
        FLUIDS INPUT LIST
      */}
      <Fragment>
        {formState.values.fluidManipulationsInput && formState.values.fluidManipulationsInput.length > 0 && (
          <FluidsContainer>
            <FluidSectionHeader>
              {translate('interventionForm.fluids.allInputDone', {
                total: formState.values.fluidManipulationsInput.length,
                input: pluralize('recharge', formState.values.fluidManipulationsInput.length)
              })}
            </FluidSectionHeader>
            {formState.values.fluidManipulationsInput
              .map((manip, index) => (
                <ManipItemContainer data-cy={`inputAdded${index}`} key={shortid.generate()}>
                  <Paper>
                    <ManipItem
                      countryCode={countryCode}
                      expectedFluid={defaultFluidSelected}
                      formState={formState}
                      handleForm={handleForm}
                      manip={manip}
                      manipulationType="input"
                      openModal={open}
                      removeFluidManipulation={removeFluidManipulation}
                    />
                  </Paper>
                </ManipItemContainer>
              ))}

            <GreenBackground>
              <Text fontWeight={600} margin="0.5rem" textTransform="uppercase">
                {`${translate('interventionForm.fluids.inputTotal')} : `}
                <TotalFluid data-cy="fluidsTotalInput">
                  {`${inputFluidTotal || formState.values.inputFluidTotal}${translate('common.weightUnit')}`}
                </TotalFluid>
              </Text>
            </GreenBackground>
          </FluidsContainer>
        )}
      </Fragment>
    </Fragment>
  );
});
