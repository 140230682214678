import React from 'react';
import styled from 'styled-components';

import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem;
  border-top: 1px solid var(--grey-lighter);
  border-bottom: 1px solid var(--grey-lighter);

  div {
    margin-right: 5%;
  }
`;

export const SkeletonTableSmall = () => (
  <>
    <SkeletonContainer>
      <Skeleton height={20} variant="rect" width="25%" />
      <Skeleton height={20} variant="rect" width="25%" />
      <Skeleton height={20} variant="rect" width="25%" />
      <Skeleton height={20} variant="rect" width="25%" />
    </SkeletonContainer>
    <SkeletonContainer>
      <Skeleton height={20} variant="rect" width="25%" />
      <Skeleton height={20} variant="rect" width="25%" />
      <Skeleton height={20} variant="rect" width="25%" />
      <Skeleton height={20} variant="rect" width="25%" />
    </SkeletonContainer>
  </>
);
