import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  display: ${props => props.display};
  margin: ${props => props.margin};
  padding: ${props => props.padding};

  ${props => props.width && css`width: ${props.width};`}

  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  text-transform: ${props => props.textTransform};
  font-weight: 600;
  ${props => props.fontFamily && css`font-family: ${props.fontFamily};`}
  ${props => props.letterSpacing && css`letter-spacing: ${props.letterSpacing};`}

  border-color: ${props => props.borderColor};
  border-width: ${props => props.borderWidth};
  border-style: ${props => props.borderStyle};

  background: ${props => props.backgroundColor};

  border-radius: ${props => props.borderRadius};

  ${props => props.boxShadow && css`box-shadow: ${props.boxShadow};`}

  transition: all 0.3s;
  cursor: ${props => props.cursor};
  outline: none;

  &:hover {
    background-color: ${props => props.backgroundColorHover};
    ${props => props.boxShadowHover && css`box-shadow: ${props.boxShadowHover};`}
    ${props => props.underlineHover && css`text-decoration: underline;`}
  }

  &:disabled {
    color: var(--grey);
    border-color: var(--grey-light);
    background: var(--grey-light);
    background-color: var(--grey-light);
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }

  &:active {
    ${props => props.colorActive && css`color: ${props.colorActive};`}
    ${props => props.backgroundColorActive && css`background-color: ${props.backgroundColorActive};`}
  }

  svg,
  span {
    margin-right: 1rem;
  }
`;

export const Button = ({ children, ...props }) => (
  <StyledButton type="button" {...props}>{children}</StyledButton>
);

Button.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundColorHover: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  borderStyle: PropTypes.string,
  borderWidth: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  color: PropTypes.string,
  cursor: PropTypes.string,
  display: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  textAlign: PropTypes.string,
  textTransform: PropTypes.string
};

Button.defaultProps = {
  backgroundColor: 'var(--blue)',
  backgroundColorHover: 'initial',
  borderColor: 'var(--blue)',
  borderRadius: 'var(--borderRadius)',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: 'var(--white)',
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '1.6rem',
  margin: '0',
  onClick: () => { },
  padding: '1rem 3rem',
  textAlign: 'center',
  textTransform: 'uppercase'
};
