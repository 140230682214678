import { FormState, InputInitializer } from 'react-use-form-state';

/** This controller allows the edition of multiple
 * fields at the same time. This can be used when
 * a form manages different types of views.
 */
export const multipleField = (
  input: InputInitializer<any, any, any>,
  formState: FormState<any, any>,
  fields: string[]
) => ({
  ...input(fields[0]),
  onChange: (e) => {
    e.preventDefault();
    fields.forEach(field => {
      formState.setField(field, e.target.value);
    });
  }
});
