import { FormState, InputInitializer } from 'react-use-form-state';

/** This controller creates a 'fake' root field with the same
 * name as the nested field in order to correctly display the
 * values in the form and control them.
 * WARNINGS:
 * - If a root field with the same name already exists, this will
 * break the formState management!
 * - If the nested field is also managed elsewhere, the 'fake'
 * root field should also be managed there.
 * - This controller only supports a depth of 1 for the nesting.
 */
export const nestedField = (
  input: InputInitializer<any, any, any>,
  formState: FormState<any, any>,
  fieldPath: string
) => {
  const fields = fieldPath.split('.');
  if (fields.length === 1) {
    return { ...input(fieldPath) };
  }
  if (fields.length > 2) {
    throw new Error('Path is too deep!');
  }
  return {
    ...input(fields[1]),
    onChange: (e) => {
      e.preventDefault();
      formState.setField(fields[0], {
        ...formState.values[fields[0]],
        [fields[1]]: e.target.value
      });
      formState.setField(fields[1], e.target.value);
    }
  };
};
