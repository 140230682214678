import { faBuilding, faUser, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent, Grid, TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  FlexContainer, FormAddress, FormCountryIdentifier, FormElement, Question, SkeletonForm
} from 'components';
import { ModalProps } from 'hooks/ModalProvider';
import { EntityView } from 'models';
import { IdentifierView } from 'models/detail/IdentifierView';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { LegalEntityService } from 'services';
import { LEGAL_ENTITY_TYPES, STOCK_ROLES_WITH_INTERVENTION_MANAGER } from 'utils/constants';
import { FormHelper, translate, UserHelper } from 'utils/helpers';
import { ModalHeader } from './ModalHeader';
import { CustomActionButtons } from './_CustomActionButtons';

export const AddEquipmentOwnerModal = ({
  onSubmit,
  onClose,
  defaultValues = new EntityView(),
  equipmentOwnerId
}: ModalProps<EntityView> & {
  defaultValues: EntityView,
  equipmentOwnerId?: string
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [identifierFields, setIdentifierFields] = useState<IdentifierView[]>([]);
  const [formState, { text, email }] = useFormState<EntityView>({ ...defaultValues });

  const canValidate = equipmentOwnerId && !formState.values.validated && UserHelper.hasAccessRight(STOCK_ROLES_WITH_INTERVENTION_MANAGER);

  useEffect(() => {
    if (!equipmentOwnerId) {
      return;
    }
    setIsLoading(true);

    LegalEntityService.getLegalEntity(equipmentOwnerId, [LEGAL_ENTITY_TYPES.EQUIPMENT_OWNER])
      .then((response) => {
        Object.entries(response).forEach(([key, value]) => formState.setField(key as any, value));
        if (response.address && typeof response.address !== 'string') {
          formState.setField('address1', response.address?.address1);
          formState.setField('address2', response.address?.address2);
          formState.setField('city', response.address?.city);
          formState.setField('country', response.address?.country);
          formState.setField('postalCode', response.address?.postalCode);
        }
      })
      .finally(() => { setIsLoading(false); });
    // eslint-disable-next-line
  }, [equipmentOwnerId]);

  const isPerson = useCallback(() => formState.values.ownerType === 'PERSON', [formState.values.ownerType]);

  const handleValidateModal = (e) => {
    e.preventDefault();
    onSubmit({
      ...formState.values,
      firstName: formState.values.firstName || formState.values.institutionName,
      institutionIdentifiers: identifierFields,
      institutionIdentifier: identifierFields && identifierFields[0]?.value,
      possibleTypes: [LEGAL_ENTITY_TYPES.EQUIPMENT_OWNER],
      address: formState.values.address1 ? {
        address1: formState.values.address1,
        address2: formState.values.address2,
        postalCode: formState.values.postalCode,
        city: formState.values.city,
        country: formState.values.country
      } : null
    });
  };

  const handleChange = useCallback((_, value) => {
    if (value === null) return;
    formState.setField('ownerType', value);
    formState.setField('lastName', '');
    formState.setField('institutionIdentifier', '');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <form autoComplete="off" style={{ overflowY: 'hidden' }} onSubmit={handleValidateModal}>
        <ModalHeader onClose={onClose}>
          <FontAwesomeIcon icon={faUserPlus} />
          {equipmentOwnerId ? translate('modalEquipmentOwner.editEquipmentOwner') : translate('modalEquipmentOwner.newEquipmentOwner')}
        </ModalHeader>

        {isLoading ? (
          <SkeletonForm />
        ) : (
          <>
            <DialogContent style={{ minWidth: '700px' }}>
              <FlexContainer flexDirection="column">
                <Question label={translate('modalEquipmentOwner.equipmentOwnerType')}>
                  <ToggleButtonGroup
                    aria-label={translate('modalEquipmentOwner.equipmentOwnerType')}
                    exclusive
                    name="isPerson"
                    size="medium"
                    value={formState.values.ownerType}
                    {...text('ownerType')}
                    style={{ marginBottom: '1rem' }}
                    onChange={handleChange}
                  >
                    <ToggleButton aria-label={translate('common.institution')} disabled={!!equipmentOwnerId} value={'INSTITUTION'}>
                      <FontAwesomeIcon icon={faBuilding} />
                      {translate('common.institution')}
                    </ToggleButton>
                    <ToggleButton aria-label={translate('common.physicalPerson')} disabled={!!equipmentOwnerId} value={'PERSON'}>
                      <FontAwesomeIcon icon={faUser} />
                      {translate('common.physicalPerson')}
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {isPerson() ? (
                    <FormElement>
                      <Grid container>
                        <Grid item style={{ paddingRight: '10px' }}>
                          <TextField
                            autoComplete="new-password"
                            error={!!formState.errors.firstName}
                            label={translate('common.firstName')}
                            name="firstName"
                            required
                            {...text('firstName')}
                          />
                        </Grid>
                        <Grid item style={{ paddingLeft: '10px' }}>
                          <TextField
                            autoComplete="new-password"
                            error={!!formState.errors.lastName}
                            label={translate('common.lastName')}
                            name="lastName"
                            required
                            {...text('lastName')}
                          />
                        </Grid>
                      </Grid>
                    </FormElement>
                  ) : (
                    <FormCountryIdentifier
                      formState={formState}
                      identifierFields={identifierFields}
                      setIdentifierFields={setIdentifierFields}
                      text={text}
                    />
                  )}

                  <TextField
                    autoComplete="new-password"
                    error={formState.errors.email && formState.errors.email !== null}
                    helperText={formState.errors.email}
                    label={translate('common.email')}
                    name="email"
                    {...email({
                      name: 'email',
                      validate: value => FormHelper.validateEmail(value)
                    })}
                  />
                  {!isPerson() && (
                    <TextField
                      autoComplete="new-password"
                      label={translate('common.contactName')}
                      name="contactName"
                      {...text('contactName')}
                    />
                  )}

                  <FormAddress
                    formState={formState}
                    isRequired
                    text={text}
                    withCountry={isPerson()}
                  />
                  {/* This input is only used for forcing disabling autocomplete in chrome browser */}
                  <input name="fakeInputForForcingDisablingAutocompleteChromee" style={{ display: 'none' }} type="text" />
                </Question>
              </FlexContainer>
            </DialogContent>

            <CustomActionButtons
              forValidation={canValidate}
              isLoading={isLoading}
              onClose={onClose}
            />
          </>
        )}
      </form>
    </>
  );
};
